@if (this.showLink()) {
  @if (this.person()?.person_id) {
    <a
      class="block hover:text-[color:var(--primary-color)] hover:underline"
      [routerLink]="['/', 'staff', 'people', 'detail', this.person()?.person_id]">
      <span>{{ this.getFullName(this.person()) }}, Id:{{ this.person()?.person_id }}</span>
      <ng-content select="[link]"></ng-content>
    </a>
  }
} @else {
  <span
    >{{ this.getFullName(this.person()) }}, Id:{{ this.person()?.person_id
    }}<ng-content select="[no_link]"></ng-content
  ></span>
}
<ng-content select="[after]"></ng-content>
