import { Component, effect, inject, input, OnInit, ViewChild } from '@angular/core';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { TooltipModule } from 'primeng/tooltip';
import { NgxPermissionsModule } from 'ngx-permissions';
import {
  DataProviderOptionModel,
  GraphQlAdvancedFilterComponent,
  GraphQlAdvancedSortComponent,
  GraphQlAdvancedTableComponent,
  GraphQlColumnModel,
  GraphQlTableModel
} from '~ngx-shared/graph-ql';
import { TemplateDirective } from '~ngx-shared/directives';
import { PersonDetailLinkComponent } from '~madrasa/staff/components/person-detail-link/person-detail-link.component';
import { AcademyCourseStudentStatusEnum, AcademyUnitModel } from '~ngx-shared/models';
import { ModelUtil } from '~ngx-shared/utils';

@Component({
  selector: 'app-course-student-list',
  standalone: true,
  imports: [
    TranslocoDirective,
    GraphQlAdvancedTableComponent,
    TooltipModule,
    TemplateDirective,
    NgxPermissionsModule,
    GraphQlAdvancedFilterComponent,
    GraphQlAdvancedSortComponent,
    PersonDetailLinkComponent
  ],
  templateUrl: './course-student-list.component.html',
  styleUrl: './course-student-list.component.scss'
})
export class CourseStudentListComponent implements OnInit {
  readonly translocoService = inject(TranslocoService);

  showFilter = input<boolean>(true);
  showSort = input<boolean>(true);
  showExport = input<boolean>(true);
  showColumnFilter = input<boolean>(true);

  courseId = input<number>();

  stateKey = input<string>();
  patchOptions = input<(options: DataProviderOptionModel) => DataProviderOptionModel>();

  @ViewChild('advancedTable') tableComponent: GraphQlAdvancedTableComponent;

  graphQlPatchOptions: (options: DataProviderOptionModel) => DataProviderOptionModel;
  graphQlTable: GraphQlTableModel;

  constructor() {
    effect(() => {
      const courseId = this.courseId();

      this.graphQlPatchOptions = options => {
        const patchOptions = this.patchOptions();
        if (patchOptions) {
          options = patchOptions(options);
        }

        if (!options.filter) {
          options.filter = {};
        }
        if (!options.filter._and) {
          options.filter._and = [];
        }

        if (courseId && Array.isArray(options?.filter?._and)) {
          options.filter._and = options.filter._and.filter((cond: any) => !cond.course_id);
          options.filter._and.push({
            course_id: {
              _eq: courseId
            }
          });
        }

        if (!options.sortBy?.length) {
          options.sortBy = [{ created_at: 'desc_nulls_last' }];
        }

        return options;
      };
    });
  }

  ngOnInit() {
    const columns: GraphQlColumnModel[] = [
      {
        path: 'id',
        type: 'number',
        classHeader: 'text-center',
        classBody: 'text-center',
        sort: { isSortable: true },
        filter: { type: 'number' }
      },
      {
        label: 'student',
        path: 'current_person_data',
        query: `
            current_person_data {
              person_id
              academic_degree_prefix
              academic_degree_suffix
              first_name
              last_name
          }
        `,
        patchResult: value =>
          ModelUtil.getFullName(value?.current_person_data, this.translocoService),
        filter: { isNotFilterable: true },
        sort: { isSortable: false }
      },
      {
        label: 'course_student_status',
        path: 'status',
        translate: true,
        prefix: 'status.',
        classHeader: 'text-center',
        classBody: 'text-center',
        type: 'tag',
        patchResult: (result: any, column: GraphQlColumnModel) => {
          switch (result.status) {
            case AcademyCourseStudentStatusEnum.INACTIVE:
              column.severity = 'danger';
              break;
            case AcademyCourseStudentStatusEnum.ACTIVE:
              column.severity = 'success';
              break;
          }
          return 'status.' + result.status;
        },
        filter: {
          type: 'array',
          options: Object.values(AcademyCourseStudentStatusEnum).map(value => ({
            label: 'status.' + value,
            value: value
          }))
        },
        sort: { isSortable: true }
      },
      {
        label: 'created_by',
        path: 'created_by_current_person_data',
        query: `
            created_by_current_person_data {
              person_id
              academic_degree_prefix
              academic_degree_suffix
              first_name
              last_name
            }
        `,
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        isNotExportable: true
      },
      {
        path: 'created_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true }
      }
    ];

    this.graphQlTable = {
      table: 'academy_course_student',
      isPaginated: true,

      showCurrentPageReport: true,
      columns: columns
    };
  }

  getRow(data: any): AcademyUnitModel | undefined {
    return data;
  }
}
