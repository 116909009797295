import {
  Component,
  computed,
  ContentChildren,
  effect,
  input,
  QueryList,
  ViewChild
} from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { ButtonDirective } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { RouterLink } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs';
import {
  DataProviderOptionModel,
  GraphQlAdvancedFilterComponent,
  GraphQlAdvancedSortComponent,
  GraphQlAdvancedTableComponent,
  GraphQlColumnModel,
  GraphQlTableModel
} from '~ngx-shared/graph-ql';
import { TemplateDirective } from '~ngx-shared/directives';
import { ConfirmationService } from '~ngx-shared/layout';
import {
  AcademyOrganisationModel,
  CoreCountryEnum,
  CoreGenderEnum,
  CoreMaritalStatusEnum,
  CorePersonDataModel
} from '~ngx-shared/models';
import { ModelUtil } from '~ngx-shared/utils';

@Component({
  selector: 'app-person-list',
  standalone: true,
  imports: [
    TranslocoDirective,
    GraphQlAdvancedTableComponent,
    ButtonDirective,
    TooltipModule,
    RouterLink,
    TemplateDirective,
    GraphQlAdvancedFilterComponent,
    GraphQlAdvancedSortComponent
  ],
  templateUrl: './person-list.component.html',
  styleUrl: './person-list.component.scss'
})
export class PersonListComponent {
  @ContentChildren(TemplateDirective) templates: QueryList<TemplateDirective>;

  showCreateButton = input<boolean>(false);
  showDetailButton = input<boolean>(false);
  showUpdateButton = input<boolean>(false);
  showDeleteButton = input<boolean>(false);
  showFilter = input<boolean>(false);
  showSort = input<boolean>(false);
  showExport = input<boolean>(false);
  showColumnFilter = input<boolean>(true);
  stateKey = input<string>();
  route = input<string>('people');
  patchOptions = input<(options: DataProviderOptionModel) => DataProviderOptionModel>();
  deleteFunc = input<(options: { data: CorePersonDataModel; refresh: () => void }) => void>();
  columns = input<GraphQlColumnModel[]>([]);

  graphQlTable = computed(() => {
    let columns = this.columns();

    columns.unshift(...this.getDefaultColumns());

    const graphQlTable: GraphQlTableModel = {
      table: 'core_current_person_data',
      isPaginated: true,

      showCurrentPageReport: true,
      columns
    };
    return graphQlTable;
  });

  graphQlPatchOptions: (options: DataProviderOptionModel) => DataProviderOptionModel;
  @ViewChild('advancedTable') tableComponent: GraphQlAdvancedTableComponent;

  constructor(
    private confirmationService: ConfirmationService,
    private apollo: Apollo
  ) {
    effect(() => {
      const patchOptions = this.patchOptions();
      this.graphQlPatchOptions = options => {
        if (patchOptions) {
          options = patchOptions(options);
        }

        // Add default sorting by created_at
        if (!options.sortBy?.length) {
          options.sortBy = [{ created_at: 'desc_nulls_last' }];
        }

        return options;
      };
    });
  }

  getRow(data: any): CorePersonDataModel | undefined {
    return data;
  }

  delete(data: any) {
    const deleteFunc = this.deleteFunc();
    if (deleteFunc) {
      deleteFunc({ data, refresh: () => this.tableComponent?.updateTable() });
      return;
    }

    const row = this.getRow(data);
    if (row) {
      let mutationOptions: any = {
        mutation: gql`
          mutation SoftDeleteCorePersonDataById($personId: bigint!, $personDataId: bigint!) {
            result_person: update_core_person_by_pk(
              pk_columns: { id: $personId }
              _set: { deleted_at: "now()" }
            ) {
              __typename
            }
            result_person_data: update_core_person_data_by_pk(
              pk_columns: { id: $personDataId }
              _set: { deleted_at: "now()" }
            ) {
              __typename
            }
          }
        `,
        variables: {
          personId: row.person_id,
          personDataId: row.id
        }
      };

      this.confirmationService.confirmDeleteApollo({
        name: `${row.first_name} - ${row.last_name}`,
        mutationOptions,
        success: () => this.tableComponent?.updateTable()
      });
    }
  }

  getDefaultColumns(): GraphQlColumnModel[] {
    const columns: GraphQlColumnModel[] = [
      {
        path: 'id',
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        isNotExportable: true,
        isNotSelectable: true
      },
      {
        label: 'Id',
        path: 'person_id',
        type: 'number',
        classHeader: 'text-center',
        classBody: 'text-center',
        sort: { isSortable: true },
        filter: { type: 'number' }
      },
      {
        path: 'first_name',
        filter: { type: 'string' },
        sort: { isSortable: true }
      },
      {
        path: 'last_name',
        filter: { type: 'string' },
        sort: { isSortable: true }
      },
      {
        path: 'extra_name',
        filter: { type: 'string' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'date_of_birth',
        classHeader: 'text-center',
        classBody: 'text-center',
        type: 'date',
        filter: { type: 'date' },
        sort: { isSortable: true }
      },
      {
        path: 'place_of_birth',
        filter: { type: 'string' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'gender',
        translate: true,
        prefix: 'gender.',
        classHeader: 'text-center',
        classBody: 'text-center',
        type: 'tag',
        patchResult: (result: any, column: GraphQlColumnModel) => {
          switch (result.gender) {
            case CoreGenderEnum.FEMALE:
              column.severity = 'danger';
              break;
            case CoreGenderEnum.MALE:
              column.severity = 'info';
              break;
          }
          return 'gender.' + result.gender;
        },
        filter: {
          type: 'array',
          options: Object.values(CoreGenderEnum).map(value => ({
            label: 'gender.' + value,
            value: value
          }))
        },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'insurance_number',
        filter: { type: 'string' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'zmr_number',
        filter: { type: 'string' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'iban',
        filter: { type: 'string' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'bic',
        filter: { type: 'string' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'account_owner',
        filter: { type: 'string' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'profession',
        filter: { type: 'string' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'marital_status',
        translate: true,
        prefix: 'marital_status.',
        classHeader: 'text-center',
        classBody: 'text-center',
        filter: {
          type: 'array',
          options: Object.values(CoreMaritalStatusEnum).map(value => ({
            label: 'marital_status.' + value,
            value: value
          }))
        },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'citizenship',
        filter: {
          type: 'array',
          options: Object.keys(CoreCountryEnum).map(key => ({
            label: 'country_iso.' + key,
            value: key
          }))
        },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'address',
        patchResult: (result: any) => {
          return ModelUtil.getAddresses(result.address);
        },
        filter: {
          label: 'address',
          path: 'address._cast.String',
          type: 'json'
        },
        sort: { isSortable: false },
        hidden: true
      },
      {
        path: 'phone_number',
        patchResult: (result: any) => {
          return ModelUtil.getPhoneNumbers(result.phone_number);
        },
        filter: {
          label: 'phone_number',
          path: 'phone_number._cast.String',
          type: 'json'
        },
        sort: { isSortable: false },
        hidden: true
      },
      {
        path: 'email_address',
        patchResult: (result: any) => {
          return ModelUtil.getEmailAddresses(result.email_address);
        },
        filter: {
          label: 'email_address',
          path: 'email_address._cast.String',
          type: 'json'
        },
        sort: { isSortable: false },
        hidden: true
      },
      {
        label: 'organisations',
        path: 'organisation_persons_active.organisation.name',
        filter: {
          label: 'organisation',
          path: 'organisation_persons_active.organisation_id',
          type: 'array',
          options: this.apollo
            .query<{
              result: AcademyOrganisationModel[];
            }>({
              query: gql`
                query ReadAcademyOrganisation($where: academy_organisation_bool_exp) {
                  result: academy_organisation(where: $where) {
                    id
                    name
                  }
                }
              `,
              variables: {
                where: {}
              }
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(organisation => ({
                  label: organisation.name,
                  value: organisation.id
                }))
              )
            )
        },
        sort: { isSortable: false },
        hidden: true
      },
      {
        path: 'created_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'updated_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true },
        hidden: true
      }
    ];

    return columns;
  }
}
