import { Component, computed, inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslocoDirective } from '@jsverse/transloco';
import { TabViewModule } from 'primeng/tabview';
import { toSignal } from '@angular/core/rxjs-interop';
import { map, of, switchMap, tap } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { flatMap, keys } from 'lodash-es';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { DetailComponent, DetailField } from '~ngx-shared/ui/detail/detail.component';
import { TabViewQueryDirective } from '~ngx-shared/directives';
import { AccountingTeacherWageModel } from '~ngx-shared/models';
import { DatePipe } from '~ngx-shared/pipes';
import { ClassBookEntryListComponent } from '~madrasa/academy/components/class-book-entry-list/class-book-entry-list.component';
import { DataProviderOptionModel, GraphQlColumnModel } from '~ngx-shared/graph-ql';

@UntilDestroy()
@Component({
  selector: 'app-teacher-wage-detail-page',
  standalone: true,
  imports: [
    BasePageComponent,
    TranslocoDirective,
    DetailComponent,
    TabViewModule,
    TabViewQueryDirective,
    DatePipe,
    ClassBookEntryListComponent
  ],
  templateUrl: './teacher-wage-detail-page.component.html',
  styleUrl: './teacher-wage-detail-page.component.scss'
})
export class TeacherWageDetailPageComponent {
  readonly router = inject(Router);
  readonly apollo = inject(Apollo);
  readonly activatedRoute = inject(ActivatedRoute);
  readonly loadingService = inject(LoadingService);

  cbePatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;

  readonly item = toSignal(
    this.activatedRoute.data.pipe(
      untilDestroyed(this),
      tap(() => this.loadingService.startLoading()),
      switchMap(data => {
        const result = !!data?.['crud'] && !!data['crud']['id'];
        if (result) {
          return this.apollo
            .query<{ result: AccountingTeacherWageModel }>({
              query: gql`
                query ReadAccountingTeacherWageById($id: bigint!) {
                  result: accounting_teacher_wage_by_pk(id: $id) {
                    id
                    created_at
                    updated_at
                    person_id
                    current_person_data {
                      person_id
                      academic_degree_prefix
                      academic_degree_suffix
                      first_name
                      last_name
                    }
                    date
                    wage
                    wage_detail
                  }
                }
              `,
              variables: {
                id: data['crud']['id']
              }
            })
            .pipe(map(queryResult => queryResult.data?.result));
        } else {
          return of(undefined);
        }
      }),
      tap(() => this.loadingService.stopLoading())
    )
  );

  readonly fields = computed<DetailField[]>(() => {
    const item = this.item();
    if (!item) {
      return [];
    }

    // Get the cbe ids with lodash
    const cbeIds = flatMap(item.wage_detail?.organisations, org =>
      flatMap(org.schools, school => keys(school?.class_book_entries))
    );

    this.cbePatchOptions = options => {
      options.filter = {
        ...options.filter,
        _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
      };
      if (Array.isArray(options?.filter?._and)) {
        options.filter._and = options.filter._and.filter((cond: any) => !cond.id);
        options.filter._and.push({
          id: {
            _in: cbeIds
          }
        });
      }
      return options;
    };

    return [
      {
        label: 'id',
        value: item.id
      },
      {
        label: 'created_at',
        type: 'datetime',
        value: item.created_at
      },
      {
        label: 'updated_at',
        type: 'datetime',
        value: item.updated_at
      },
      {
        label: 'teacher',
        type: 'person_link',
        value: item.current_person_data
      },
      {
        label: 'date',
        type: 'date',
        value: item.date
      },
      {
        label: 'wage',
        type: 'currency',
        value: item.wage
      }
    ];
  });

  readonly cbeColumns = computed<GraphQlColumnModel[]>(() => {
    return [
      {
        path: 'school_helper',
        query: 'school { id }',
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        isNotExportable: true,
        isNotSelectable: true
      },
      {
        path: 'organisation_helper',
        query: 'school { organisation { id } }',
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        isNotExportable: true,
        isNotSelectable: true
      },
      {
        label: 'hourly_rate',
        path: 'id',
        type: 'currency',
        patchResult: (result: any) => {
          return this.item()?.wage_detail?.organisations?.[result?.school?.organisation.id]
            ?.schools?.[result?.school?.id]?.class_book_entries?.[result?.id]?.hourly_rate;
        },
        filter: { isNotFilterable: true },
        sort: { isSortable: false }
      }
    ];
  });
}
