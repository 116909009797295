import { Component, inject, OnInit } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { Router } from '@angular/router';
import { TabViewModule } from 'primeng/tabview';
import { AccountListComponent } from '~madrasa/accounting/components/account-list/account-list.component';
import { BasePageComponent } from '~ngx-shared/layout';
import { TabViewQueryDirective } from '~ngx-shared/directives';
import { AccountGroupListComponent } from '~madrasa/accounting/components/account-group-list/account-group-list.component';
import { AccountingPeriodService } from '~madrasa/accounting/services/accounting-period.service';

@Component({
  selector: 'app-account-plan-page',
  standalone: true,
  imports: [
    AccountListComponent,
    BasePageComponent,
    TranslocoDirective,
    AccountGroupListComponent,
    TabViewModule,
    TabViewQueryDirective
  ],
  templateUrl: './account-plan-page.component.html',
  styleUrl: './account-plan-page.component.scss'
})
export class AccountPlanPageComponent implements OnInit {
  readonly router = inject(Router);
  readonly accountingPeriodService = inject(AccountingPeriodService);

  ngOnInit() {
    this.accountingPeriodService.getPeriods().subscribe();
  }
}
