import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { catchError, delayWhen, finalize, Observable, of, timer } from 'rxjs';
import { Data } from '@angular/router';
import { environment } from '~ngx-shared/environment';
import { ToastService } from '~ngx-shared/layout';
import { FileService } from './file.service';

export type PrintStatus = {
  [key: string]: {
    isOpening?: boolean;
    isDownloading?: boolean;
  };
};

@Injectable({ providedIn: 'root' })
export class PrintService {
  readonly httpClient = inject(HttpClient);
  readonly toastService = inject(ToastService);
  readonly fileService = inject(FileService);

  readonly status = signal<PrintStatus>({});
  private externalServiceUrl = environment.externalServiceUrl;

  public post(params: {
    path: string;
    data: Data;
    openBlob?: boolean;
    fileNameToDownload?: string;
    httpParams?: HttpParams;
    showSuccessToast?: boolean;
  }): void {
    const status = this.status();
    if (!status[params.path]?.isOpening && !status[params.path]?.isDownloading) {
      status[params.path] = {
        isOpening: !!params.openBlob,
        isDownloading: !!params.fileNameToDownload
      };
      this.status.set(status);
      this._subscribe(
        params.path,
        this.httpClient.post(`${this.externalServiceUrl}/print/${params.path}`, params.data, {
          params: params.httpParams,
          responseType: 'blob'
        }),
        params.openBlob,
        params.fileNameToDownload,
        params.showSuccessToast
      );
    }
  }

  public get(params: {
    path: string;
    openBlob?: boolean;
    fileNameToDownload?: string;
    queryParams?: HttpParams;
  }): void {
    const status = this.status();
    if (!status[params.path]?.isOpening && !status[params.path]?.isDownloading) {
      status[params.path] = {
        isOpening: !!params.openBlob,
        isDownloading: !!params.fileNameToDownload
      };
      this.status.set(status);
      this._subscribe(
        params.path,
        this.httpClient.get(`${this.externalServiceUrl}/print/${params.path}`, {
          params: params.queryParams,
          responseType: 'blob'
        }),
        params.openBlob,
        params.fileNameToDownload,
        false
      );
    }
  }

  private _subscribe(
    path: string,
    observable: Observable<any>,
    openBlob: boolean = true,
    fileNameToDownload?: string,
    showSuccessToast?: boolean
  ): void {
    observable
      .pipe(
        finalize(() => {
          const status = this.status();
          status[path] = { isDownloading: false, isOpening: false };
          this.status.set(status);
        }),
        catchError(() => {
          return of(null);
        }),
        delayWhen(fileBlob => {
          if (fileBlob) {
            return timer(100);
          }
          return timer(0);
        })
      )
      .subscribe(fileBlob => {
        if (openBlob || fileNameToDownload) {
          this.fileService.openFileBlob(fileBlob, fileNameToDownload);
        } else if (showSuccessToast) {
          this.toastService.toastSuccessSave();
        }
      });
  }

  readonly PAPER_TEMPLATE = 'paper-templates/';
  paperTemplate(id: number | undefined): void {
    this.get({ path: this.PAPER_TEMPLATE + id, openBlob: true });
  }

  readonly CASH_BOOK_ENTRY = 'cash-book-entries/';
  cashBookEntry(id: number | undefined, fileName?: string | undefined): void {
    this.get({ path: this.CASH_BOOK_ENTRY + id, openBlob: true, fileNameToDownload: fileName });
  }

  readonly CERTIFICATE = 'certificates/';
  certificate(id: number | undefined, fileName?: string | undefined): void {
    this.get({ path: this.CERTIFICATE + id, openBlob: true, fileNameToDownload: fileName });
  }
}
