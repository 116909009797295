import {
  AfterContentInit,
  Component,
  ContentChildren,
  QueryList,
  TemplateRef,
  Type
} from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyFieldSelectProps } from '@ngx-formly/core/select';
import { TemplateUtil } from '~ngx-shared/utils';
import { TemplateDirective } from '~ngx-shared/directives';
import { FieldWrapperProps } from '~ngx-shared/formly/field-wrapper/field-wrapper.component';

export interface AutoCompleteSelectProps extends FieldWrapperProps, FormlyFieldSelectProps {
  [key: string]: any;
  virtualScroll?: boolean;
  virtualScrollSize?: number;

  forceSelection?: boolean;
  multiple?: boolean;
  optionLabel?: string | (string | ((item: any) => string));

  clickLabel?: string;
  clickIcon?: string;
  clickClass?: string;

  completeMethod?(
    event: { originalEvent: Event; query: string },
    field: FieldTypeConfig<AutoCompleteSelectProps>
  ): void;

  clickMethod?(event: MouseEvent, field: FieldTypeConfig<AutoCompleteSelectProps>): void;
}

export interface AutoCompleteSelectFieldConfig extends FormlyFieldConfig<AutoCompleteSelectProps> {
  type?: 'autocomplete-select' | Type<AutoCompleteSelectTypeComponent>;
}

@Component({
  selector: 'lib-formly-auto-complete-select-type',
  templateUrl: './auto-complete-select-type.component.html',
  styleUrl: './auto-complete-select-type.component.css'
})
export class AutoCompleteSelectTypeComponent
  extends FieldType<FieldTypeConfig<AutoCompleteSelectProps>>
  implements AfterContentInit
{
  @ContentChildren(TemplateDirective) templates: QueryList<TemplateDirective>;

  headerTemplate: TemplateRef<any> | null = null;
  emptyTemplate: TemplateRef<any> | null = null;
  selectedTemplate: TemplateRef<any> | null = null;
  suggestionTemplate: TemplateRef<any> | null = null;
  footerTemplate: TemplateRef<any> | null = null;

  ngAfterContentInit(): void {
    TemplateUtil.setTemplates(this, this.templates, [
      {
        template: 'headerTemplate',
        name: 'header'
      },
      {
        template: 'suggestionTemplate',
        name: 'suggestion'
      },
      {
        template: 'selectedTemplate',
        name: 'selected'
      },
      {
        template: 'emptyTemplate',
        name: 'empty'
      },
      {
        template: 'footerTemplate',
        name: 'footer'
      }
    ]);
  }

  getValueAsString(data: any, field: string): string {
    return TemplateUtil.getValueAsString(data, field);
  }
}
