<div *transloco="let transloco">
  <p-tabView libTabViewQuery="t">
    <p-tabPanel header="{{ transloco('date') }}">
      <ng-template pTemplate="content">
        <div class="flex flex-col gap-6">
          <span class="block text-3xl">
            {{ transloco('date') }}
            @if (this.nowModel.date) {
              - {{ this.nowModel.date | libDate }}
            }
          </span>

          @if (!this.nowResult()) {
            <lib-formly-form
              class="w-full lg:w-3/4 xl:w-4/6"
              (savedEvent)="this.nowSavedEvent($event)"
              [fields]="this.nowFields"
              [form]="this.nowForm"
              [model]="this.nowModel"
              [navigateBackOnCancel]="false"
              [navigateBackOnSave]="false"
              [options]="this.nowOptions"
              [saveButtonText]="'calculate'"
              [showCancelButton]="false"
              [showToastMessages]="false"
              [submit]="this.nowSubmit">
            </lib-formly-form>
          } @else {
            <app-student-list
              [columns]="this.nowColumns()"
              [stateKey]="this.router.url + 'course-invoice-list-student-list'"></app-student-list>

            <div class="flex gap-2">
              <button
                (click)="this.nowDebitBalance($event)"
                [label]="transloco('debit_balance')"
                [loading]="this.isBusy()"
                class=""
                icon="pi pi-check-circle"
                pButton
                type="button"></button>

              <button
                (click)="this.nowReset()"
                [label]="transloco('reset')"
                [loading]="this.isBusy()"
                class="p-button-danger"
                icon="pi pi-undo"
                pButton
                type="button"></button>
            </div>
          }
        </div>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel header="{{ transloco('retroactive') }}">
      <ng-template pTemplate="content">
        <div class="flex flex-col gap-6">
          <span class="block text-3xl">
            {{ transloco('retroactive') }}
            @if (this.retroactiveModel.input_date) {
              - {{ this.retroactiveModel.input_date | libDate: 'MM/yyyy' }}
            }
          </span>

          @if (!this.retroactiveResult()) {
            <lib-formly-form
              class="w-full lg:w-3/4 xl:w-4/6"
              (savedEvent)="this.retroactiveSavedEvent($event)"
              [fields]="this.retroactiveFields"
              [form]="this.retroactiveForm"
              [model]="this.retroactiveModel"
              [navigateBackOnCancel]="false"
              [navigateBackOnSave]="false"
              [options]="this.retroactiveOptions"
              [saveButtonText]="'calculate'"
              [showCancelButton]="false"
              [showToastMessages]="false"
              [submit]="this.retroactiveSubmit">
            </lib-formly-form>
          } @else {
            <app-student-list
              [columns]="this.retroactiveColumns()"
              [stateKey]="this.router.url + 'course-invoice-list-student-list'"></app-student-list>

            <div class="flex gap-2">
              <button
                (click)="this.retroactiveDebitBalance($event)"
                [label]="transloco('debit_balance')"
                [loading]="this.isBusy()"
                class=""
                icon="pi pi-check-circle"
                pButton
                type="button"></button>

              <button
                (click)="this.retroactiveReset()"
                [label]="transloco('reset')"
                [loading]="this.isBusy()"
                class="p-button-danger"
                icon="pi pi-undo"
                pButton
                type="button"></button>
            </div>
          }
        </div>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</div>
