import { Component, inject, OnInit } from '@angular/core';
import { ButtonDirective } from 'primeng/button';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslocoDirective } from '@jsverse/transloco';
import { Router, RouterLink } from '@angular/router';
import { BasePageComponent, BusyComponent } from '~ngx-shared/layout';
import { EntryListComponent } from '~madrasa/accounting/components/entry-list/entry-list.component';
import { AccountingPeriodService } from '~madrasa/accounting/services/accounting-period.service';

@Component({
  selector: 'app-entry-list-page',
  standalone: true,
  imports: [
    BasePageComponent,
    TranslocoDirective,
    ButtonDirective,
    NgxPermissionsModule,
    RouterLink,
    BusyComponent,
    EntryListComponent
  ],
  templateUrl: './entry-list-page.component.html',
  styleUrl: './entry-list-page.component.scss'
})
export class EntryListPageComponent implements OnInit {
  readonly router = inject(Router);
  readonly accountingPeriodService = inject(AccountingPeriodService);

  ngOnInit() {
    this.accountingPeriodService.getPeriods().subscribe();
  }
}
