import { Component, computed, effect, inject } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { PrimeTemplate } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { toSignal } from '@angular/core/rxjs-interop';
import { map, of, switchMap, tap } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ButtonDirective } from 'primeng/button';
import { NgxPermissionsModule } from 'ngx-permissions';
import {
  AcademyClassBookEntryModel,
  AcademyClassBookEntryPermission,
  AcademyClassBookEntryStudentStatusEnum,
  AcademyWeekdayEnum
} from '~ngx-shared/models';
import { TabViewQueryDirective } from '~ngx-shared/directives';
import { DetailComponent, DetailField } from '~ngx-shared/ui/detail/detail.component';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { ModelUtil } from '~ngx-shared/utils';
import { CourseDetailComponent } from '~madrasa/academy/components/course-detail/course-detail.component';
import { UnitDetailComponent } from '~madrasa/academy/components/unit-detail/unit-detail.component';
import { PersonListComponent } from '~madrasa/staff/components/person-list/person-list.component';
import { DataProviderOptionModel, GraphQlColumnModel } from '~ngx-shared/graph-ql';
import { FormlyUtil } from '~ngx-shared/formly';
import { AuthorizationService, Role } from '~ngx-shared/authentication';

@UntilDestroy()
@Component({
  selector: 'app-class-book-entry-detail-page',
  standalone: true,
  imports: [
    BasePageComponent,
    TranslocoDirective,
    DetailComponent,
    PrimeTemplate,
    TabViewModule,
    TabViewQueryDirective,
    CourseDetailComponent,
    UnitDetailComponent,
    ButtonDirective,
    NgxPermissionsModule,
    RouterLink,
    PersonListComponent
  ],
  templateUrl: './class-book-entry-detail-page.component.html',
  styleUrl: './class-book-entry-detail-page.component.scss'
})
export class ClassBookEntryDetailPageComponent {
  readonly router = inject(Router);
  readonly authorizationService = inject(AuthorizationService);

  readonly item = toSignal(
    this.activatedRoute.data.pipe(
      untilDestroyed(this),
      tap(() => this.loadingService.startLoading()),
      switchMap(data => {
        const result = !!data?.['crud'] && !!data['crud']['id'];
        if (result) {
          return this.apollo
            .query<{ result: AcademyClassBookEntryModel }>({
              query: gql`
                query ReadAcademyClassBookEntryById($id: bigint!) {
                  result: academy_class_book_entry_by_pk(id: $id) {
                    id
                    created_at
                    updated_at
                    date
                    subject_matter
                    homework
                    is_online
                    expires_at
                    school {
                      name
                      organisation {
                        name
                      }
                    }
                    course {
                      id
                      name
                      school_period {
                        name
                      }
                    }
                    unit {
                      id
                      weekday
                      starts_at
                      ends_at
                      subject {
                        name
                      }
                    }
                    class_book_entry_students_aggregate {
                      aggregate {
                        count
                      }
                    }

                    class_book_entry_teachers_aggregate {
                      aggregate {
                        count
                      }
                    }
                  }
                }
              `,
              variables: {
                id: data['crud']['id']
              }
            })
            .pipe(map(queryResult => queryResult.data?.result));
        } else {
          return of(undefined);
        }
      }),
      tap(() => this.loadingService.stopLoading())
    )
  );
  readonly fields = computed<DetailField[]>(() => {
    const item = this.item();
    if (!item) {
      return [];
    }
    return [
      {
        label: 'id',
        value: item.id
      },
      {
        label: 'created_at',
        type: 'datetime',
        value: item.created_at
      },
      {
        label: 'updated_at',
        type: 'datetime',
        value: item.updated_at
      },
      {
        label: 'school_period',
        value: item.course?.school_period?.name
      },
      {
        label: 'date',
        type: 'date',
        value: item.date
      },
      {
        label: 'expires_at',
        type: 'date',
        value: item.expires_at
      },
      {
        label: 'organisation',
        value: item.school?.organisation?.name
      },
      {
        label: 'school',
        value: item.school?.name
      },
      {
        label: 'course',
        value: item.course?.name
      },
      {
        label: 'subject',
        value: item.unit?.subject?.name
      },
      {
        label: 'is_online',
        type: 'boolean',
        value: item.is_online
      },
      {
        label: 'weekday',
        translate: true,
        value: 'weekday.' + ModelUtil.getEnumKeyByValue(AcademyWeekdayEnum, item.unit?.weekday)
      },
      {
        label: 'starts_at',
        value: item.unit?.starts_at
      },
      {
        label: 'ends_at',
        value: item.unit?.ends_at
      },
      {
        label: 'students',
        value: item.class_book_entry_students_aggregate?.aggregate?.count
      },
      {
        label: 'teachers',
        value: item.class_book_entry_teachers_aggregate?.aggregate?.count
      },
      {
        label: 'subject_matter',
        value: item.subject_matter
      },
      {
        label: 'homework',
        value: item.homework
      }
    ];
  });

  readonly isExpired = computed(() => {
    const item = this.item();
    if (!item?.date) {
      return false;
    }
    const date = FormlyUtil.fromIsoDateString(item?.expires_at);

    return this.authorizationService.cannot(Role.TEACHER) || (date && date >= new Date());
  });

  readonly studentColumns = computed<GraphQlColumnModel[]>(() => {
    const item = this.item();
    if (!item) {
      return [];
    }
    return [
      {
        path: 'class_book_entry_students.status',
        query: `class_book_entry_students (where: { class_book_entry_id: { _eq: ${this.item()?.id} } }) { status }`,
        translate: true,
        classHeader: 'text-center',
        classBody: 'text-center',
        type: 'tag',
        patchResult: (result: any, column: GraphQlColumnModel) => {
          switch (result.class_book_entry_students[0].status) {
            case AcademyClassBookEntryStudentStatusEnum.ABSENT:
              column.severity = 'danger';
              break;
            case AcademyClassBookEntryStudentStatusEnum.EXCUSED:
              column.severity = 'info';
              break;
            case AcademyClassBookEntryStudentStatusEnum.PRESENT:
              column.severity = 'success';
              break;
          }
          return result.class_book_entry_students[0].status;
        },
        filter: {
          type: 'array',
          options: Object.values(AcademyClassBookEntryStudentStatusEnum).map(value => ({
            label: value,
            value: value
          }))
        },
        sort: { isSortable: true }
      }
    ];
  });

  readonly teacherColumns = computed<GraphQlColumnModel[]>(() => {
    const item = this.item();
    if (!item) {
      return [];
    }
    return [
      {
        label: 'class_book_entry_teachers.present',
        query: `class_book_entry_teachers (where: { class_book_entry_id: { _eq: ${this.item()?.id} } }) { present }`,
        type: 'boolean',
        classHeader: 'text-center',
        classBody: 'text-center',
        filter: {
          type: 'boolean'
        },
        patchResult: value => value.class_book_entry_teachers?.[0]?.present,
        sort: { isSortable: true }
      }
    ];
  });

  studentPatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  teacherPatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  protected readonly AcademyClassBookEntryPermission = AcademyClassBookEntryPermission;

  constructor(
    private apollo: Apollo,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService
  ) {
    effect(() => {
      const id = this.item()?.id;
      if (id) {
        this.studentPatchOptions = options => {
          options.filter = {
            ...options.filter,
            _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
          };
          if (Array.isArray(options?.filter?._and)) {
            options.filter._and = options.filter._and.filter(
              (cond: any) => !cond.class_book_entry_students
            );
            options.filter._and.push({
              class_book_entry_students: {
                class_book_entry_id: {
                  _eq: this.item()?.id
                }
              }
            });
          }

          // Add default sorting
          if (!options.sortBy?.length) {
            options.sortBy = [{ first_name: 'asc_nulls_last' }];
          }
          return options;
        };

        this.teacherPatchOptions = options => {
          options.filter = {
            ...options.filter,
            _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
          };
          if (Array.isArray(options?.filter?._and)) {
            options.filter._and = options.filter._and.filter(
              (cond: any) => !cond.class_book_entry_teachers
            );
            options.filter._and.push({
              class_book_entry_teachers: {
                class_book_entry_id: {
                  _eq: this.item()?.id
                }
              }
            });
          }

          // Add default sorting
          if (!options.sortBy?.length) {
            options.sortBy = [{ first_name: 'asc_nulls_last' }];
          }
          return options;
        };
      }
    });
  }
}
