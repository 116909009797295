import { Component, inject, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AbstractControl, FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Apollo } from 'apollo-angular';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { of } from 'rxjs';
import { TranslocoService } from '@jsverse/transloco';
import { cloneDeep } from 'lodash-es';
import { FormlyService } from '~madrasa/services';
import {
  AccountingAccountCategoryEnum,
  AccountingAccountModel,
  AccountingCashBookEntryTypeEnum,
  AccountingEntryRowTypeEnum
} from '~ngx-shared/models';
import {
  BankDataImport,
  BankDataImportActionType,
  BankDataImportItem
} from '~madrasa/accounting/services/bank-data-import.service';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';

@UntilDestroy()
@Component({
  selector: 'app-create-update-bank-data-import-action-form-dialog',
  standalone: true,
  imports: [FormlyModule],
  templateUrl: './create-update-bank-data-import-action-form-dialog.component.html',
  styleUrl: './create-update-bank-data-import-action-form-dialog.component.scss'
})
export class CreateUpdateBankDataImportActionFormDialogComponent implements OnInit {
  readonly apollo = inject(Apollo);
  readonly dialogRef = inject(DynamicDialogRef);
  readonly dialogConfig = inject(DynamicDialogConfig);
  readonly formlyService = inject(FormlyService);
  readonly translocoService = inject(TranslocoService);

  form = new FormGroup({});
  model: BankDataImportActionType = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.create_update_bank_data_import_action'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  accounts?: AccountingAccountModel[] = [];
  importData?: BankDataImport;
  item?: BankDataImportItem;

  ngOnInit(): void {
    this.accounts = this.dialogConfig.data?.accounts;
    this.importData = this.dialogConfig.data?.importData;
    this.item = this.dialogConfig.data?.item;

    this.model = cloneDeep(this.item?.action) || {};

    const category: AccountingAccountCategoryEnum =
      this.item?.type === AccountingCashBookEntryTypeEnum.INCOMING
        ? AccountingAccountCategoryEnum.INCOME
        : AccountingAccountCategoryEnum.EXPENSE;

    const options = this.accounts
      ?.filter(
        (account: AccountingAccountModel) =>
          account?.account_group?.category === category ||
          account?.account_group?.category === AccountingAccountCategoryEnum.ACTIVE
      )
      ?.map(account => ({
        label: account.full_name,
        value: account.id
      }));

    function getSum(array: any | undefined): number {
      if (array && Array.isArray(array)) {
        return array.reduce((n: number, k: any) => n + (k.amount || 0), 0);
      }
      return 0;
    }

    this.fields = [
      FormlyUtil.createRepeat(
        {
          key: 'rows',
          defaultValue: [{ amount: this.item?.amount }],
          props: {
            label: String(
              this.item?.type === AccountingCashBookEntryTypeEnum.INCOMING
                ? AccountingEntryRowTypeEnum.CREDIT
                : AccountingEntryRowTypeEnum.DEBIT
            ),
            addTextValue: 'amount'
          },
          validators: {
            rows: {
              expression: (control: AbstractControl, field: FormlyFieldConfig) => {
                const sum = getSum(control.value);
                if (field.props) {
                  field.props['_over'] = sum > (this.item?.amount || 0);
                  field.props['_under'] = sum < (this.item?.amount || 0);
                }
                return !(field.props?.['_over'] || field.props?.['_under']);
              },

              message: (error: any, field: FormlyFieldConfig) => {
                if (field.props?.['_over']) {
                  return this.translocoService.selectTranslate(
                    'madrasa.forms.create_update_bank_data_import_action.sum_over_error'
                  );
                }
                if (field.props?.['_under']) {
                  return this.translocoService.selectTranslate(
                    'madrasa.forms.create_update_bank_data_import_action.sum_under_error'
                  );
                }
                return '';
              }
            }
          }
        },
        [
          FormlyUtil.createNumberField('amount', {
            props: {
              required: true,
              maxFractionDigits: 2,
              iconPre: 'pi pi-euro',
              min: 0
            }
          }),
          FormlyUtil.createSelectField('account_id', {
            props: {
              label: 'account',
              required: true,
              showClear: true,
              canFilter: true,
              options
            }
          })
        ]
      ),
      FormlyUtil.createGroup('student_balances', [
        FormlyUtil.createRepeat(
          {
            key: 'student_balances',
            props: {
              hideLabel: true,
              disableMargin: true,
              disableTopMargin: true,
              addTextValue: 'student'
            },
            validators: {
              rows: {
                expression: (control: AbstractControl, field: FormlyFieldConfig) => {
                  if (control.value?.length > 0) {
                    const sum = control.value?.reduce((acc: any, curr: any) => {
                      return acc + getSum(curr.balances);
                    }, 0);
                    if (field.props) {
                      field.props['_over'] = sum > (this.item?.amount || 0);
                      field.props['_under'] = sum < (this.item?.amount || 0);
                    }
                    return !(field.props?.['_over'] || field.props?.['_under']);
                  }
                  return true;
                },

                message: (error: any, field: FormlyFieldConfig) => {
                  if (field.props?.['_over']) {
                    return this.translocoService.selectTranslate(
                      'madrasa.forms.create_update_bank_data_import_action.sum_over_error'
                    );
                  }
                  if (field.props?.['_under']) {
                    return this.translocoService.selectTranslate(
                      'madrasa.forms.create_update_bank_data_import_action.sum_under_error'
                    );
                  }
                  return '';
                }
              }
            }
          },
          [
            {
              ...this.formlyService.createPersonFieldConfig({
                required: false,
                forceSelection: true,
                conditions: field => {
                  return [{ latest_student: {} }];
                }
              })
            },
            FormlyUtil.createRepeat(
              {
                key: 'balances',
                hide: true,
                props: {
                  hideLabel: true,
                  disableMargin: true,
                  disableTopMargin: true,
                  initialCount: 1,
                  addTextValue: 'amount'
                },
                expressions: {
                  hide: field => {
                    return field.parent?.model?.person_id === undefined;
                  }
                }
              },
              [
                FormlyUtil.createRow([
                  FormlyUtil.createNumberField('amount', {
                    props: {
                      required: true,
                      maxFractionDigits: 2,
                      iconPre: 'pi pi-euro',
                      min: 0
                    }
                  })
                ]),
                FormlyUtil.createRow([
                  FormlyUtil.createDatePickerField('month_of_balance', {
                    props: {
                      label: 'for_month',
                      dateFormat: 'mm/yy',
                      view: 'month',
                      showButtonBar: false,
                      required: true
                    }
                  })
                ])
              ]
            )
          ]
        )
      ]),
      FormlyUtil.createRow([
        this.formlyService.createFileUploadFieldConfig('receipt_documents', {
          props: {
            label: 'receipts',
            namespace: 'receipt_document',
            multiple: true
          }
        })
      ])
    ];

    this.submit = (formSaveModel: FormSaveModel) => {
      return of(formSaveModel.input);
    };
  }

  savedEvent(formSaveModel: FormSaveModel) {
    if (!formSaveModel.hasError) {
      this.dialogRef.close(formSaveModel);
    }
  }

  canceled($event: boolean) {
    if (this.dialogRef) {
      this.dialogRef.close(undefined);
    }
  }
}
