import { Component, computed, effect, input, OnInit, ViewChild } from '@angular/core';
import { ButtonDirective } from 'primeng/button';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslocoDirective } from '@jsverse/transloco';
import { TooltipModule } from 'primeng/tooltip';
import { Apollo, gql } from 'apollo-angular';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import {
  DataProviderOptionModel,
  GraphQlAdvancedFilterComponent,
  GraphQlAdvancedSortComponent,
  GraphQlAdvancedTableComponent,
  GraphQlColumnModel,
  GraphQlTableModel
} from '~ngx-shared/graph-ql';
import { TemplateDirective } from '~ngx-shared/directives';
import { ConfirmationService } from '~ngx-shared/layout';
import { Role } from '~ngx-shared/authentication';
import { NoteModel } from '~ngx-shared/models/note';
import { PersonDetailLinkComponent } from '~madrasa/staff/components/person-detail-link/person-detail-link.component';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';

@Component({
  selector: 'app-note-list',
  standalone: true,
  imports: [
    ButtonDirective,
    GraphQlAdvancedFilterComponent,
    GraphQlAdvancedSortComponent,
    GraphQlAdvancedTableComponent,
    NgxPermissionsModule,
    TemplateDirective,
    TranslocoDirective,
    TooltipModule,
    PersonDetailLinkComponent,
    FormlyModule
  ],
  templateUrl: './note-list.component.html',
  styleUrl: './note-list.component.scss'
})
export class NoteListComponent implements OnInit {
  showFilter = input<boolean>(true);
  showSort = input<boolean>(true);

  stateKey = input<string>();
  patchOptions = input<(options: DataProviderOptionModel) => DataProviderOptionModel>();
  columns = input<GraphQlColumnModel[]>([]);
  table = input.required<string>();

  graphQlTable = computed(() => {
    let columns = this.columns();

    columns.unshift(...this.getDefaultColumns());

    // Push message column to the end
    columns.push({
      label: 'note',
      path: 'message',
      filter: { type: 'string' },
      sort: { isSortable: false }
    });

    const graphQlTable: GraphQlTableModel = {
      table: this.table(),
      isPaginated: true,

      showCurrentPageReport: true,
      columns
    };
    return graphQlTable;
  });

  @ViewChild('advancedTable') tableComponent: GraphQlAdvancedTableComponent;

  form = new FormGroup({});
  model: NoteModel = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.create_note'
    }
  };
  fields: FormlyFieldConfig[];
  patchInput = input<(formSaveModel: FormSaveModel, input: any) => any>();
  submit: FormSubmitModel;

  graphQlPatchOptions: (options: DataProviderOptionModel) => DataProviderOptionModel;
  protected readonly Role = Role;

  constructor(
    private apollo: Apollo,
    private confirmationService: ConfirmationService
  ) {
    effect(() => {
      this.graphQlPatchOptions = options => {
        const patchOptions = this.patchOptions();
        if (patchOptions) {
          options = patchOptions(options);
        }

        // Add default sorting by updated_at
        if (!options.sortBy?.length) {
          options.sortBy = [{ updated_at: 'desc_nulls_last' }];
        }

        return options;
      };
    });
  }

  ngOnInit() {
    this.fields = [
      FormlyUtil.createRow([
        FormlyUtil.createEditorField('message', {
          props: {
            label: 'new_note',
            required: true
          }
        })
      ])
    ];

    this.submit = (formSaveModel: FormSaveModel) => {
      let input = {
        ...formSaveModel.input
      };

      const patchInput = this.patchInput();
      if (patchInput) {
        input = patchInput(formSaveModel, input);
      }

      return this.apollo.mutate({
        mutation: gql`
          mutation CreateNote(
            $input: ${this.table()}_insert_input!
          ) {
            result: insert_${this.table()}_one(object: $input) {
              __typename
            }
          }
        `,
        variables: {
          input
        }
      });
    };
  }

  getDefaultColumns(): GraphQlColumnModel[] {
    return [
      {
        path: 'id',
        type: 'number',
        classHeader: 'text-center w-1/12',
        classBody: 'text-center',
        sort: { isSortable: true },
        filter: { type: 'number' }
      },
      {
        path: 'created_at',
        classHeader: 'w-1/12',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true }
      },
      {
        label: 'created_by',
        classHeader: 'w-1/12',
        path: 'created_by_current_person_data',
        query: `
            created_by_current_person_data {
              person_id
              academic_degree_prefix
              academic_degree_suffix
              first_name
              last_name
            }
        `,
        filter: { isNotFilterable: true },
        sort: { isSortable: false }
      }
      // {
      //   path: 'updated_at',
      //   type: 'datetime',
      //   filter: { type: 'date' },
      //   sort: { isSortable: true },
      //   hidden: true
      // },
    ];
  }

  getRow(data: any): NoteModel | undefined {
    return data;
  }

  savedEvent(formSaveModel: FormSaveModel) {
    this.tableComponent?.updateTable();
    this.form.reset();
  }

  delete(data: any) {
    const row = this.getRow(data);
    if (row) {
      let mutation: any = gql`
        mutation SoftDeleteNoteNoteById($id: bigint!) {
          result: update_note_note_by_pk(pk_columns: { id: $id }, _set: { deleted_at: "now()" }) {
            __typename
          }
        }
      `;

      this.confirmationService.confirmDeleteApollo({
        name: String(row.id),
        mutationOptions: {
          mutation,
          variables: {
            id: row.id
          }
        },
        success: () => this.tableComponent?.updateTable()
      });
    }
  }
}
