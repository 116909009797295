import { Component, computed, effect, inject, input, ViewChild } from '@angular/core';
import { ButtonDirective } from 'primeng/button';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TooltipModule } from 'primeng/tooltip';
import { MenuModule } from 'primeng/menu';
import { gql } from 'apollo-angular';
import { ConfirmationService } from '~ngx-shared/layout';
import { AccountingAccountCategoryEnum, AccountingAccountGroupModel } from '~ngx-shared/models';
import { TemplateDirective } from '~ngx-shared/directives';
import {
  DataProviderOptionModel,
  GraphQlAdvancedFilterComponent,
  GraphQlAdvancedSortComponent,
  GraphQlAdvancedTableComponent,
  GraphQlColumnModel,
  GraphQlTableModel
} from '~ngx-shared/graph-ql';

@Component({
  selector: 'app-account-group-list',
  standalone: true,
  imports: [
    GraphQlAdvancedFilterComponent,
    GraphQlAdvancedSortComponent,
    GraphQlAdvancedTableComponent,
    NgxPermissionsModule,
    TemplateDirective,
    TranslocoDirective,
    TooltipModule,
    MenuModule,
    ButtonDirective
  ],
  templateUrl: './account-group-list.component.html',
  styleUrl: './account-group-list.component.scss'
})
export class AccountGroupListComponent {
  readonly confirmationService = inject(ConfirmationService);

  showFilter = input<boolean>(false);
  showSort = input<boolean>(false);
  showExport = input<boolean>(false);
  showColumnFilter = input<boolean>(true);
  showCreateButton = input<boolean>(true);

  stateKey = input<string>();
  patchOptions = input<(options: DataProviderOptionModel) => DataProviderOptionModel>();
  columns = input<GraphQlColumnModel[]>([]);
  accountPeriodId = input.required<number>();

  graphQlTable = computed(() => {
    let columns = this.columns();

    columns.unshift(...this.getDefaultColumns());

    const graphQlTable: GraphQlTableModel = {
      table: 'accounting_account_group',
      isPaginated: true,

      showCurrentPageReport: true,
      columns
    };
    return graphQlTable;
  });
  @ViewChild('advancedTable') tableComponent: GraphQlAdvancedTableComponent;

  graphQlPatchOptions: (options: DataProviderOptionModel) => DataProviderOptionModel;

  constructor() {
    effect(() => {
      this.graphQlPatchOptions = options => {
        const currentPeriodId = this.accountPeriodId();
        const patchOptions = this.patchOptions();
        if (patchOptions) {
          options = patchOptions(options);
        }

        if (!options.filter) {
          options.filter = {};
        }
        if (!options.filter._and) {
          options.filter._and = [];
        }

        if (Array.isArray(options?.filter?._and)) {
          options.filter._and = options.filter._and.filter(
            (cond: any) => !cond.accounting_period_id
          );
          options.filter._and.push({
            accounting_period_id: {
              _eq: currentPeriodId
            }
          });
        }

        // Add default sorting by updated_at
        if (!options.sortBy?.length) {
          options.sortBy = [
            { number: 'asc_nulls_last' },
            { category: 'asc_nulls_last' },
            { name: 'asc_nulls_last' }
          ];
        }

        return options;
      };
    });
  }

  getDefaultColumns(): GraphQlColumnModel[] {
    const columns: GraphQlColumnModel[] = [
      {
        path: 'id',
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        isNotExportable: true,
        isNotSelectable: true
      },
      {
        label: 'name',
        path: 'full_name',
        filter: { isNotFilterable: true },
        sort: { isSortable: false }
      },
      {
        path: 'category',
        type: 'tag',
        classHeader: 'text-center',
        classBody: 'text-center',
        translate: true,
        prefix: 'account_category.',
        patchResult: (result: any, column: GraphQlColumnModel) => {
          switch (result?.category) {
            case AccountingAccountCategoryEnum.ACTIVE:
              column.severity = 'success';
              break;
            case AccountingAccountCategoryEnum.PASSIVE:
              column.severity = 'danger';
              break;
            case AccountingAccountCategoryEnum.INCOME:
              column.severity = 'info';
              break;
            case AccountingAccountCategoryEnum.EXPENSE:
              column.severity = 'warning';
              break;
          }
          return 'account_category.' + result?.category;
        },
        filter: {
          type: 'array',
          options: Object.values(AccountingAccountCategoryEnum).map(value => ({
            label: 'account_category.' + value,
            value: value
          }))
        },
        sort: { isSortable: true }
      },
      {
        label: 'amount',
        classHeader: 'text-center',
        classBody: 'text-center',
        path: 'sum_amount',
        type: 'currency',
        filter: { isNotFilterable: true },
        sort: { isSortable: true }
      },
      {
        label: 'account_count',
        classHeader: 'text-center',
        classBody: 'text-center',
        path: 'accounts_aggregate.count',
        type: 'number',
        query: `accounts_aggregate { aggregate { count } }`,
        patchResult: (result: any) => result?.accounts_aggregate?.aggregate?.count,
        filter: { isNotFilterable: true },
        sort: { isSortable: false }
      },
      {
        path: 'created_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true }
      },
      {
        path: 'updated_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true },
        hidden: true
      }
    ];

    return columns;
  }

  getRow(data: any): AccountingAccountGroupModel | undefined {
    return data;
  }

  delete(data: any) {
    const row = this.getRow(data);
    if (row) {
      let mutation: any = gql`
        mutation SoftDeleteAccountingAccountGroupById($id: bigint!) {
          result: update_accounting_account_group_by_pk(
            pk_columns: { id: $id }
            _set: { deleted_at: "now()" }
          ) {
            __typename
          }
        }
      `;

      this.confirmationService.confirmDeleteApollo({
        name: row.name,
        mutationOptions: {
          mutation,
          variables: {
            id: row.id
          }
        },
        success: () => this.tableComponent?.updateTable()
      });
    }
  }
}
