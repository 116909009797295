<div *transloco="let transloco" class="flex flex-col space-y-5">
  @if (this.showFilter() || this.showSort()) {
    <div class="flex flex-wrap justify-stretch gap-5">
      <div class="min-w-fit flex-1">
        @if (this.showFilter()) {
          <lib-graph-ql-advanced-filter
            [graphQlAdvancedTable]="advancedTable"
            [graphQlTable]="this.graphQlTable"
            [stateKey]="this.stateKey()"></lib-graph-ql-advanced-filter>
        }
      </div>
      <div class="min-w-fit flex-1">
        @if (this.showSort()) {
          <lib-graph-ql-advanced-sort
            [graphQlAdvancedTable]="advancedTable"
            [graphQlTable]="this.graphQlTable"
            [stateKey]="this.stateKey()"></lib-graph-ql-advanced-sort>
        }
      </div>
    </div>
  }

  <lib-graph-ql-advanced-table
    #advancedTable
    [graphQlTable]="this.graphQlTable"
    [patchOptions]="this.graphQlPatchOptions"
    [showColumnFilter]="this.showColumnFilter()"
    [showExport]="this.showExport()"
    [stateKey]="this.stateKey()">
    <ng-template libTemplate="caption">
      <button
        (click)="this.openDialog()"
        [pTooltip]="transloco('new')"
        class="p-button-success"
        icon="pi pi-upload"
        pButton
        style="padding: 0.5rem 0"
        tooltipPosition="top"
        type="button"></button>
    </ng-template>
    <ng-template let-data libTemplate="actions">
      <div class="flex items-center justify-center gap-2">
        @let status = this.fileService.status()[data.id];
        <button
          (click)="this.downloadFile(data, true)"
          [disabled]="!status?.isOpening && status?.isDownloading"
          [loading]="status?.isOpening || false"
          [pTooltip]="transloco('show')"
          class="p-button-info p-button-text"
          icon="pi pi-eye"
          pButton
          tooltipPosition="top"
          type="button"></button>

        <button
          (click)="this.downloadFile(data)"
          [disabled]="status?.isOpening"
          [loading]="(!status?.isOpening && status?.isDownloading) || false"
          [pTooltip]="transloco('download')"
          class="p-button-info p-button-text"
          icon="pi pi-download"
          pButton
          tooltipPosition="top"
          type="button"></button>

        @if (!data?.person_documents_aggregate?.aggregate?.count) {
          <button
            (click)="this.delete(data)"
            [pTooltip]="transloco('delete')"
            class="p-button-danger p-button-text"
            icon="pi pi-trash"
            pButton
            tooltipPosition="top"
            type="button"></button>
        }
      </div>
    </ng-template>
  </lib-graph-ql-advanced-table>
</div>
