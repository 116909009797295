<app-file-table
  *transloco="let transloco"
  [files]="this.files()"
  [namespace]="this.namespace()"
  class="overflow-scroll">
  <ng-template libTemplate="caption">
    @if (this.progress() > 0) {
      <p-progressBar
        [showValue]="false"
        [style]="{ height: '4px' }"
        [value]="this.progress()"
        class="absolute left-0 top-0 w-full"></p-progressBar>
    }
    <div class="flex">
      <input
        #uploader
        (change)="this.uploadFile(uploader.files); uploader.value = ''"
        [accept]="this.accept()"
        [multiple]="this.multiple()"
        [size]="this.size() || this.sizeLimit"
        hidden
        type="file" />
      @if (this.canUpload() && !this.disabled() && !this.readonly()) {
        <button
          (click)="uploader.click()"
          class="p-button-outlined"
          label="{{ transloco('upload') }}"
          pButton
          type="button"></button>
      }
    </div>

    @if (this.progress() > 0) {
      <p-progressBar
        [showValue]="false"
        [style]="{ height: '4px' }"
        mode="indeterminate"
        class="absolute bottom-0 left-0 w-full"></p-progressBar>
    } @else {
      <small class="mt-4 block">Max. 15MB</small>
    }
  </ng-template>

  <ng-template let-file libTemplate="actions">
    @if (!this.disabled() && !this.readonly()) {
      <button
        (click)="this.removeFile(file.id)"
        [pTooltip]="transloco('delete')"
        class="p-button-danger p-button-text mr-2"
        icon="pi pi-trash"
        pButton
        tooltipPosition="top"
        type="button"></button>
    }
  </ng-template>
</app-file-table>
