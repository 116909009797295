import { Component, effect, inject, input, OnInit, ViewChild } from '@angular/core';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { TooltipModule } from 'primeng/tooltip';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ButtonDirective } from 'primeng/button';
import { RouterLink } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs';
import {
  AcademyCertificateModel,
  AcademyCertificatePermission,
  AcademyCertificateTypeEnum,
  AcademyOrganisationModel,
  AcademySchoolModel,
  CorePersonDataModel
} from '~ngx-shared/models';
import { ModelUtil } from '~ngx-shared/utils';
import { PersonDetailLinkComponent } from '~madrasa/staff/components/person-detail-link/person-detail-link.component';
import { TemplateDirective } from '~ngx-shared/directives';
import {
  DataProviderOptionModel,
  GraphQlAdvancedFilterComponent,
  GraphQlAdvancedSortComponent,
  GraphQlAdvancedTableComponent,
  GraphQlColumnModel,
  GraphQlTableModel
} from '~ngx-shared/graph-ql';
import { ConfirmationService } from '~ngx-shared/layout';
import { SchoolPeriodService } from '~madrasa/academy/services/school-period.service';
import { PrintService } from '~madrasa/services';

@Component({
  selector: 'app-certificate-list',
  standalone: true,
  imports: [
    TranslocoDirective,
    GraphQlAdvancedTableComponent,
    TooltipModule,
    TemplateDirective,
    NgxPermissionsModule,
    GraphQlAdvancedFilterComponent,
    GraphQlAdvancedSortComponent,
    PersonDetailLinkComponent,
    ButtonDirective,
    RouterLink
  ],
  templateUrl: './certificate-list.component.html',
  styleUrl: './certificate-list.component.scss'
})
export class CertificateListComponent implements OnInit {
  readonly translocoService = inject(TranslocoService);
  readonly schoolPeriodService = inject(SchoolPeriodService);
  readonly apollo = inject(Apollo);
  readonly confirmationService = inject(ConfirmationService);
  readonly printService = inject(PrintService);

  showCreateButton = input<boolean>(false);
  showFilter = input<boolean>(true);
  showSort = input<boolean>(true);
  showExport = input<boolean>(false);
  showColumnFilter = input<boolean>(true);

  organisationId = input<number>();
  schoolId = input<number>();
  courseId = input<number>();
  personId = input<number>();

  stateKey = input<string>();
  patchOptions = input<(options: DataProviderOptionModel) => DataProviderOptionModel>();

  @ViewChild('advancedTable') tableComponent: GraphQlAdvancedTableComponent;

  graphQlPatchOptions: (options: DataProviderOptionModel) => DataProviderOptionModel;
  graphQlTable: GraphQlTableModel;
  protected readonly AcademyCertificatePermission = AcademyCertificatePermission;

  constructor() {
    effect(() => {
      const courseId = this.courseId();
      this.graphQlPatchOptions = options => {
        const patchOptions = this.patchOptions();
        if (patchOptions) {
          options = patchOptions(options);
        }

        if (!options.filter) {
          options.filter = {};
        }
        if (!options.filter._and) {
          options.filter._and = [];
        }

        if (courseId && Array.isArray(options?.filter?._and)) {
          options.filter._and = options.filter._and.filter((cond: any) => !cond.course_id);
          options.filter._and.push({
            course_id: {
              _eq: courseId
            }
          });
        }

        if (!options.sortBy?.length) {
          options.sortBy = [{ created_at: 'desc_nulls_last' }];
        }

        return options;
      };
    });
  }

  ngOnInit() {
    const columns: GraphQlColumnModel[] = [
      {
        path: 'id',
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        isNotExportable: true,
        isNotSelectable: true
      },
      {
        path: 'person_id',
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        isNotExportable: true,
        isNotSelectable: true
      },
      {
        path: 'course_id',
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        isNotExportable: true,
        isNotSelectable: true
      },
      {
        label: 'school_period',
        path: 'school_period.name',
        filter: {
          label: 'school_period',
          path: 'school_period_id',
          type: 'array',
          options: this.schoolPeriodService.getPeriods().pipe(
            map(
              schoolPeriods =>
                schoolPeriods?.map(schoolPeriod => ({
                  label: schoolPeriod.name,
                  value: schoolPeriod.id
                })) || []
            )
          )
        },
        sort: { isSortable: true }
      },
      {
        label: 'organisation',
        path: 'organisation.name',
        filter: {
          label: 'organisation',
          path: 'organisation_id',
          type: 'array',
          options: this.apollo
            .query<{
              result: AcademyOrganisationModel[];
            }>({
              query: gql`
                query ReadAcademyOrganisation($where: academy_organisation_bool_exp) {
                  result: academy_organisation(where: $where) {
                    id
                    name
                  }
                }
              `,
              variables: {
                where: {}
              }
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(organisation => ({
                  label: organisation.name,
                  value: organisation.id
                }))
              )
            )
        },
        sort: { isSortable: false },
        hidden: true
      },
      {
        label: 'school',
        path: 'school.name',
        filter: {
          label: 'school',
          path: 'school_id',
          type: 'array',
          options: this.apollo
            .query<{
              result: AcademySchoolModel[];
            }>({
              query: gql`
                query ReadAcademySchool($where: academy_school_bool_exp) {
                  result: academy_school(where: $where) {
                    id
                    name
                  }
                }
              `,
              variables: {
                where: {}
              }
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(school => ({
                  label: school.name,
                  value: school.id
                }))
              )
            )
        },
        sort: { isSortable: false },
        hidden: true
      },
      {
        label: 'student_id',
        path: 'person_id',
        filter: { type: 'number' },
        sort: { isSortable: true },
        isNotSelectable: true
      },
      {
        label: 'student',
        path: 'current_person_data',
        query: `
            current_person_data {
              person_id
              academic_degree_prefix
              academic_degree_suffix
              first_name
              last_name
          }
        `,
        patchResult: value =>
          ModelUtil.getFullName(value?.current_person_data, this.translocoService),
        filter: { isNotFilterable: true },
        sort: { isSortable: false }
      },
      {
        path: 'certificate_type',
        classHeader: 'text-center',
        classBody: 'text-center',
        translate: true,
        prefix: 'certificate_type.',
        filter: {
          type: 'array',
          options: Object.values(AcademyCertificateTypeEnum).map(value => ({
            label: 'certificate_type.' + value,
            value: value
          }))
        }
      },
      {
        path: 'date_of_certificate',
        type: 'date',
        filter: { type: 'date' },
        sort: { isSortable: true }
      },
      {
        label: 'certificate_template',
        path: 'certificate_template.name',
        filter: { type: 'string' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        label: 'created_by',
        path: 'created_by_current_person_data',
        query: `
            created_by_current_person_data {
              person_id
              academic_degree_prefix
              academic_degree_suffix
              first_name
              last_name
            }
        `,
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        isNotExportable: true,
        hidden: true
      },
      {
        path: 'created_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true }
      },
      {
        path: 'updated_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true }
      }
    ];

    this.graphQlTable = {
      table: 'academy_certificate',
      isPaginated: true,

      showCurrentPageReport: true,
      columns: columns
    };
  }

  getRow(data: any): AcademyCertificateModel | undefined {
    return data;
  }

  getFullName(personDataModel: CorePersonDataModel | undefined) {
    return ModelUtil.getFullName(personDataModel, this.translocoService);
  }

  delete(data: any) {
    const row = this.getRow(data);
    if (row) {
      let mutation: any = gql`
        mutation SoftDeleteAcademyCertificateById($id: bigint!) {
          result: update_academy_certificate_by_pk(
            pk_columns: { id: $id }
            _set: { deleted_at: "now()" }
          ) {
            __typename
          }
        }
      `;

      this.confirmationService.confirmDeleteApollo({
        name: String(row.id) + ' - ' + this.getFullName(row.current_person_data),
        mutationOptions: {
          mutation,
          variables: {
            id: row.id
          }
        },
        success: () => this.tableComponent?.updateTable()
      });
    }
  }

  generate(data: any) {
    const row = this.getRow(data);
    if (row) {
      this.printService.certificate(
        row.id,
        `${row.course_id}-${row.person_id}-${this.getFullName(row.current_person_data)}`
      );
    }
  }
}
