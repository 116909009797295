import { Role } from '~ngx-shared/authentication';
import { Aggregate } from '~ngx-shared/models';
import { DefaultProperties } from '../default.model';
import { AcademyUnitModel } from './unit.model';
import { AcademySchoolModel } from './school.model';
import { AcademyCourseTypeModel } from './course-type.model';
import { AcademySchoolPeriodModel } from './school-period.model';
import { AcademyCourseStudentModel } from './course-student.model';
import { AcademyCourseTeacherModel } from './course-teacher.model';
import { AcademyRoomModel } from './room.model';
import { AcademyCourseNameModel } from './course-name.model';
import { AcademyCoursePriceModel } from './course-price.model';
import { AcademyCertificateModel } from './certificate.model';
import { AcademyCertificateTemplateModel } from './certificate-template.model';

export interface AcademyCourseModel extends DefaultProperties {
  // Columns
  id?: number;
  course_type_id?: number;
  school_id?: number;
  school_period_id?: number;
  parent_course_id?: number;
  room_id?: number;
  course_name_id?: number;
  name?: string;
  description?: string;
  is_online?: boolean;
  address?: any;
  color?: string;

  // Relations
  course_type?: AcademyCourseTypeModel;
  course_name?: AcademyCourseNameModel;
  school?: AcademySchoolModel;
  school_period?: AcademySchoolPeriodModel;
  parent?: AcademyCourseModel;
  room?: AcademyRoomModel;

  course_course_price?: AcademyCoursePriceModel;

  children?: AcademyCourseModel[];
  children_aggregate?: Aggregate;

  units?: AcademyUnitModel[];
  units_aggregate?: Aggregate;

  latest_course_students?: AcademyCourseStudentModel[];
  latest_course_students_active?: AcademyCourseStudentModel[];
  course_students?: AcademyCourseStudentModel[];
  course_students_aggregate?: Aggregate;

  course_teachers?: AcademyCourseTeacherModel[];
  course_teachers_aggregate?: Aggregate;

  certificates?: AcademyCertificateModel[];
  certificate_templates?: AcademyCertificateTemplateModel[];
}

export class AcademyCoursePermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR, Role.TEACHER];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [
    ...AcademyCoursePermission.DELETE,
    Role.MANAGER,
    Role.ORGANISATOR,
    Role.DIRECTOR
  ];
}
