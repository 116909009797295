<div *transloco="let transloco" class="flex flex-col space-y-5">
  @if (this.showFilter() || this.showSort()) {
    <div class="flex flex-wrap justify-stretch gap-5">
      <div class="min-w-fit flex-1">
        @if (this.showFilter()) {
          <lib-graph-ql-advanced-filter
            [graphQlAdvancedTable]="advancedTable"
            [graphQlTable]="this.graphQlTable()"
            [stateKey]="this.stateKey()"></lib-graph-ql-advanced-filter>
        }
      </div>
      <div class="min-w-fit flex-1">
        @if (this.showSort()) {
          <lib-graph-ql-advanced-sort
            [graphQlAdvancedTable]="advancedTable"
            [graphQlTable]="this.graphQlTable()"
            [stateKey]="this.stateKey()"></lib-graph-ql-advanced-sort>
        }
      </div>
    </div>
  }

  <lib-graph-ql-advanced-table
    #advancedTable
    [graphQlTable]="this.graphQlTable()"
    [patchOptions]="this.graphQlPatchOptions"
    [showColumnFilter]="this.showColumnFilter()"
    [showExport]="this.showExport()"
    [stateKey]="this.stateKey()">
    <ng-template libTemplate="caption">
      <div class="flex gap-2">
        <button (click)="this.addColumn('front')" class="flex gap-2" pButton type="button">
          <i class="pi pi-chevron-left"></i>
          <i class="pi pi-plus"></i>
          <span>{{
            transloco('madrasa.components.accounting.student_balances.add_to_front')
          }}</span>
        </button>

        <button (click)="this.addColumn('back')" class="flex gap-2" pButton type="button">
          <span>{{ transloco('madrasa.components.accounting.student_balances.add_to_back') }}</span>
          <i class="pi pi-plus"></i>
          <i class="pi pi-chevron-right"></i>
        </button>
      </div>
    </ng-template>
    @for (column of this.columns(); track column) {
      <ng-template let-data [libTemplate]="column.path + 'Cell'">
        <div class="flex flex-col gap-2 text-right">
          @for (balance of data?.[column.path || '']; track balance; let last = $last) {
            <span> {{ balance.amount / 100 | currency }}</span>
            @if (last) {
              <hr class="my-1" />
            }
          }

          @let sum = this.getSum(data?.[column.path || '']);
          <span
            [ngClass]="{
              'text-green-500': sum > 0,
              'text-red-500': sum < 0
            }"
            class="font-bold">
            {{ sum / 100 | currency }}</span
          >
        </div>
      </ng-template>
    }
    <ng-template let-data libTemplate="current_person_dataCell">
      <div class="flex flex-col gap-2">
        <app-person-detail-link [person]="data"></app-person-detail-link>
      </div>
    </ng-template>
  </lib-graph-ql-advanced-table>
</div>
