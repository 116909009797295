import { Component, inject, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { map } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { FilePersonDocumentTypeModel } from '~ngx-shared/models';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { FormlyService } from '~madrasa/services';

@UntilDestroy()
@Component({
  selector: 'app-create-person-document-form-dialog',
  standalone: true,
  imports: [FormlyModule],
  templateUrl: './create-person-document-form-dialog.component.html',
  styleUrl: './create-person-document-form-dialog.component.scss'
})
export class CreatePersonDocumentFormDialogComponent implements OnInit {
  readonly apollo = inject(Apollo);
  readonly dialogRef = inject(DynamicDialogRef);
  readonly dialogConfig = inject(DynamicDialogConfig);
  readonly formlyService = inject(FormlyService);

  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.create_person_document'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  ngOnInit(): void {
    const personId = this.dialogConfig.data?.personId;

    this.fields = [
      FormlyUtil.createRow([
        FormlyUtil.createSelectField('person_document_type_id', {
          props: {
            label: 'person_document_type',
            required: true,
            options: this.apollo
              .query<{
                result: FilePersonDocumentTypeModel[];
              }>({
                query: gql`
                  query ReadAcademySchool($where: file_person_document_type_bool_exp) {
                    result: file_person_document_type(where: $where) {
                      id
                      name
                    }
                  }
                `,
                variables: {
                  where: {}
                }
              })
              .pipe(
                map(queryResult =>
                  queryResult.data.result.map(item => ({
                    label: item.name,
                    value: item.id
                  }))
                )
              )
          }
        })
      ]),
      FormlyUtil.createRow([
        this.formlyService.createFileUploadFieldConfig('person_documents', {
          props: {
            label: 'documents',
            required: true,
            namespace: 'person_document',
            multiple: true
          }
        })
      ])
    ];

    this.submit = (formSaveModel: FormSaveModel) => {
      const documents = formSaveModel.input.person_documents.map((id: any) => {
        return {
          id: id,
          person_id: personId,
          person_document_type_id: formSaveModel.input.person_document_type_id
        };
      });

      return this.apollo.mutate({
        mutation: gql`
          mutation CreateFilePersonDocument($documents: [file_person_document_insert_input!]!) {
            result: insert_file_person_document(objects: $documents) {
              __typename
            }
          }
        `,
        variables: {
          documents
        }
      });
    };
  }

  savedEvent(formSaveModel: FormSaveModel) {
    if (!formSaveModel.hasError) {
      this.dialogRef.close(formSaveModel);
    }
  }

  canceled($event: boolean) {
    if (this.dialogRef) {
      this.dialogRef.close(undefined);
    }
  }
}
