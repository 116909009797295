<lib-layout-base-page
  *transloco="let transloco"
  [titleAddon]="this.accountingPeriodService.currentPeriod()?.id">
  <div action class="flex w-full flex-col gap-2 md:w-auto md:flex-row md:items-center">
    <a
      [label]="transloco('cash_book_entry_type.incoming')"
      [queryParams]="{ type: 'incoming' }"
      [routerLink]="['.', 'new']"
      class="p-button-success text-white"
      icon="pi pi-arrow-down"
      pButton
      type="button"></a>

    <a
      [label]="transloco('cash_book_entry_type.outgoing')"
      [queryParams]="{ type: 'outgoing' }"
      [routerLink]="['.', 'new']"
      class="p-button-danger text-white"
      icon="pi pi-arrow-up"
      pButton
      type="button"></a>

    <a
      *ngxPermissionsOnly="this.AccountingCashBookCategoryPermission.CREATE"
      [label]="transloco('cash_book_entry_categories')"
      [routerLink]="['.', 'categories']"
      class="text-white"
      pButton
      type="button"></a>
  </div>

  <div class="m-base">
    <lib-busy [isLoading]="!this.accountingPeriodService.currentPeriod()?.id">
      <div class="flex flex-col space-y-5">
        <div class="w-full space-y-2 md:w-1/3 lg:w-1/4">
          <div class="flex justify-between gap-2 text-2xl">
            <span class="">{{ transloco('cash_book_entry_type.incoming') }}:</span>
            <span class="font-bold text-green-500">{{ this.incoming() | currency }}</span>
          </div>
          <div class="flex justify-between gap-2 text-2xl">
            <span class="">{{ transloco('cash_book_entry_type.outgoing') }}:</span>
            <span class="font-bold text-red-500">{{ this.outgoing() | currency }}</span>
          </div>

          <hr />

          <div class="flex justify-between gap-2 text-2xl">
            <span class="">{{ transloco('sum') }}:</span>
            <span
              [ngClass]="{
                'text-green-500': this.balance() > 0,
                'text-red-500': this.balance() < 0
              }"
              class="font-bold"
              >{{ this.balance() | currency }}</span
            >
          </div>

          <hr class="my-1" />
          <hr class="my-1" />
        </div>

        <app-cash-book-entry-list
          [accountPeriodId]="this.accountingPeriodService.currentPeriod()?.id!"
          [showExport]="true"
          [showFilter]="true"
          [showSort]="true"
          [stateKey]="this.router.url + 'cash-book-entry-list'">
        </app-cash-book-entry-list>
      </div>
    </lib-busy>
  </div>
</lib-layout-base-page>
