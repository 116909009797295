<lib-layout-base-page
  *transloco="let transloco"
  [titleAddon]="this.accountingPeriodService.currentPeriod()?.id">
  <a
    [label]="transloco('new')"
    [routerLink]="['.', 'new']"
    action
    class="p-button-success text-white"
    icon="pi pi-plus"
    pButton
    type="button"></a>

  <div class="m-base flex flex-col space-y-5">
    <p-table
      *transloco="let transloco"
      [value]="this.files()"
      responsiveLayout="scroll"
      styleClass="p-datatable-gridlines p-datatable-striped md:shadow-none shadow">
      <ng-template pTemplate="header">
        <tr>
          <th class="w-1/12 text-center">{{ transloco('number') }}</th>
          <th>{{ transloco('name') }}</th>
          <th>{{ transloco('file_name') }}</th>
          <th>{{ transloco('progress') }}</th>
          <th>{{ transloco('debit') }}</th>
          <th>{{ transloco('created_at') }}</th>
          <th>{{ transloco('updated_at') }}</th>
          <th>{{ transloco('actions') }}</th>
        </tr>
      </ng-template>

      <ng-template let-data let-rowIndex="rowIndex" pTemplate="body">
        <tr>
          <td class="w-1/12 text-center">{{ rowIndex + 1 }}</td>
          <td>
            <span>{{ this.getRow(data)?.name }}</span>
          </td>
          <td>
            <span>{{ this.getRow(data)?.file_name }}</span>
          </td>
          <td>
            <span>{{ this.getBookedCount(data) }} / {{ this.getRow(data)?.items?.length }}</span>
          </td>
          <td>
            <a
              [routerLink]="[
                '/',
                'accounting',
                'account-plan',
                'accounts',
                'detail',
                this.getRow(data)?.active_account_id
              ]"
              class="cursor-pointer hover:text-primary hover:underline">
              <span>{{ this.getRow(data)?.active_account_name }}</span>
            </a>
          </td>
          <td>
            <span>{{ this.getRow(data)?.created_at | libDateTime }}</span>
          </td>
          <td>
            <span>{{ this.getRow(data)?.updated_at | libDateTime }}</span>
          </td>
          <td class="w-0">
            <div class="flex items-center justify-center space-x-2">
              <a
                [pTooltip]="transloco('load')"
                [routerLink]="['/', 'accounting', 'bank-data-import', this.getRow(data)?.id]"
                class="p-button-info p-button-text mr-2"
                icon="pi pi-play"
                pButton
                tooltipPosition="top"
                type="button"></a>

              <button
                (click)="this.delete(data)"
                [pTooltip]="transloco('delete')"
                class="p-button-danger p-button-text"
                icon="pi pi-trash"
                pButton
                tooltipPosition="top"
                type="button"></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="8" class="text-center">
            <span>{{ transloco('no_data') }}</span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</lib-layout-base-page>
