import { Component, Type } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FieldWrapperProps } from '../field-wrapper/field-wrapper.component';

interface FileProps extends FieldWrapperProps {
  accept?: string;
  size?: number;
}

export interface FileFieldConfig extends FormlyFieldConfig<FileProps> {
  type?: 'file' | Type<FileTypeComponent>;
}

@Component({
  selector: 'lib-formly-file-type',
  templateUrl: './file-type.component.html',
  styleUrl: './file-type.component.css'
})
export class FileTypeComponent extends FieldType<FieldTypeConfig<FileProps>> {}
