import { Component, computed, inject, OnInit } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, of, switchMap, tap } from 'rxjs';
import { TranslocoDirective } from '@jsverse/transloco';
import { PrimeTemplate } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { ButtonDirective } from 'primeng/button';
import { DetailComponent, DetailField } from '~ngx-shared/ui/detail/detail.component';
import { AccountingAccountModel } from '~ngx-shared/models';
import { BasePageComponent, BusyComponent, LoadingService } from '~ngx-shared/layout';
import { TabViewQueryDirective } from '~ngx-shared/directives';
import { AccountingPeriodService } from '~madrasa/accounting/services/accounting-period.service';
import { EntryListComponent } from '~madrasa/accounting/components/entry-list/entry-list.component';
import { DataProviderOptionModel } from '~ngx-shared/graph-ql';

@UntilDestroy()
@Component({
  selector: 'app-account-detail-page',
  standalone: true,
  imports: [
    BasePageComponent,
    TranslocoDirective,
    DetailComponent,
    PrimeTemplate,
    TabViewModule,
    TabViewQueryDirective,
    BusyComponent,
    EntryListComponent,
    ButtonDirective,
    RouterLink
  ],
  templateUrl: './account-detail-page.component.html',
  styleUrl: './account-detail-page.component.scss'
})
export class AccountDetailPageComponent implements OnInit {
  readonly apollo = inject(Apollo);
  readonly activatedRoute = inject(ActivatedRoute);
  readonly loadingService = inject(LoadingService);
  readonly accountingPeriodService = inject(AccountingPeriodService);
  readonly item = toSignal(
    this.activatedRoute.data.pipe(
      untilDestroyed(this),
      tap(() => this.loadingService.startLoading()),
      switchMap(data => {
        const result = !!data?.['crud'] && !!data['crud']['id'];
        if (result) {
          return this.apollo
            .query<{ result: AccountingAccountModel }>({
              query: gql`
                query ReadAccountingAccountById($id: bigint!) {
                  result: accounting_account_by_pk(id: $id) {
                    id
                    created_at
                    updated_at
                    account_group {
                      accounting_period_id
                      full_name
                    }
                    name
                    number
                    opening_amount
                    sum_amount

                    school {
                      name
                      organisation {
                        name
                      }
                    }
                  }
                }
              `,
              variables: {
                id: data['crud']['id']
              }
            })
            .pipe(map(queryResult => queryResult.data?.result));
        } else {
          return of(undefined);
        }
      }),
      tap(() => this.loadingService.stopLoading())
    )
  );
  readonly fields = computed<DetailField[]>(() => {
    const item = this.item();
    if (!item) {
      return [];
    }

    this.studentPatchOptions = options => {
      options.filter = {
        ...options.filter,
        _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
      };
      if (Array.isArray(options?.filter?._and)) {
        options.filter._and = options.filter._and.filter((cond: any) => !cond.entry_rows);
        options.filter._and.push({
          entry_rows: {
            account_id: {
              _eq: item.id
            }
          }
        });
      }

      return options;
    };

    return [
      {
        label: 'id',
        value: item.id
      },
      {
        label: 'created_at',
        type: 'datetime',
        value: item.created_at
      },
      {
        label: 'updated_at',
        type: 'datetime',
        value: item.updated_at
      },
      {
        label: 'accounting_period',
        value: item.account_group?.accounting_period_id
      },
      {
        label: 'account_group',
        value: item.account_group?.full_name
      },
      {
        label: 'name',
        value: item.name
      },
      {
        label: 'number',
        value: item.number
      },
      {
        label: 'organisation',
        value: item.school?.organisation?.name
      },
      {
        label: 'school',
        value: item.school?.name
      },
      {
        label: 'opening_amount',
        type: 'currency',
        value: item.opening_amount
      },
      {
        label: 'amount',
        type: 'currency',
        value: item.sum_amount
      }
    ];
  });

  studentPatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;

  ngOnInit() {
    this.accountingPeriodService.getPeriods().subscribe();
  }
}
