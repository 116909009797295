import { Component, computed, effect, inject, input, signal } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { Router } from '@angular/router';
import { AccordionModule } from 'primeng/accordion';
import { PrimeTemplate } from 'primeng/api';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgxPermissionsModule } from 'ngx-permissions';
import { DataProviderOptionModel } from '~ngx-shared/graph-ql';
import { FormSaveModel } from '~ngx-shared/formly';
import { NotePersonNoteTypeEnum } from '~ngx-shared/models/note';
import { NoteListComponent } from '~madrasa/note/components/note-list/note-list.component';
import {
  CorePersonDataModel,
  StaffOrganisatorModel,
  StaffOrganisatorPermission
} from '~ngx-shared/models';
import { DetailComponent, DetailField } from '~ngx-shared/ui/detail/detail.component';
import { BusyComponent } from '~ngx-shared/layout';

@Component({
  selector: 'app-organisator-detail',
  standalone: true,
  imports: [
    BusyComponent,
    DetailComponent,
    AccordionModule,
    NoteListComponent,
    PrimeTemplate,
    TranslocoDirective,
    NgxPermissionsModule
  ],
  templateUrl: './organisator-detail.component.html',
  styleUrl: './organisator-detail.component.scss'
})
export class OrganisatorDetailComponent {
  readonly apollo = inject(Apollo);
  readonly router = inject(Router);

  readonly inputPersonData = input<CorePersonDataModel | undefined>(undefined, {
    alias: 'personData'
  });
  readonly isLoading = signal(true);
  readonly personData = signal<CorePersonDataModel | undefined>(undefined);

  readonly fields = computed<DetailField[]>(() => {
    const item = this.personData();
    if (!item?.latest_organisator) {
      return [];
    }
    return [
      {
        label: 'updated_at',
        type: 'datetime',
        value: item.updated_at
      },
      {
        label: 'organisation',
        value: item.latest_organisator.organisation_organisators_active
          ?.map(sda => sda.organisation?.name)
          .join(', ')
      },
      {
        label: 'status',
        translate: true,
        value: 'status.' + item.latest_organisator.status
      }
    ];
  });

  notePatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  notePatchInput: (formSaveModel: FormSaveModel, input: any) => any;
  protected readonly StaffOrganisatorPermission = StaffOrganisatorPermission;

  constructor() {
    effect(
      () => {
        const personId = this.inputPersonData()?.person_id;
        if (personId) {
          this.isLoading.set(true);
          this.apollo
            .query<{ result: StaffOrganisatorModel[] }>({
              query: gql`
                query ReadStaffOrganisatorById($personId: bigint!) {
                  result: staff_latest_organisator(
                    where: { person_id: { _eq: $personId } }
                    limit: 1
                  ) {
                    id
                    person_id
                    updated_at
                    status
                    organisation_organisators_active {
                      organisation {
                        name
                      }
                    }
                  }
                }
              `,
              variables: {
                personId: personId
              }
            })
            .pipe(map(queryResult => queryResult.data?.result?.[0]))
            .subscribe(staffOrganisator => {
              const personData = cloneDeep(this.inputPersonData());
              if (personData) {
                personData.latest_organisator = staffOrganisator;
                this.personData.set(personData);
              }
              this.isLoading.set(false);
            });

          this.notePatchOptions = options => {
            options.filter = {
              ...options.filter,
              _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
            };
            if (Array.isArray(options?.filter?._and)) {
              options.filter._and = options.filter._and.filter((cond: any) => !cond.person_id);
              options.filter._and.push({
                person_note_type: {
                  _eq: NotePersonNoteTypeEnum.ORGANISATOR
                },
                person_id: {
                  _eq: personId
                }
              });
            }
            return options;
          };

          this.notePatchInput = (formSaveModel, input) => {
            return {
              ...input,
              person_id: personId,
              person_note_type: NotePersonNoteTypeEnum.ORGANISATOR
            };
          };
        }
      },
      { allowSignalWrites: true }
    );
  }
}
