<div *transloco="let transloco" class="flex flex-col space-y-5">
  @if (this.showFilter() || this.showSort()) {
    <div class="flex flex-wrap justify-stretch gap-5">
      <div class="min-w-fit flex-1">
        @if (this.showFilter()) {
          <lib-graph-ql-advanced-filter
            [graphQlAdvancedTable]="advancedTable"
            [graphQlTable]="this.graphQlTable()"
            [stateKey]="this.stateKey()"></lib-graph-ql-advanced-filter>
        }
      </div>
      <div class="min-w-fit flex-1">
        @if (this.showSort()) {
          <lib-graph-ql-advanced-sort
            [graphQlAdvancedTable]="advancedTable"
            [graphQlTable]="this.graphQlTable()"
            [stateKey]="this.stateKey()"></lib-graph-ql-advanced-sort>
        }
      </div>
    </div>
  }

  <lib-graph-ql-advanced-table
    #advancedTable
    [graphQlTable]="this.graphQlTable()"
    [patchOptions]="this.graphQlPatchOptions"
    [showColumnFilter]="this.showColumnFilter()"
    [showExport]="this.showExport()"
    [stateKey]="this.stateKey()">
    <ng-template let-data libTemplate="creditCell">
      <ng-container
        [ngTemplateOutletContext]="{ $implicit: this.getRow(data)?.['credits'] }"
        [ngTemplateOutlet]="accountLink"></ng-container>
    </ng-template>
    <ng-template let-data libTemplate="debitCell">
      <ng-container
        [ngTemplateOutletContext]="{ $implicit: this.getRow(data)?.['debits'] }"
        [ngTemplateOutlet]="accountLink"></ng-container>
    </ng-template>
    <ng-template #accountLink let-entryRows>
      <div class="flex flex-col gap-2">
        @for (entryRow of entryRows; track entryRow) {
          <a
            [routerLink]="[
              '/',
              'accounting',
              'account-plan',
              'accounts',
              'detail',
              entryRow.account?.id
            ]"
            class="cursor-pointer hover:text-primary hover:underline">
            <span class="">{{ entryRow.account?.full_name }}</span>
          </a>
        }
      </div>
    </ng-template>
    @if (this.showCreateButton()) {
      <ng-template libTemplate="caption">
        <a
          [pTooltip]="transloco('new')"
          [routerLink]="['/', 'accounting', 'cash-book-entries', 'new']"
          class="p-button-success !p-2 text-white"
          icon="pi pi-plus"
          pButton
          tooltipPosition="top"
          type="button"></a>
      </ng-template>
    }
    <ng-template let-data libTemplate="actions">
      <div class="flex items-center justify-center gap-2">
        <a
          [pTooltip]="transloco('show')"
          [routerLink]="['/', 'accounting', 'entries', 'detail', this.getRow(data)?.id]"
          class="p-button-info p-button-text"
          icon="pi pi-eye"
          pButton
          tooltipPosition="top"
          type="button"></a>

        <!--        @if (!this.getRow(data)?.entry_id) {-->
        <!--          <a-->
        <!--            [pTooltip]="transloco('edit')"-->
        <!--            [routerLink]="['/', 'accounting', 'cash-book-entries', this.getRow(data)?.id]"-->
        <!--            class="p-button-warning p-button-text"-->
        <!--            icon="pi pi-pencil"-->
        <!--            pButton-->
        <!--            tooltipPosition="top"-->
        <!--            type="button"></a>-->
        <!--        }-->

        <!--        @if (!this.getRow(data)?.entry_id) {-->
        <!--          <button-->
        <!--            (click)="this.delete(data)"-->
        <!--            [pTooltip]="transloco('delete')"-->
        <!--            class="p-button-danger p-button-text"-->
        <!--            icon="pi pi-trash"-->
        <!--            pButton-->
        <!--            tooltipPosition="top"-->
        <!--            type="button"></button>-->
        <!--        }-->
      </div>
    </ng-template>
  </lib-graph-ql-advanced-table>
</div>
