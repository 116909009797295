<lib-layout-base-page *transloco="let transloco">
  <a
    *ngxPermissionsOnly="this.AccountingStudentBalancePermission.CREATE"
    [label]="transloco('new')"
    [routerLink]="['.', 'new']"
    action
    class="p-button-success text-white"
    icon="pi pi-plus"
    pButton
    type="button"></a>

  <div class="m-base">
    <div class="flex flex-col gap-6">
      <app-student-balance-list
        [showExport]="true"
        [showFilter]="true"
        [showSort]="true"
        [stateKey]="this.router.url + 'student-balance-list'"></app-student-balance-list>

      <span class="block text-3xl">
        {{ transloco('madrasa.components.accounting.student_balances.student_balance_per_month') }}
      </span>

      <app-student-balance-per-month-of-balance-list
        [stateKey]="
          this.router.url + 'student-balance-per-month-of-balance-list'
        "></app-student-balance-per-month-of-balance-list>
    </div>
  </div>
</lib-layout-base-page>
