<div *transloco="let transloco" class="flex flex-col space-y-5">
  <span class="block text-3xl">
    {{ transloco('date') }}
    @if (this.model.date) {
      - {{ this.model.date | libDate: 'MM/yyyy' }}
    }
  </span>
  <lib-formly-form
    class="w-full lg:w-3/4 xl:w-4/6"
    (savedEvent)="this.savedEvent($event)"
    [fields]="this.fields"
    [form]="this.form"
    [model]="this.model"
    [navigateBackOnCancel]="false"
    [navigateBackOnSave]="false"
    [options]="this.options"
    [saveButtonText]="'generate'"
    [showCancelButton]="false"
    [showToastMessages]="false"
    [submit]="this.submit">
  </lib-formly-form>

  @if (this.result()) {
    <app-teacher-list
      [columns]="this.columns()"
      [stateKey]="this.router.url + 'calculate-teacher-wage-list'"></app-teacher-list>
  }
</div>
