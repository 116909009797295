import { Component, inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { map, of, switchMap } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { TranslocoService } from '@jsverse/transloco';
import { AccountingStudentBalanceModel } from '~ngx-shared/models';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { ModelUtil } from '~ngx-shared/utils';
import { AuthorizationService } from '~ngx-shared/authentication';
import { FormlyService } from '~madrasa/services';

@UntilDestroy()
@Component({
  selector: 'app-create-update-student-balance-form-page',
  standalone: true,
  imports: [BasePageComponent, FormlyModule],
  templateUrl: './create-update-student-balance-form-page.component.html',
  styleUrl: './create-update-student-balance-form-page.component.scss'
})
export class CreateUpdateStudentBalanceFormPageComponent implements OnInit {
  readonly apollo = inject(Apollo);
  readonly activatedRoute = inject(ActivatedRoute);
  readonly loadingService = inject(LoadingService);
  readonly authorizationService = inject(AuthorizationService);
  readonly formlyService = inject(FormlyService);
  readonly translocoService = inject(TranslocoService);

  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.create_update_student_balance'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  ngOnInit(): void {
    this.loadingService.startLoading();

    this.activatedRoute.data
      .pipe(
        untilDestroyed(this),
        switchMap(data => {
          const result = !!data?.['crud'] && !data['crud']['is_new'] && !!data['crud']['id'];
          if (result) {
            return this.apollo
              .query<{ result: AccountingStudentBalanceModel }>({
                query: gql`
                  query ReadAccountingStudentBalanceId($id: bigint!) {
                    result: accounting_student_balance_by_pk(id: $id) {
                      id
                      amount
                      month_of_balance
                      description
                      cash_book_entry_id
                      entry_id
                      person_id

                      current_person_data {
                        academic_degree_prefix
                        academic_degree_suffix
                        first_name
                        last_name
                        person_id
                        date_of_birth
                      }
                    }
                  }
                `,
                variables: {
                  id: data['crud']['id']
                }
              })
              .pipe(map(queryResult => queryResult.data?.result));
          } else {
            return of(undefined);
          }
        })
      )
      .subscribe(result => {
        if (result) {
          let model = cloneDeep(result);

          if (model) {
            this.model = {
              ...model,
              month_of_balance: FormlyUtil.fromIsoDateString(model.month_of_balance),
              amount: model.amount ? model.amount / 100 : undefined,
              person_id: {
                label: ModelUtil.getFullName(model.current_person_data, this.translocoService),
                value: {
                  label: ModelUtil.getFullName(model.current_person_data, this.translocoService),
                  value: model.current_person_data
                }
              }
            };
          }
        }

        this.fields = [
          {
            key: 'id'
          },
          FormlyUtil.createRow([
            FormlyUtil.createDatePickerField('month_of_balance', {
              props: {
                label: 'for_month',
                dateFormat: 'mm/yy',
                view: 'month',
                showButtonBar: false,
                required: true
              }
            })
          ]),
          FormlyUtil.createRow([
            {
              ...this.formlyService.createPersonFieldConfig({
                required: true,
                forceSelection: true,
                conditions: field => {
                  return [
                    { latest_student: {} },
                    {
                      school_students_active: {
                        school_id: {
                          _eq: field.model?.school_id
                        }
                      }
                    }
                  ];
                }
              })
            }
          ]),
          FormlyUtil.createRow([
            FormlyUtil.createNumberField('amount', {
              props: {
                required: true,
                maxFractionDigits: 2,
                iconPre: 'pi pi-euro',
                min: Number.NEGATIVE_INFINITY
              }
            })
          ]),
          FormlyUtil.createRow([FormlyUtil.createTextAreaField('description')])
        ];

        this.loadingService.stopLoading();
      });

    this.submit = (formSaveModel: FormSaveModel) => {
      const input = {
        ...formSaveModel.input,
        month_of_balance: FormlyUtil.toIsoDateString(formSaveModel.input.month_of_balance),
        person_id: formSaveModel.input.person_id?.value?.value?.person_id,
        amount: formSaveModel.input.amount ? Math.round(formSaveModel.input.amount * 100) : null
      };

      const params: string[] = ['$input: accounting_student_balance_insert_input!'];
      const queries: string[] = [
        `
         result: insert_accounting_student_balance_one(object: $input) {
            __typename
         }
        `
      ];
      let variables: any = { input };

      if (this.model?.id) {
        ModelUtil.deleteKey(input);

        // Clear params
        params.length = 0;
        queries.length = 0;

        params.push('$id: bigint!', '$input: accounting_student_balance_set_input!');

        queries.push(`
          result: update_accounting_student_balance_by_pk(
            pk_columns: { id: $id }
            _set: $input
          ) {
            __typename
          }
        `);

        variables = { id: this.model.id, input };
      }

      return this.apollo.mutate({
        mutation: gql`
            mutation CreateUpdateAccountingStudentBalance(
              ${params.join('\n')}
            ) {
             ${queries.join('\n')}
            }
          `,
        variables
      });
    };
  }
}
