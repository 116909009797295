<lib-layout-base-page *transloco="let transloco">
  <app-list-tab-menu [items]="this.items()">
    <ng-template libTemplate="school_periodsKey">
      <app-school-period-list
        [showExport]="true"
        [showFilter]="true"
        [showSort]="true"
        [stateKey]="this.router.url + 'school-period-list'"></app-school-period-list>
    </ng-template>

    <ng-template libTemplate="organisationsKey">
      <app-organisation-list
        [showExport]="true"
        [showFilter]="true"
        [showSort]="true"
        [stateKey]="this.router.url + 'organisation-list'"></app-organisation-list>
    </ng-template>

    <ng-template libTemplate="subjectsKey">
      <app-subject-list
        [showExport]="true"
        [showFilter]="true"
        [showSort]="true"
        [stateKey]="this.router.url + 'subject-list'"></app-subject-list>
    </ng-template>

    <ng-template libTemplate="course_typesKey">
      <app-course-type-list
        [showExport]="true"
        [showFilter]="true"
        [showSort]="true"
        [stateKey]="this.router.url + 'course-type-list'"></app-course-type-list>
    </ng-template>

    <ng-template libTemplate="course_namesKey">
      <app-course-name-list
        [showExport]="true"
        [showFilter]="true"
        [showSort]="true"
        [stateKey]="this.router.url + 'course-name-list'"></app-course-name-list>
    </ng-template>

    <ng-template libTemplate="education_typesKey">
      <app-education-type-list
        [showExport]="true"
        [showFilter]="true"
        [showSort]="true"
        [stateKey]="this.router.url + 'education-type-list'"></app-education-type-list>
    </ng-template>

    <ng-template libTemplate="teacher_hourly_ratesKey">
      <app-teacher-hourly-rate-list
        [showFilter]="true"
        [showSort]="true"
        [stateKey]="this.router.url + 'teacher-hourly-rate-list'"></app-teacher-hourly-rate-list>
    </ng-template>

    <ng-template libTemplate="person_document_typesKey">
      <app-person-document-type-list
        [showFilter]="true"
        [showSort]="true"
        [stateKey]="this.router.url + 'person-document-type-list'"></app-person-document-type-list>
    </ng-template>

    <ng-template libTemplate="certificate_templatesKey">
      <app-certificate-template-list
        [showFilter]="true"
        [showSort]="true"
        [stateKey]="this.router.url + 'certificate-template-list'"></app-certificate-template-list>
    </ng-template>
  </app-list-tab-menu>
</lib-layout-base-page>
