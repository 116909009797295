<lib-layout-base-page *transloco="let transloco">
  <div class="flex flex-col">
    <p-tabView libTabViewQuery="t">
      <p-tabPanel header="{{ transloco('general_information') }}">
        <ng-template pTemplate="content">
          <div class="flex flex-col gap-6">
            <lib-detail [fields]="this.fields()">
              <ng-template let-data libTemplate="cash_book_entryKey">
                @if (data) {
                  <a
                    [routerLink]="['/', 'accounting', 'cash-book-entries', 'detail', data?.id]"
                    class="cursor-pointer hover:text-primary hover:underline">
                    <span>{{ transloco('cash_book_entry') }} - Nr.: {{ data?.number }}</span>
                  </a>
                } @else {
                  <span>------</span>
                }
              </ng-template>
            </lib-detail>

            <app-entry-row-list [entryId]="this.item()?.id!"></app-entry-row-list>

            <span class="block text-2xl">{{ transloco('receipts') }}</span>
            <app-file-table
              [files]="this.item()?.receipt_documents || []"
              namespace="receipt_document"></app-file-table>
          </div>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </div>
</lib-layout-base-page>
