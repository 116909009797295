import { Component } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { ButtonDirective } from 'primeng/button';
import { NgxPermissionsModule } from 'ngx-permissions';
import { Router, RouterLink } from '@angular/router';
import { BasePageComponent } from '~ngx-shared/layout';
import { AccountingStudentBalancePermission } from '~ngx-shared/models';
import { StudentBalanceListComponent } from '~madrasa/accounting/components/student-balance-list/student-balance-list.component';
import { StudentBalancePerMonthOfBalanceListComponent } from '~madrasa/accounting/components/student-balance-per-month-of-balance-list/student-balance-per-month-of-balance-list.component';

@Component({
  selector: 'app-student-balance-list-page',
  standalone: true,
  imports: [
    BasePageComponent,
    ButtonDirective,
    TranslocoDirective,
    RouterLink,
    NgxPermissionsModule,
    StudentBalanceListComponent,
    StudentBalancePerMonthOfBalanceListComponent
  ],
  templateUrl: './student-balance-list-page.component.html',
  styleUrl: './student-balance-list-page.component.scss'
})
export class StudentBalanceListPageComponent {
  protected readonly AccountingStudentBalancePermission = AccountingStudentBalancePermission;
  constructor(public router: Router) {}
}
