import { Component, inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Apollo, gql } from 'apollo-angular';
import { from, map, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { detect } from 'chardet';
import {
  BankDataImport,
  BankingDataImportService
} from '~madrasa/accounting/services/bank-data-import.service';
import { AccountingAccountCategoryEnum, AccountingAccountModel } from '~ngx-shared/models';
import { AccountingPeriodService } from '~madrasa/accounting/services/accounting-period.service';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { BasePageComponent } from '~ngx-shared/layout';

@UntilDestroy()
@Component({
  selector: 'app-create-bank-data-import-form-page',
  standalone: true,
  imports: [BasePageComponent, FormlyModule],
  templateUrl: './create-bank-data-import-form-page.component.html',
  styleUrl: './create-bank-data-import-form-page.component.scss'
})
export class CreateBankDataImportFormPageComponent implements OnInit {
  readonly apollo = inject(Apollo);
  readonly accountingPeriodService = inject(AccountingPeriodService);
  readonly bankDataImportService = inject(BankingDataImportService);

  form = new FormGroup({});
  model: BankDataImport = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.create_bank_data_import'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  ngOnInit(): void {
    this.fields = [
      FormlyUtil.createRow([
        FormlyUtil.createTextField('name', {
          props: {
            required: true
          }
        })
      ]),
      FormlyUtil.createRow([
        FormlyUtil.createSelectField('active_account_id', {
          props: {
            label: 'account',
            required: true,
            showClear: true,
            options: this.apollo
              .query<{
                result: AccountingAccountModel[];
              }>({
                query: gql`
                  query ReadAccountingAccounts($where: accounting_account_bool_exp = {}) {
                    result: accounting_account(
                      where: $where
                      order_by: [{ account_group: { number: asc } }, { number: asc }, { name: asc }]
                    ) {
                      id
                      full_name
                    }
                  }
                `,
                variables: {
                  where: {
                    account_group: {
                      accounting_period_id: {
                        _eq: this.accountingPeriodService.currentPeriod()?.id
                      },
                      category: {
                        _eq: AccountingAccountCategoryEnum.ACTIVE
                      }
                    }
                  }
                }
              })
              .pipe(
                map(queryResult =>
                  queryResult.data?.result?.map(item => ({
                    label: item.full_name,
                    value: item.id
                  }))
                )
              )
          }
        })
      ]),
      FormlyUtil.createRow([
        FormlyUtil.createFileField('file', {
          props: {
            required: true
          }
        })
      ])
    ];

    this.submit = (formSaveModel: FormSaveModel) => {
      return from((formSaveModel.input.file as File).arrayBuffer()).pipe(
        untilDestroyed(this),
        tap(arrayBuffer => {
          const encoding = detect(new Uint8Array(arrayBuffer)) || 'utf-8';
          const decoder = new TextDecoder(encoding);
          const fileContent = decoder.decode(arrayBuffer);
          this.bankDataImportService.saveImport({
            id: uuidv4(),
            name: formSaveModel.input.name,
            accounting_period_id: this.accountingPeriodService.currentPeriod()?.id,
            active_account_id: formSaveModel.input.active_account_id,
            file_size: formSaveModel.input.file?.size,
            file_name: formSaveModel.input.file?.name,
            file_content: fileContent,
            items: [],
            is_analyzed: false,
            is_done: false,
            incoming_amount: 0,
            outgoing_amount: 0,
            created_at: new Date(),
            updated_at: new Date()
          });
        })
      );
    };

    this.accountingPeriodService.getPeriods().subscribe();
  }
}
