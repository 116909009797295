<div *transloco="let transloco" class="flex flex-col space-y-5">
  @if (this.showFilter() || this.showSort()) {
    <div class="flex flex-wrap justify-stretch gap-5">
      <div class="min-w-fit flex-1">
        @if (this.showFilter()) {
          <lib-graph-ql-advanced-filter
            [graphQlAdvancedTable]="advancedTable"
            [graphQlTable]="this.graphQlTable"
            [stateKey]="this.stateKey()"></lib-graph-ql-advanced-filter>
        }
      </div>
      <div class="min-w-fit flex-1">
        @if (this.showSort()) {
          <lib-graph-ql-advanced-sort
            [graphQlAdvancedTable]="advancedTable"
            [graphQlTable]="this.graphQlTable"
            [stateKey]="this.stateKey()"></lib-graph-ql-advanced-sort>
        }
      </div>
    </div>
  }

  <lib-graph-ql-advanced-table
    #advancedTable
    [graphQlTable]="this.graphQlTable"
    [patchOptions]="this.graphQlPatchOptions"
    [showColumnFilter]="this.showColumnFilter()"
    [showExport]="this.showExport()"
    [stateKey]="this.stateKey()">
    @if (this.showCreateButton()) {
      <ng-template libTemplate="caption">
        <a
          [pTooltip]="transloco('new')"
          [routerLink]="['/', 'academy', 'courses', 'certificates', 'new']"
          [queryParams]="{
            organisation_id: this.organisationId(),
            school_id: this.schoolId(),
            course_id: this.courseId(),
            person_id: this.personId()
          }"
          class="p-button-success !p-2 text-white"
          icon="pi pi-plus"
          pButton
          tooltipPosition="top"
          type="button"></a>
      </ng-template>
    }
    <ng-template let-data libTemplate="created_by_current_person_dataCell">
      <div class="flex flex-col gap-2">
        <app-person-detail-link
          [person]="data.created_by_current_person_data"></app-person-detail-link>
      </div>
    </ng-template>
    <ng-template let-data libTemplate="current_person_dataCell">
      <div class="flex flex-col gap-2">
        <app-person-detail-link [person]="data.current_person_data"></app-person-detail-link>
      </div>
    </ng-template>
    <ng-template let-data libTemplate="actions">
      <div class="flex items-center justify-center gap-2">
        @let status =
          this.printService.status()[this.printService.CERTIFICATE + this.getRow(data)?.id];
        <button
          *ngxPermissionsOnly="this.AcademyCertificatePermission.CREATE"
          (click)="this.generate(data)"
          [disabled]="!status?.isOpening && status?.isDownloading"
          [loading]="status?.isOpening || false"
          [pTooltip]="transloco('print')"
          class="p-button-info p-button-text"
          icon="pi pi-print"
          pButton
          tooltipPosition="top"
          type="button"></button>
        <a
          *ngxPermissionsOnly="this.AcademyCertificatePermission.UPDATE"
          [pTooltip]="transloco('edit')"
          [routerLink]="['/', 'academy', 'courses', 'certificates', this.getRow(data)?.id]"
          class="p-button-warning p-button-text"
          icon="pi pi-pencil"
          pButton
          tooltipPosition="top"
          type="button"></a>
        @if (!data?.unit_teachers?.length) {
          <button
            (click)="this.delete(data)"
            *ngxPermissionsOnly="this.AcademyCertificatePermission.SOFT_DELETE"
            [pTooltip]="transloco('delete')"
            class="p-button-danger p-button-text"
            icon="pi pi-trash"
            pButton
            tooltipPosition="top"
            type="button"></button>
        }
      </div>
    </ng-template>
  </lib-graph-ql-advanced-table>
</div>
