import { Component, inject, OnInit, signal } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BasePageComponent } from '~ngx-shared/layout';
import { ListTabMenuComponent } from '~madrasa/core/components/list-tab-menu/list-tab-menu.component';
import { TemplateDirective } from '~ngx-shared/directives';
import { TravelCostListComponent } from '~madrasa/accounting/components/travel-cost-list/travel-cost-list.component';
import { AccountingPeriodService } from '~madrasa/accounting/services/accounting-period.service';
import { TravelCostPriceListComponent } from '~madrasa/accounting/components/travel-cost-price-list/travel-cost-price-list.component';
import { CostCenterListComponent } from '~madrasa/accounting/components/cost-center-list/cost-center-list.component';
import { AccountingPeriodListComponent } from '~madrasa/accounting/components/accounting-period-list/accounting-period-list.component';
import { OperatingCostListComponent } from '~madrasa/accounting/components/operating-cost-list/operating-cost-list.component';
import { OperatingCostTypeListComponent } from '~madrasa/accounting/components/operating-cost-type-list/operating-cost-type-list.component';

@UntilDestroy()
@Component({
  selector: 'app-accounting-management-page',
  standalone: true,
  imports: [
    BasePageComponent,
    ListTabMenuComponent,
    TranslocoDirective,
    TemplateDirective,
    TravelCostListComponent,
    TravelCostPriceListComponent,
    CostCenterListComponent,
    AccountingPeriodListComponent,
    OperatingCostListComponent,
    OperatingCostTypeListComponent
  ],
  templateUrl: './accounting-management-page.component.html',
  styleUrl: './accounting-management-page.component.scss'
})
export class AccountingManagementPageComponent implements OnInit {
  readonly router = inject(Router);
  readonly accountingPeriodService = inject(AccountingPeriodService);

  readonly items = signal<MenuItem[]>([]);

  ngOnInit(): void {
    this.accountingPeriodService.currentPeriod$.pipe(untilDestroyed(this)).subscribe(period => {
      if (period) {
        this.items.set([
          {
            id: 'accounting_periods'
          },
          {
            id: 'cost_centers',
            state: { titleAddon: period.id }
          },
          {
            id: 'travel_cost_prices',
            state: { titleAddon: period.id }
          },
          {
            id: 'operating_cost_types'
          }
        ]);
      }
    });
    this.accountingPeriodService.getPeriods().subscribe();
  }
}
