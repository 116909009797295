<div>
  <form (ngSubmit)="this.submitForm($event)" *transloco="let transloco" [formGroup]="this.form">
    <div>
      <formly-form
        [fields]="this.fields"
        [form]="this.form"
        [model]="this.model"
        [options]="this.options!"></formly-form>
      <ng-content select="[beforeSubmit]"></ng-content>
      <div class="flex space-x-2">
        <ng-content select="[submit]"></ng-content>
        @if (this.form.enabled) {
          @if (this.showSaveButton) {
            <button
              [icon]="this.saving ? 'pi pi-spin pi-spinner' : this.saveButtonIcon"
              [label]="transloco(this.saveButtonText)"
              [disabled]="this.saving"
              pButton
              type="submit"></button>
          }
          @if (this.showCancelButton) {
            <button
              (click)="this.cancel()"
              [disabled]="this.saving"
              class="p-button-outlined p-button-secondary"
              label="{{ transloco('cancel') }}"
              pButton
              type="button"></button>
          }
        }
      </div>
      <ng-content select="[afterSubmit]"></ng-content>
    </div>
  </form>
  @if (!this.environment.production && !this.environment.staging) {
    <div class="mt-5">
      <button
        (click)="this.showDebug = !this.showDebug"
        class="p-button-secondary p-button-outlined"
        label="Debug"
        pButton
        type="button"></button>
      @if (this.showDebug) {
        <div>
          @if (this.form) {
            <pre class="mt-2 bg-gray-200 p-2">{{ this.form.getRawValue() | json }}</pre>
          }
        </div>
      }
    </div>
  }
</div>
