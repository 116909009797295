import { Component, computed, effect, inject, input, signal } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs';
import { cloneDeep, sum } from 'lodash-es';
import { Router } from '@angular/router';
import { AccordionModule } from 'primeng/accordion';
import { TranslocoDirective } from '@jsverse/transloco';
import { TagModule } from 'primeng/tag';
import { NgxPermissionsModule } from 'ngx-permissions';
import { DetailComponent, DetailField } from '~ngx-shared/ui/detail/detail.component';
import {
  AcademyClassBookEntryStudentStatusEnum,
  AcademyCourseStudentStatusEnum,
  AccountingStudentBalancePermission,
  CorePersonDataModel,
  StaffStudentModel,
  StaffStudentPermission
} from '~ngx-shared/models';
import { BusyComponent } from '~ngx-shared/layout';
import { DataProviderOptionModel, GraphQlColumnModel } from '~ngx-shared/graph-ql';
import { ClassBookEntryListComponent } from '~madrasa/academy/components/class-book-entry-list/class-book-entry-list.component';
import { CourseListComponent } from '~madrasa/academy/components/course-list/course-list.component';
import { TemplateDirective } from '~ngx-shared/directives';
import { NoteListComponent } from '~madrasa/note/components/note-list/note-list.component';
import { FormSaveModel } from '~ngx-shared/formly';
import { NotePersonNoteTypeEnum } from '~ngx-shared/models/note';
import { StudentBalanceListComponent } from '~madrasa/accounting/components/student-balance-list/student-balance-list.component';

@Component({
  selector: 'app-student-detail',
  standalone: true,
  imports: [
    DetailComponent,
    BusyComponent,
    AccordionModule,
    ClassBookEntryListComponent,
    CourseListComponent,
    TranslocoDirective,
    TagModule,
    TemplateDirective,
    NoteListComponent,
    NgxPermissionsModule,
    StudentBalanceListComponent
  ],
  templateUrl: './student-detail.component.html',
  styleUrl: './student-detail.component.scss'
})
export class StudentDetailComponent {
  readonly apollo = inject(Apollo);
  readonly router = inject(Router);

  readonly inputPersonData = input<CorePersonDataModel | undefined>(undefined, {
    alias: 'personData'
  });
  readonly isLoading = signal(true);
  readonly personData = signal<CorePersonDataModel | undefined>(undefined);

  readonly fields = computed<DetailField[]>(() => {
    const item = this.personData();
    if (!item?.latest_student) {
      return [];
    }
    const fields: DetailField[] = [
      {
        label: 'updated_at',
        type: 'datetime',
        value: item.updated_at
      },
      {
        label: 'organisation',
        value: item.organisation_persons_active?.map(opa => opa.organisation?.name).join(', ')
      },
      {
        label: 'school',
        value: item.latest_student.school_students_active?.map(ssa => ssa.school?.name).join(', ')
      },
      {
        label: 'status',
        translate: true,
        value: 'status.' + item.latest_student.status
      },
      {
        label: 'has_photo_publish_approval',
        type: 'boolean',
        value: item.latest_student.has_photo_publish_approval
      },
      {
        label: 'registration_date',
        type: 'date',
        value: item.latest_student.registration_date
      },
      {
        label: 'debit_date',
        translate: true,
        value: 'debit_date.' + item.latest_student.debit_date
      },
      {
        label: 'course_price',
        type: 'currency',
        value: sum(item.latest_student?.latest_course_students_active?.map((x: any) => x.price))
      },
      {
        label: 'student_balance',
        type: 'currency',
        value: item.latest_student.balance
      }
    ];
    return fields;
  });

  readonly courseColumns = computed<GraphQlColumnModel[]>(() => {
    const personId = this.inputPersonData()?.person_id;
    return [
      {
        label: 'status',
        path: 'latest_course_students.status',
        query: `
          latest_course_students (limit: 1, where: { person_id: { _eq: ${personId || 0} } }) {
            status
          }
        `,
        patchResult: value =>
          value?.latest_course_students.map((student: any) => student.status).join(', '),
        filter: { isNotFilterable: true }
      },
      {
        label: 'course_price',
        path: 'latest_course_students.course_price',
        type: 'currency',
        query: `
          latest_course_students (limit: 1, where: { person_id: { _eq: ${personId || 0} } }) {
            course_price
          }
        `,
        patchResult: value =>
          value?.latest_course_students.map((student: any) => student.course_price).join(', '),
        filter: { isNotFilterable: true }
      }
    ];
  });

  readonly cbeColumns = computed<GraphQlColumnModel[]>(() => {
    const personId = this.inputPersonData()?.person_id;
    return [
      {
        label: 'status',
        path: 'class_book_entry_students.status',
        query: `
          class_book_entry_students (limit: 1, where: { person_id: { _eq: ${personId || 0} } }) {
            status
          }
        `,
        patchResult: value =>
          value?.class_book_entry_students.map((student: any) => student.status).join(', '),
        filter: { isNotFilterable: true }
      }
    ];
  });

  coursePatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  cbePatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;

  notePatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  notePatchInput: (formSaveModel: FormSaveModel, input: any) => any;

  protected readonly AcademyClassBookEntryStudentStatusEnum =
    AcademyClassBookEntryStudentStatusEnum;
  protected readonly AcademyCourseStudentStatusEnum = AcademyCourseStudentStatusEnum;
  protected readonly StaffStudentPermission = StaffStudentPermission;
  protected readonly AccountingStudentBalancePermission = AccountingStudentBalancePermission;

  constructor() {
    effect(
      () => {
        const personId = this.inputPersonData()?.person_id;
        if (personId) {
          this.isLoading.set(true);
          this.apollo
            .query<{ result: StaffStudentModel[] }>({
              query: gql`
                query ReadStaffStudentById($personId: bigint!) {
                  result: staff_latest_student(where: { person_id: { _eq: $personId } }, limit: 1) {
                    id
                    person_id
                    updated_at
                    has_photo_publish_approval
                    registration_date
                    debit_date
                    status
                    balance

                    school_students_active {
                      school {
                        name
                      }
                    }

                    latest_course_students_active {
                      price
                    }
                  }
                }
              `,
              variables: {
                personId: personId
              }
            })
            .pipe(map(queryResult => queryResult.data?.result?.[0]))
            .subscribe(staffStudent => {
              const personData = cloneDeep(this.inputPersonData());
              if (personData) {
                personData.latest_student = staffStudent;
                this.personData.set(personData);
              }
              this.isLoading.set(false);
            });

          this.coursePatchOptions = options => {
            options.filter = {
              ...options.filter,
              _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
            };
            if (Array.isArray(options?.filter?._and)) {
              options.filter._and = options.filter._and.filter(
                (cond: any) => !cond.course_students
              );
              options.filter._and.push({
                course_students: {
                  person_id: {
                    _eq: personId
                  }
                }
              });
            }
            return options;
          };

          this.cbePatchOptions = options => {
            options.filter = {
              ...options.filter,
              _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
            };
            if (Array.isArray(options?.filter?._and)) {
              options.filter._and = options.filter._and.filter(
                (cond: any) => !cond.class_book_entry_students
              );
              options.filter._and.push({
                class_book_entry_students: {
                  person_id: {
                    _eq: personId
                  }
                }
              });
            }
            return options;
          };

          this.notePatchOptions = options => {
            options.filter = {
              ...options.filter,
              _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
            };
            if (Array.isArray(options?.filter?._and)) {
              options.filter._and = options.filter._and.filter((cond: any) => !cond.person_id);
              options.filter._and.push({
                person_note_type: {
                  _eq: NotePersonNoteTypeEnum.STUDENT
                },
                person_id: {
                  _eq: personId
                }
              });
            }
            return options;
          };

          this.notePatchInput = (formSaveModel, input) => {
            return {
              ...input,
              person_id: personId,
              person_note_type: NotePersonNoteTypeEnum.STUDENT
            };
          };
        }
      },
      { allowSignalWrites: true }
    );
  }
}
