import { Routes } from '@angular/router';
import { ngxPermissionsGuard } from 'ngx-permissions';
import { LayoutComponent } from '~ngx-shared/layout';
import { crudGuard } from '~ngx-shared/guards/crud.guard';
import { crudResolver } from '~ngx-shared/resolvers/crud.resolver';
import { detailGuard } from '~ngx-shared/guards/detail.guard';
import { breadcrumbResolver } from '~ngx-shared/resolvers/breadcrumb.resolver';
import {
  AcademyCertificatePermission,
  AcademyClassBookEntryPermission,
  AcademyCourseNamePermission,
  AcademyCoursePermission,
  AcademyCourseTypePermission,
  AcademyOrganisationPermission,
  AcademyRoomPermission,
  AcademySchoolPeriodPermission,
  AcademySchoolPermission,
  AcademySubjectPermission,
  AcademyTeacherHourlyRatePermission,
  AcademyUnitPermission,
  AccountingCashBookEntryCategoryPermission,
  AccountingCashBookEntryPermission,
  AccountingStudentBalancePermission,
  CoreTenantPermission,
  FilePersonDocumentTypePermission,
  StaffDirectorPermission,
  StaffEducationTypePermission,
  StaffManagerPermission,
  StaffOrganisatorPermission,
  StaffParentPermission,
  StaffStudentPermission,
  StaffTeacherPermission
} from '~ngx-shared/models';
import { Role } from '~ngx-shared/authentication';
import { BankDataImportListPageComponent } from './accounting/pages/bank-data-import-list-page/bank-data-import-list-page.component';
import { CreateBankDataImportFormPageComponent } from './accounting/pages/create-bank-data-import-form-page/create-bank-data-import-form-page.component';
import { BankDataImportPageComponent } from './accounting/pages/bank-data-import-page/bank-data-import-page.component';
import { CashBookEntryCategoryListPageComponent } from './accounting/pages/cash-book-entry-category-list-page/cash-book-entry-category-list-page.component';
import { CreateUpdateCashBookEntryCategoryFormPageComponent } from './accounting/pages/create-update-cash-book-entry-category-form-page/create-update-cash-book-entry-category-form-page.component';
import { CashBookEntryListPageComponent } from './accounting/pages/cash-book-entry-list-page/cash-book-entry-list-page.component';
import { CashBookEntryDetailPageComponent } from './accounting/pages/cash-book-entry-detail-page/cash-book-entry-detail-page.component';
import { EntryListPageComponent } from './accounting/pages/entry-list-page/entry-list-page.component';
import { CreateUpdateOperatingCostTypeFormPageComponent } from './accounting/pages/create-update-operating-cost-type-form-page/create-update-operating-cost-type-form-page.component';
import { CreateUpdateOperatingCostFormPageComponent } from './accounting/pages/create-update-operating-cost-form-page/create-update-operating-cost-form-page.component';
import { CourseEvaluationsPageComponent } from './academy/pages/course-evaluations-page/course-evaluations-page.component';
import { TenantDetailPageComponent } from './core/pages/tenant-detail-page/tenant-detail-page.component';
import { NewRegistrationWizardPageComponent } from './staff/pages/new-registration-wizard-page/new-registration-wizard-page.component';
import { CreatePersonDataFormPageComponent } from './staff/pages/create-person-data-form-page/create-person-data-form-page.component';
import { CreateTeacherDataFormPageComponent } from './staff/pages/create-teacher-data-form-page/create-teacher-data-form-page.component';
import { CreateDirectorDataFormPageComponent } from './staff/pages/create-director-data-form-page/create-director-data-form-page.component';
import { CreateStudentDataFormPageComponent } from './staff/pages/create-student-data-form-page/create-student-data-form-page.component';
import { AcademyManagementPageComponent } from './academy/pages/academy-management-page/academy-management-page.component';
import { CreateParentDataFormPageComponent } from './staff/pages/create-parent-data-form-page/create-parent-data-form-page.component';
import { ParentListPageComponent } from './staff/pages/parent-list-page/parent-list-page.component';
import { ManagerListPageComponent } from './staff/pages/manager-list-page/manager-list-page.component';
import { CreateManagerDataFormPageComponent } from './staff/pages/create-manager-data-form-page/create-manager-data-form-page.component';
import { OrganisatorListPageComponent } from './staff/pages/organisator-list-page/organisator-list-page.component';
import { CreateOrganisatorDataFormPageComponent } from './staff/pages/create-organisator-data-form-page/create-organisator-data-form-page.component';
import { TenantListPageComponent } from './core/pages/tenant-list-page/tenant-list-page.component';
import { PageNotFoundPageComponent } from './core/pages/page-not-found-page/page-not-found-page.component';
import { CreateUpdateTenantFormPageComponent } from './core/pages/create-update-tenant-form-page/create-update-tenant-form-page.component';
import { ClassBookEntryListPageComponent } from './academy/pages/class-book-entry-list-page/class-book-entry-list-page.component';
import { ClassBookEntryDetailPageComponent } from './academy/pages/class-book-entry-detail-page/class-book-entry-detail-page.component';
import { UpdateClassBookEntryFormPageComponent } from './academy/pages/update-class-book-entry-form-page/update-class-book-entry-form-page.component';
import { CourseListPageComponent } from './academy/pages/course-list-page/course-list-page.component';
import { CourseDetailPageComponent } from './academy/pages/course-detail-page/course-detail-page.component';
import { CreateUpdateCourseFormPageComponent } from './academy/pages/create-update-course-form-page/create-update-course-form-page.component';
import { CreateUpdateOrganisationFormPageComponent } from './academy/pages/create-update-organisation-form-page/create-update-organisation-form-page.component';
import { OrganisationDetailPageComponent } from './academy/pages/organisation-detail-page/organisation-detail-page.component';
import { CreateUpdateSubjectFormPageComponent } from './academy/pages/create-update-subject-form-page/create-update-subject-form-page.component';
import { SchoolListPageComponent } from './academy/pages/school-list-page/school-list-page.component';
import { SchoolDetailPageComponent } from './academy/pages/school-detail-page/school-detail-page.component';
import { CreateUpdateSchoolFormPageComponent } from './academy/pages/create-update-school-form-page/create-update-school-form-page.component';
import { SubjectDetailPageComponent } from './academy/pages/subject-detail-page/subject-detail-page.component';
import { CreateUpdateUnitFormPageComponent } from './academy/pages/create-update-unit-form-page/create-update-unit-form-page.component';
import { UnitListPageComponent } from './academy/pages/unit-list-page/unit-list-page.component';
import { UnitDetailPageComponent } from './academy/pages/unit-detail-page/unit-detail-page.component';
import { CourseTypeDetailPageComponent } from './academy/pages/course-type-detail-page/course-type-detail-page.component';
import { CreateUpdateCourseTypeFormPageComponent } from './academy/pages/create-update-course-type-form-page/create-update-course-type-form-page.component';
import { CreateUpdateSchoolPeriodFormPageComponent } from './academy/pages/create-update-school-period-form-page/create-update-school-period-form-page.component';
import { SchoolPeriodDetailPageComponent } from './academy/pages/school-period-detail-page/school-period-detail-page.component';
import { TeacherListPageComponent } from './staff/pages/teacher-list-page/teacher-list-page.component';
import { DirectorListPageComponent } from './staff/pages/director-list-page/director-list-page.component';
import { StudentListPageComponent } from './staff/pages/student-list-page/student-list-page.component';
import { PersonDetailPageComponent } from './staff/pages/person-detail-page/person-detail-page.component';
import { PersonListPageComponent } from './staff/pages/person-list-page/person-list-page.component';
import { SettingsPageComponent } from './staff/pages/settings-page/settings-page.component';
import { DashboardPageComponent } from './academy/pages/dashboard-page/dashboard-page.component';
import { RoomListPageComponent } from './academy/pages/room-list-page/room-list-page.component';
import { RoomDetailPageComponent } from './academy/pages/room-detail-page/room-detail-page.component';
import { CreateUpdateRoomFormPageComponent } from './academy/pages/create-update-room-form-page/create-update-room-form-page.component';
import { CreateUpdateCourseNameFormPageComponent } from './academy/pages/create-update-course-name-form-page/create-update-course-name-form-page.component';
import { CourseNameDetailPageComponent } from './academy/pages/course-name-detail-page/course-name-detail-page.component';
import { TeacherHourlyRateDetailPageComponent } from './academy/pages/teacher-hourly-rate-detail-page/teacher-hourly-rate-detail-page.component';
import { CreateUpdateTeacherHourlyRateFormPageComponent } from './academy/pages/create-update-teacher-hourly-rate-form-page/create-update-teacher-hourly-rate-form-page.component';
import { CreateUpdateEducationTypeFormPageComponent } from './staff/pages/create-update-education-type-form-page/create-update-education-type-form-page.component';
import { ProfilePageComponent } from './staff/pages/profile-page/profile-page.component';
import { TeacherWageDetailPageComponent } from './accounting/pages/teacher-wage-detail-page/teacher-wage-detail-page.component';
import { CreateUpdatePersonDocumentTypeFormPageComponent } from './file/pages/create-update-person-document-type-form-page/create-update-person-document-type-form-page.component';
import { CreateUpdateCostCenterFormPageComponent } from './accounting/pages/create-update-cost-center-form-page/create-update-cost-center-form-page.component';
import { AccountGroupDetailPageComponent } from './accounting/pages/account-group-detail-page/account-group-detail-page.component';
import { CreateUpdateAccountGroupFormPageComponent } from './accounting/pages/create-update-account-group-form-page/create-update-account-group-form-page.component';
import { AccountDetailPageComponent } from './accounting/pages/account-detail-page/account-detail-page.component';
import { CreateUpdateAccountFormPageComponent } from './accounting/pages/create-update-account-form-page/create-update-account-form-page.component';
import { AccountPlanPageComponent } from './accounting/pages/account-plan-page/account-plan-page.component';
import { AccountPlanOverviewPageComponent } from './accounting/pages/account-plan-overview-page/account-plan-overview-page.component';
import { CreateUpdateCashBookEntryFormPageComponent } from './accounting/pages/create-update-cash-book-entry-form-page/create-update-cash-book-entry-form-page.component';
import { EntryDetailPageComponent } from './accounting/pages/entry-detail-page/entry-detail-page.component';
import { CreateEntryFormPageComponent } from './accounting/pages/create-entry-form-page/create-entry-form-page.component';
import { StudentBalanceListPageComponent } from './accounting/pages/student-balance-list-page/student-balance-list-page.component';
import { CreateUpdateStudentBalanceFormPageComponent } from './accounting/pages/create-update-student-balance-form-page/create-update-student-balance-form-page.component';
import { StudentBalanceDetailPageComponent } from './accounting/pages/student-balance-detail-page/student-balance-detail-page.component';
import { CreateUpdateTravelCostFormPageComponent } from './accounting/pages/create-update-travel-cost-form-page/create-update-travel-cost-form-page.component';
import { CreateUpdateTravelCostPriceFormPageComponent } from './accounting/pages/create-update-travel-cost-price-form-page/create-update-travel-cost-price-form-page.component';
import { AccountingManagementPageComponent } from './accounting/pages/accounting-management-page/accounting-management-page.component';
import { SettlementPageComponent } from './accounting/pages/settlement-page/settlement-page.component';
import { PrintManagementPageComponent } from './print/pages/print-management-page/print-management-page.component';
import { CreateUpdatePaperTemplateFormPageComponent } from './print/pages/create-update-paper-template-form-page/create-update-paper-template-form-page.component';
import { CreateUpdateCertificateTemplateFormPageComponent } from './academy/pages/create-update-certificate-template-form-page/create-update-certificate-template-form-page.component';
import { CreateUpdateCertificateFormPageComponent } from './academy/pages/create-update-certificate-form-page/create-update-certificate-form-page.component';
import { CreateUpdateCourseCertificateFormPageComponent } from './academy/pages/create-update-course-certificate-form-page/create-update-course-certificate-form-page.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: '/academy',
        pathMatch: 'full'
      },
      {
        path: 'core',
        canActivate: [ngxPermissionsGuard],
        data: {
          breadcrumb: 'system',
          permissions: {
            only: CoreTenantPermission.READ,
            redirectTo: '/'
          }
        },
        children: [
          {
            path: 'tenants',
            data: {
              breadcrumb: 'tenants',
              icon: 'pi pi-fw pi-building-columns'
            },
            children: [
              {
                path: '',
                component: TenantListPageComponent
              },
              {
                path: 'detail/:tenant-id',
                component: TenantDetailPageComponent,
                canActivate: [detailGuard('tenant-id')],
                resolve: { crud: crudResolver('tenant-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'tenant'
                }
              },
              {
                path: ':tenant-id',
                component: CreateUpdateTenantFormPageComponent,
                canActivate: [crudGuard('tenant-id')],
                resolve: {
                  crud: crudResolver('tenant-id'),
                  breadcrumb: breadcrumbResolver('tenant-id')
                },
                data: {
                  breadcrumbParamValue: 'tenant'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'accounting',
        data: {
          breadcrumb: 'accounting'
        },
        children: [
          {
            path: '',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'management',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'management',
              icon: 'pi pi-fw pi-cog',
              permissions: {
                only: [Role.LESSOR, Role.MANAGER],
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: AccountingManagementPageComponent
              },
              {
                path: 'travel-costs',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'travel_costs',
                  icon: 'pi pi-fw pi-money-bill',
                  permissions: {
                    only: [Role.LESSOR, Role.MANAGER],
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/accounting/management',
                    pathMatch: 'full'
                  },
                  {
                    path: 'prices',
                    data: {
                      breadcrumb: 'travel_cost_prices',
                      icon: 'pi pi-fw pi-money-bill'
                    },
                    children: [
                      {
                        path: '',
                        redirectTo: '/accounting/management',
                        pathMatch: 'full'
                      },
                      {
                        path: ':travel-cost-price-id',
                        component: CreateUpdateTravelCostPriceFormPageComponent,
                        canActivate: [crudGuard('travel-cost-price-id')],
                        resolve: {
                          crud: crudResolver('travel-cost-price-id'),
                          breadcrumb: breadcrumbResolver('travel-cost-price-id')
                        },
                        data: {
                          breadcrumbParamValue: 'travel_cost_price'
                        }
                      }
                    ]
                  }
                ]
              },
              {
                path: 'operating-costs',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'operating_costs',
                  icon: 'pi pi-fw pi-money-bill',
                  permissions: {
                    only: [Role.LESSOR, Role.MANAGER],
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/accounting/management',
                    pathMatch: 'full'
                  },
                  {
                    path: 'types',
                    data: {
                      breadcrumb: 'operating_cost_types',
                      icon: 'pi pi-fw pi-money-bill'
                    },
                    children: [
                      {
                        path: '',
                        redirectTo: '/accounting/management',
                        pathMatch: 'full'
                      },
                      {
                        path: ':operating-cost-type-id',
                        component: CreateUpdateOperatingCostTypeFormPageComponent,
                        canActivate: [crudGuard('operating-cost-type-id')],
                        resolve: {
                          crud: crudResolver('operating-cost-type-id'),
                          breadcrumb: breadcrumbResolver('operating-cost-type-id')
                        },
                        data: {
                          breadcrumbParamValue: 'operating_cost_type'
                        }
                      }
                    ]
                  }
                ]
              },
              {
                path: 'cost-centers',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'cost_centers',
                  icon: 'pi pi-fw pi-money-bill',
                  permissions: {
                    only: [Role.LESSOR, Role.MANAGER],
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/accounting/management',
                    pathMatch: 'full'
                  },
                  {
                    path: ':cost-center-id',
                    component: CreateUpdateCostCenterFormPageComponent,
                    canActivate: [crudGuard('cost-center-id')],
                    resolve: {
                      crud: crudResolver('cost-center-id'),
                      breadcrumb: breadcrumbResolver('cost-center-id')
                    },
                    data: {
                      breadcrumbParamValue: 'cost_center'
                    }
                  }
                ]
              }
            ]
          },
          {
            path: 'settlements',
            data: {
              breadcrumb: 'settlements',
              icon: 'pi pi-fw pi-cog'
            },
            children: [
              {
                path: '',
                component: SettlementPageComponent,
                canActivate: [ngxPermissionsGuard],
                data: {
                  permissions: {
                    only: [Role.LESSOR, Role.MANAGER],
                    redirectTo: '/'
                  }
                }
              },
              {
                path: 'teacher-wages',
                data: {
                  breadcrumb: 'teacher_wages',
                  icon: 'pi pi-fw pi-money-bill'
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/',
                    pathMatch: 'full'
                  },
                  {
                    path: 'detail/:teacher-wage-id',
                    component: TeacherWageDetailPageComponent,
                    canActivate: [detailGuard('teacher-wage-id')],
                    resolve: { crud: crudResolver('teacher-wage-id') },
                    data: {
                      breadcrumb: 'detail_value',
                      breadcrumbParamValue: 'teacher_wage'
                    }
                  }
                ]
              },
              {
                path: 'travel-costs',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'travel_costs',
                  icon: 'pi pi-fw pi-money-bill',
                  permissions: {
                    only: [Role.LESSOR, Role.MANAGER],
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/',
                    pathMatch: 'full'
                  },
                  {
                    path: ':travel-cost-id',
                    component: CreateUpdateTravelCostFormPageComponent,
                    canActivate: [crudGuard('travel-cost-id')],
                    resolve: {
                      crud: crudResolver('travel-cost-id'),
                      breadcrumb: breadcrumbResolver('travel-cost-id')
                    },
                    data: {
                      breadcrumbParamValue: 'travel_cost'
                    }
                  }
                ]
              },
              {
                path: 'operating-costs',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'operating_costs',
                  icon: 'pi pi-fw pi-money-bill',
                  permissions: {
                    only: [Role.LESSOR, Role.MANAGER],
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/',
                    pathMatch: 'full'
                  },
                  {
                    path: ':operating-cost-id',
                    component: CreateUpdateOperatingCostFormPageComponent,
                    canActivate: [crudGuard('operating-cost-id')],
                    resolve: {
                      crud: crudResolver('operating-cost-id'),
                      breadcrumb: breadcrumbResolver('operating-cost-id')
                    },
                    data: {
                      breadcrumbParamValue: 'operating_cost'
                    }
                  }
                ]
              }
            ]
          },
          {
            path: 'account-plan',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'account_plan',
              icon: 'pi pi-fw pi-money-bill',
              permissions: {
                only: [Role.LESSOR, Role.MANAGER],
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: AccountPlanOverviewPageComponent
              },
              {
                path: 'edit',
                component: AccountPlanPageComponent,
                data: {
                  breadcrumb: 'edit_value',
                  breadcrumbParamValue: 'account_plan',
                  icon: 'pi pi-fw pi-money-bill'
                }
              },
              {
                path: 'account-groups',
                data: {
                  breadcrumb: 'account_groups',
                  icon: 'pi pi-fw pi-money-bill'
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/accounting/account-plan',
                    pathMatch: 'full'
                  },
                  {
                    path: 'detail/:account-group-id',
                    component: AccountGroupDetailPageComponent,
                    canActivate: [detailGuard('account-group-id')],
                    resolve: { crud: crudResolver('account-group-id') },
                    data: {
                      breadcrumb: 'detail_value',
                      breadcrumbParamValue: 'account_group'
                    }
                  },
                  {
                    path: ':account-group-id',
                    component: CreateUpdateAccountGroupFormPageComponent,
                    canActivate: [crudGuard('account-group-id')],
                    resolve: {
                      crud: crudResolver('account-group-id'),
                      breadcrumb: breadcrumbResolver('account-group-id')
                    },
                    data: {
                      breadcrumbParamValue: 'account_group'
                    }
                  }
                ]
              },
              {
                path: 'accounts',
                data: {
                  breadcrumb: 'account',
                  icon: 'pi pi-fw pi-money-bill'
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/accounting/account-plan',
                    pathMatch: 'full'
                  },
                  {
                    path: 'detail/:account-id',
                    component: AccountDetailPageComponent,
                    canActivate: [detailGuard('account-id')],
                    resolve: { crud: crudResolver('account-id') },
                    data: {
                      breadcrumb: 'detail_value',
                      breadcrumbParamValue: 'account'
                    }
                  },
                  {
                    path: ':account-id',
                    component: CreateUpdateAccountFormPageComponent,
                    canActivate: [crudGuard('account-id')],
                    resolve: {
                      crud: crudResolver('account-id'),
                      breadcrumb: breadcrumbResolver('account-id')
                    },
                    data: {
                      breadcrumbParamValue: 'account'
                    }
                  }
                ]
              }
            ]
          },
          {
            path: 'cash-book-entries',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'cash_book_entries',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: AccountingCashBookEntryPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: CashBookEntryListPageComponent
              },
              {
                path: 'categories',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'cash_book_entry_categories',
                  icon: 'pi pi-fw pi-money-bill',
                  permissions: {
                    only: AccountingCashBookEntryCategoryPermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    component: CashBookEntryCategoryListPageComponent
                  },
                  {
                    path: ':cash-book-entry-category-id',
                    component: CreateUpdateCashBookEntryCategoryFormPageComponent,
                    canActivate: [ngxPermissionsGuard, crudGuard('cash-book-entry-category-id')],
                    resolve: {
                      crud: crudResolver('cash-book-entry-category-id'),
                      breadcrumb: breadcrumbResolver('cash-book-entry-category-id')
                    },
                    data: {
                      breadcrumbParamValue: 'cash_book_entry_category',
                      permissions: {
                        only: [
                          ...AccountingCashBookEntryCategoryPermission.CREATE,
                          ...AccountingCashBookEntryCategoryPermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'detail/:cash-book-entry-id',
                component: CashBookEntryDetailPageComponent,
                canActivate: [ngxPermissionsGuard, detailGuard('cash-book-entry-id')],
                resolve: { crud: crudResolver('cash-book-entry-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'cash_book_entry',
                  permissions: {
                    only: AccountingCashBookEntryPermission.READ,
                    redirectTo: '/'
                  }
                }
              },
              {
                path: ':cash-book-entry-id',
                component: CreateUpdateCashBookEntryFormPageComponent,
                canActivate: [ngxPermissionsGuard, crudGuard('cash-book-entry-id')],
                resolve: {
                  crud: crudResolver('cash-book-entry-id'),
                  breadcrumb: breadcrumbResolver('cash-book-entry-id')
                },
                data: {
                  breadcrumbParamValue: 'cash_book_entry',
                  permissions: {
                    only: [
                      ...AccountingCashBookEntryPermission.CREATE,
                      ...AccountingCashBookEntryPermission.UPDATE
                    ],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'entries',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'account_entries',
              icon: 'pi pi-fw pi-money-bill',
              permissions: {
                only: [Role.LESSOR, Role.MANAGER],
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: EntryListPageComponent
              },
              {
                path: 'detail/:entry-id',
                component: EntryDetailPageComponent,
                canActivate: [detailGuard('entry-id')],
                resolve: { crud: crudResolver('entry-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'account_entry'
                }
              },
              {
                path: ':entry-id',
                component: CreateEntryFormPageComponent,
                canActivate: [crudGuard('entry-id', true, false)],
                resolve: {
                  crud: crudResolver('entry-id'),
                  breadcrumb: breadcrumbResolver('entry-id')
                },
                data: {
                  breadcrumbParamValue: 'account_entry'
                }
              }
            ]
          },
          {
            path: 'student-balances',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'student_balances',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: AccountingStudentBalancePermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: StudentBalanceListPageComponent
              },
              {
                path: 'detail/:student-balance-id',
                component: StudentBalanceDetailPageComponent,
                canActivate: [detailGuard('student-balance-id')],
                resolve: { crud: crudResolver('student-balance-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'student_balance'
                }
              },
              {
                path: ':student-balance-id',
                component: CreateUpdateStudentBalanceFormPageComponent,
                canActivate: [ngxPermissionsGuard, crudGuard('student-balance-id')],
                resolve: {
                  crud: crudResolver('student-balance-id'),
                  breadcrumb: breadcrumbResolver('student-balance-id')
                },
                data: {
                  breadcrumbParamValue: 'student_balance',
                  permissions: {
                    only: [
                      ...AccountingStudentBalancePermission.CREATE,
                      ...AccountingStudentBalancePermission.UPDATE
                    ],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'bank-data-import',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'bank_data_import',
              icon: 'pi pi-fw pi-file-import',
              permissions: {
                only: [Role.LESSOR, Role.MANAGER],
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: BankDataImportListPageComponent
              },
              {
                path: 'new',
                component: CreateBankDataImportFormPageComponent,
                data: {
                  breadcrumb: 'add_value',
                  breadcrumbParamValue: 'bank_data_import'
                }
              },
              {
                path: ':bank-data-id',
                component: BankDataImportPageComponent,
                data: {
                  breadcrumb: 'edit_value',
                  breadcrumbParamValue: 'bank_data_import'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'academy',
        data: {
          breadcrumb: 'academy'
        },
        children: [
          {
            path: '',
            redirectTo: '/academy/dashboard',
            pathMatch: 'full'
          },
          {
            path: 'dashboard',
            component: DashboardPageComponent,
            data: {
              breadcrumb: 'dashboard',
              icon: 'pi pi-fw pi-home'
            }
          },
          {
            path: 'management',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'management',
              icon: 'pi pi-fw pi-cog',
              permissions: {
                only: [
                  ...AcademyOrganisationPermission.READ,
                  ...AcademySchoolPeriodPermission.READ,
                  ...AcademyCourseTypePermission.READ,
                  ...AcademySubjectPermission.READ,
                  ...AcademyTeacherHourlyRatePermission.READ
                ],
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: AcademyManagementPageComponent
              },
              {
                path: 'organisations',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'organisations',
                  icon: 'pi pi-fw pi-building-columns',
                  permissions: {
                    only: AcademyOrganisationPermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/academy/management',
                    pathMatch: 'full'
                  },
                  {
                    path: 'detail/:organisation-id',
                    component: OrganisationDetailPageComponent,
                    canActivate: [detailGuard('organisation-id')],
                    resolve: { crud: crudResolver('organisation-id') },
                    data: {
                      breadcrumb: 'detail_value',
                      breadcrumbParamValue: 'organisation'
                    }
                  },
                  {
                    path: ':organisation-id',
                    component: CreateUpdateOrganisationFormPageComponent,
                    canActivate: [ngxPermissionsGuard, crudGuard('organisation-id')],
                    resolve: {
                      crud: crudResolver('organisation-id'),
                      breadcrumb: breadcrumbResolver('organisation-id')
                    },
                    data: {
                      breadcrumbParamValue: 'organisation',
                      permissions: {
                        only: [
                          ...AcademyOrganisationPermission.CREATE,
                          ...AcademyOrganisationPermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'school-periods',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'school_periods',
                  icon: 'pi pi-fw pi-eject',
                  permissions: {
                    only: AcademySchoolPeriodPermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/academy/management',
                    pathMatch: 'full'
                  },
                  {
                    path: 'detail/:school-period-id',
                    component: SchoolPeriodDetailPageComponent,
                    canActivate: [detailGuard('school-period-id')],
                    resolve: { crud: crudResolver('school-period-id') },
                    data: {
                      breadcrumb: 'detail_value',
                      breadcrumbParamValue: 'school_period'
                    }
                  },
                  {
                    path: ':school-period-id',
                    component: CreateUpdateSchoolPeriodFormPageComponent,
                    canActivate: [ngxPermissionsGuard, crudGuard('school-period-id')],
                    resolve: {
                      crud: crudResolver('school-period-id'),
                      breadcrumb: breadcrumbResolver('school-period-id')
                    },
                    data: {
                      breadcrumbParamValue: 'school_period',
                      permissions: {
                        only: [
                          ...AcademySchoolPeriodPermission.CREATE,
                          ...AcademySchoolPeriodPermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'course-types',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'course_types',
                  icon: 'pi pi-fw pi-building-columns',
                  permissions: {
                    only: AcademyCourseTypePermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/academy/management',
                    pathMatch: 'full'
                  },
                  {
                    path: 'detail/:course-type-id',
                    component: CourseTypeDetailPageComponent,
                    canActivate: [detailGuard('course-type-id')],
                    resolve: { crud: crudResolver('course-type-id') },
                    data: {
                      breadcrumb: 'detail_value',
                      breadcrumbParamValue: 'course_type'
                    }
                  },
                  {
                    path: ':course-type-id',
                    component: CreateUpdateCourseTypeFormPageComponent,
                    canActivate: [ngxPermissionsGuard, crudGuard('course-type-id')],
                    resolve: {
                      crud: crudResolver('course-type-id'),
                      breadcrumb: breadcrumbResolver('course-type-id')
                    },
                    data: {
                      breadcrumbParamValue: 'course_type',
                      permissions: {
                        only: [
                          ...AcademyCourseTypePermission.CREATE,
                          ...AcademyCourseTypePermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'course-names',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'course_names',
                  icon: 'pi pi-fw pi-building-columns',
                  permissions: {
                    only: AcademyCourseNamePermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/academy/management',
                    pathMatch: 'full'
                  },
                  {
                    path: 'detail/:course-name-id',
                    component: CourseNameDetailPageComponent,
                    canActivate: [detailGuard('course-name-id')],
                    resolve: { crud: crudResolver('course-name-id') },
                    data: {
                      breadcrumb: 'detail_value',
                      breadcrumbParamValue: 'course_name'
                    }
                  },
                  {
                    path: ':course-name-id',
                    component: CreateUpdateCourseNameFormPageComponent,
                    canActivate: [ngxPermissionsGuard, crudGuard('course-name-id')],
                    resolve: {
                      crud: crudResolver('course-name-id'),
                      breadcrumb: breadcrumbResolver('course-name-id')
                    },
                    data: {
                      breadcrumbParamValue: 'course_name',
                      permissions: {
                        only: [
                          ...AcademyCourseNamePermission.CREATE,
                          ...AcademyCourseNamePermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'certificate-templates',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'certificate_templates',
                  icon: 'pi pi-fw pi-building-columns',
                  permissions: {
                    only: [Role.LESSOR, Role.MANAGER],
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/academy/management',
                    pathMatch: 'full'
                  },
                  {
                    path: ':certificate-template-id',
                    component: CreateUpdateCertificateTemplateFormPageComponent,
                    canActivate: [ngxPermissionsGuard, crudGuard('certificate-template-id')],
                    resolve: {
                      crud: crudResolver('certificate-template-id'),
                      breadcrumb: breadcrumbResolver('certificate-template-id')
                    },
                    data: {
                      breadcrumbParamValue: 'certificate_template',
                      permissions: {
                        only: [Role.LESSOR, Role.MANAGER],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'subjects',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'subjects',
                  icon: 'pi pi-fw pi-building-columns',
                  permissions: {
                    only: AcademySubjectPermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/academy/management',
                    pathMatch: 'full'
                  },
                  {
                    path: 'detail/:subject-id',
                    component: SubjectDetailPageComponent,
                    canActivate: [detailGuard('subject-id')],
                    resolve: { crud: crudResolver('subject-id') },
                    data: {
                      breadcrumb: 'detail_value',
                      breadcrumbParamValue: 'subject'
                    }
                  },
                  {
                    path: ':subject-id',
                    component: CreateUpdateSubjectFormPageComponent,
                    canActivate: [ngxPermissionsGuard, crudGuard('subject-id')],
                    resolve: {
                      crud: crudResolver('subject-id'),
                      breadcrumb: breadcrumbResolver('subject-id')
                    },
                    data: {
                      breadcrumbParamValue: 'subject',
                      permissions: {
                        only: [
                          ...AcademySubjectPermission.CREATE,
                          ...AcademySubjectPermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'education-types',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'education_type',
                  icon: 'pi pi-fw pi-cog',
                  permissions: {
                    only: StaffEducationTypePermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/academy/management',
                    pathMatch: 'full'
                  },
                  {
                    path: ':education-type-id',
                    component: CreateUpdateEducationTypeFormPageComponent,
                    canActivate: [ngxPermissionsGuard, crudGuard('education-type-id')],
                    resolve: {
                      crud: crudResolver('education-type-id'),
                      breadcrumb: breadcrumbResolver('education-type-id')
                    },
                    data: {
                      breadcrumbParamValue: 'education_type',
                      permissions: {
                        only: [
                          ...StaffEducationTypePermission.CREATE,
                          ...StaffEducationTypePermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'teacher-hourly-rates',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'teacher_hourly_rates',
                  icon: 'pi pi-fw pi-building-columns',
                  permissions: {
                    only: AcademyTeacherHourlyRatePermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/academy/management',
                    pathMatch: 'full'
                  },
                  {
                    path: 'detail/:teacher-hourly-rate-id',
                    component: TeacherHourlyRateDetailPageComponent,
                    canActivate: [detailGuard('teacher-hourly-rate-id')],
                    resolve: { crud: crudResolver('teacher-hourly-rate-id') },
                    data: {
                      breadcrumb: 'detail_value',
                      breadcrumbParamValue: 'teacher_hourly_rate'
                    }
                  },
                  {
                    path: ':teacher-hourly-rate-id',
                    component: CreateUpdateTeacherHourlyRateFormPageComponent,
                    canActivate: [ngxPermissionsGuard, crudGuard('teacher-hourly-rate-id')],
                    resolve: {
                      crud: crudResolver('teacher-hourly-rate-id'),
                      breadcrumb: breadcrumbResolver('teacher-hourly-rate-id')
                    },
                    data: {
                      breadcrumbParamValue: 'teacher_hourly_rate',
                      permissions: {
                        only: [
                          ...AcademyTeacherHourlyRatePermission.CREATE,
                          ...AcademyTeacherHourlyRatePermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'person-document-types',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'person_document_type',
                  icon: 'pi pi-fw pi-cog',
                  permissions: {
                    only: FilePersonDocumentTypePermission.READ,
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/academy/management',
                    pathMatch: 'full'
                  },
                  {
                    path: ':person-document-type-id',
                    component: CreateUpdatePersonDocumentTypeFormPageComponent,
                    canActivate: [ngxPermissionsGuard, crudGuard('person-document-type-id')],
                    resolve: {
                      crud: crudResolver('person-document-type-id'),
                      breadcrumb: breadcrumbResolver('person-document-type-id')
                    },
                    data: {
                      breadcrumbParamValue: 'person_document_type',
                      permissions: {
                        only: [
                          ...FilePersonDocumentTypePermission.CREATE,
                          ...FilePersonDocumentTypePermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              }
            ]
          },
          {
            path: 'class-book-entries',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'class_book_entries',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: AcademyClassBookEntryPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: ClassBookEntryListPageComponent
              },
              {
                path: 'detail/:class-book-entry-id',
                component: ClassBookEntryDetailPageComponent,
                canActivate: [detailGuard('class-book-entry-id')],
                resolve: { crud: crudResolver('class-book-entry-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'class_book_entry'
                }
              },
              {
                path: ':class-book-entry-id',
                component: UpdateClassBookEntryFormPageComponent,
                canActivate: [ngxPermissionsGuard, crudGuard('class-book-entry-id', false)],
                resolve: {
                  crud: crudResolver('class-book-entry-id'),
                  breadcrumb: breadcrumbResolver('class-book-entry-id')
                },
                data: {
                  breadcrumbParamValue: 'class_book_entry',
                  permissions: {
                    only: [
                      ...AcademyClassBookEntryPermission.CREATE,
                      ...AcademyClassBookEntryPermission.UPDATE
                    ],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'courses',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'courses',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: AcademyCoursePermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: CourseListPageComponent
              },
              {
                path: 'evaluations',
                component: CourseEvaluationsPageComponent,
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'evaluations',
                  icon: 'pi pi-fw pi-building-columns',
                  permissions: {
                    only: AcademyCoursePermission.CREATE,
                    redirectTo: '/'
                  }
                }
              },
              {
                path: 'certificates',
                data: {
                  breadcrumb: 'certificates',
                  icon: 'pi pi-fw pi-graduation-cap'
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/academy/courses',
                    pathMatch: 'full'
                  },
                  {
                    path: ':certificate-id',
                    component: CreateUpdateCertificateFormPageComponent,
                    canActivate: [ngxPermissionsGuard, crudGuard('certificate-id')],
                    resolve: {
                      crud: crudResolver('certificate-id'),
                      breadcrumb: breadcrumbResolver('certificate-id')
                    },
                    data: {
                      breadcrumbParamValue: 'certificate',
                      permissions: {
                        only: AcademyCertificatePermission.UPDATE,
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              },
              {
                path: 'detail/:course-id',
                component: CourseDetailPageComponent,
                canActivate: [detailGuard('course-id')],
                resolve: { crud: crudResolver('course-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'course'
                }
              },
              {
                path: ':course-id',
                canActivate: [crudGuard('course-id')],
                resolve: {
                  crud: crudResolver('course-id'),
                  breadcrumb: breadcrumbResolver('course-id')
                },
                data: {
                  breadcrumbParamValue: 'course'
                },
                children: [
                  {
                    path: '',
                    component: CreateUpdateCourseFormPageComponent,
                    canActivate: [ngxPermissionsGuard],
                    data: {
                      permissions: {
                        only: [
                          ...AcademyCoursePermission.CREATE,
                          ...AcademyCoursePermission.UPDATE
                        ],
                        redirectTo: '/'
                      }
                    }
                  },
                  {
                    path: 'certificates',
                    component: CreateUpdateCourseCertificateFormPageComponent,
                    canActivate: [ngxPermissionsGuard],
                    data: {
                      breadcrumb: 'certificates',
                      icon: 'pi pi-fw pi-graduation-cap',
                      permissions: {
                        only: AcademyCertificatePermission.UPDATE,
                        redirectTo: '/'
                      }
                    }
                  }
                ]
              }
            ]
          },
          {
            path: 'rooms',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'rooms',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: AcademyRoomPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: RoomListPageComponent
              },
              {
                path: 'detail/:room-id',
                component: RoomDetailPageComponent,
                canActivate: [detailGuard('room-id')],
                resolve: { crud: crudResolver('room-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'room'
                }
              },
              {
                path: ':room-id',
                component: CreateUpdateRoomFormPageComponent,
                canActivate: [ngxPermissionsGuard, crudGuard('room-id')],
                resolve: {
                  crud: crudResolver('room-id'),
                  breadcrumb: breadcrumbResolver('room-id')
                },
                data: {
                  breadcrumbParamValue: 'room',
                  permissions: {
                    only: [...AcademyRoomPermission.CREATE, ...AcademyRoomPermission.UPDATE],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'schools',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'schools',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: AcademySchoolPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: SchoolListPageComponent
              },
              {
                path: 'detail/:school-id',
                component: SchoolDetailPageComponent,
                canActivate: [detailGuard('school-id')],
                resolve: { crud: crudResolver('school-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'school'
                }
              },
              {
                path: ':school-id',
                component: CreateUpdateSchoolFormPageComponent,
                canActivate: [ngxPermissionsGuard, crudGuard('school-id')],
                resolve: {
                  crud: crudResolver('school-id'),
                  breadcrumb: breadcrumbResolver('school-id')
                },
                data: {
                  breadcrumbParamValue: 'school',
                  permissions: {
                    only: [...AcademySchoolPermission.CREATE, ...AcademySchoolPermission.UPDATE],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'units',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'units',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: AcademyUnitPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: UnitListPageComponent
              },
              {
                path: 'detail/:unit-id',
                component: UnitDetailPageComponent,
                canActivate: [detailGuard('unit-id')],
                resolve: { crud: crudResolver('unit-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'unit'
                }
              },
              {
                path: ':unit-id',
                component: CreateUpdateUnitFormPageComponent,
                canActivate: [ngxPermissionsGuard, crudGuard('unit-id')],
                resolve: {
                  crud: crudResolver('unit-id'),
                  breadcrumb: breadcrumbResolver('unit-id')
                },
                data: {
                  breadcrumbParamValue: 'unit',
                  permissions: {
                    only: [...AcademyUnitPermission.CREATE, ...AcademyUnitPermission.UPDATE],
                    redirectTo: '/'
                  }
                }
              }
            ]
          }
        ]
      },
      {
        path: 'staff',
        data: {
          breadcrumb: 'staff'
        },
        children: [
          {
            path: 'settings',
            data: {
              breadcrumb: 'settings',
              icon: 'pi pi-fw pi-cog'
            },
            children: [
              {
                path: '',
                component: SettingsPageComponent
              }
            ]
          },
          {
            path: 'profile',
            component: ProfilePageComponent,
            data: {
              breadcrumb: 'profile',
              icon: 'pi pi-fw pi-user'
            }
          },
          {
            path: 'people',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'people',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                except: Role.TEACHER,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: PersonListPageComponent
              },
              {
                path: 'detail/:person-id',
                component: PersonDetailPageComponent,
                canActivate: [detailGuard('person-id')],
                resolve: { crud: crudResolver('person-id') },
                data: {
                  breadcrumb: 'detail_value',
                  breadcrumbParamValue: 'person'
                }
              },
              {
                path: ':person-id',
                component: CreatePersonDataFormPageComponent,
                canActivate: [crudGuard('person-id')],
                resolve: {
                  crud: crudResolver('person-id'),
                  breadcrumb: breadcrumbResolver('person-id')
                },
                data: {
                  breadcrumbParamValue: 'person'
                }
              }
            ]
          },
          {
            path: 'new-registration',
            component: NewRegistrationWizardPageComponent,
            data: {
              breadcrumb: 'madrasa.components.staff.new_registration_wizard.new_registration',
              icon: 'pi pi-fw pi-building-columns'
            }
          },
          {
            path: 'students',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'students',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: StaffStudentPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: StudentListPageComponent
              },
              {
                path: 'detail/:student-id',
                redirectTo: '/staff/people/detail/:student-id'
              },
              {
                path: ':student-id',
                component: CreateStudentDataFormPageComponent,
                canActivate: [ngxPermissionsGuard, crudGuard('student-id')],
                resolve: {
                  crud: crudResolver('student-id'),
                  breadcrumb: breadcrumbResolver('student-id')
                },
                data: {
                  breadcrumbParamValue: 'student',
                  permissions: {
                    only: [...StaffStudentPermission.CREATE, ...StaffStudentPermission.UPDATE],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'teachers',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'teachers',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: StaffTeacherPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: TeacherListPageComponent
              },
              {
                path: 'detail/:teacher-id',
                redirectTo: '/staff/people/detail/:teacher-id'
              },
              {
                path: ':teacher-id',
                component: CreateTeacherDataFormPageComponent,
                canActivate: [ngxPermissionsGuard, crudGuard('teacher-id')],
                resolve: {
                  crud: crudResolver('teacher-id'),
                  breadcrumb: breadcrumbResolver('teacher-id')
                },
                data: {
                  breadcrumbParamValue: 'teacher',
                  permissions: {
                    only: [...StaffTeacherPermission.CREATE, ...StaffTeacherPermission.UPDATE],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'directors',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'directors',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: StaffStudentPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: DirectorListPageComponent
              },
              {
                path: 'detail/:director-id',
                redirectTo: '/staff/people/detail/:director-id'
              },
              {
                path: ':director-id',
                component: CreateDirectorDataFormPageComponent,
                canActivate: [ngxPermissionsGuard, crudGuard('director-id')],
                resolve: {
                  crud: crudResolver('director-id'),
                  breadcrumb: breadcrumbResolver('director-id')
                },
                data: {
                  breadcrumbParamValue: 'director',
                  permissions: {
                    only: [...StaffDirectorPermission.CREATE, ...StaffDirectorPermission.UPDATE],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'parents',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'parents',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: StaffParentPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: ParentListPageComponent
              },
              {
                path: 'detail/:parent-id',
                redirectTo: '/staff/people/detail/:parent-id'
              },
              {
                path: ':parent-id',
                component: CreateParentDataFormPageComponent,
                canActivate: [ngxPermissionsGuard, crudGuard('parent-id')],
                resolve: {
                  crud: crudResolver('parent-id'),
                  breadcrumb: breadcrumbResolver('parent-id')
                },
                data: {
                  breadcrumbParamValue: 'parent',
                  permissions: {
                    only: [...StaffParentPermission.CREATE, ...StaffParentPermission.UPDATE],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'managers',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'managers',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: StaffManagerPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: ManagerListPageComponent
              },
              {
                path: 'detail/:manager-id',
                redirectTo: '/staff/people/detail/:manager-id'
              },
              {
                path: ':manager-id',
                component: CreateManagerDataFormPageComponent,
                canActivate: [ngxPermissionsGuard, crudGuard('manager-id')],
                resolve: {
                  crud: crudResolver('manager-id'),
                  breadcrumb: breadcrumbResolver('manager-id')
                },
                data: {
                  breadcrumbParamValue: 'manager',
                  permissions: {
                    only: [...StaffManagerPermission.CREATE, ...StaffManagerPermission.UPDATE],
                    redirectTo: '/'
                  }
                }
              }
            ]
          },
          {
            path: 'organisators',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'organisators',
              icon: 'pi pi-fw pi-building-columns',
              permissions: {
                only: StaffOrganisatorPermission.READ,
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: OrganisatorListPageComponent
              },
              {
                path: 'detail/:organisator-id',
                redirectTo: '/staff/people/detail/:organisator-id'
              },
              {
                path: ':organisator-id',
                component: CreateOrganisatorDataFormPageComponent,
                canActivate: [ngxPermissionsGuard, crudGuard('organisator-id')],
                resolve: {
                  crud: crudResolver('organisator-id'),
                  breadcrumb: breadcrumbResolver('organisator-id')
                },
                data: {
                  breadcrumbParamValue: 'organisator',
                  permissions: {
                    only: [
                      ...StaffOrganisatorPermission.CREATE,
                      ...StaffOrganisatorPermission.UPDATE
                    ],
                    redirectTo: '/'
                  }
                }
              }
            ]
          }
        ]
      },
      {
        path: 'print',
        data: {
          breadcrumb: 'print'
        },
        children: [
          {
            path: '',
            redirectTo: '/',
            pathMatch: 'full'
          },
          {
            path: 'management',
            canActivate: [ngxPermissionsGuard],
            data: {
              breadcrumb: 'management',
              icon: 'pi pi-fw pi-cog',
              permissions: {
                only: [Role.LESSOR, Role.MANAGER],
                redirectTo: '/'
              }
            },
            children: [
              {
                path: '',
                component: PrintManagementPageComponent
              },
              {
                path: 'paper-templates',
                canActivate: [ngxPermissionsGuard],
                data: {
                  breadcrumb: 'paper_templates',
                  icon: 'pi pi-fw pi-print',
                  permissions: {
                    only: [Role.LESSOR, Role.MANAGER],
                    redirectTo: '/'
                  }
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/print/management',
                    pathMatch: 'full'
                  },
                  {
                    path: ':paper-template-id',
                    component: CreateUpdatePaperTemplateFormPageComponent,
                    canActivate: [crudGuard('paper-template-id')],
                    resolve: {
                      crud: crudResolver('paper-template-id'),
                      breadcrumb: breadcrumbResolver('paper-template-id')
                    },
                    data: {
                      breadcrumbParamValue: 'paper_template'
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'not-found',
        data: {
          breadcrumb: 'Not Found'
        },
        component: PageNotFoundPageComponent
      },
      { path: '**', redirectTo: 'not-found' }
    ]
  }
];
