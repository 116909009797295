import { TranslocoService } from '@jsverse/transloco';
import {
  CoreAcademicDegreesPrefix,
  CoreAcademicDegreesSuffix,
  CorePersonDataModel
} from '~ngx-shared/models';

export class ModelUtil {
  // Clear ids
  static deleteKey(item: any, key = 'id') {
    if (key in item) {
      delete item[key];
    }
  }

  static deleteKeys(item: any, keys: string[]) {
    keys.forEach(key => {
      this.deleteKey(item, key);
    });
  }

  static deleteNull(item: any) {
    Object.keys(item).forEach(key => {
      if (!item[key]) {
        this.deleteKey(item, key);
      }
    });
  }

  static deleteTypename(item: any) {
    this.deleteKey(item, '__typename');
  }

  static deleteTimestamps(item: any) {
    this.deleteKey(item, 'updated_at');
    this.deleteKey(item, 'created_at');
  }

  static getEnumKeyByValue(enumObj: any, value?: number): string | undefined {
    return Object.keys(enumObj).find(key => enumObj[key] === value);
  }

  static getValidId(id: string | null): number | undefined {
    const number = Number(id);
    return isNaN(number) ? undefined : number;
  }

  static getAddresses(addresses?: any[]): string[] {
    const values: string[] = [];
    if (addresses?.length) {
      for (const address of addresses) {
        let value = '';
        if (address.street) {
          value += address.street;
        }
        if (address.street_number) {
          value += ' ' + address.street_number;
        }
        if (address.postcode) {
          value += ', ' + address.postcode;
        }
        if (address.place) {
          value += ' ' + address.place;
        }
        values.push(value.trim().replace(/^,+|,+$/g, ''));
      }
    }
    return values;
  }

  static getEmailAddresses(emailAddresses?: any[]): string[] {
    const values: string[] = [];
    if (emailAddresses?.length) {
      for (const address of emailAddresses) {
        const email = address.email?.trim();
        if (email) {
          values.push(email);
        }
      }
    }
    return values;
  }

  static getPhoneNumbers(phone_numbers?: any[]): string[] {
    const values: string[] = [];
    if (phone_numbers?.length) {
      for (const phone_number of phone_numbers) {
        const phone = phone_number.phone?.trim();
        if (phone) {
          values.push(phone);
        }
      }
    }
    return values;
  }

  static getAcademicDegreePrefix(
    prefix: CoreAcademicDegreesPrefix[] | undefined,
    translocoService?: TranslocoService
  ): string {
    let value = '';
    if (prefix?.length) {
      value = prefix.reduce(
        (acc, item) => `${acc} ${translocoService?.translate(`academic_degree.${item}`)}`,
        ''
      );
    }
    return value;
  }

  static getAcademicDegreeSuffix(
    suffix: CoreAcademicDegreesSuffix[] | undefined,
    translocoService?: TranslocoService
  ): string {
    let value = '';
    if (suffix?.length) {
      value = suffix.reduce(
        (acc, item) => `${acc} ${translocoService?.translate(`academic_degree.${item}`)}`,
        ''
      );
    }
    return value;
  }

  static getFullName(data?: CorePersonDataModel, translocoService?: TranslocoService): string {
    let fullName = '';
    if (data) {
      fullName = `${data.first_name} ${data.last_name}`;
      const prefix = this.getAcademicDegreePrefix(data.academic_degree_prefix, translocoService);
      if (prefix) {
        fullName = `${prefix} ${fullName}`;
      }
      const suffix = this.getAcademicDegreeSuffix(data.academic_degree_suffix, translocoService);
      if (suffix) {
        fullName = `${fullName} ${suffix}`;
      }
    }
    return fullName;
  }
}
