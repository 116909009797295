import { Component, Type } from '@angular/core';
import { FieldArrayType, FieldArrayTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FieldWrapperProps } from '../field-wrapper/field-wrapper.component';

interface RepeatProps extends FieldWrapperProps {
  addText?: string;
  addTextValue?: string;

  initialCount?: number;
  maxCount?: number;
  minCount?: number;

  canAdd?: (field: FormlyFieldConfig<RepeatProps>) => boolean;
  canRemove?: (field: FormlyFieldConfig<RepeatProps>, index: number) => boolean;

  disableTopMargin?: boolean;
}

export interface RepeatFieldConfig extends FormlyFieldConfig<RepeatProps> {
  type?: 'repeat' | Type<RepeatTypeComponent>;
}

@Component({
  selector: 'lib-formly-repeat-type',
  templateUrl: './repeat-type.component.html',
  styleUrl: './repeat-type.component.css'
})
export class RepeatTypeComponent extends FieldArrayType<FieldArrayTypeConfig<RepeatProps>> {}
