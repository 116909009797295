import { Component, effect, input, OnInit, ViewChild } from '@angular/core';
import { ButtonDirective } from 'primeng/button';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslocoDirective } from '@jsverse/transloco';
import { RouterLink } from '@angular/router';
import { TooltipModule } from 'primeng/tooltip';
import { gql } from 'apollo-angular';
import { ConfirmationService } from '~ngx-shared/layout';
import { TemplateDirective } from '~ngx-shared/directives';
import {
  DataProviderOptionModel,
  GraphQlAdvancedFilterComponent,
  GraphQlAdvancedSortComponent,
  GraphQlAdvancedTableComponent,
  GraphQlColumnModel,
  GraphQlTableModel
} from '~ngx-shared/graph-ql';
import { StaffEducationTypeModel, StaffEducationTypePermission } from '~ngx-shared/models';

@Component({
  selector: 'app-education-type-list',
  standalone: true,
  imports: [
    ButtonDirective,
    GraphQlAdvancedTableComponent,
    TemplateDirective,
    TranslocoDirective,
    RouterLink,
    TooltipModule,
    NgxPermissionsModule,
    GraphQlAdvancedFilterComponent,
    GraphQlAdvancedSortComponent
  ],
  templateUrl: './education-type-list.component.html',
  styleUrl: './education-type-list.component.scss'
})
export class EducationTypeListComponent implements OnInit {
  showFilter = input<boolean>(false);
  showSort = input<boolean>(false);
  showExport = input<boolean>(false);
  showColumnFilter = input<boolean>(true);
  stateKey = input<string>();
  patchOptions = input<(options: DataProviderOptionModel) => DataProviderOptionModel>();

  @ViewChild('advancedTable') tableComponent: GraphQlAdvancedTableComponent;

  graphQlPatchOptions: (options: DataProviderOptionModel) => DataProviderOptionModel;
  graphQlTable: GraphQlTableModel;
  protected readonly AcademyEducationTypePermission = StaffEducationTypePermission;

  constructor(private confirmationService: ConfirmationService) {
    effect(() => {
      this.graphQlPatchOptions = options => {
        const patchOptions = this.patchOptions();
        if (patchOptions) {
          options = patchOptions(options);
        }

        // Add default sorting by updated_at
        if (!options.sortBy?.length) {
          options.sortBy = [{ rank: 'asc_nulls_last' }];
        }

        return options;
      };
    });
  }

  ngOnInit() {
    const columns: GraphQlColumnModel[] = [
      {
        path: 'id',
        type: 'number',
        classHeader: 'text-center',
        classBody: 'text-center',
        sort: { isSortable: true },
        filter: { type: 'number' }
      },
      {
        path: 'name',
        filter: { type: 'string' },
        sort: { isSortable: true }
      },
      {
        path: 'rank',
        type: 'number',
        filter: { type: 'number' },
        sort: { isSortable: true }
      },
      {
        path: 'created_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'updated_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        label: 'education_path_count',
        path: 'education_paths_aggregate.count',
        query: `education_paths_aggregate { aggregate { count } }`,
        filter: { isNotFilterable: true },
        sort: { isSortable: true },
        patchResult: (result: any) => result?.education_paths_aggregate?.aggregate?.count
      }
    ];

    this.graphQlTable = {
      table: 'staff_education_type',
      isPaginated: true,

      showCurrentPageReport: true,
      columns: columns
    };
  }

  getRow(data: any): StaffEducationTypeModel | undefined {
    return data;
  }

  delete(data: any) {
    const row = this.getRow(data);
    if (row) {
      let mutation: any = gql`
        mutation SoftDeleteStaffEducationTypeById($id: bigint!) {
          result: update_staff_education_type_by_pk(
            pk_columns: { id: $id }
            _set: { deleted_at: "now()" }
          ) {
            __typename
          }
        }
      `;

      this.confirmationService.confirmDeleteApollo({
        name: row.name,
        mutationOptions: {
          mutation,
          variables: {
            id: row.id
          }
        },
        success: () => this.tableComponent?.updateTable()
      });
    }
  }
}
