<div *transloco="let transloco" class="flex flex-col space-y-5">
  <lib-graph-ql-advanced-table
    #advancedTable
    [graphQlTable]="this.graphQlTable()"
    [patchOptions]="this.graphQlPatchOptions"
    [showColumnFilter]="this.showColumnFilter()"
    [showExport]="this.showExport()"
    [stateKey]="this.stateKey()">
    @if ((this.accountingPeriodService.activePeriod()?.id || 0) < this.year()) {
      <ng-template libTemplate="caption">
        <button
          (click)="this.createNewPeriod()"
          [label]="transloco('create_value', { value: transloco('accounting_period') })"
          [loading]="this.accountingPeriodService.isLoading()"
          class="p-button-success text-white"
          icon="pi pi-cog"
          pButton
          type="button"></button>
      </ng-template>
    }

    <ng-template let-data libTemplate="actions">
      <div class="flex items-center justify-center gap-2">
        @if (this.getRow(data)?.id !== this.accountingPeriodService.currentPeriod()?.id) {
          <button
            (click)="this.setCurrentPeriod(data)"
            [pTooltip]="transloco('activate')"
            class="p-button-primary p-button-text mr-2"
            icon="pi pi-check-circle"
            pButton
            tooltipPosition="top"
            type="button"></button>
        }
      </div>
    </ng-template>
  </lib-graph-ql-advanced-table>
</div>
