import { Component, inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, of, switchMap } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { AccountingCostCenterModel } from '~ngx-shared/models';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { ModelUtil } from '~ngx-shared/utils';
import { AccountingPeriodService } from '~madrasa/accounting/services/accounting-period.service';

@UntilDestroy()
@Component({
  selector: 'app-create-update-cost-center-form-page',
  standalone: true,
  imports: [BasePageComponent, FormlyModule],
  templateUrl: './create-update-cost-center-form-page.component.html',
  styleUrl: './create-update-cost-center-form-page.component.scss'
})
export class CreateUpdateCostCenterFormPageComponent implements OnInit {
  readonly apollo = inject(Apollo);
  readonly activatedRoute = inject(ActivatedRoute);
  readonly loadingService = inject(LoadingService);
  readonly accountingPeriodService = inject(AccountingPeriodService);

  form = new FormGroup({});
  model: AccountingCostCenterModel = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.create_update_cost_center'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  ngOnInit(): void {
    this.loadingService.startLoading();

    this.activatedRoute.data
      .pipe(
        untilDestroyed(this),
        switchMap(data => {
          const result = !!data?.['crud'] && !data['crud']['is_new'] && !!data['crud']['id'];
          if (result) {
            return this.apollo
              .query<{ result: AccountingCostCenterModel }>({
                query: gql`
                  query ReadAccountingCostCenterId($id: bigint!) {
                    result: accounting_cost_center_by_pk(id: $id) {
                      id
                      name
                    }
                  }
                `,
                variables: {
                  id: data['crud']['id']
                }
              })
              .pipe(map(queryResult => queryResult.data?.result));
          } else {
            return of(undefined);
          }
        })
      )
      .subscribe(result => {
        if (result) {
          this.model = cloneDeep(result);
        }

        this.fields = [
          {
            key: 'id'
          },
          FormlyUtil.createRow([
            FormlyUtil.createTextField('name', {
              props: {
                required: true
              }
            })
          ])
        ];

        this.loadingService.stopLoading();
      });

    this.submit = (formSaveModel: FormSaveModel) => {
      const input = {
        ...formSaveModel.input,
        accounting_period_id: this.accountingPeriodService.currentPeriod()?.id
      };

      let options: any = {
        mutation: gql`
          mutation CreateAccountingCostCenter($input: accounting_cost_center_insert_input!) {
            result: insert_accounting_cost_center_one(object: $input) {
              __typename
            }
          }
        `,
        variables: {
          input
        }
      };

      if (this.model?.id) {
        ModelUtil.deleteKey(input);
        ModelUtil.deleteKey(input, 'accounting_period_id');

        options = {
          mutation: gql`
            mutation UpdateAccountingCostCenter(
              $id: bigint!
              $input: accounting_cost_center_set_input!
            ) {
              result: update_accounting_cost_center_by_pk(pk_columns: { id: $id }, _set: $input) {
                __typename
              }
            }
          `,
          variables: {
            id: this.model.id,
            input
          }
        };
      }

      return this.apollo.mutate(options);
    };

    this.accountingPeriodService.getPeriods().subscribe();
  }
}
