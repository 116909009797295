import { Component, OnInit, signal } from '@angular/core';
import { ButtonDirective } from 'primeng/button';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { Router } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MenuModule } from 'primeng/menu';
import { sum } from 'lodash-es';
import { BasePageComponent } from '~ngx-shared/layout';
import { StaffStudentPermission } from '~ngx-shared/models';
import { AuthorizationService } from '~ngx-shared/authentication';
import { SearchPersonFormDialogComponent } from '~madrasa/staff/components/search-person-form-dialog/search-person-form-dialog.component';
import { DialogUtil } from '~ngx-shared/utils/dialog.util';
import { TenantService } from '~madrasa/services';
import { StudentListComponent } from '~madrasa/staff/components/student-list/student-list.component';
import { GraphQlColumnModel } from '~ngx-shared/graph-ql';

@UntilDestroy()
@Component({
  selector: 'app-student-list-page',
  standalone: true,
  imports: [
    BasePageComponent,
    ButtonDirective,
    TranslocoDirective,
    NgxPermissionsModule,
    MenuModule,
    StudentListComponent
  ],
  templateUrl: './student-list-page.component.html',
  styleUrl: './student-list-page.component.scss'
})
export class StudentListPageComponent implements OnInit {
  readonly items = signal<MenuItem[]>([]);
  readonly columns = signal<GraphQlColumnModel[]>([]);

  protected readonly StaffStudentPermission = StaffStudentPermission;

  constructor(
    public authorizationService: AuthorizationService,
    private translocoService: TranslocoService,
    private dialogService: DialogService,
    public router: Router,
    public tenantService: TenantService
  ) {}

  ngOnInit() {
    this.translocoService
      .selectTranslate(['new', 'madrasa.components.staff.create_from_existing_person'])
      .pipe(untilDestroyed(this))
      .subscribe(translated => {
        this.items.set([
          {
            label: translated[0],
            icon: 'pi pi-plus',
            routerLink: ['.', 'new']
          },
          {
            label: translated[1],
            icon: 'pi pi-arrows-h',
            command: () => {
              this.dialogService
                .open(SearchPersonFormDialogComponent, {
                  ...DialogUtil.BASE_DIALOG_CONFIG,
                  header: this.translocoService.translate(
                    'madrasa.components.staff.select_from_existing_person'
                  )
                })
                .onClose.pipe(untilDestroyed(this))
                .subscribe(result => {
                  if (result?.input?.person_id?.value?.value?.person_id) {
                    void this.router.navigate(
                      [this.router.url, result.input.person_id.value.value.person_id],
                      { queryParams: { is_new: true } }
                    );
                  }
                });
            }
          }
        ]);
      });

    this.columns.set([
      this.tenantService.currentTenant?.are_course_names_forced
        ? {
            label: 'course',
            path: 'latest_course_students_active.course.course_name.name',
            filter: { type: 'string' },
            sort: { isSortable: false }
          }
        : {
            label: 'course',
            path: 'latest_course_students_active.course.name',
            filter: { type: 'string' },
            sort: { isSortable: false }
          },
      {
        label: 'course_price',
        path: 'latest_course_students_active.price',
        patchResult: (result: any) =>
          sum(result.latest_course_students_active?.map((x: any) => x.price)),
        type: 'currency',
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        hidden: true
      },
      {
        label: 'student_balance',
        path: 'latest_student.balance',
        type: 'currency',
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        hidden: true
      }
    ]);
  }
}
