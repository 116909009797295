import { Component, effect, input, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs';
import { ButtonDirective } from 'primeng/button';
import { PrimeTemplate } from 'primeng/api';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TranslocoDirective } from '@jsverse/transloco';
import { KeyProperties } from '~ngx-shared/models';
import {
  GraphQlAdvancedTableComponent,
  GraphQlSortBy,
  GraphQlSortColumnModel,
  GraphQlSortModel,
  GraphQlTableModel
} from '~ngx-shared/graph-ql';
import { GraphQlService } from '../../services/graph-ql.service';

interface GraphQlSortColumn extends KeyProperties {
  label?: string;
  path?: string;
}

interface GraphQlSort extends KeyProperties {
  column?: GraphQlSortColumn;
  sortBy?: GraphQlSortBy;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'lib-graph-ql-advanced-sort',
  templateUrl: './graph-ql-advanced-sort.component.html',
  styleUrls: ['./graph-ql-advanced-sort.component.css'],
  standalone: true,
  imports: [TranslocoDirective, DropdownModule, FormsModule, PrimeTemplate, ButtonDirective]
})
export class GraphQlAdvancedSortComponent implements OnInit {
  @Input() graphQlAdvancedTable?: GraphQlAdvancedTableComponent;

  graphQlTable = input.required<GraphQlTableModel>();
  stateKey = input<string | undefined>(undefined);

  columns: GraphQlSortColumnModel[] = [];
  sorts: GraphQlSort[] = [];

  sortOptions: { label: string; value: GraphQlSortBy }[] = [
    'asc_nulls_last',
    'desc_nulls_last'
  ].map(value => ({
    label: value,
    value: <GraphQlSortBy>value
  }));

  isUpdatingState = false;

  subscription: Subscription | undefined;

  private _updateSortBy$ = new Subject<GraphQlSortModel[] | undefined>();

  constructor(private graphQlService: GraphQlService) {
    effect(() => {
      // Get sort columns from table
      const columns = this.graphQlTable()?.columns;
      if (columns) {
        this.columns = columns
          .filter(column => !!column.sort?.path || (!!column.path && !!column.sort?.isSortable))
          .map(column => {
            column.sort = {
              ...column.sort,
              label: column.sort?.label || column.sort?.path || column.label || column.path,
              path: column.sort?.path || column.path
            };
            return column.sort;
          });
      }
      if (this.columns?.length) {
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
        if (this.stateKey()) {
          this.subscription = this.graphQlService
            .sortBy$(this.stateKey()!)
            .pipe(untilDestroyed(this))
            .subscribe(state => {
              if (!this.isUpdatingState) {
                this.sorts = this._convertToSorts(state);
              }
            });
        }
      }
    });
  }

  ngOnInit() {
    this._updateSortBy$
      .pipe(distinctUntilChanged(), debounceTime(200), untilDestroyed(this))
      .subscribe(sortBy => {
        this.isUpdatingState = true;
        this.graphQlService.updateSort(this.stateKey(), sortBy);
        this.isUpdatingState = false;
      });
  }

  addSortBy() {
    this.sorts.push({});
  }

  removeSortBy(index: number) {
    this.sorts.splice(index, 1);
    this.updateState();
  }

  updateState(sort?: GraphQlSort) {
    if (this.stateKey()) {
      if (sort && !sort.sortBy) {
        // Set sortBy.sort_by to default value
        sort.sortBy = 'asc_nulls_last';
      }
      this._updateSortBy$.next(this._convertToSortQuery());
    }
  }

  // Convert sorts to object for state update
  private _convertToSortQuery(): GraphQlSortModel[] {
    const sorts: GraphQlSortModel[] = [];
    if (this.sorts && this.sorts.length) {
      this.sorts.forEach(fSort => {
        if (fSort.column?.path && fSort.sortBy) {
          const sort: GraphQlSortModel = {};
          const path = fSort.column.path.split('.');
          const last = path.pop();
          if (last) {
            path.reduce<KeyProperties>(function (tempWhere, tempKey) {
              return (tempWhere[tempKey] = tempWhere[tempKey] || {});
            }, sort)[last] = fSort.sortBy;
          } else {
            sort[fSort.column.path] = fSort.sortBy;
          }
          sorts.push(sort);
        }
      });
    }
    return sorts;
  }

  // Converts object to sorts
  // We need to make it recursive to support nested objects
  // Example from: {
  //  a: {
  //    b: {
  //      c: 'asc_nulls_last'
  //    }
  //  }
  // }
  // To: [{ selectedColumn: { path: 'a.b.c', label: 'c' }, sortBy: 'asc_nulls_last' }]
  private _convertToSorts(sort?: GraphQlSortModel[]): GraphQlSort[] {
    const sorts: GraphQlSort[] = [];
    sort?.forEach(fSort => {
      sorts.push(...this._convertObjectToSortsHelper(fSort));
    });
    return sorts;
  }

  private _convertObjectToSortsHelper(sort: GraphQlSortModel, path: string[] = []): GraphQlSort[] {
    const sorts: GraphQlSort[] = [];
    Object.keys(sort).forEach(key => {
      const value = sort[key];
      if (typeof value === 'object' && value !== null) {
        sorts.push(...this._convertObjectToSortsHelper(value, [...path, key]));
      } else {
        const column = this.columns.find(column => column.path === [...path, key].join('.'));
        if (column) {
          sorts.push({ column, sortBy: value as GraphQlSortBy });
        }
      }
    });
    return sorts;
  }
}
