<lib-layout-base-page *transloco="let transloco">
  <app-list-tab-menu [items]="this.items()">
    <ng-template libTemplate="course_invoiceKey">
      <app-course-invoice-list></app-course-invoice-list>
    </ng-template>

    <ng-template libTemplate="teacher_wagesKey">
      <app-calculate-teacher-wage-list></app-calculate-teacher-wage-list>
    </ng-template>

    <ng-template libTemplate="travel_costsKey">
      <app-travel-cost-list
        [accountingPeriodId]="this.accountingPeriodService.currentPeriod()?.id"
        [stateKey]="this.router.url + 'travel-cost-list'"></app-travel-cost-list>
    </ng-template>

    <ng-template libTemplate="operating_costsKey">
      <app-operating-cost-list
        [stateKey]="this.router.url + 'operating-cost-list'"></app-operating-cost-list>
    </ng-template>
  </app-list-tab-menu>
</lib-layout-base-page>
