import { FieldType, FormlyFieldConfig, FormlyFieldProps } from '@ngx-formly/core';
import { Type } from '@angular/core';
import { format, parseISO } from 'date-fns';
import { InputMaskFieldConfig } from '~ngx-shared/formly/input-mask-type/input-mask-type.component';
import { ColorPickerFieldConfig } from '~ngx-shared/formly/color-type/color-type.component';
import { EditorFieldConfig } from '~ngx-shared/formly/editor-type/editor-type.component';
import { FieldWrapperProps } from '../field-wrapper/field-wrapper.component';
import { TextFieldConfig } from '../text-type/text-type.component';
import { CheckboxFieldConfig } from '../checkbox-type/checkbox-type.component';
import { DatepickerFieldConfig } from '../date-type/date-type.component';
import { SelectFieldConfig } from '../select-type/select-type.component';
import { NumberFieldConfig } from '../number-type/number-type.component';
import { GroupWrapperProps } from '../group-wrapper/group-wrapper.component';
import { MultiSelectFieldConfig } from '../multi-select-type/multi-select-type.component';
import { RepeatFieldConfig } from '../repeat-type/repeat-type.component';
import { AutoCompleteFieldConfig } from '../auto-complete-type/auto-complete-type.component';
import { TextAreaFieldConfig } from '../text-area-type/text-area-type.component';
import { TableFieldConfig } from '../table-type/table-type.component';
import { AutoCompleteSelectFieldConfig } from '../auto-complete-select-type/auto-complete-select-type.component';
import { LabelFieldConfig } from '../label-type/label-type.component';
import { FileFieldConfig } from '../file-type/file-type.component';

export class FormlyUtil {
  static ISO_DATE_FORMAT = 'yyyy-MM-dd';
  static ISO_TIME_FORMAT = 'HH:mm:ss';
  static ISO_TIME_FORMAT_WITHOUT_SECONDS = 'HH:mm:00';

  static toIsoDateString(date: any): string | null {
    if (!date) {
      return null;
    }
    return format(date, FormlyUtil.ISO_DATE_FORMAT);
  }

  static toIsoTimeString(date: any): string {
    return format(date, FormlyUtil.ISO_TIME_FORMAT_WITHOUT_SECONDS);
  }

  static fromIsoDateString(date: string | Date | undefined, useDefault = true): Date | undefined {
    if (!date) {
      if (useDefault) {
        return new Date();
      }
      return undefined;
    }
    if (typeof date === 'string') {
      return parseISO(date);
    }
    return date;
  }

  static fromIsoTimeString(time: string | Date | undefined): Date {
    // Check if it is HH:mm:ss then convert to date
    if (typeof time === 'string' && time.match(/^\d{2}:\d{2}:\d{2}$/)) {
      return parseISO(`1970-01-01T${time}`);
    }

    return new Date();
  }

  static findKeyRecursively(
    key: string,
    fields?: FormlyFieldConfig[]
  ): FormlyFieldConfig | undefined {
    if (!fields) {
      return undefined;
    }
    for (const field of fields) {
      if (field.key === key) {
        return field;
      }
      const result = FormlyUtil.findKeyRecursively(key, field.fieldGroup);
      if (result) {
        return result;
      }
    }
    return undefined;
  }

  static createRow(
    fieldGroup: FormlyFieldConfig<FieldWrapperProps & { [p: string]: any }>[] | undefined
  ): FormlyFieldConfig {
    return {
      fieldGroupClassName: 'flex gap-3 flex-wrap',
      fieldGroup: fieldGroup?.map(field => {
        if (!field.className) {
          field.className = 'flex-1';
        }
        return field;
      })
    };
  }

  static createGroup(
    label: string,
    fieldGroup: FormlyFieldConfig<GroupWrapperProps & { [p: string]: any }>[] | undefined
  ): FormlyFieldConfig {
    return {
      props: {
        label
      },
      fieldGroup,
      wrappers: ['group-wrapper']
    };
  }

  static createTabbed(
    fieldGroup: FormlyFieldConfig<FormlyFieldProps & { [p: string]: any }>[] | undefined,
    query = 't'
  ): FormlyFieldConfig {
    return {
      type: 'tab',
      props: {
        query
      },
      fieldGroup
    };
  }

  static createTab(
    label: string,
    fieldGroup: FormlyFieldConfig<FormlyFieldProps & { [p: string]: any }>[] | undefined
  ): FormlyFieldConfig {
    return {
      props: {
        label: label
      },
      fieldGroup
    };
  }

  //
  // FIELDS
  //

  static createRepeat(
    config: RepeatFieldConfig,
    fieldGroup: FormlyFieldConfig<FieldWrapperProps & { [p: string]: any }>[] | undefined
  ): FormlyFieldConfig {
    let defaultValue: any = config.defaultValue || [];
    if (config.props?.initialCount) {
      defaultValue = [];
      for (let i = 0; i < config.props.initialCount; i++) {
        defaultValue.push({});
      }
    }
    return {
      ...config,
      type: 'repeat',
      defaultValue: defaultValue,
      fieldArray: {
        fieldGroup
      }
    };
  }

  static createGenericField<T = FormlyFieldConfig<FieldWrapperProps>>(
    key: string,
    type:
      | string
      | Type<FieldType<FormlyFieldConfig<FieldWrapperProps & { [p: string]: any }>>>
      | undefined,
    config?: T
  ): FormlyFieldConfig {
    return {
      ...this._defaultConfig(),
      ...config,
      key,
      type
    };
  }

  static createTextField(key: string, config?: TextFieldConfig): FormlyFieldConfig {
    return {
      ...this._defaultConfig(),
      ...config,
      expressions: {
        ...config?.expressions
      },
      key,
      type: 'text'
    };
  }

  static createCheckboxField(key: string, config?: CheckboxFieldConfig): FormlyFieldConfig {
    return {
      ...this._defaultConfig(),
      defaultValue: false,
      ...config,
      props: {
        ...config?.props
      },
      expressions: {
        ...config?.expressions
      },
      key,
      type: 'checkbox'
    };
  }

  static createToggleField(key: string, config?: CheckboxFieldConfig): FormlyFieldConfig {
    return {
      ...this._defaultConfig(),
      defaultValue: false,
      ...config,
      props: {
        ...config?.props
      },
      expressions: {
        ...config?.expressions
      },
      key,
      type: 'toggle'
    };
  }

  static createDatePickerField(key: string, config?: DatepickerFieldConfig): FormlyFieldConfig {
    return {
      ...this._defaultConfig(),
      ...config,
      props: {
        ...config?.props,
        showButtonBar: true
      },
      expressions: {
        ...config?.expressions
      },
      key,
      type: 'date-picker'
    };
  }

  static createInputMaskField(key: string, config?: InputMaskFieldConfig): FormlyFieldConfig {
    return {
      ...this._defaultConfig(),
      ...config,
      props: {
        ...config?.props
      },
      expressions: {
        ...config?.expressions
      },
      key,
      type: 'input-mask'
    };
  }

  static createDateTimePickerField(key: string, config?: DatepickerFieldConfig): FormlyFieldConfig {
    return {
      ...FormlyUtil.createDatePickerField(key, config),
      props: {
        ...config?.props,
        showTime: true,
        showButtonBar: true
      }
    };
  }

  static createTimePickerField(key: string, config?: DatepickerFieldConfig): FormlyFieldConfig {
    return {
      ...FormlyUtil.createDatePickerField(key, config),
      props: {
        ...config?.props,
        timeOnly: true
      }
    };
  }

  static createColorPickerField(key: string, config?: ColorPickerFieldConfig): FormlyFieldConfig {
    return {
      ...this._defaultConfig(),
      ...config,
      props: {
        ...config?.props
      },
      expressions: {
        ...config?.expressions
      },
      key,
      type: 'color-picker'
    };
  }

  static createSelectField(key: string, config?: SelectFieldConfig): FormlyFieldConfig {
    return {
      ...this._defaultConfig(),
      ...config,
      expressions: {
        ...config?.expressions
      },
      key,
      type: 'select'
    };
  }

  static createMultiSelectField(key: string, config?: MultiSelectFieldConfig): FormlyFieldConfig {
    return {
      ...this._defaultConfig(),
      ...config,
      expressions: {
        ...config?.expressions
      },
      key,
      type: 'multi-select'
    };
  }

  static createNumberField(key: string, config?: NumberFieldConfig): FormlyFieldConfig {
    return {
      ...this._defaultConfig(),
      ...config,
      expressions: {
        ...config?.expressions
      },
      key,
      type: 'number'
    };
  }

  static createAutoCompleteField(key: string, config?: AutoCompleteFieldConfig): FormlyFieldConfig {
    return {
      ...this._defaultConfig(),
      ...config,
      props: {
        options: [],
        placeholder: 'search',
        valueProp: (option: any) => option,
        ...config?.props
      },
      expressions: {
        ...config?.expressions
      },
      key,
      type: 'autocomplete'
    };
  }

  static createAutoCompleteSelectField(
    key: string,
    config?: AutoCompleteSelectFieldConfig
  ): FormlyFieldConfig {
    return {
      ...this._defaultConfig(),
      ...config,
      props: {
        options: [],
        placeholder: 'search',
        valueProp: (option: any) => option,
        ...config?.props
      },
      expressions: {
        ...config?.expressions
      },
      key,
      type: 'autocomplete-select'
    };
  }

  static createTextAreaField(key: string, config?: TextAreaFieldConfig): FormlyFieldConfig {
    return {
      ...this._defaultConfig(),
      ...config,
      props: {
        ...config?.props
      },
      expressions: {
        ...config?.expressions
      },
      key,
      type: 'textarea'
    };
  }

  static createEditorField(key: string, config?: EditorFieldConfig): FormlyFieldConfig {
    return {
      ...this._defaultConfig(),
      ...config,
      props: {
        ...config?.props
      },
      expressions: {
        ...config?.expressions
      },
      key,
      type: 'editor'
    };
  }

  static createTable(
    config: TableFieldConfig,
    fieldGroup: FormlyFieldConfig<FieldWrapperProps & { [p: string]: any }>[] | undefined
  ): FormlyFieldConfig {
    let defaultValue: any = undefined;
    if (config.props?.initialCount) {
      defaultValue = [];
      for (let i = 0; i < config.props.initialCount; i++) {
        defaultValue.push({});
      }
    }
    return {
      ...config,
      className: 'w-full overflow-visible',
      type: 'table',
      defaultValue: defaultValue,
      fieldArray: {
        fieldGroup
      }
    };
  }

  static createLabelField(key: string, config?: LabelFieldConfig): FormlyFieldConfig {
    return {
      ...this._defaultConfig(),
      ...config,
      props: {
        ...config?.props,
        hideLabel: true
      },
      expressions: {
        ...config?.expressions
      },
      key,
      type: 'label'
    };
  }

  static createFileField(key: string, config?: FileFieldConfig): FormlyFieldConfig {
    return {
      ...this._defaultConfig(),
      ...config,
      expressions: {
        ...config?.expressions
      },
      key,
      type: 'file'
    };
  }

  private static _defaultConfig(): FormlyFieldConfig {
    return {};
  }
}
