<div *transloco="let transloco" class="flex flex-col space-y-5">
  @if (this.showFilter() || this.showSort()) {
    <div class="flex flex-wrap justify-stretch gap-5">
      <div class="min-w-fit flex-1">
        @if (this.showFilter()) {
          <lib-graph-ql-advanced-filter
            [graphQlAdvancedTable]="advancedTable"
            [graphQlTable]="this.graphQlTable()"
            [stateKey]="this.stateKey()"></lib-graph-ql-advanced-filter>
        }
      </div>
      <div class="min-w-fit flex-1">
        @if (this.showSort()) {
          <lib-graph-ql-advanced-sort
            [graphQlAdvancedTable]="advancedTable"
            [graphQlTable]="this.graphQlTable()"
            [stateKey]="this.stateKey()"></lib-graph-ql-advanced-sort>
        }
      </div>
    </div>
  }

  <lib-graph-ql-advanced-table
    #advancedTable
    [graphQlTable]="this.graphQlTable()"
    [patchOptions]="this.graphQlPatchOptions"
    [showColumnFilter]="this.showColumnFilter()"
    [showExport]="this.showExport()"
    [stateKey]="this.stateKey()">
    <ng-template let-data libTemplate="created_by_current_person_dataCell">
      <div class="flex flex-col gap-2">
        <app-person-detail-link
          [person]="data.created_by_current_person_data"></app-person-detail-link>
      </div>
    </ng-template>
    <ng-template let-data libTemplate="student_balance_current_person_dataCell">
      <div class="flex flex-col gap-2">
        <app-person-detail-link [person]="data.student_balances?.[0]?.current_person_data">
          -
          @for (student_balance of data.student_balances; track student_balance) {
            <a
              after
              class="block hover:text-[color:var(--primary-color)] hover:underline"
              [routerLink]="['/', 'accounting', 'student-balances', 'detail', student_balance?.id]">
              <span>{{ student_balance?.month_of_balance | libDate: 'MM/yyyy' }}</span>
              <ng-content select="[link]"></ng-content>
            </a>
          }
        </app-person-detail-link>
      </div>
    </ng-template>
    <ng-template let-data libTemplate="school.nameCell">
      <div class="flex flex-col gap-2">
        <a
          [queryParams]="{
            t: 6
          }"
          [routerLink]="['/', 'academy', 'schools', 'detail', data.school_id]"
          class="block hover:text-[color:var(--primary-color)] hover:underline"
          >{{ data.school?.name }}, Id:{{ data.school_id }}</a
        >
      </div>
    </ng-template>

    <ng-template let-data libTemplate="typeCell">
      <div class="flex flex-col gap-2">
        <p-tag
          [severity]="
            data.type === this.AccountingCashBookEntryTypeEnum.INCOMING ? 'success' : 'danger'
          "
          [value]="transloco('cash_book_entry_type.' + data.type)"></p-tag>
      </div>
    </ng-template>
    @if (this.showCreateButton()) {
      <ng-template libTemplate="caption">
        <a
          [pTooltip]="transloco('new')"
          [routerLink]="['/', 'accounting', 'cash-book-entries', 'new']"
          class="p-button-success !p-2 text-white"
          icon="pi pi-plus"
          pButton
          tooltipPosition="top"
          type="button"></a>
      </ng-template>
    }
    <ng-template let-data libTemplate="actions">
      <div class="flex items-center justify-center gap-2">
        <a
          [pTooltip]="transloco('show')"
          [routerLink]="['/', 'accounting', 'cash-book-entries', 'detail', this.getRow(data)?.id]"
          class="p-button-info p-button-text"
          icon="pi pi-eye"
          pButton
          tooltipPosition="top"
          type="button"></a>

        @if (
          this.getRow(data)?.type === AccountingCashBookEntryTypeEnum.INCOMING &&
          this.getRow(data)?.student_balances?.length
        ) {
          @let status =
            this.printService.status()[this.printService.CASH_BOOK_ENTRY + this.getRow(data)?.id];
          <button
            (click)="this.generate(data)"
            [disabled]="!status?.isOpening && status?.isDownloading"
            [loading]="status?.isOpening || false"
            [pTooltip]="transloco('print')"
            class="p-button-info p-button-text"
            icon="pi pi-print"
            pButton
            tooltipPosition="top"
            type="button"></button>
        }

        @if (!this.getRow(data)?.entry?.id) {
          <a
            *ngxPermissionsOnly="this.AccountingCashBookEntryPermission.UPDATE"
            [pTooltip]="transloco('edit')"
            [routerLink]="['/', 'accounting', 'cash-book-entries', this.getRow(data)?.id]"
            class="p-button-warning p-button-text"
            icon="pi pi-pencil"
            pButton
            tooltipPosition="top"
            type="button"></a>

          <a
            *ngxPermissionsOnly="this.AccountingCashBookEntryPermission.UPDATE"
            [pTooltip]="transloco('to_book')"
            [routerLink]="['/', 'accounting', 'entries', 'new']"
            [queryParams]="{
              cash_book_entry_id: this.getRow(data)?.id,
              amount: this.getRow(data)?.amount,
              school_id: this.getRow(data)?.school_id,
              date_of_entry: this.getRow(data)?.date_of_entry,
              type: this.getRow(data)?.type
            }"
            class="p-button-success p-button-text"
            icon="pi pi-check-circle"
            pButton
            tooltipPosition="top"
            type="button"></a>

          <button
            *ngxPermissionsOnly="this.AccountingCashBookEntryPermission.SOFT_DELETE"
            (click)="this.delete(data)"
            [pTooltip]="transloco('delete')"
            class="p-button-danger p-button-text"
            icon="pi pi-trash"
            pButton
            tooltipPosition="top"
            type="button"></button>
        }
      </div>
    </ng-template>
  </lib-graph-ql-advanced-table>
</div>
