<lib-layout-base-page
  *transloco="let transloco"
  [titleParamValue]="this.item()?.course_name?.name || this.item()?.name">
  <div class="flex flex-col">
    <p-tabView libTabViewQuery="t">
      <p-tabPanel header="{{ transloco('general_information') }}">
        <ng-template pTemplate="content">
          <app-course-detail [id]="this.item()?.id"></app-course-detail>
        </ng-template>
      </p-tabPanel>

      @if (this.item()?.id) {
        <p-tabPanel header="{{ transloco('students') }}">
          <ng-template pTemplate="content">
            <div class="flex flex-col gap-6">
              <app-student-list
                [columns]="this.studentColumns()"
                [patchOptions]="this.studentPatchOptions"
                [stateKey]="'course-detail-student-list'">
              </app-student-list>

              <span class="block text-3xl"> {{ transloco('change_history') }} </span>

              <app-course-student-list
                [courseId]="this.item()?.id"
                [stateKey]="'course-detail-course-student-list'"></app-course-student-list>
            </div>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel header="{{ transloco('certificates') }}">
          <ng-template pTemplate="content">
            <div class="flex flex-col gap-6">
              <div class="flex items-center gap-2">
                @for (
                  type of [
                    this.AcademyCertificateTypeEnum.FIRST_SEMESTER,
                    this.AcademyCertificateTypeEnum.SECOND_SEMESTER
                  ];
                  track type
                ) {
                  <a
                    *ngxPermissionsOnly="
                      this.item()?.[type]?.aggregate?.count > 0
                        ? this.AcademyCertificatePermission.UPDATE
                        : this.AcademyCertificatePermission.CREATE
                    "
                    [label]="
                      transloco(
                        this.item()?.[type]?.aggregate?.count > 0 ? 'edit_value' : 'add_value',
                        {
                          value: transloco('certificate_type.' + type)
                        }
                      )
                    "
                    [routerLink]="['/', 'academy', 'courses', this.item()?.id, 'certificates']"
                    [queryParams]="{
                      certificate_type: type
                    }"
                    [ngClass]="{
                      'p-button-success': this.item()?.[type]?.aggregate?.count <= 0
                    }"
                    [icon]="
                      this.item()?.[type]?.aggregate?.count > 0 ? 'pi pi-pencil' : 'pi pi-plus'
                    "
                    pButton
                    type="button"></a>
                }
              </div>
              <app-certificate-list
                [organisationId]="this.item()?.school?.organisation_id"
                [schoolId]="this.item()?.school_id"
                [courseId]="this.item()?.id"
                [showExport]="true"
                [showFilter]="true"
                [showSort]="true"
                [stateKey]="'course-detail-certificate-list'">
              </app-certificate-list>
            </div>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel header="{{ transloco('teachers') }}">
          <ng-template pTemplate="content">
            <app-person-list
              [columns]="this.teacherColumns()"
              [patchOptions]="this.teacherPatchOptions"
              [showDetailButton]="this.authorizationService.cannot(Role.TEACHER)"
              [showExport]="true"
              [showFilter]="true"
              [showSort]="true"
              [stateKey]="'course-detail-teacher-list'">
            </app-person-list>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel header="{{ transloco('units') }}">
          <ng-template pTemplate="content">
            <app-unit-list
              [patchOptions]="this.unitPatchOptions"
              [showExport]="true"
              [showFilter]="true"
              [showSort]="true"
              [stateKey]="'course-detail-unit-list'"></app-unit-list>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel header="{{ transloco('class_book_entries') }}">
          <ng-template pTemplate="content">
            <app-class-book-entry-list
              [patchOptions]="this.cbePatchOptions"
              [showExport]="true"
              [showFilter]="true"
              [showSort]="true"
              [stateKey]="'course-detail-cbe-list'"></app-class-book-entry-list>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel
          *ngxPermissionsOnly="this.AcademyCoursePermission.CREATE"
          header="{{ transloco('notes') }}">
          <ng-template pTemplate="content">
            <app-note-list
              [patchInput]="this.notePatchInput"
              [patchOptions]="this.notePatchOptions"
              [stateKey]="'course-detail-note-list'"
              [table]="'note_course_note'"></app-note-list>
          </ng-template>
        </p-tabPanel>
      }
    </p-tabView>
  </div>
</lib-layout-base-page>
