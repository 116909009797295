<div
  *transloco="let transloco"
  [ngClass]="{
    'mb-5': !this.props.disableMargin,
    'mt-8': !this.props.disableTopMargin
  }"
  class="flex flex-col gap-2">
  @if (this.props.label && !this.props.hideLabel) {
    <label [for]="id" class="mb-5">
      <span class="text-2xl">{{ transloco(props.label) }}</span>
      @if (this.props.required && !this.props.hideRequiredMarker) {
        <span aria-hidden="true" class="text-red-600">*</span>
      }
      @if (this.props.description) {
        <p>{{ transloco(this.props.description) }}</p>
      }
      <hr class="m-0" />
    </label>
  }
  @for (field of this.field.fieldGroup; track field; let i = $index; let last = $last) {
    <div>
      <formly-field [field]="this.field"></formly-field>
      @if (
        !this.formControl.disabled &&
        i + 1 > (this.props.minCount || 0) &&
        !(
          this.props.maxCount &&
          this.props.initialCount &&
          this.props.initialCount === this.props.maxCount
        )
      ) {
        <div class="align-items-center flex justify-end">
          @if (!this.props.canRemove || this.props.canRemove(this.field, i)) {
            <button
              class="p-button-danger !bg-rose-400"
              icon="pi pi-trash"
              pButton
              label="{{ transloco('delete') }}"
              type="button"
              (click)="this.remove(i)"></button>
          }
        </div>
      }
      <!--        <hr class="mt-2" *ngIf="!last" />-->
    </div>
  }
  @if (
    (!this.props.canAdd || this.props.canAdd(this.field)) &&
    !this.formControl.disabled &&
    (this.props.maxCount ? this.props.maxCount > this.formControl.length : true)
  ) {
    <div class="align-items-center my-5 flex justify-end">
      <button
        pButton
        class="p-button-success"
        icon="pi pi-plus"
        [label]="
          this.props.addTextValue
            ? transloco('add_value', { value: transloco(this.props.addTextValue) })
            : this.props.addText
              ? transloco(this.props.addText)
              : transloco('add')
        "
        type="button"
        (click)="this.add()"></button>
    </div>
  }
  @if (this.showError) {
    <small class="p-error">
      <formly-validation-message
        class="ui-message-text"
        [field]="this.field"></formly-validation-message>
    </small>
  }
</div>
