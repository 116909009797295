import { Component, effect, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { CommonModule } from '@angular/common';
import { TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, delay, Observable, of, switchMap } from 'rxjs';
import { AppConfig, LayoutService, LoadingService, MenuService } from '~ngx-shared/layout';
import { AuthorizationService, Role } from '~ngx-shared/authentication';
import { VersionService } from '~ngx-shared/services';
import { TenantService, UserService } from '~madrasa/services';
import {
  AcademyClassBookEntryPermission,
  AcademyCoursePermission,
  AcademyCourseTypePermission,
  AcademyOrganisationPermission,
  AcademyRoomPermission,
  AcademySchoolPeriodPermission,
  AcademySchoolPermission,
  AcademySubjectPermission,
  AcademyUnitPermission,
  AccountingCashBookEntryPermission,
  AccountingStudentBalancePermission,
  CorePersonPermission,
  CoreTenantModel,
  CoreTenantPermission,
  StaffDirectorPermission,
  StaffManagerPermission,
  StaffOrganisatorPermission,
  StaffParentPermission,
  StaffStudentPermission,
  StaffTeacherPermission
} from '~ngx-shared/models';

@UntilDestroy()
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, CommonModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  constructor(
    private primengConfig: PrimeNGConfig,
    private layoutService: LayoutService,
    private translocoService: TranslocoService,
    private loadingService: LoadingService,
    private menuService: MenuService,
    private versionService: VersionService,
    private messageService: MessageService,
    private tenantService: TenantService,
    authorizationService: AuthorizationService,
    userService: UserService
  ) {
    authorizationService.setAllowedRoles(Object.values(Role));
    userService.init();

    effect(() => {
      const isNewAvailable = this.versionService.isNewVersionAvailable();
      if (isNewAvailable) {
        this.messageService.add({
          severity: 'error',
          summary: 'update',
          closable: false,
          sticky: true,
          key: 'new_version_available'
        });
      }
    });
  }

  ngOnInit(): void {
    this.loadingService.startLoading();
    this.primengConfig.ripple = true; //enables core ripple functionality

    //optional configuration with the default configuration
    const config: AppConfig = {
      ripple: true, //toggles ripple on and off
      inputStyle: 'outlined', //default style for input elements
      menuMode: 'static', //layout mode of the menu, valid values are "static" and "overlay"
      colorScheme: 'light', //color scheme of the template, valid values are "light" and "dark"
      theme: 'tailwind-light', //default component theme for PrimeNG
      scale: 13.5 //size of the body font size to scale the whole application
    };
    this.layoutService.config.set(config);

    this.translocoService.selectTranslateObject('primeng').subscribe(translated => {
      this.primengConfig.setTranslation(translated);
    });

    this.menuService.sideBarItems$.next([
      {
        label: 'Home',
        items: [
          {
            label: 'dashboard',
            icon: 'pi pi-fw pi-home',
            routerLink: ['/', 'academy', 'dashboard']
          },
          {
            label: 'profile',
            icon: 'pi pi-fw pi-user',
            routerLink: ['/', 'staff', 'profile']
          }
        ]
      },
      {
        label: 'staff',
        state: {
          permissions: [
            ...StaffStudentPermission.READ,
            ...StaffParentPermission.READ,
            ...StaffTeacherPermission.READ,
            ...StaffDirectorPermission.READ,
            ...StaffOrganisatorPermission.READ,
            ...StaffManagerPermission.READ,
            ...CorePersonPermission.READ
          ]
        },
        items: [
          {
            label: 'students',
            icon: 'pi pi-fw pi-users',
            routerLink: ['/', 'staff', 'students'],
            state: { permissions: StaffStudentPermission.READ }
          },
          {
            label: 'parents',
            icon: 'pi pi-fw pi-users',
            routerLink: ['/', 'staff', 'parents'],
            state: { permissions: StaffParentPermission.READ }
          },
          {
            label: 'teachers',
            icon: 'pi pi-fw pi-users',
            routerLink: ['/', 'staff', 'teachers'],
            state: { permissions: StaffTeacherPermission.READ }
          },
          {
            label: 'directors',
            icon: 'pi pi-fw pi-users',
            routerLink: ['/', 'staff', 'directors'],
            state: { permissions: StaffDirectorPermission.READ }
          },
          {
            label: 'organisators',
            icon: 'pi pi-fw pi-users',
            routerLink: ['/', 'staff', 'organisators'],
            state: { permissions: StaffOrganisatorPermission.READ }
          },
          {
            label: 'managers',
            icon: 'pi pi-fw pi-users',
            routerLink: ['/', 'staff', 'managers'],
            state: { permissions: StaffManagerPermission.READ }
          },
          {
            label: 'people',
            icon: 'pi pi-fw pi-users',
            routerLink: ['/', 'staff', 'people'],
            state: { permissions: CorePersonPermission.READ }
          }
        ]
      },
      {
        label: 'academy',
        items: [
          {
            label: 'class_book_entries',
            icon: 'pi pi-fw pi-book',
            routerLink: ['/', 'academy', 'class-book-entries'],
            state: { permissions: AcademyClassBookEntryPermission.READ }
          },
          {
            label: 'units',
            icon: 'pi pi-fw pi-calendar-clock',
            routerLink: ['/', 'academy', 'units'],
            state: { permissions: AcademyUnitPermission.READ }
          },
          {
            label: 'courses',
            icon: 'pi pi-fw pi-graduation-cap',
            routerLink: ['/', 'academy', 'courses'],
            state: { permissions: AcademyCoursePermission.READ },
            items: [
              {
                label: 'evaluations',
                icon: 'pi pi-fw pi-graduation-cap',
                routerLink: ['/', 'academy', 'courses', 'evaluations'],
                state: { permissions: AcademyCoursePermission.CREATE }
              }
            ]
          },
          {
            label: 'rooms',
            icon: 'pi pi-fw pi-building',
            routerLink: ['/', 'academy', 'rooms'],
            state: { permissions: AcademyRoomPermission.READ }
          },
          {
            label: 'schools',
            icon: 'pi pi-fw pi-building-columns',
            routerLink: ['/', 'academy', 'schools'],
            state: { permissions: AcademySchoolPermission.READ }
          },
          {
            label: 'management',
            icon: 'pi pi-fw pi-cog',
            routerLink: ['/', 'academy', 'management'],
            state: {
              permissions: [
                ...AcademyOrganisationPermission.READ,
                ...AcademySchoolPeriodPermission.READ,
                ...AcademyCourseTypePermission.READ,
                ...AcademySubjectPermission.READ
              ]
            }
          }
        ]
      },
      {
        label: 'accounting',
        items: [
          {
            label: 'account_plan',
            icon: 'pi pi-fw pi-money-bill',
            routerLink: ['/', 'accounting', 'account-plan'],
            state: { permissions: [Role.LESSOR, Role.MANAGER] }
          },
          {
            label: 'account_entries',
            icon: 'pi pi-fw pi-money-bill',
            routerLink: ['/', 'accounting', 'entries'],
            state: { permissions: [Role.LESSOR, Role.MANAGER] }
          },
          {
            label: 'cash_book_entries',
            icon: 'pi pi-fw pi-money-bill',
            routerLink: ['/', 'accounting', 'cash-book-entries'],
            state: { permissions: AccountingCashBookEntryPermission.READ }
          },
          {
            label: 'student_balances',
            icon: 'pi pi-fw pi-money-bill',
            routerLink: ['/', 'accounting', 'student-balances'],
            state: { permissions: AccountingStudentBalancePermission.READ }
          },
          {
            label: 'settlements',
            icon: 'pi pi-fw pi-dollar',
            routerLink: ['/', 'accounting', 'settlements'],
            state: { permissions: [Role.LESSOR, Role.MANAGER] }
          },
          {
            label: 'bank_data_import',
            icon: 'pi pi-fw pi-file-import',
            routerLink: ['/', 'accounting', 'bank-data-import'],
            state: { permissions: [Role.LESSOR, Role.MANAGER] }
          },
          {
            label: 'management',
            icon: 'pi pi-fw pi-cog',
            routerLink: ['/', 'accounting', 'management'],
            state: { permissions: [Role.LESSOR, Role.MANAGER] }
          }
        ],
        state: {
          permissions: [
            Role.LESSOR,
            Role.MANAGER,
            ...AccountingCashBookEntryPermission.READ,
            ...AccountingStudentBalancePermission.READ
          ]
        }
      },
      {
        label: 'print',
        items: [
          {
            label: 'management',
            icon: 'pi pi-fw pi-cog',
            routerLink: ['/', 'print', 'management'],
            state: { permissions: [Role.LESSOR, Role.MANAGER] }
          }
        ],
        state: { permissions: [Role.LESSOR, Role.MANAGER] }
      },
      {
        label: 'System',
        items: [
          {
            label: 'tenants',
            icon: 'pi pi-fw pi-building-columns',
            routerLink: ['/', 'core', 'tenants']
          }
        ],
        state: { permissions: CoreTenantPermission.READ }
      }
    ]);
    this.fetchTenantsUntilResult().subscribe(tenants => this.loadingService.stopLoading());
  }

  fetchTenantsUntilResult(): Observable<CoreTenantModel[] | undefined> {
    return this.tenantService.getTenants(false).pipe(
      untilDestroyed(this),
      switchMap(tenants => {
        if (tenants && tenants.length > 0) {
          return of(tenants);
        } else {
          return of(null).pipe(
            untilDestroyed(this),
            delay(2000),
            switchMap(() => this.fetchTenantsUntilResult())
          );
        }
      }),
      catchError(error => {
        console.error('Error fetching tenants:', error);
        return of(null).pipe(
          untilDestroyed(this),
          delay(2000),
          switchMap(() => this.fetchTenantsUntilResult())
        );
      })
    );
  }
}
