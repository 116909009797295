<lib-layout-base-page
  *transloco="let transloco"
  [titleAddon]="this.accountingPeriodService.currentPeriod()?.id">
  <a
    [label]="transloco('edit')"
    [routerLink]="['.', 'edit']"
    action
    class="p-button-warning text-white"
    icon="pi pi-pencil"
    pButton
    type="button"></a>

  <lib-busy [isLoading]="this.accountingPeriodService.isLoading()">
    <div class="space-y-6">
      <div class="w-full space-y-4 rounded-lg bg-white p-4 text-center shadow md:w-1/2">
        <span class="text-2xl font-bold">{{ transloco('GUV') }}</span>
        <hr />
        <div class="relative grid grid-cols-2 gap-4 text-xl">
          <div>
            <span class="font-bold">{{ transloco('incomes') }}</span>
          </div>
          <div>
            <span class="font-bold">{{ transloco('expenses') }}</span>
          </div>
          <div>
            <span class="">{{ this.sumIncomeGroupsAmount() / 100 | currency }}</span>
          </div>
          <div>
            <span class="">{{ this.sumExpenseGroupsAmount() / 100 | currency }}</span>
          </div>
          @let sumIncome = this.sumIncomeGroupsAmount() - this.sumExpenseGroupsAmount();
          @let sumExpense = this.sumExpenseGroupsAmount() - this.sumIncomeGroupsAmount();

          <div
            [ngClass]="{
              'border-t text-green-500': sumIncome >= 0
            }">
            @if (sumIncome >= 0) {
              <span class="mt-2 block font-bold">{{ sumIncome / 100 | currency }}</span>
            }
          </div>
          <div
            [ngClass]="{
              'border-t text-red-500': sumExpense > 0
            }">
            @if (sumExpense > 0) {
              <span class="mt-2 block font-bold">{{ sumExpense / 100 | currency }}</span>
            }
          </div>

          <div class="absolute inset-y-0 left-1/2 flex items-center">
            <div class="h-full border-l border-gray-300"></div>
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-4 md:grid md:grid-cols-2">
        <ng-container
          [ngTemplateOutletContext]="{
            $implicit: this.expenseGroups(),
            sum: this.sumExpenseGroupsAmount()
          }"
          [ngTemplateOutlet]="card">
        </ng-container>

        <ng-container
          [ngTemplateOutletContext]="{
            $implicit: this.incomeGroups(),
            sum: this.sumIncomeGroupsAmount()
          }"
          [ngTemplateOutlet]="card">
        </ng-container>

        <ng-container
          [ngTemplateOutletContext]="{
            $implicit: this.activeGroups(),
            sum: this.sumActiveGroupsAmount()
          }"
          [ngTemplateOutlet]="card">
        </ng-container>

        <ng-container
          [ngTemplateOutletContext]="{
            $implicit: this.passiveGroups(),
            sum: this.sumPassiveGroupsAmount()
          }"
          [ngTemplateOutlet]="card">
        </ng-container>
      </div>
    </div>
  </lib-busy>

  <ng-template #card let-groups let-sum="sum">
    <div class="flex w-full flex-col justify-between space-y-4 rounded-lg bg-white p-4 shadow">
      @for (group of groups; track group) {
        <div class="space-y-4">
          <div class="flex items-center justify-between text-2xl font-bold">
            <span class="">{{ group.full_name }}</span>
            @switch (group.category) {
              @case (AccountingAccountCategoryEnum.ACTIVE) {
                <p-tag
                  class="flex"
                  severity="success"
                  [value]="transloco('account_category.' + group.category)"></p-tag>
              }
              @case (AccountingAccountCategoryEnum.PASSIVE) {
                <p-tag
                  class="flex"
                  severity="danger"
                  [value]="transloco('account_category.' + group.category)"></p-tag>
              }
              @case (AccountingAccountCategoryEnum.INCOME) {
                <p-tag
                  class="flex"
                  severity="info"
                  [value]="transloco('account_category.' + group.category)"></p-tag>
              }
              @case (AccountingAccountCategoryEnum.EXPENSE) {
                <p-tag
                  class="flex"
                  severity="warning"
                  [value]="transloco('account_category.' + group.category)"></p-tag>
              }
            }
          </div>
          <div>
            @for (account of group.accounts; track account) {
              <div class="flex justify-between text-xl">
                <a
                  [routerLink]="[
                    '/',
                    'accounting',
                    'account-plan',
                    'accounts',
                    'detail',
                    account.id
                  ]"
                  class="cursor-pointer hover:text-primary hover:underline">
                  <span class="">{{ account.full_name }}</span>
                </a>
                <span class="">{{ (account.sum_amount || 0) / 100 | currency }}</span>
              </div>
            }
          </div>
          <div class="flex justify-end text-xl font-bold">
            <div class="w-1/3 border-t border-double text-right">
              <span class="mt-2 block">{{ (group.sum_amount || 0) / 100 | currency }}</span>
            </div>
          </div>
        </div>
      }

      <div class="">
        <hr class="my-1" />
        <hr class="my-1" />
        <div class="flex justify-between text-2xl font-bold">
          <span class="">{{ transloco('total') }}</span>

          <span class="block">{{ sum / 100 | currency }}</span>
        </div>
      </div>
    </div>
  </ng-template>
</lib-layout-base-page>
