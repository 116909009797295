<lib-layout-base-page *transloco="let transloco" [titleParamValue]="this.item()?.name">
  <div class="flex flex-col">
    <p-tabView libTabViewQuery="t">
      <p-tabPanel header="{{ transloco('general_information') }}">
        <ng-template pTemplate="content">
          <lib-detail [fields]="this.fields()"></lib-detail>
        </ng-template>
      </p-tabPanel>

      @if (this.item()?.id) {
        <p-tabPanel
          *ngxPermissionsOnly="this.AcademyOrganisationPermission.CREATE"
          header="{{ transloco('notes') }}">
          <ng-template pTemplate="content">
            <app-note-list
              [patchInput]="this.notePatchInput"
              [patchOptions]="this.notePatchOptions"
              [stateKey]="'organisation-detail-note-list'"
              [table]="'note_organisation_note'"></app-note-list>
          </ng-template>
        </p-tabPanel>
      }
    </p-tabView>
  </div>
</lib-layout-base-page>
