import { Component, inject, OnInit, signal } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Apollo, gql } from 'apollo-angular';
import { PrimeTemplate } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { ButtonDirective } from 'primeng/button';
import { NgxPermissionsModule } from 'ngx-permissions';
import { sum } from 'lodash-es';
import { addMonths, format } from 'date-fns';
import { Router } from '@angular/router';
import { AuthorizationService } from '~ngx-shared/authentication';
import { TenantService } from '~madrasa/services';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { ConfirmationService, ToastService } from '~ngx-shared/layout';
import { GraphQlColumnModel } from '~ngx-shared/graph-ql';
import { StudentListComponent } from '~madrasa/staff/components/student-list/student-list.component';
import { TabViewQueryDirective } from '~ngx-shared/directives';
import { AccountingStudentBalanceModel, CorePersonDataModel } from '~ngx-shared/models';
import { DatePipe } from '~ngx-shared/pipes';

@UntilDestroy()
@Component({
  selector: 'app-course-invoice-list',
  standalone: true,
  imports: [
    TranslocoDirective,
    FormlyModule,
    StudentListComponent,
    PrimeTemplate,
    TabViewModule,
    TabViewQueryDirective,
    ButtonDirective,
    NgxPermissionsModule,
    DatePipe
  ],
  templateUrl: './course-invoice-list.component.html',
  styleUrl: './course-invoice-list.component.scss'
})
export class CourseInvoiceListComponent implements OnInit {
  readonly apollo = inject(Apollo);
  readonly router = inject(Router);
  readonly tenantService = inject(TenantService);
  readonly toastService = inject(ToastService);
  readonly confirmationService = inject(ConfirmationService);
  readonly authorizationService = inject(AuthorizationService);

  retroactiveForm = new FormGroup<any>({});
  retroactiveModel: any = {};
  retroactiveOptions: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.course_invoice_retroactive'
    }
  };
  retroactiveFields: FormlyFieldConfig[];
  retroactiveSubmit: FormSubmitModel;

  readonly retroactiveResult = signal<{ [key: number]: any } | undefined>(undefined);
  readonly retroactiveColumns = signal<GraphQlColumnModel[]>([]);

  nowForm = new FormGroup<any>({});
  nowModel: any = {};
  nowOptions: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.course_invoice_now'
    }
  };
  nowFields: FormlyFieldConfig[];
  nowSubmit: FormSubmitModel;

  readonly nowResult = signal<{ [key: number]: CorePersonDataModel } | undefined>(undefined);
  readonly nowColumns = signal<GraphQlColumnModel[]>([]);

  readonly isBusy = signal(false);

  ngOnInit(): void {
    this.retroactiveColumns.set([
      {
        label: 'course_price_calculated',
        path: 'id',
        type: 'currency',
        patchResult: (result: any) => this.retroactiveResult()?.[result.person_id].course_price,
        filter: { isNotFilterable: true },
        sort: { isSortable: false }
      },
      {
        label: 'current_debit_for_month',
        patchResult: (result: any) =>
          sum(
            this.retroactiveResult()?.[result.person_id]?.student_balances?.map(
              (item: any) => item.amount
            )
          ),
        type: 'currency',
        filter: { isNotFilterable: true },
        sort: { isSortable: false }
      }
    ]);

    this.retroactiveFields = [
      FormlyUtil.createRow([
        FormlyUtil.createDatePickerField('input_date', {
          props: {
            label: 'date',
            required: true,
            dateFormat: 'mm/yy',
            view: 'month'
          }
        })
      ])
    ];

    this.retroactiveSubmit = (formSaveModel: FormSaveModel) => {
      const date = formSaveModel.input.input_date;

      return this.apollo.query({
        query: gql`
          query AccountingCoursePriceStudents($input_date: date!) {
            result: accounting_calculate_students_course_price_by_date(
              args: { input_date: $input_date }
            ) {
              data
            }

            person: core_current_person_data {
              id
              person_id
              student_balances ( where: {
                amount: { _lt: 0 }
                month_of_balance: {
                  _gte: "${format(date, 'yyyy')}-${format(date, 'MM')}-01"
                  _lt:  "${format(addMonths(date, 1), 'yyyy')}-${format(addMonths(date, 1), 'MM')}-01" } }) {
                  id
                  amount
                  month_of_balance
              }
            }
          }
        `,
        variables: {
          input_date: FormlyUtil.toIsoDateString(formSaveModel.input.input_date)
        }
      });
    };

    this.nowColumns.set([
      {
        label: 'course_price_calculated',
        patchResult: (result: any) =>
          sum(
            this.nowResult()?.[result.person_id]?.course_students?.map((item: any) => item.price)
          ),
        type: 'currency',
        filter: { isNotFilterable: true },
        sort: { isSortable: false }
      },
      {
        label: 'current_debit_for_month',
        patchResult: (result: any) =>
          sum(
            this.nowResult()?.[result.person_id]?.student_balances?.map((item: any) => item.amount)
          ),
        type: 'currency',
        filter: { isNotFilterable: true },
        sort: { isSortable: false }
      }
    ]);

    this.nowFields = [
      FormlyUtil.createRow([
        FormlyUtil.createDatePickerField('date', {
          props: {
            required: true
          }
        })
      ])
    ];

    this.nowSubmit = (formSaveModel: FormSaveModel) => {
      const date = formSaveModel.input.date;

      return this.apollo.query<{ result: CorePersonDataModel[] }>({
        query: gql`
          query AccountingCoursePriceStudents {
            result: core_current_person_data(where: { latest_student: {} }) {
              id
              person_id
              course_students (
                limit: 1
                order_by: { created_at: desc }
                where: {
                  status: { _eq: active }
                  created_at: {
                    _lte: "${FormlyUtil.toIsoDateString(date)}"
                  }
                }
                ) {
                  price
              }

              student_balances ( where: {
                amount: { _lt: 0 }
                month_of_balance: {
                  _gte: "${format(date, 'yyyy')}-${format(date, 'MM')}-01"
                  _lt:  "${format(addMonths(date, 1), 'yyyy')}-${format(addMonths(date, 1), 'MM')}-01" } }) {
                  id
                  amount
                  month_of_balance
              }
            }
          }
        `
      });
    };
  }

  retroactiveSavedEvent(formSaveModel: FormSaveModel) {
    // Convert into an object with person_id as key and course_price as value, event.output.result is an array of objects
    // gets first element then come data array with person_id and course_price
    const result = formSaveModel.output?.result?.[0]?.data?.reduce((acc: any, curr: any) => {
      acc[curr.person_id] = curr.course_price;
      return acc;
    }, {});

    formSaveModel.output.person.forEach((person: any) => {
      if (result[person.person_id]) {
        result[person.person_id] = {
          course_price: result[person.person_id],
          student_balances: person.student_balances
        };
      }
    });

    this.retroactiveResult.set(result);
  }

  retroactiveReset() {
    this.retroactiveForm.reset();
    this.retroactiveModel = {};
    this.retroactiveResult.set(undefined);
  }

  retroactiveDebitBalance(event: MouseEvent) {
    this.confirmationService.confirmPopup(event, () => {
      const result = this.retroactiveResult();
      if (result) {
        this.isBusy.set(true);
        const studentBalances = Object.keys(result)
          .map(personId => {
            const person_id = Number(personId);
            const currentBalance = sum(
              result[person_id]?.student_balances?.map((item: any) => item.amount)
            );

            if (!(currentBalance < 0)) {
              const amount = result[person_id]?.course_price;

              if (amount > 0) {
                return {
                  person_id,
                  amount: -amount,
                  month_of_balance: FormlyUtil.toIsoDateString(this.nowModel.date),
                  description: `${format(this.nowModel.date, 'MM')}/${format(this.nowModel.date, 'yyyy')}`
                } as AccountingStudentBalanceModel;
              }
            }
            return undefined;
          })
          .filter(item => !!item)
          .filter(
            (item: AccountingStudentBalanceModel): item is AccountingStudentBalanceModel => !!item
          );

        this.apollo
          .mutate({
            mutation: gql`
              mutation CreateAccountingStudentBalances(
                $studentBalances: [accounting_student_balance_insert_input!]!
              ) {
                insert_accounting_student_balance(objects: $studentBalances) {
                  affected_rows
                }
              }
            `,
            variables: {
              studentBalances
            }
          })
          .subscribe(() => {
            this.toastService.toastSuccessSave();
            this.nowReset();
          });
      }
    });
  }

  nowSavedEvent(formSaveModel: FormSaveModel) {
    this.nowResult.set(
      formSaveModel.output?.result?.reduce((acc: any, curr: any) => {
        acc[curr.person_id] = curr;
        return acc;
      }, {})
    );
  }

  nowReset() {
    this.nowForm.reset();
    this.nowModel = {};
    this.nowResult.set(undefined);
    this.isBusy.set(false);
  }

  nowDebitBalance(event: MouseEvent) {
    this.confirmationService.confirmPopup(event, () => {
      const result = this.nowResult();
      if (result) {
        this.isBusy.set(true);
        const studentBalances = Object.keys(result)
          .map(personId => {
            const person_id = Number(personId);
            const currentBalance = sum(
              result[person_id]?.student_balances?.map((item: any) => item.amount)
            );

            if (!(currentBalance < 0)) {
              const amount = sum(
                result[person_id]?.course_students?.map((item: any) => item.price)
              );

              if (amount > 0) {
                return {
                  person_id,
                  amount: -amount,
                  month_of_balance: FormlyUtil.toIsoDateString(this.nowModel.date),
                  description: `${format(this.nowModel.date, 'MM')}/${format(this.nowModel.date, 'yyyy')}`
                } as AccountingStudentBalanceModel;
              }
            }
            return undefined;
          })
          .filter(item => !!item)
          .filter(
            (item: AccountingStudentBalanceModel): item is AccountingStudentBalanceModel => !!item
          );

        this.apollo
          .mutate({
            mutation: gql`
              mutation CreateAccountingStudentBalances(
                $studentBalances: [accounting_student_balance_insert_input!]!
              ) {
                insert_accounting_student_balance(objects: $studentBalances) {
                  affected_rows
                }
              }
            `,
            variables: {
              studentBalances
            }
          })
          .subscribe(() => {
            this.toastService.toastSuccessSave();
            this.nowReset();
          });
      }
    });
  }
}
