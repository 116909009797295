import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { TooltipModule } from 'primeng/tooltip';
import { MenuModule } from 'primeng/menu';
import { ButtonDirective } from 'primeng/button';
import { Apollo, gql } from 'apollo-angular';
import { UntilDestroy } from '@ngneat/until-destroy';
import { of, switchMap } from 'rxjs';
import { CurrencyPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { TagModule } from 'primeng/tag';
import { AccountingAccountCategoryEnum, AccountingAccountGroupModel } from '~ngx-shared/models';
import { AccountingPeriodService } from '~madrasa/accounting/services/accounting-period.service';
import { BasePageComponent, BusyComponent } from '~ngx-shared/layout';

@UntilDestroy()
@Component({
  selector: 'app-account-plan-overview-page',
  standalone: true,
  imports: [
    TranslocoDirective,
    TooltipModule,
    BasePageComponent,
    MenuModule,
    ButtonDirective,
    BusyComponent,
    NgTemplateOutlet,
    CurrencyPipe,
    TagModule,
    NgClass
  ],
  templateUrl: './account-plan-overview-page.component.html',
  styleUrl: './account-plan-overview-page.component.scss'
})
export class AccountPlanOverviewPageComponent implements OnInit {
  readonly apollo = inject(Apollo);
  readonly accountingPeriodService = inject(AccountingPeriodService);

  readonly activeGroups = signal<AccountingAccountGroupModel[]>([]);
  readonly passiveGroups = signal<AccountingAccountGroupModel[]>([]);
  readonly incomeGroups = signal<AccountingAccountGroupModel[]>([]);
  readonly expenseGroups = signal<AccountingAccountGroupModel[]>([]);

  readonly sumActiveGroupsAmount = computed(() =>
    this.activeGroups().reduce((sum, group) => sum + (group.sum_amount || 0), 0)
  );
  readonly sumPassiveGroupsAmount = computed(() =>
    this.passiveGroups().reduce((sum, group) => sum + (group.sum_amount || 0), 0)
  );
  readonly sumIncomeGroupsAmount = computed(() =>
    this.incomeGroups().reduce((sum, group) => sum + (group.sum_amount || 0), 0)
  );
  readonly sumExpenseGroupsAmount = computed(() =>
    this.expenseGroups().reduce((sum, group) => sum + (group.sum_amount || 0), 0)
  );

  ngOnInit() {
    this.accountingPeriodService.currentPeriod$
      .pipe(
        switchMap(accountingPeriod => {
          if (accountingPeriod?.id) {
            const query: string[] = Object.values(AccountingAccountCategoryEnum).map(
              category =>
                `${category}: accounting_account_group(
                  where: {
                    accounting_period_id: { _eq: ${accountingPeriod.id} }
                    category: { _eq: ${category} }
                  }
                  order_by: [{ number: asc }, { name: asc }]
                  ) {
                    id
                    full_name
                    category
                    sum_amount
                    accounts ( order_by: [{ number: asc }, { name: asc }] ) {
                      id
                      full_name
                      sum_amount
                    }
                  }`
            );

            return this.apollo.query<{
              [AccountingAccountCategoryEnum.ACTIVE]: AccountingAccountGroupModel[];
              [AccountingAccountCategoryEnum.PASSIVE]: AccountingAccountGroupModel[];
              [AccountingAccountCategoryEnum.INCOME]: AccountingAccountGroupModel[];
              [AccountingAccountCategoryEnum.EXPENSE]: AccountingAccountGroupModel[];
            }>({
              query: gql`
                query ReadAccountingAccountGroupsWithAccounts {
                  ${query.join('\n')}
                }
              `
            });
          }
          return of(undefined);
        })
      )
      .subscribe(queryResult => {
        if (queryResult?.data) {
          this.activeGroups.set(queryResult.data[AccountingAccountCategoryEnum.ACTIVE]);
          this.passiveGroups.set(queryResult.data[AccountingAccountCategoryEnum.PASSIVE]);
          this.incomeGroups.set(queryResult.data[AccountingAccountCategoryEnum.INCOME]);
          this.expenseGroups.set(queryResult.data[AccountingAccountCategoryEnum.EXPENSE]);
        }
      });

    this.accountingPeriodService.getPeriods().subscribe();
  }

  protected readonly AccountingAccountCategoryEnum = AccountingAccountCategoryEnum;
}
