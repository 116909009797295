import { Component, effect, input, Input, OnInit } from '@angular/core';
import { Data } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { format, parseISO } from 'date-fns';
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ButtonDirective } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { PrimeTemplate } from 'primeng/api';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TranslocoDirective } from '@jsverse/transloco';
import { AsyncPipe } from '@angular/common';
import { KeyProperties } from '~ngx-shared/models';
import {
  FilterMatchModeEnum,
  GraphQlAdvancedTableComponent,
  GraphQlFilterBy,
  GraphQlFilterColumnModel,
  GraphQlFilterModel,
  GraphQlTableModel
} from '~ngx-shared/graph-ql';
import { SelectOptionsPipe } from '~ngx-shared/graph-ql/pipes';
import { GraphQlService } from '../../services/graph-ql.service';

interface GraphQlFilter {
  column?: GraphQlFilterColumnModel;
  matchMode?: FilterMatchModeEnum;
  value?: any;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'lib-graph-ql-advanced-filter',
  templateUrl: './graph-ql-advanced-filter.component.html',
  styleUrls: ['./graph-ql-advanced-filter.component.css'],
  standalone: true,
  imports: [
    TranslocoDirective,
    DropdownModule,
    FormsModule,
    PrimeTemplate,
    CalendarModule,
    InputTextModule,
    ButtonDirective,
    SelectOptionsPipe,
    AsyncPipe
  ]
})
export class GraphQlAdvancedFilterComponent implements OnInit {
  @Input() graphQlAdvancedTable?: GraphQlAdvancedTableComponent;

  graphQlTable = input.required<GraphQlTableModel>();
  stateKey = input<string | undefined>(undefined);

  columns: GraphQlFilterColumnModel[] = [];
  filters: GraphQlFilter[] = [];

  isUpdatingState = false;

  subscription: Subscription | undefined;

  private _updateFilter$ = new Subject<GraphQlFilterModel | undefined>();

  readonly booleanOptions = [
    { label: 'yes', value: true },
    { label: 'no', value: false }
  ];

  readonly isNullOptions = [
    { label: 'yes', value: false },
    { label: 'no', value: true }
  ];

  constructor(private graphQlService: GraphQlService) {
    effect(() => {
      // Get sort columns from table
      const columns = this.graphQlTable()?.columns;
      if (columns) {
        this.columns = columns
          .filter(
            column => !!column.filter?.path || (!!column.path && !column.filter?.isNotFilterable)
          )
          .map(column => {
            column.filter = {
              ...column.filter,
              label: column.filter?.label || column.filter?.path || column.label || column.path,
              path: column.filter?.path || column.path
            };
            return column.filter;
          });
      }
      if (this.columns?.length) {
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
        if (this.stateKey()) {
          this.subscription = this.graphQlService
            .filter$(this.stateKey()!)
            .pipe(untilDestroyed(this))
            .subscribe(state => {
              if (!this.isUpdatingState) {
                this.filters = this._convertToFilters(state);
              }
            });
        }
      }
    });
  }

  ngOnInit(): void {
    this._updateFilter$
      .pipe(distinctUntilChanged(), debounceTime(500), untilDestroyed(this))
      .subscribe(filter => {
        this.isUpdatingState = true;
        this.graphQlService.updateFilter(this.stateKey(), filter);
        this.isUpdatingState = false;
      });
  }

  addFilter() {
    this.filters.push({});
  }

  removeFilter(index: number) {
    this.filters.splice(index, 1);
    this.updateState();
  }

  updateState(filter?: GraphQlFilter) {
    if (this.stateKey()) {
      if (filter) {
        if (!filter.matchMode) {
          const type = filter.column?.type || 'string';
          switch (type) {
            default:
            case 'number': {
              filter.matchMode = FilterMatchModeEnum.EQUALS;
              break;
            }
            case 'string': {
              filter.matchMode = FilterMatchModeEnum.STARTS_WITH;
              break;
            }
            case 'date': {
              filter.matchMode = FilterMatchModeEnum.DATE_IS;
              break;
            }
            case 'time': {
              filter.matchMode = FilterMatchModeEnum.EQUALS;
              break;
            }
            case 'json': {
              filter.matchMode = FilterMatchModeEnum.CONTAINS;
              break;
            }
          }
        }

        // Check if filter has all required properties then update state
        if (
          filter.column &&
          filter.value != null &&
          filter.value !== undefined &&
          filter.value !== ''
        ) {
          this._updateFilter$.next(this._convertToFilterQuery());
        }
      } else {
        this._updateFilter$.next(this._convertToFilterQuery());
      }
    }
  }

  getMatchModes(column?: GraphQlFilterColumnModel): string[] {
    const modes = [];
    const type = column?.type || 'string';
    switch (type) {
      default:
      case 'string': {
        modes.push(FilterMatchModeEnum.STARTS_WITH);
        modes.push(FilterMatchModeEnum.CONTAINS);
        modes.push(FilterMatchModeEnum.NOT_CONTAINS);
        modes.push(FilterMatchModeEnum.ENDS_WITH);
        modes.push(FilterMatchModeEnum.EQUALS);
        modes.push(FilterMatchModeEnum.NOT_EQUALS);
        break;
      }
      case 'time' || 'number': {
        modes.push(FilterMatchModeEnum.EQUALS);
        modes.push(FilterMatchModeEnum.NOT_EQUALS);
        modes.push(FilterMatchModeEnum.LESS_THAN);
        modes.push(FilterMatchModeEnum.LESS_THAN_OR_EQUAL_TO);
        modes.push(FilterMatchModeEnum.GREATER_THAN);
        modes.push(FilterMatchModeEnum.GREATER_THAN_OR_EQUAL_TO);
        break;
      }
      case 'date': {
        modes.push(FilterMatchModeEnum.DATE_IS);
        modes.push(FilterMatchModeEnum.DATE_IS_NOT);
        modes.push(FilterMatchModeEnum.DATE_BEFORE);
        modes.push(FilterMatchModeEnum.DATE_AFTER);
        break;
      }
      case 'array': {
        modes.push(FilterMatchModeEnum.IN);
        break;
      }
      case 'boolean': {
        modes.push(FilterMatchModeEnum.EQUALS);
        break;
      }
      case 'is_null': {
        modes.push(FilterMatchModeEnum.IS_NULL);
        break;
      }
      case 'json': {
        modes.push(FilterMatchModeEnum.CONTAINS);
        break;
      }
    }
    return modes;
  }

  private _convertToFilterQuery(): GraphQlFilterModel {
    const andArray: GraphQlFilterModel[] = [];
    this.filters.forEach(fFilter => {
      if (fFilter.value != null && fFilter.value !== undefined && fFilter.value !== '') {
        let value = fFilter.value;
        let matchMode = fFilter.matchMode as FilterMatchModeEnum;
        if (fFilter.column?.type === 'date') {
          value = format(value, 'yyyy-MM-dd');
        } else if (fFilter.column?.type === 'time') {
          value = format(value, 'HH:mm');
        } else if (fFilter.column?.type === 'array') {
          matchMode = FilterMatchModeEnum.IN;
        } else if (fFilter.column?.type === 'boolean') {
          matchMode = FilterMatchModeEnum.EQUALS;
        } else if (fFilter.column?.type === 'is_null') {
          matchMode = FilterMatchModeEnum.IS_NULL;
        }
        if (fFilter.column?.patchValue) {
          value = fFilter.column.patchValue(fFilter.value, fFilter.column);
        }
        const filter: KeyProperties = {};
        const path = fFilter?.column?.path?.split('.') as string[];
        const last = path.pop();
        if (last) {
          path.reduce<KeyProperties>(function (tempWhere: any, tempKey: any) {
            return (tempWhere[tempKey] = tempWhere[tempKey] || {});
          }, filter)[last] = this._convertToOperation(matchMode, value);
          andArray.push(filter);
        }
      }
    });
    const filters: GraphQlFilterModel = {};
    if (andArray.length) {
      filters._and = andArray;
    }
    return filters;
  }

  // Convert filters to object for state update
  // We need to make it recursive to support nested objects
  // Example from: state:{ _and: [
  // { a: { b: { d: { '_eq': 'test' } } } }
  // ] }
  private _convertToFilters(state?: GraphQlFilterModel): GraphQlFilter[] {
    const filters: GraphQlFilter[] = [];
    if (state) {
      if (Array.isArray(state._and)) {
        state._and.forEach((fFilter: GraphQlFilterModel) => {
          filters.push(...this._convertObjectToFiltersHelper(fFilter));
        });
      }
    }
    return filters;
  }

  // Skip last element because it's the matchMode
  private _convertObjectToFiltersHelper(
    filter: GraphQlFilterModel | GraphQlFilterModel[] | GraphQlFilterBy,
    path: string[] = []
  ): GraphQlFilter[] {
    const filters: GraphQlFilter[] = [];
    Object.keys(filter).forEach(key => {
      // I know it's not the best way
      let value = (filter as any)[key];
      if (typeof value === 'object' && !!value) {
        filters.push(
          ...this._convertObjectToFiltersHelper(
            value as GraphQlFilterModel | GraphQlFilterModel[] | GraphQlFilterBy,
            [...path, key]
          )
        );
      } else {
        // If path is empty, it means we are at the root
        if (path.length === 0) {
          path = [...path, key];
        }
        // We have now a GraphQlFilterBy as filter
        const column = this.columns.find(column => column.path === path.join('.'));
        if (column) {
          if (value !== null && value !== undefined && value !== '') {
            let filterValue: any = value;
            if (column.type === 'date') {
              filterValue = parseISO(value as string);
            } else if (column.type === 'time') {
              // Value is 14:05
              const [hours, minutes] = (value as string).split(':');
              filterValue = new Date();
              filterValue.setHours(+hours);
              filterValue.setMinutes(+minutes);
            }
            let matchMode = FilterMatchModeEnum.EQUALS;
            switch (key) {
              case '_ilike':
                if (typeof filterValue === 'string') {
                  if (value.startsWith('%') && value.endsWith('%')) {
                    filterValue = filterValue.slice(0, -1).slice(1);
                    matchMode = FilterMatchModeEnum.CONTAINS;
                  } else if (filterValue.startsWith('%')) {
                    filterValue = filterValue.slice(1);
                    matchMode = FilterMatchModeEnum.ENDS_WITH;
                  } else if (filterValue.endsWith('%')) {
                    filterValue = filterValue.slice(0, -1);
                    matchMode = FilterMatchModeEnum.STARTS_WITH;
                  }
                } else {
                  matchMode = FilterMatchModeEnum.CONTAINS;
                }
                break;
              case '_nilike':
                matchMode = FilterMatchModeEnum.NOT_CONTAINS;
                break;
              case '_eq':
                if (column.type == 'date') {
                  matchMode = FilterMatchModeEnum.DATE_IS;
                } else {
                  matchMode = FilterMatchModeEnum.EQUALS;
                }
                break;
              case '_neq':
                if (column.type == 'date') {
                  matchMode = FilterMatchModeEnum.DATE_IS_NOT;
                } else {
                  matchMode = FilterMatchModeEnum.NOT_EQUALS;
                }
                break;
              case '_in':
                matchMode = FilterMatchModeEnum.IN;
                break;
              case '_lt':
                if (column.type == 'date') {
                  matchMode = FilterMatchModeEnum.DATE_BEFORE;
                } else {
                  matchMode = FilterMatchModeEnum.LESS_THAN;
                }
                break;
              case '_lte':
                matchMode = FilterMatchModeEnum.LESS_THAN_OR_EQUAL_TO;
                break;
              case '_gt':
                if (column.type == 'date') {
                  matchMode = FilterMatchModeEnum.DATE_AFTER;
                } else {
                  matchMode = FilterMatchModeEnum.GREATER_THAN;
                }
                break;
              case '_gte':
                matchMode = FilterMatchModeEnum.GREATER_THAN_OR_EQUAL_TO;
                break;
              case '_is_null':
                matchMode = FilterMatchModeEnum.IS_NULL;
                break;
            }

            filters.push({ column, matchMode, value: filterValue });
          }
        }
      }
    });
    return filters;
  }

  private _convertToOperation(matchMode?: FilterMatchModeEnum, value?: any): Data {
    let operation = {};
    if (matchMode) {
      switch (matchMode) {
        // text
        case FilterMatchModeEnum.STARTS_WITH:
          operation = { _ilike: `${value}%` };
          break;
        // text
        case FilterMatchModeEnum.CONTAINS:
          operation = { _ilike: `%${value}%` };
          break;
        // text
        case FilterMatchModeEnum.NOT_CONTAINS:
          operation = { _nilike: `%${value}%` };
          break;
        // text
        case FilterMatchModeEnum.ENDS_WITH:
          operation = { _ilike: `%${value}` };
          break;
        // text
        // numeric
        case FilterMatchModeEnum.EQUALS:
          operation = { _eq: value };
          break;
        // text
        // numeric
        case FilterMatchModeEnum.NOT_EQUALS:
          operation = { _neq: value };
          break;
        case FilterMatchModeEnum.IN:
          operation = { _in: value };
          break;
        // numeric
        case FilterMatchModeEnum.LESS_THAN:
          operation = { _lt: value };
          break;
        // numeric
        case FilterMatchModeEnum.LESS_THAN_OR_EQUAL_TO:
          operation = { _lte: value };
          break;
        // numeric
        case FilterMatchModeEnum.GREATER_THAN:
          operation = { _gt: value };
          break;
        // numeric
        case FilterMatchModeEnum.GREATER_THAN_OR_EQUAL_TO:
          operation = { _gte: value };
          break;
        case FilterMatchModeEnum.BETWEEN:
          break;
        case FilterMatchModeEnum.IS:
          break;
        case FilterMatchModeEnum.IS_NOT:
          break;
        case FilterMatchModeEnum.BEFORE:
          break;
        case FilterMatchModeEnum.AFTER:
          break;
        // date
        case FilterMatchModeEnum.DATE_IS:
          operation = { _eq: value };
          break;
        // date
        case FilterMatchModeEnum.DATE_IS_NOT:
          operation = { _neq: value };
          break;
        // date
        case FilterMatchModeEnum.DATE_BEFORE:
          operation = { _lt: value };
          break;
        // date
        case FilterMatchModeEnum.DATE_AFTER:
          operation = { _gt: value };
          break;
        case FilterMatchModeEnum.IS_NULL:
          operation = { _is_null: value };
          break;
      }
    }
    return operation;
  }

  protected readonly filter = filter;
}
