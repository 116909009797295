import { Component, OnInit, signal } from '@angular/core';
import { ButtonDirective } from 'primeng/button';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { Router } from '@angular/router';
import { gql } from 'apollo-angular';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MenuModule } from 'primeng/menu';
import { BasePageComponent, ConfirmationService } from '~ngx-shared/layout';
import { PersonListComponent } from '~madrasa/staff/components/person-list/person-list.component';
import { DataProviderOptionModel, GraphQlColumnModel } from '~ngx-shared/graph-ql';
import { CorePersonDataModel, StaffManagerPermission, StaffStatusEnum } from '~ngx-shared/models';
import { AuthorizationService } from '~ngx-shared/authentication';
import { SearchPersonFormDialogComponent } from '~madrasa/staff/components/search-person-form-dialog/search-person-form-dialog.component';
import { DialogUtil } from '~ngx-shared/utils/dialog.util';

@UntilDestroy()
@Component({
  selector: 'app-manager-list-page',
  standalone: true,
  imports: [
    BasePageComponent,
    ButtonDirective,
    PersonListComponent,
    TranslocoDirective,
    NgxPermissionsModule,
    MenuModule
  ],
  templateUrl: './manager-list-page.component.html',
  styleUrl: './manager-list-page.component.scss'
})
export class ManagerListPageComponent implements OnInit {
  readonly columns = signal<GraphQlColumnModel[]>([]);
  readonly items = signal<MenuItem[]>([]);

  patchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  deleteFunc?: (options: { data: CorePersonDataModel; refresh: () => void }) => void;

  protected readonly StaffManagerPermission = StaffManagerPermission;

  constructor(
    public authorizationService: AuthorizationService,
    private confirmationService: ConfirmationService,
    private translocoService: TranslocoService,
    private dialogService: DialogService,
    public router: Router
  ) {}

  ngOnInit() {
    this.translocoService
      .selectTranslate(['new', 'madrasa.components.staff.create_from_existing_person'])
      .pipe(untilDestroyed(this))
      .subscribe(translated => {
        this.items.set([
          {
            label: translated[0],
            icon: 'pi pi-plus',
            routerLink: ['.', 'new']
          },
          {
            label: translated[1],
            icon: 'pi pi-arrows-h',
            command: () => {
              this.dialogService
                .open(SearchPersonFormDialogComponent, {
                  ...DialogUtil.BASE_DIALOG_CONFIG,
                  header: this.translocoService.translate(
                    'madrasa.components.staff.select_from_existing_person'
                  )
                })
                .onClose.pipe(untilDestroyed(this))
                .subscribe(result => {
                  if (result?.input?.person_id?.value?.value?.person_id) {
                    void this.router.navigate(
                      [this.router.url, result.input.person_id.value.value.person_id],
                      { queryParams: { is_new: true } }
                    );
                  }
                });
            }
          }
        ]);
      });

    const columns: GraphQlColumnModel[] = [
      {
        path: 'latest_manager.status',
        translate: true,
        prefix: 'status.',
        classHeader: 'text-center',
        classBody: 'text-center',
        type: 'tag',
        patchResult: (result: any, column: GraphQlColumnModel) => {
          switch (result.latest_manager.status) {
            case StaffStatusEnum.INACTIVE:
              column.severity = 'danger';
              break;
            case StaffStatusEnum.WAITING_LIST:
              column.severity = 'info';
              break;
            case StaffStatusEnum.ACTIVE:
              column.severity = 'success';
              break;
          }
          return 'status.' + result.latest_manager.status;
        },
        filter: {
          type: 'array',
          options: Object.values(StaffStatusEnum).map(value => ({
            label: 'status.' + value,
            value: value
          }))
        },
        sort: { isSortable: true }
      }
    ];

    this.columns.set(columns);

    this.patchOptions = options => {
      options.filter = {
        ...options.filter,
        _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
      };
      if (Array.isArray(options?.filter?._and)) {
        options.filter._and = options.filter._and.filter((cond: any) => !cond.latest_manager);
        options.filter._and.push({ latest_manager: {} });
      }
      return options;
    };

    this.deleteFunc = options => {
      if (options.data) {
        let mutation: any = gql`
          mutation SoftDeleteStaffManagerById($id: bigint!) {
            result: update_staff_manager(
              _set: { deleted_at: "now()" }
              where: { person_id: { _eq: $id } }
            ) {
              __typename
            }
          }
        `;
        if (
          options.data.managers?.[0]?.deleted_at &&
          this.authorizationService.can(StaffManagerPermission.DELETE)
        ) {
          mutation = gql`
            mutation DeleteStaffManagerById($id: bigint!) {
              result: delete_staff_manager(where: { person_id: { _eq: $id } }) {
                __typename
              }
            }
          `;
        }

        this.confirmationService.confirmDeleteApollo({
          name: `${options.data.first_name} - ${options.data.last_name}`,
          mutationOptions: {
            mutation,
            variables: {
              id: options.data.person_id
            }
          },
          success: options.refresh
        });
      }
    };
  }
}
