import { Role } from '~ngx-shared/authentication';
import { DefaultProperties } from '../default.model';
import { AcademyOrganisationModel } from './organisation.model';
import { AcademyCourseModel } from './course.model';
import { AcademySchoolDirectorModel } from './school-director.model';
import { AcademySchoolTeacherModel } from './school-teacher.model';
import { AcademyRoomModel } from './room.model';
import { AcademySchoolParentModel } from './school-parent.model';
import { AcademySchoolStudentModel } from './school-student.model';
import { AcademyCoursePriceModel } from './course-price.model';
import { AcademyTeacherHourlyRateModel } from './teacher-hourly-rate.model';

export interface AcademySchoolModel extends DefaultProperties {
  // Columns
  id?: number;
  organisation_id?: number;
  name?: string;
  address?: any;
  hourly_rate?: number;
  monthly_rate?: number;
  teacher_hourly_rate_id?: number;
  paper_template_id?: number;

  // Relations
  organisation?: AcademyOrganisationModel;
  school_course_price?: AcademyCoursePriceModel;
  teacher_hourly_rate?: AcademyTeacherHourlyRateModel;

  rooms?: AcademyRoomModel[];
  courses?: AcademyCourseModel[];
  school_directors_active?: AcademySchoolDirectorModel[];
  school_teachers_active?: AcademySchoolTeacherModel[];
  school_students_active?: AcademySchoolStudentModel[];
  school_parents_active?: AcademySchoolParentModel[];
}

export class AcademySchoolPermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.ORGANISATOR, Role.DIRECTOR, Role.TEACHER];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...AcademySchoolPermission.DELETE, Role.MANAGER];
}
