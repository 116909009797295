import { Component, computed, inject } from '@angular/core';
import { PrimeTemplate } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { TranslocoDirective } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { map, of, switchMap, tap } from 'rxjs';
import {
  AccountingCashBookEntryModel,
  AccountingCashBookEntryPermission,
  AccountingCashBookEntryTypeEnum
} from '~ngx-shared/models';
import { AuthorizationService } from '~ngx-shared/authentication';
import { FileTableComponent } from '~madrasa/core/components/file-table/file-table.component';
import { TabViewQueryDirective, TemplateDirective } from '~ngx-shared/directives';
import { DetailComponent, DetailField } from '~ngx-shared/ui/detail/detail.component';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { StudentBalanceListComponent } from '~madrasa/accounting/components/student-balance-list/student-balance-list.component';
import { DataProviderOptionModel } from '~ngx-shared/graph-ql';

@UntilDestroy()
@Component({
  selector: 'app-cash-book-entry-detail-page',
  standalone: true,
  imports: [
    BasePageComponent,
    TranslocoDirective,
    DetailComponent,
    PrimeTemplate,
    TabViewModule,
    TabViewQueryDirective,
    FileTableComponent,
    TemplateDirective,
    RouterLink,
    StudentBalanceListComponent
  ],
  templateUrl: './cash-book-entry-detail-page.component.html',
  styleUrl: './cash-book-entry-detail-page.component.scss'
})
export class CashBookEntryDetailPageComponent {
  readonly apollo = inject(Apollo);
  readonly activatedRoute = inject(ActivatedRoute);
  readonly loadingService = inject(LoadingService);
  readonly authorizationService = inject(AuthorizationService);

  readonly item = toSignal(
    this.activatedRoute.data.pipe(
      untilDestroyed(this),
      tap(() => this.loadingService.startLoading()),
      switchMap(data => {
        const result = !!data?.['crud'] && !!data['crud']['id'];
        if (result) {
          return this.apollo
            .query<{ result: AccountingCashBookEntryModel }>({
              query: gql`
                query ReadAccountingAccountCashBookEntryById($id: bigint!) {
                  result: accounting_cash_book_entry_by_pk(id: $id) {
                    id
                    number
                    created_at
                    updated_at
                    date_of_entry
                    type
                    amount
                    description
                    accounting_period_id
                    payed_by

                    cash_book_entry_category {
                      name
                      is_student_balance
                    }

                    ${
                      this.authorizationService.can(AccountingCashBookEntryPermission.UPDATE)
                        ? 'entry { id, number }'
                        : ''
                    }

                    school {
                      name
                      organisation {
                        name
                      }
                    }

                    receipt_documents {
                      id
                      name
                      size
                    }
                  }
                }
              `,
              variables: {
                id: data['crud']['id']
              }
            })
            .pipe(map(queryResult => queryResult.data?.result));
        } else {
          return of(undefined);
        }
      }),
      tap(() => this.loadingService.stopLoading())
    )
  );

  studentPatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;

  readonly fields = computed<DetailField[]>(() => {
    const item = this.item();
    if (!item) {
      return [];
    }

    this.studentPatchOptions = options => {
      options.filter = {
        ...options.filter,
        _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
      };
      if (Array.isArray(options?.filter?._and)) {
        options.filter._and = options.filter._and.filter((cond: any) => !cond.cash_book_entry_id);
        options.filter._and.push({
          cash_book_entry_id: {
            _eq: this.item()?.id
          }
        });
      }
      return options;
    };

    return [
      {
        label: 'number',
        value: item.number
      },
      {
        label: 'created_at',
        type: 'datetime',
        value: item.created_at
      },
      {
        label: 'updated_at',
        type: 'datetime',
        value: item.updated_at
      },
      {
        label: 'accounting_period',
        value: item.accounting_period_id
      },
      {
        label: 'organisation',
        value: item.school?.organisation?.name
      },
      {
        label: 'school',
        value: item.school?.name
      },
      {
        label: 'type',
        translate: true,
        value: 'cash_book_entry_type.' + item.type
      },
      {
        label: 'cash_book_entry_category',
        value: item.cash_book_entry_category?.name
      },
      {
        label: 'date',
        type: 'date',
        value: item.date_of_entry
      },
      {
        label: 'payed_by',
        value: item.payed_by
      },
      {
        label: 'amount',
        type: 'currency',
        value: item.amount
      },
      ...(this.authorizationService.can(AccountingCashBookEntryPermission.UPDATE)
        ? ([
            {
              label: 'is_invoiced',
              type: 'boolean',
              value: !!item.entry?.id
            },
            {
              key: 'entry_link',
              label: 'account_entry',
              value: item.entry
            }
          ] as DetailField[])
        : []),
      {
        label: 'description',
        value: item.description
      }
    ];
  });
  protected readonly AccountingCashBookEntryTypeEnum = AccountingCashBookEntryTypeEnum;
}
