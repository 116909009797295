import { Role } from '~ngx-shared/authentication';
import { DefaultProperties } from '../default.model';
import { AccountingCashBookEntryTypeEnum } from './cash-book-entry-type.enum';
import { AccountingCashBookEntryModel } from './cash-book-entry.model';
import { AccountingPeriodModel } from './period.model';

export interface AccountingCashBookEntryCategoryModel extends DefaultProperties {
  // Columns
  id?: number;
  type?: AccountingCashBookEntryTypeEnum;
  name?: string;
  accounting_period_id?: number;
  is_student_balance?: boolean;
  is_receipt_optional?: boolean;

  // Relations
  accounting_period?: AccountingPeriodModel;
  cash_book_entries?: AccountingCashBookEntryModel[];
}

export class AccountingCashBookEntryCategoryPermission {
  static readonly CREATE = [Role.LESSOR, Role.MANAGER];
  static readonly READ = [Role.LESSOR, Role.MANAGER, Role.DIRECTOR];
  static readonly UPDATE = [Role.LESSOR, Role.MANAGER];
  static readonly DELETE = [Role.LESSOR];
  static readonly SOFT_DELETE = [...AccountingCashBookEntryCategoryPermission.DELETE, Role.MANAGER];
}
