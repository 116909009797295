<lib-busy *transloco="let transloco" [height]="'50vh'" [isLoading]="this.isLoading()">
  <div class="flex flex-col gap-6">
    <lib-detail [fields]="this.fields()"></lib-detail>

    <p-accordion [multiple]="true">
      <p-accordionTab header="{{ transloco('teacher_salary') }}">
        <div class="flex flex-col gap-6">
          <lib-detail [fields]="this.teacherSalaryFields()"></lib-detail>

          <span class="mt-4 block text-2xl font-semibold">{{
            transloco('school_teacher_salaries')
          }}</span>
          <app-school-teacher-salary-list
            [hideTeacherColumn]="true"
            [patchOptions]="this.schoolTeacherSalaryPatchOptions"
            [showExport]="true"
            [showFilter]="true"
            [showSort]="true"
            [stateKey]="
              this.router.url + 'teacher-detail-school-teacher-salary-list'
            "></app-school-teacher-salary-list>

          <span class="mt-4 block text-2xl font-semibold">{{
            transloco('organisation_teacher_salaries')
          }}</span>
          <app-organisation-teacher-salary-list
            [hideTeacherColumn]="true"
            [patchOptions]="this.organisationTeacherSalaryPatchOptions"
            [showExport]="true"
            [showFilter]="true"
            [showSort]="true"
            [stateKey]="
              this.router.url + 'teacher-detail-organisation-teacher-salary-list'
            "></app-organisation-teacher-salary-list>
        </div>
      </p-accordionTab>

      <p-accordionTab *ngxPermissionsExcept="Role.TEACHER" header="{{ transloco('courses') }}">
        <app-course-list
          [hideTeacherColumn]="true"
          [patchOptions]="this.coursePatchOptions"
          [showExport]="true"
          [showFilter]="true"
          [showSort]="true"
          [stateKey]="'teacher-detail-course-list'"></app-course-list>
      </p-accordionTab>

      <p-accordionTab *ngxPermissionsExcept="Role.TEACHER" header="{{ transloco('units') }}">
        <app-unit-list
          [hideTeacherColumn]="true"
          [patchOptions]="this.unitPatchOptions"
          [showExport]="true"
          [showFilter]="true"
          [showSort]="true"
          [stateKey]="'teacher-detail-unit-list'"></app-unit-list>
      </p-accordionTab>

      <p-accordionTab
        *ngxPermissionsExcept="Role.TEACHER"
        header="{{ transloco('class_book_entries') }}">
        <app-class-book-entry-list
          [columns]="this.cbeColumns()"
          [hideTeacherColumn]="true"
          [patchOptions]="this.cbePatchOptions"
          [showExport]="true"
          [showFilter]="true"
          [showSort]="true"
          [stateKey]="'teacher-detail-cbe-list'">
          <ng-template let-data libTemplate="class_book_entry_teachers.presentCell">
            @if (data?.class_book_entry_teachers?.[0]?.present) {
              <p-tag severity="success" [value]="transloco('true')"></p-tag>
            } @else {
              <p-tag severity="secondary" [value]="transloco('false')"></p-tag>
            }
          </ng-template>
        </app-class-book-entry-list>
      </p-accordionTab>

      <p-accordionTab header="{{ transloco('teacher_wages') }}">
        <app-teacher-wage-list
          [patchOptions]="this.teacherWagePatchOptions"></app-teacher-wage-list>
      </p-accordionTab>

      <p-accordionTab header="{{ transloco('travel_costs') }}">
        <app-travel-cost-list
          [patchOptions]="this.travelCostPatchOptions"
          [showActions]="false"
          [showColumnFilter]="false"
          [showCreateButton]="false"
          [showDocuments]="false"
          [showExport]="false"
          [showFilter]="false"
          [showSort]="false"></app-travel-cost-list>
      </p-accordionTab>

      <p-accordionTab
        *ngxPermissionsOnly="StaffTeacherPermission.CREATE"
        header="{{ transloco('notes') }}">
        <ng-template pTemplate="content">
          <app-note-list
            [patchInput]="this.notePatchInput"
            [patchOptions]="this.notePatchOptions"
            [stateKey]="'teacher-detail-note-list'"
            [table]="'note_person_note'"></app-note-list>
        </ng-template>
      </p-accordionTab>
    </p-accordion>
  </div>
</lib-busy>
