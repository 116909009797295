import { Component, computed, inject } from '@angular/core';
import { PrimeTemplate } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { TranslocoDirective } from '@jsverse/transloco';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, of, switchMap, tap } from 'rxjs';
import { TabViewQueryDirective, TemplateDirective } from '~ngx-shared/directives';
import { DetailComponent, DetailField } from '~ngx-shared/ui/detail/detail.component';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import {
  AccountingStudentBalanceModel,
  AccountingStudentBalancePermission
} from '~ngx-shared/models';
import { AuthorizationService } from '~ngx-shared/authentication';

@UntilDestroy()
@Component({
  selector: 'app-student-balance-detail-page',
  standalone: true,
  imports: [
    BasePageComponent,
    TranslocoDirective,
    DetailComponent,
    PrimeTemplate,
    TabViewModule,
    TabViewQueryDirective,
    TemplateDirective,
    RouterLink
  ],
  templateUrl: './student-balance-detail-page.component.html',
  styleUrl: './student-balance-detail-page.component.scss'
})
export class StudentBalanceDetailPageComponent {
  readonly apollo = inject(Apollo);
  readonly activatedRoute = inject(ActivatedRoute);
  readonly loadingService = inject(LoadingService);
  readonly authorizationService = inject(AuthorizationService);

  readonly item = toSignal(
    this.activatedRoute.data.pipe(
      untilDestroyed(this),
      tap(() => this.loadingService.startLoading()),
      switchMap(data => {
        const result = !!data?.['crud'] && !!data['crud']['id'];
        if (result) {
          return this.apollo
            .query<{ result: AccountingStudentBalanceModel }>({
              query: gql`
                query ReadAccountingAccountStudentBalanceById($id: bigint!) {
                  result: accounting_student_balance_by_pk(id: $id) {
                    id
                    created_at
                    updated_at
                    amount
                    month_of_balance
                    description
                    person_id

                   ${
                     this.authorizationService.can(AccountingStudentBalancePermission.UPDATE)
                       ? 'entry { id, number }, cash_book_entry { id, number },'
                       : ''
                   }

                    current_person_data {
                      academic_degree_prefix
                      academic_degree_suffix
                      first_name
                      last_name
                      person_id

                      organisation_persons_active {
                        organisation {
                          name
                        }
                      }
                      school_students_active {
                        school {
                          name
                        }
                      }
                    }
                  }
                }
              `,
              variables: {
                id: data['crud']['id']
              }
            })
            .pipe(map(queryResult => queryResult.data?.result));
        } else {
          return of(undefined);
        }
      }),
      tap(() => this.loadingService.stopLoading())
    )
  );

  readonly fields = computed<DetailField[]>(() => {
    const item = this.item();
    if (!item) {
      return [];
    }
    return [
      {
        label: 'id',
        value: item.id
      },
      {
        label: 'created_at',
        type: 'datetime',
        value: item.created_at
      },
      {
        label: 'updated_at',
        type: 'datetime',
        value: item.updated_at
      },
      {
        label: 'organisation',
        value: item.current_person_data?.organisation_persons_active?.map(
          item => item?.organisation?.name
        )
      },
      {
        label: 'school',
        value: item.current_person_data?.school_students_active?.map(item => item?.school?.name)
      },
      ...(this.authorizationService.can(AccountingStudentBalancePermission.UPDATE)
        ? ([
            {
              key: 'entry',
              label: 'account_entry',
              value: item.entry
            },
            {
              key: 'cash_book_entry',
              label: 'cash_book_entry',
              value: item.cash_book_entry
            }
          ] as DetailField[])
        : []),
      {
        label: 'for_month',
        type: 'date',
        typeOptions: 'MM/yyyy',
        value: item.month_of_balance
      },
      {
        label: 'amount',
        type: 'currency',
        value: item.amount
      },
      {
        label: 'description',
        value: item.description
      }
    ];
  });
}
