<lib-layout-base-page
  *transloco="let transloco"
  [titleAddon]="this.accountingPeriodService.currentPeriod()?.id">
  <div class="flex flex-col">
    <p-tabView libTabViewQuery="t">
      <p-tabPanel header="{{ transloco('account') }}">
        <ng-template pTemplate="content">
          <app-account-list
            [accountPeriodId]="this.accountingPeriodService.currentPeriod()?.id!"
            [showExport]="true"
            [showFilter]="true"
            [showSort]="true"
            [stateKey]="this.router.url + 'account-list'"></app-account-list>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel header="{{ transloco('account_group') }}">
        <ng-template pTemplate="content">
          <app-account-group-list
            [showFilter]="true"
            [showSort]="true"
            [showExport]="true"
            [stateKey]="this.router.url + 'account-group-list'"
            [accountPeriodId]="
              this.accountingPeriodService.currentPeriod()?.id!
            "></app-account-group-list>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </div>
</lib-layout-base-page>
