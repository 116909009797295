import { Component, inject, OnInit, signal } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ListTabMenuComponent } from '~madrasa/core/components/list-tab-menu/list-tab-menu.component';
import { BasePageComponent } from '~ngx-shared/layout';
import { TemplateDirective } from '~ngx-shared/directives';
import { CalculateTeacherWageListComponent } from '~madrasa/accounting/components/calculate-teacher-wage-list/calculate-teacher-wage-list.component';
import { CourseInvoiceListComponent } from '~madrasa/accounting/components/course-invoice-list/course-invoice-list.component';
import { AccountingPeriodService } from '~madrasa/accounting/services/accounting-period.service';
import { TravelCostListComponent } from '~madrasa/accounting/components/travel-cost-list/travel-cost-list.component';
import { OperatingCostListComponent } from '~madrasa/accounting/components/operating-cost-list/operating-cost-list.component';

@UntilDestroy()
@Component({
  selector: 'app-settlement-page',
  standalone: true,
  imports: [
    BasePageComponent,
    ListTabMenuComponent,
    TranslocoDirective,
    TemplateDirective,
    CalculateTeacherWageListComponent,
    CourseInvoiceListComponent,
    TravelCostListComponent,
    OperatingCostListComponent
  ],
  templateUrl: './settlement-page.component.html',
  styleUrl: './settlement-page.component.scss'
})
export class SettlementPageComponent implements OnInit {
  router = inject(Router);
  readonly accountingPeriodService = inject(AccountingPeriodService);

  readonly items = signal<MenuItem[]>([]);

  ngOnInit(): void {
    this.accountingPeriodService.currentPeriod$.pipe(untilDestroyed(this)).subscribe(period => {
      if (period) {
        this.items.set([
          {
            id: 'course_invoice'
          },
          {
            id: 'teacher_wages'
          },
          {
            id: 'travel_costs',
            state: { titleAddon: period.id }
          },
          {
            id: 'operating_costs'
          }
        ]);
      }
    });
    this.accountingPeriodService.getPeriods().subscribe();
  }
}
