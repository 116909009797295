import { Injectable, signal } from '@angular/core';
import { select } from '@ngneat/elf';
import { Apollo, gql } from 'apollo-angular';
import { Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { toSignal } from '@angular/core/rxjs-interop';
import { AccountingPeriodModel } from '~ngx-shared/models';
import { StoreService } from '~ngx-shared/services';

type AccountPeriodStore = {
  activePeriod?: AccountingPeriodModel;
  currentPeriod?: AccountingPeriodModel;
  periods?: AccountingPeriodModel[];
};

@Injectable({
  providedIn: 'root'
})
export class AccountingPeriodService {
  periodStore = this.storeService.createStore<AccountPeriodStore>({
    name: 'accounting-period',
    initial: undefined
  });

  // Shows the current selected period
  currentPeriod$ = this.periodStore.pipe(select(state => state.currentPeriod));
  // Shows active period
  activePeriod$ = this.periodStore.pipe(select(state => state.activePeriod));
  // Shows all periods
  periods$ = this.periodStore.pipe(select(state => state.periods));

  readonly currentPeriod = toSignal(this.currentPeriod$);
  readonly activePeriod = toSignal(this.activePeriod$);

  readonly isLoading = signal<boolean>(false);

  constructor(
    private apollo: Apollo,
    private storeService: StoreService
  ) {}

  getPeriods(fromCache: boolean = true): Observable<AccountingPeriodModel[] | undefined> {
    this.isLoading.set(true);
    if (fromCache && this.periodStore.value?.periods && this.periodStore.value.periods.length) {
      this.isLoading.set(false);
      return this.periods$;
    }
    return this.apollo
      .query<{ periods: AccountingPeriodModel[] }>({
        query: gql`
          query ReadAccountingPeriodsOrderedById {
            periods: accounting_accounting_period(order_by: { id: desc }) {
              id
              created_at
              updated_at
              is_active
              is_archived
            }
          }
        `
      })
      .pipe(
        map(queryResult => queryResult.data.periods),
        tap(periods => {
          this.updatePeriods(periods, true);
          this.isLoading.set(false);
        })
      );
  }

  updatePeriods(periods: AccountingPeriodModel[] = [], setActiveAsCurrent: boolean = false) {
    const activePeriod = periods.find(period => period.is_active);
    this.periodStore.update(state => ({
      ...state,
      periods,
      activePeriod,
      currentPeriod: setActiveAsCurrent ? activePeriod : undefined
    }));
  }

  setCurrentPeriod(currentPeriod: AccountingPeriodModel): void {
    if (
      this.periodStore.value.periods &&
      this.periodStore.value.periods.length &&
      this.periodStore.value.periods.some(
        (period: AccountingPeriodModel) => period.id === currentPeriod.id
      )
    ) {
      this.periodStore.update(state => ({
        ...state,
        currentPeriod
      }));
    }
  }

  createNewPeriod(): Observable<AccountingPeriodModel> {
    return this.apollo
      .query<{ periods: AccountingPeriodModel[] }>({
        query: gql`
          mutation CreateNewAccountingPeriod {
            accounting_create_new_accounting_period(args: {}) {
              id
              created_at
              updated_at
              is_active
              is_archived
            }
          }
        `
      })
      .pipe(
        tap(() => {
          this.getPeriods(false).subscribe();
        })
      );
  }
}
