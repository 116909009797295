<div *transloco="let transloco" class="flex flex-col space-y-5">
  @if (this.showFilter() || this.showSort()) {
    <div class="flex flex-wrap justify-stretch gap-5">
      <div class="min-w-fit flex-1">
        @if (this.showFilter()) {
          <lib-graph-ql-advanced-filter
            [graphQlAdvancedTable]="advancedTable"
            [graphQlTable]="this.graphQlTable"
            [stateKey]="this.stateKey()"></lib-graph-ql-advanced-filter>
        }
      </div>
      <div class="min-w-fit flex-1">
        @if (this.showSort()) {
          <lib-graph-ql-advanced-sort
            [graphQlAdvancedTable]="advancedTable"
            [graphQlTable]="this.graphQlTable"
            [stateKey]="this.stateKey()"></lib-graph-ql-advanced-sort>
        }
      </div>
    </div>
  }

  <lib-graph-ql-advanced-table
    #advancedTable
    [graphQlTable]="this.graphQlTable"
    [patchOptions]="this.graphQlPatchOptions"
    [showColumnFilter]="this.showColumnFilter()"
    [showExport]="this.showExport()"
    [stateKey]="this.stateKey()">
    @if (this.showCreateButton()) {
      <ng-template libTemplate="caption">
        <a
          [pTooltip]="transloco('new')"
          [routerLink]="['/', 'accounting', 'settlements', 'travel-costs', 'new']"
          class="p-button-success !p-2 text-white"
          icon="pi pi-plus"
          pButton
          tooltipPosition="top"
          type="button"></a>
      </ng-template>
    }
    <ng-template let-data libTemplate="current_person_dataCell">
      <div class="flex flex-col gap-2">
        <app-person-detail-link [person]="data.current_person_data"></app-person-detail-link>
      </div>
    </ng-template>
    <ng-template let-data libTemplate="travel_cost_documentsCell">
      <div class="flex flex-col gap-2">
        @for (document of data.travel_cost_documents; track document) {
          @let status = this.fileService.status()[document.id];
          <div class="flex items-center justify-between gap-2">
            <span>{{ document.name }}</span>
            <div class="flex items-center gap-2">
              <button
                (click)="this.downloadFile(document, true)"
                [disabled]="!status?.isOpening && status?.isDownloading"
                [loading]="status?.isOpening || false"
                [pTooltip]="transloco('show')"
                class="p-button-info p-button-text"
                icon="pi pi-eye"
                pButton
                tooltipPosition="top"
                type="button"></button>

              <button
                (click)="this.downloadFile(document)"
                [disabled]="status?.isOpening"
                [loading]="(!status?.isOpening && status?.isDownloading) || false"
                [pTooltip]="transloco('download')"
                class="p-button-info p-button-text"
                icon="pi pi-download"
                pButton
                tooltipPosition="top"
                type="button"></button>
            </div>
          </div>
        }
      </div>
    </ng-template>
    @if (this.showActions()) {
      <ng-template let-data libTemplate="actions">
        <div class="flex items-center justify-center gap-2">
          <a
            [pTooltip]="transloco('edit')"
            [routerLink]="['/', 'accounting', 'settlements', 'travel-costs', this.getRow(data)?.id]"
            class="p-button-warning p-button-text"
            icon="pi pi-pencil"
            pButton
            tooltipPosition="top"
            type="button"></a>

          <button
            (click)="this.delete(data)"
            [pTooltip]="transloco('delete')"
            class="p-button-danger p-button-text"
            icon="pi pi-trash"
            pButton
            tooltipPosition="top"
            type="button"></button>
        </div>
      </ng-template>
    }
  </lib-graph-ql-advanced-table>
</div>
