<lib-layout-base-page *transloco="let transloco">
  <app-list-tab-menu [items]="this.items()">
    <ng-template libTemplate="travel_cost_pricesKey">
      <app-travel-cost-price-list
        [accountingPeriodId]="this.accountingPeriodService.currentPeriod()?.id"
        [stateKey]="this.router.url + 'travel-cost-price-list'"></app-travel-cost-price-list>
    </ng-template>

    <ng-template libTemplate="cost_centersKey">
      <app-cost-center-list
        [accountingPeriodId]="this.accountingPeriodService.currentPeriod()?.id"
        [stateKey]="this.router.url + 'travel-cost-center-list'"></app-cost-center-list>
    </ng-template>

    <ng-template libTemplate="accounting_periodsKey">
      <app-accounting-period-list></app-accounting-period-list>
    </ng-template>

    <ng-template libTemplate="operating_cost_typesKey">
      <app-operating-cost-type-list
        [stateKey]="this.router.url + 'operating-cost-type-list'"></app-operating-cost-type-list>
    </ng-template>
  </app-list-tab-menu>
</lib-layout-base-page>
