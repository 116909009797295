<lib-layout-base-page
  *transloco="let transloco"
  [titleAddon]="this.accountingPeriodService.currentPeriod()?.id">
  <div action class="flex w-full flex-col gap-2 md:w-auto md:flex-row md:items-center">
    <a
      [label]="transloco('account_category.income')"
      [queryParams]="{ type: 'incoming' }"
      [routerLink]="['.', 'new']"
      class="p-button-success text-white"
      pButton
      type="button"></a>

    <a
      [label]="transloco('account_category.expense')"
      [queryParams]="{ type: 'outgoing' }"
      [routerLink]="['.', 'new']"
      class="p-button-danger text-white"
      pButton
      type="button"></a>

    <a
      [label]="transloco('custom')"
      [queryParams]="{ type: 'custom' }"
      [routerLink]="['.', 'new']"
      class="text-white"
      pButton
      type="button"></a>
  </div>

  <div class="m-base flex flex-col">
    <lib-busy [isLoading]="!this.accountingPeriodService.currentPeriod()?.id">
      <app-entry-list
        [accountPeriodId]="this.accountingPeriodService.currentPeriod()?.id!"
        [showExport]="true"
        [showFilter]="true"
        [showSort]="true"
        [stateKey]="this.router.url + 'entry-list'"></app-entry-list>
    </lib-busy>
  </div>
</lib-layout-base-page>
