<lib-layout-base-page
  [title]="this.getFullName(this.readOnlyModel.current_person_data)"
  [titleAddon]="this.schoolPeriodService.currentPeriod()?.name">
  <div class="m-base-form">
    <lib-formly-form
      [fields]="this.fields"
      [form]="this.form"
      [model]="this.model"
      [navigateBackOnCancel]="true"
      [navigateBackOnSave]="true"
      [options]="this.options"
      [submit]="this.submit">
    </lib-formly-form>
  </div>
</lib-layout-base-page>
