import { Component, Type } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FieldWrapperProps } from '../field-wrapper/field-wrapper.component';

interface EditorProps extends FieldWrapperProps {
  rows?: number;
  cols?: number;

  noBoldButton?: boolean;
  noItalicButton?: boolean;
  noUnderlineButton?: boolean;
  noStrikeButton?: boolean;
  noBlockquoteButton?: boolean;
  noCodeBlockButton?: boolean;

  noListButton?: boolean;
  noScriptButton?: boolean;
  noIndentButton?: boolean;

  noSizeButton?: boolean;
  noHeaderButton?: boolean;

  noTextStyleButton?: boolean;
  noAlignButton?: boolean;

  noLinkButton?: boolean;
}

export interface EditorFieldConfig extends FormlyFieldConfig<EditorProps> {
  type?: 'editor' | Type<EditorTypeComponent>;
}

@Component({
  selector: 'lib-editor-type',
  templateUrl: './editor-type.component.html',
  styleUrl: './editor-type.component.css'
})
export class EditorTypeComponent extends FieldType<FieldTypeConfig<EditorProps>> {
  // modules = {
  //   toolbar: [
  //     ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  //     ['blockquote', 'code-block'],
  //
  //     // [{ header: 1 }, { header: 2 }], // custom button values
  //     [{ list: 'ordered' }, { list: 'bullet' }],
  //     [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  //     [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  //     // [{ direction: 'rtl' }], // text direction
  //
  //     [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  //     [{ header: [1, 2, 3, 4, 5, 6, false] }],
  //
  //     [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  //     // [{ font: [] }],
  //     [{ align: [] }],
  //
  //     ['clean'], // remove formatting button
  //
  //     ['link'] //, 'image', 'video'] // link and image, video
  //   ]
  // };

  getModules() {
    const toolbar = [];

    if (
      !this.props.noBoldButton ||
      !this.props.noItalicButton ||
      !this.props.noUnderlineButton ||
      !this.props.noStrikeButton
    ) {
      const buttons = [];
      if (!this.props.noBoldButton) {
        buttons.push('bold');
      }
      if (!this.props.noItalicButton) {
        buttons.push('italic');
      }
      if (!this.props.noUnderlineButton) {
        buttons.push('underline');
      }
      if (!this.props.noStrikeButton) {
        buttons.push('strike');
      }
      toolbar.push(buttons);
    }

    if (!this.props.noBlockquoteButton || !this.props.noCodeBlockButton) {
      const buttons = [];
      if (!this.props.noBlockquoteButton) {
        buttons.push('blockquote');
      }
      if (!this.props.noCodeBlockButton) {
        buttons.push('code-block');
      }
      toolbar.push(buttons);
    }

    if (!this.props.noListButton) {
      toolbar.push([{ list: 'ordered' }, { list: 'bullet' }]);
    }
    if (!this.props.noScriptButton) {
      toolbar.push([{ script: 'sub' }, { script: 'super' }]);
    }
    if (!this.props.noIndentButton) {
      toolbar.push([{ indent: '-1' }, { indent: '+1' }]);
    }

    if (!this.props.noSizeButton) {
      toolbar.push([{ size: ['small', false, 'large', 'huge'] }]);
    }

    if (!this.props.noHeaderButton) {
      toolbar.push([{ header: [1, 2, 3, 4, 5, 6, false] }]);
    }

    if (!this.props.noTextStyleButton) {
      toolbar.push([{ color: [] }, { background: [] }]);
    }

    if (!this.props.noAlignButton) {
      toolbar.push([{ align: [] }]);
    }

    toolbar.push(['clean']);

    if (!this.props.noLinkButton) {
      toolbar.push(['link']);
    }

    return { toolbar };
  }
}
