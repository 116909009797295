import { Component, inject, OnInit, signal } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { map, of, switchMap } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { ButtonDirective } from 'primeng/button';
import { NgxPermissionsModule } from 'ngx-permissions';
import {
  FilePaperTemplateMediaTypeEnum,
  PrintOrientationEnum,
  PrintPaperTemplateModel,
  PrintPositionEnum
} from '~ngx-shared/models';
import { FormlyService, PrintService, TenantService } from '~madrasa/services';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { ModelUtil, QueryUtil } from '~ngx-shared/utils';

@UntilDestroy()
@Component({
  selector: 'app-create-update-paper-template-form-page',
  standalone: true,
  imports: [
    BasePageComponent,
    FormlyModule,
    ButtonDirective,
    NgxPermissionsModule,
    TranslocoDirective
  ],
  templateUrl: './create-update-paper-template-form-page.component.html',
  styleUrl: './create-update-paper-template-form-page.component.scss'
})
export class CreateUpdatePaperTemplateFormPageComponent implements OnInit {
  readonly apollo = inject(Apollo);
  readonly formlyService = inject(FormlyService);
  readonly activatedRoute = inject(ActivatedRoute);
  readonly loadingService = inject(LoadingService);
  readonly translocoService = inject(TranslocoService);
  readonly tenantService = inject(TenantService);
  readonly printService = inject(PrintService);

  form = new FormGroup({});
  readOnlyModel: any = {};
  model: PrintPaperTemplateModel = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.create_update_paper_template'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  readonly isGenerating = signal(false);

  ngOnInit(): void {
    this.loadingService.startLoading();

    this.activatedRoute.data
      .pipe(
        untilDestroyed(this),
        switchMap(data => {
          const result = !!data?.['crud'] && !data['crud']['is_new'] && !!data['crud']['id'];
          if (result) {
            return this.apollo
              .query<{ result: PrintPaperTemplateModel }>({
                query: gql`
                  query ReadPrintPaperTemplateById($id: bigint!) {
                    result: print_paper_template_by_pk(id: $id) {
                      id

                      name
                      orientation
                      font_size
                      margin_top
                      margin_bottom
                      margin_left
                      margin_right
                      style

                      logo_height
                      logo_width
                      logo_position
                      logo_margin_top
                      logo_margin_bottom
                      logo_margin_left
                      logo_margin_right
                      logo_style

                      bg_height
                      bg_width
                      bg_vertical_position
                      bg_horizontal_position
                      bg_style

                      header
                      header_font_size
                      header_position
                      header_margin_top
                      header_margin_bottom
                      header_margin_left
                      header_margin_right
                      header_style

                      footer
                      footer_font_size
                      footer_position
                      footer_margin_top
                      footer_margin_bottom
                      footer_margin_left
                      footer_margin_right
                      footer_style

                      page_counter_font_size
                      page_counter_position
                      page_counter_margin_top
                      page_counter_margin_bottom
                      page_counter_margin_left
                      page_counter_margin_right
                      page_counter_style

                      paper_template_media {
                        id
                        paper_template_media_type
                        name
                        size
                      }
                    }
                  }
                `,
                variables: {
                  id: data['crud']['id']
                }
              })
              .pipe(map(queryResult => queryResult.data?.result));
          } else {
            return of(undefined);
          }
        })
      )
      .subscribe(result => {
        if (result) {
          this.readOnlyModel = result;
          let model = cloneDeep(result);

          if (model) {
            this.model = {
              ...model,
              logo: model.paper_template_media
                ?.filter(
                  item => item.paper_template_media_type === FilePaperTemplateMediaTypeEnum.LOGO
                )
                ?.map(item => item.id)
                ?.filter((item, index) => index === 0),
              background: model.paper_template_media
                ?.filter(
                  item =>
                    item.paper_template_media_type === FilePaperTemplateMediaTypeEnum.BACKGROUND
                )
                ?.map(item => item.id)
                ?.filter((item, index) => index === 0)
            };
          }
        }

        this.fields = [
          {
            key: 'id'
          },
          FormlyUtil.createRow([
            FormlyUtil.createTextField('name', {
              props: {
                required: true
              }
            })
          ]),
          FormlyUtil.createGroup('page', [
            FormlyUtil.createRow([
              FormlyUtil.createSelectField('orientation', {
                props: {
                  required: true,
                  showClear: true,
                  options: Object.values(PrintOrientationEnum).map(value => ({
                    label: 'orientation.' + value,
                    value: value
                  }))
                }
              }),
              FormlyUtil.createNumberField('font_size', {
                props: {
                  required: true,
                  min: 0,
                  max: 100,
                  suffix: 'px'
                }
              })
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createNumberField('margin_top', {
                props: {
                  min: Number.NEGATIVE_INFINITY,
                  suffix: 'px'
                }
              }),
              FormlyUtil.createNumberField('margin_bottom', {
                props: {
                  min: Number.NEGATIVE_INFINITY,
                  suffix: 'px'
                }
              })
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createNumberField('margin_left', {
                props: {
                  min: Number.NEGATIVE_INFINITY,
                  suffix: 'px'
                }
              }),
              FormlyUtil.createNumberField('margin_right', {
                props: {
                  min: Number.NEGATIVE_INFINITY,
                  suffix: 'px'
                }
              })
            ]),
            FormlyUtil.createRow([FormlyUtil.createTextAreaField('style')])
          ]),
          FormlyUtil.createGroup('logo', [
            this.formlyService.createFileUploadFieldConfig('logo', {
              props: {
                hideLabel: true,
                required: false,
                namespace: 'paper_template_media',
                accept: 'image/*',
                maxFiles: 1
              }
            }),
            FormlyUtil.createRow([
              FormlyUtil.createNumberField('logo_height', {
                props: {
                  suffix: 'px'
                }
              }),
              FormlyUtil.createNumberField('logo_width', {
                props: {
                  suffix: 'px'
                }
              })
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createSelectField('logo_position', {
                props: {
                  showClear: true,
                  options: Object.values(PrintPositionEnum).map(value => ({
                    label: 'position.' + value,
                    value: value
                  }))
                }
              })
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createNumberField('logo_margin_top', {
                props: {
                  min: Number.NEGATIVE_INFINITY,
                  suffix: 'px'
                }
              }),
              FormlyUtil.createNumberField('logo_margin_bottom', {
                props: {
                  min: Number.NEGATIVE_INFINITY,
                  suffix: 'px'
                }
              })
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createNumberField('logo_margin_left', {
                props: {
                  min: Number.NEGATIVE_INFINITY,
                  suffix: 'px'
                }
              }),
              FormlyUtil.createNumberField('logo_margin_right', {
                props: {
                  min: Number.NEGATIVE_INFINITY,
                  suffix: 'px'
                }
              })
            ]),
            FormlyUtil.createRow([FormlyUtil.createTextAreaField('logo_style')])
          ]),

          FormlyUtil.createGroup('background', [
            this.formlyService.createFileUploadFieldConfig('background', {
              props: {
                hideLabel: true,
                required: false,
                namespace: 'paper_template_media',
                accept: 'image/*',
                maxFiles: 1
              }
            }),
            FormlyUtil.createRow([
              FormlyUtil.createNumberField('bg_height', {
                props: {
                  suffix: '%',
                  min: 0,
                  max: 100
                }
              }),
              FormlyUtil.createNumberField('bg_width', {
                props: {
                  suffix: '%',
                  min: 0,
                  max: 100
                }
              })
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createSelectField('bg_horizontal_position', {
                props: {
                  showClear: true,
                  options: Object.values(PrintPositionEnum).map(value => ({
                    label: 'position.' + value,
                    value: value
                  }))
                }
              }),
              FormlyUtil.createSelectField('bg_vertical_position', {
                props: {
                  showClear: true,
                  options: Object.values(PrintPositionEnum).map(value => ({
                    label: 'position.' + value,
                    value: value
                  }))
                }
              })
            ]),
            FormlyUtil.createRow([FormlyUtil.createTextAreaField('bg_style')])
          ]),

          FormlyUtil.createGroup('header', [
            FormlyUtil.createRow([FormlyUtil.createTextAreaField('header')]),
            FormlyUtil.createRow([
              FormlyUtil.createNumberField('header_font_size', {
                props: {
                  min: 0,
                  max: 100,
                  suffix: 'px'
                }
              }),
              FormlyUtil.createSelectField('header_position', {
                props: {
                  showClear: true,
                  options: Object.values(PrintPositionEnum).map(value => ({
                    label: 'position.' + value,
                    value: value
                  }))
                }
              })
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createNumberField('header_margin_top', {
                props: {
                  min: Number.NEGATIVE_INFINITY,
                  suffix: 'px'
                }
              }),
              FormlyUtil.createNumberField('header_margin_bottom', {
                props: {
                  min: Number.NEGATIVE_INFINITY,
                  suffix: 'px'
                }
              })
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createNumberField('header_margin_left', {
                props: {
                  min: Number.NEGATIVE_INFINITY,
                  suffix: 'px'
                }
              }),
              FormlyUtil.createNumberField('header_margin_right', {
                props: {
                  min: Number.NEGATIVE_INFINITY,
                  suffix: 'px'
                }
              })
            ]),
            FormlyUtil.createRow([FormlyUtil.createTextAreaField('header_style')])
          ]),
          FormlyUtil.createGroup('footer', [
            FormlyUtil.createRow([FormlyUtil.createTextAreaField('footer')]),
            FormlyUtil.createRow([
              FormlyUtil.createNumberField('footer_font_size', {
                props: {
                  min: 0,
                  max: 100,
                  suffix: 'px'
                }
              }),
              FormlyUtil.createSelectField('footer_position', {
                props: {
                  showClear: true,
                  options: Object.values(PrintPositionEnum).map(value => ({
                    label: 'position.' + value,
                    value: value
                  }))
                }
              })
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createNumberField('footer_margin_top', {
                props: {
                  min: Number.NEGATIVE_INFINITY,
                  suffix: 'px'
                }
              }),
              FormlyUtil.createNumberField('footer_margin_bottom', {
                props: {
                  min: Number.NEGATIVE_INFINITY,
                  suffix: 'px'
                }
              })
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createNumberField('footer_margin_left', {
                props: {
                  min: Number.NEGATIVE_INFINITY,
                  suffix: 'px'
                }
              }),
              FormlyUtil.createNumberField('footer_margin_right', {
                props: {
                  min: Number.NEGATIVE_INFINITY,
                  suffix: 'px'
                }
              })
            ]),
            FormlyUtil.createRow([FormlyUtil.createTextAreaField('footer_style')])
          ]),
          FormlyUtil.createGroup('page_counter', [
            FormlyUtil.createRow([
              FormlyUtil.createNumberField('page_counter_font_size', {
                props: {
                  min: 0,
                  max: 100,
                  suffix: 'px'
                }
              }),
              FormlyUtil.createSelectField('page_counter_position', {
                props: {
                  showClear: true,
                  options: Object.values(PrintPositionEnum).map(value => ({
                    label: 'position.' + value,
                    value: value
                  }))
                }
              })
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createNumberField('page_counter_margin_top', {
                props: {
                  min: Number.NEGATIVE_INFINITY,
                  suffix: 'px'
                }
              }),
              FormlyUtil.createNumberField('page_counter_margin_bottom', {
                props: {
                  min: Number.NEGATIVE_INFINITY,
                  suffix: 'px'
                }
              })
            ]),
            FormlyUtil.createRow([
              FormlyUtil.createNumberField('page_counter_margin_left', {
                props: {
                  min: Number.NEGATIVE_INFINITY,
                  suffix: 'px'
                }
              }),
              FormlyUtil.createNumberField('page_counter_margin_right', {
                props: {
                  min: Number.NEGATIVE_INFINITY,
                  suffix: 'px'
                }
              })
            ]),
            FormlyUtil.createRow([FormlyUtil.createTextAreaField('page_counter_style')])
          ])
        ];

        this.loadingService.stopLoading();
      });

    this.submit = (formSaveModel: FormSaveModel) => {
      const input = {
        ...formSaveModel.input,
        paper_template_media: {
          data: [
            ...(formSaveModel.input.logo?.map((item: any) => ({
              id: item,
              paper_template_media_type: FilePaperTemplateMediaTypeEnum.LOGO
            })) || []),
            ...(formSaveModel.input.background?.map((item: any) => ({
              id: item,
              paper_template_media_type: FilePaperTemplateMediaTypeEnum.BACKGROUND
            })) || [])
          ]
        }
      };

      ModelUtil.deleteKey(input, 'logo');
      ModelUtil.deleteKey(input, 'background');

      const params: string[] = ['$input: print_paper_template_insert_input!'];
      const queries: string[] = [
        `
         result: insert_print_paper_template_one(object: $input) {
            __typename
         }
        `
      ];
      let variables: any = { input };

      if (this.model?.id) {
        ModelUtil.deleteKey(input);
        ModelUtil.deleteKey(input, 'paper_template_media');

        // Clear params
        params.length = 0;
        queries.length = 0;

        params.push('$id: bigint!', '$input: print_paper_template_set_input!');

        queries.push(`
          result: update_print_paper_template_by_pk(
            pk_columns: { id: $id }
            _set: $input
          ) {
            __typename
          }
        `);

        variables = { id: this.model.id, input };

        let newPaperTemplateMedia = QueryUtil.comparer({
          itemsA: formSaveModel.input.logo,
          itemsB: this.readOnlyModel.paper_template_media
            ?.filter(
              (item: any) => item.paper_template_media_type === FilePaperTemplateMediaTypeEnum.LOGO
            )
            ?.map((item: any) => item.id),
          compareFunc: (itemA: any, itemB: any) => itemA === itemB,
          resultMapFunc: item => item
        })?.map((id: any) => ({
          id,
          paper_template_id: this.model.id,
          paper_template_media_type: FilePaperTemplateMediaTypeEnum.LOGO
        }));

        QueryUtil.comparer({
          itemsA: formSaveModel.input.background,
          itemsB: this.readOnlyModel.paper_template_media
            ?.filter(
              (item: any) =>
                item.paper_template_media_type === FilePaperTemplateMediaTypeEnum.BACKGROUND
            )
            ?.map((item: any) => item.id),
          compareFunc: (itemA: any, itemB: any) => itemA === itemB,
          resultMapFunc: item => item
        })
          ?.map((id: any) => ({
            id,
            paper_template_id: this.model.id,
            paper_template_media_type: FilePaperTemplateMediaTypeEnum.BACKGROUND
          }))
          ?.forEach(item => {
            if (!newPaperTemplateMedia) {
              newPaperTemplateMedia = [];
            }
            newPaperTemplateMedia.push(item);
          });

        if (newPaperTemplateMedia?.length) {
          params.push('$newPaperTemplateMedia: [file_paper_template_media_insert_input!] = [] ');
          queries.push(
            'insert_file_paper_template_media(objects: $newPaperTemplateMedia) { __typename }'
          );
          variables = {
            ...variables,
            newPaperTemplateMedia
          };
        }

        const updatePaperTemplateMedia = [];
        // Get deleted media
        updatePaperTemplateMedia.push(
          ...(QueryUtil.comparer({
            itemsA: this.readOnlyModel?.paper_template_media
              ?.filter(
                (item: any) =>
                  item.paper_template_media_type === FilePaperTemplateMediaTypeEnum.LOGO
              )
              ?.map((item: any) => item.id),
            itemsB: formSaveModel.input?.logo,
            compareFunc: (itemA: any, itemB: any) => itemA === itemB,
            resultMapFunc: item => ({
              where: {
                id: { _eq: item }
              },
              _set: { deleted_at: 'now()' }
            })
          }) || [])
        );

        updatePaperTemplateMedia.push(
          ...(QueryUtil.comparer({
            itemsA: this.readOnlyModel?.paper_template_media
              ?.filter(
                (item: any) =>
                  item.paper_template_media_type === FilePaperTemplateMediaTypeEnum.BACKGROUND
              )
              ?.map((item: any) => item.id),
            itemsB: formSaveModel.input?.background,
            compareFunc: (itemA: any, itemB: any) => itemA === itemB,
            resultMapFunc: item => ({
              where: {
                id: { _eq: item }
              },
              _set: { deleted_at: 'now()' }
            })
          }) || [])
        );

        if (updatePaperTemplateMedia.length) {
          params.push('$updatePaperTemplateMedia: [file_paper_template_media_updates!] = []');
          queries.push(
            'update_file_paper_template_media_many(updates: $updatePaperTemplateMedia) { __typename }'
          );
          variables = {
            ...variables,
            updatePaperTemplateMedia
          };
        }
      }

      return this.apollo.mutate({
        mutation: gql`
            mutation CreatePrintPaperTemplate(
              ${params.join('\n')}
            ) {
             ${queries.join('\n')}
            }
          `,
        variables
      });
    };
  }

  generate() {
    this.form.markAsDirty();
    this.form.disable();
    this.isGenerating.set(true);
  }

  savedEvent(formSaveModel: FormSaveModel) {
    if (this.isGenerating() && this.model?.id && !formSaveModel.hasError) {
      this.printService.paperTemplate(this.model.id);
      this.isGenerating.set(false);
      this.form.enable();
    }
  }
}
