<lib-busy [isLoading]="this.loadingService.isLoading()">
  <div *transloco="let transloco" class="flex h-full flex-col space-y-5 py-8">
    <div class="flex flex-wrap justify-between gap-4">
      <div class="flex w-full flex-col items-start gap-5 md:w-auto md:flex-row md:items-center">
        <div class="">
          <span
            class="overflow-hidden truncate text-ellipsis whitespace-nowrap text-wrap text-3xl lg:text-4xl">
            {{
              transloco(this.title || '', {
                value: this.titleParamValue ? transloco(this.titleParamValue) : undefined
              }) | uppercase
            }}
            @if (this.titleAddon) {
              - {{ this.titleAddon }}
            }
          </span>
        </div>

        <ng-content select="[action]"></ng-content>
      </div>
      <div>
        @if (this.showBackButton) {
          <button
            (click)="this.back()"
            class="p-button-secondary p-button-outlined"
            icon="pi pi-arrow-left"
            label="{{ transloco('back') }}"
            pButton
            type="button"></button>
        }
      </div>
    </div>

    <ng-content></ng-content>
  </div>
</lib-busy>
