import { Component, inject, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { TranslocoModule } from '@jsverse/transloco';
import { MenuModule } from 'primeng/menu';
import { TabMenuModule } from 'primeng/tabmenu';
import { BasePageComponent } from '~ngx-shared/layout';
import { TenantService } from '~madrasa/services';
import { ListTabMenuComponent } from '~madrasa/core/components/list-tab-menu/list-tab-menu.component';
import { TemplateDirective } from '~ngx-shared/directives';
import { PaperTemplateListComponent } from '~madrasa/print/components/paper-template-list/paper-template-list.component';

@Component({
  selector: 'app-print-management-page',
  standalone: true,
  imports: [
    BasePageComponent,
    TabViewModule,
    TranslocoModule,
    MenuModule,
    TabMenuModule,
    ListTabMenuComponent,
    TemplateDirective,
    PaperTemplateListComponent
  ],
  templateUrl: './print-management-page.component.html',
  styleUrl: './print-management-page.component.scss'
})
export class PrintManagementPageComponent implements OnInit {
  router = inject(Router);
  tenantService = inject(TenantService);

  readonly items = signal<MenuItem[]>([]);

  ngOnInit(): void {
    this.items.set([
      {
        id: 'paper_templates'
      }
    ]);
  }
}
