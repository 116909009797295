import { Component, inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, of, switchMap } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { TranslocoService } from '@jsverse/transloco';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { FormlyService } from '~madrasa/services';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { AccountingTravelCostModel, AccountingTravelCostPriceModel } from '~ngx-shared/models';
import { ModelUtil, QueryUtil } from '~ngx-shared/utils';
import { AccountingPeriodService } from '~madrasa/accounting/services/accounting-period.service';

@UntilDestroy()
@Component({
  selector: 'app-create-update-travel-cost-form-page',
  standalone: true,
  imports: [BasePageComponent, FormlyModule],
  templateUrl: './create-update-travel-cost-form-page.component.html',
  styleUrl: './create-update-travel-cost-form-page.component.scss'
})
export class CreateUpdateTravelCostFormPageComponent implements OnInit {
  readonly apollo = inject(Apollo);
  readonly activatedRoute = inject(ActivatedRoute);
  readonly loadingService = inject(LoadingService);
  readonly translocoService = inject(TranslocoService);
  readonly formlyService = inject(FormlyService);
  readonly accountingPeriodService = inject(AccountingPeriodService);

  form = new FormGroup({});
  readOnlyModel: any = {};
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.create_update_travel_cost'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  ngOnInit(): void {
    this.loadingService.startLoading();

    this.activatedRoute.data
      .pipe(
        untilDestroyed(this),
        switchMap(data => {
          const result = !!data?.['crud'] && !data['crud']['is_new'] && !!data['crud']['id'];
          if (result) {
            return this.apollo
              .query<{ result: AccountingTravelCostModel }>({
                query: gql`
                  query ReadAcademyTravelCostId($id: bigint!) {
                    result: accounting_travel_cost_by_pk(id: $id) {
                      id
                      kilometer
                      ticket_cost
                      month_of_cost
                      travel_cost_price_id
                      person_id

                      current_person_data {
                        academic_degree_prefix
                        academic_degree_suffix
                        first_name
                        last_name
                        person_id
                        date_of_birth
                      }

                      travel_cost_documents {
                        id
                      }
                    }
                  }
                `,
                variables: {
                  id: data['crud']['id']
                }
              })
              .pipe(map(queryResult => queryResult.data?.result));
          } else {
            return of(undefined);
          }
        })
      )
      .subscribe(result => {
        if (result) {
          this.readOnlyModel = result;
          let model = cloneDeep(result);

          if (model) {
            this.model = {
              ...model,
              ticket_cost: (model.ticket_cost || 0) / 100,
              month_of_cost: FormlyUtil.fromIsoDateString(model.month_of_cost),
              person_id: {
                label: ModelUtil.getFullName(model.current_person_data, this.translocoService),
                value: {
                  label: ModelUtil.getFullName(model.current_person_data, this.translocoService),
                  value: model.current_person_data
                }
              },
              travel_cost_documents: model.travel_cost_documents?.map((item: any) => item.id)
            };
          }
        }

        this.fields = [
          {
            key: 'id'
          },
          FormlyUtil.createRow([
            FormlyUtil.createDatePickerField('month_of_cost', {
              props: {
                label: 'for_month',
                dateFormat: 'mm/yy',
                view: 'month',
                showButtonBar: false,
                required: true
              }
            })
          ]),
          FormlyUtil.createRow([
            {
              ...this.formlyService.createPersonFieldConfig({
                required: true,
                forceSelection: true,
                readonly: this.model?.id,
                conditions: field => {
                  return [{ latest_teacher: {} }];
                }
              })
            }
          ]),
          FormlyUtil.createRow([
            FormlyUtil.createSelectField('travel_cost_price_id', {
              props: {
                label: 'travel_cost_price',
                options: this.apollo
                  .query<{
                    result: AccountingTravelCostPriceModel[];
                  }>({
                    query: gql`
                      query ReadAccountingTravelCostPrices {
                        result: accounting_travel_cost_price(order_by: { name: asc }) {
                          id
                          name
                        }
                      }
                    `
                  })
                  .pipe(
                    map(queryResult =>
                      queryResult.data.result.map(item => ({
                        label: item.name,
                        value: item.id
                      }))
                    )
                  )
              },
              expressions: {
                hide: field => field.model?.ticket_cost
              }
            })
          ]),
          FormlyUtil.createRow([
            FormlyUtil.createNumberField('kilometer', {
              props: {
                maxFractionDigits: 2,
                suffix: ' km',
                min: 0
              },
              expressions: {
                hide: field => !field.model?.travel_cost_price_id
              }
            })
          ]),
          FormlyUtil.createRow([
            FormlyUtil.createNumberField('ticket_cost', {
              props: {
                maxFractionDigits: 2,
                iconPre: 'pi pi-euro',
                min: 0
              },
              expressions: {
                hide: field => field.model?.travel_cost_price_id
              }
            })
          ]),
          FormlyUtil.createRow([
            this.formlyService.createFileUploadFieldConfig('travel_cost_documents', {
              props: {
                label: 'travel_cost_documents',
                namespace: 'travel_cost_document',
                multiple: true
              }
            })
          ])
        ];

        this.loadingService.stopLoading();
      });

    this.submit = (formSaveModel: FormSaveModel) => {
      const input = {
        ...formSaveModel.input,
        accounting_period_id: this.accountingPeriodService.currentPeriod()?.id,
        month_of_cost: FormlyUtil.toIsoDateString(formSaveModel.input.month_of_cost),
        person_id: formSaveModel.input.person_id?.value?.value?.person_id,
        ticket_cost: formSaveModel.input.ticket_cost
          ? Math.round(formSaveModel.input.ticket_cost * 100)
          : null,
        travel_cost_price_id:
          formSaveModel.input.travel_cost_price_id?.id || formSaveModel.input.travel_cost_price_id,
        travel_cost_documents: {
          data: (
            QueryUtil.comparer({
              itemsA: formSaveModel.input.travel_cost_documents,
              itemsB: this.readOnlyModel.travel_cost_documents,
              compareFunc: (itemA: any, itemB: any) => itemA === itemB?.id,
              resultMapFunc: item => item
            }) || []
          )?.map((id: any) => ({
            id
          }))
        }
      };

      const params: string[] = ['$input: accounting_travel_cost_insert_input!'];
      const queries: string[] = [
        `
          result: insert_accounting_travel_cost_one(object: $input) {
            __typename
          }
        `
      ];

      let variables: any = { input };

      if (this.model?.id) {
        ModelUtil.deleteKey(input);
        ModelUtil.deleteKey(input, 'accounting_period_id');
        ModelUtil.deleteKey(input, 'person_id');
        ModelUtil.deleteKey(input, 'travel_cost_documents');

        // Clear params
        params.length = 0;
        queries.length = 0;

        params.push('$id: bigint!', '$input: accounting_travel_cost_set_input!');

        queries.push(`
          result: update_accounting_travel_cost_by_pk(pk_columns: { id: $id }, _set: $input) {
            __typename
          }
        `);

        variables = { id: this.model.id, input };

        const updateTravelCostDocuments = [
          // Get deleted travel cost documents
          ...(QueryUtil.comparer({
            itemsA: this.readOnlyModel.travel_cost_documents,
            itemsB: formSaveModel.input.travel_cost_documents,
            compareFunc: (itemA: any, itemB: any) => !!itemB && itemA.id === itemB,
            resultMapFunc: item => ({
              where: {
                id: { _eq: item.id }
              },
              _set: { deleted_at: 'now()' }
            })
          }) || [])
        ];

        if (updateTravelCostDocuments.length) {
          params.push('$updateTravelCostDocuments: [file_travel_cost_document_updates!] = [] ');
          queries.push(
            'update_file_travel_cost_document_many(updates: $updateTravelCostDocuments) { __typename }'
          );
          variables = {
            ...variables,
            updateTravelCostDocuments
          };
        }

        const newTravelCostDocuments = (
          QueryUtil.comparer({
            itemsA: formSaveModel.input.travel_cost_documents,
            itemsB: this.readOnlyModel.travel_cost_documents,
            compareFunc: (itemA: any, itemB: any) => itemA === itemB?.id,
            resultMapFunc: item => item
          }) || []
        )?.map((id: any) => ({
          id,
          travel_cost_id: this.model.id
        }));

        if (newTravelCostDocuments.length) {
          params.push('$newTravelCostDocuments: [file_travel_cost_document_insert_input!] = [] ');
          queries.push(
            'insert_file_travel_cost_document(objects: $newTravelCostDocuments) { __typename }'
          );
          variables = {
            ...variables,
            newTravelCostDocuments
          };
        }
      }

      return this.apollo.mutate({
        mutation: gql`
            mutation CreateUpdateAcademyTravelCost(
              ${params.join('\n')}
            ) {
             ${queries.join('\n')}
            }
          `,
        variables
      });
    };

    this.accountingPeriodService.getPeriods().subscribe();
  }
}
