import { Component, inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { combineLatest, filter, map, of, switchMap, tap } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { TranslocoService } from '@jsverse/transloco';
import {
  AccountingCashBookEntryCategoryModel,
  AccountingCashBookEntryModel,
  AccountingCashBookEntryPermission,
  AccountingCashBookEntryTypeEnum
} from '~ngx-shared/models';
import { AccountingPeriodService } from '~madrasa/accounting/services/accounting-period.service';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { ModelUtil, QueryUtil } from '~ngx-shared/utils';
import { AuthorizationService } from '~ngx-shared/authentication';
import { FormlyService } from '~madrasa/services';

@UntilDestroy()
@Component({
  selector: 'app-create-update-cash-book-entry-form-page',
  standalone: true,
  imports: [BasePageComponent, FormlyModule],
  templateUrl: './create-update-cash-book-entry-form-page.component.html',
  styleUrl: './create-update-cash-book-entry-form-page.component.scss'
})
export class CreateUpdateCashBookEntryFormPageComponent implements OnInit {
  readonly apollo = inject(Apollo);
  readonly activatedRoute = inject(ActivatedRoute);
  readonly loadingService = inject(LoadingService);
  readonly authorizationService = inject(AuthorizationService);
  readonly formlyService = inject(FormlyService);
  readonly translocoService = inject(TranslocoService);
  readonly accountingPeriodService = inject(AccountingPeriodService);

  form = new FormGroup({});
  readOnlyModel: AccountingCashBookEntryModel = {};
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.create_update_cash_book_entry'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  ngOnInit(): void {
    this.loadingService.startLoading();

    combineLatest([
      this.activatedRoute.data.pipe(
        untilDestroyed(this),
        switchMap(data => {
          const result = !!data?.['crud'] && !data['crud']['is_new'] && !!data['crud']['id'];
          if (result) {
            return this.apollo
              .query<{ result: AccountingCashBookEntryModel }>({
                query: gql`
                  query ReadAccountingCashBookEntryId($id: bigint!) {
                    result: accounting_cash_book_entry_by_pk(id: $id) {
                      id
                      accounting_period_id
                      school_id
                      cash_book_entry_category_id
                      date_of_entry
                      type
                      amount
                      description
                      payed_by

                      school {
                        organisation_id
                      }

                      cash_book_entry_category {
                        id
                        name
                        is_student_balance
                        is_receipt_optional
                      }

                      receipt_documents {
                        id
                      }

                      entry {
                        id
                      }

                      student_balances {
                        id
                        amount
                        month_of_balance
                        current_person_data {
                          academic_degree_prefix
                          academic_degree_suffix
                          first_name
                          last_name
                          person_id
                          date_of_birth
                        }
                      }
                    }
                  }
                `,
                variables: {
                  id: data['crud']['id']
                }
              })
              .pipe(map(queryResult => queryResult.data?.result));
          } else {
            return of(undefined);
          }
        })
      ),
      this.accountingPeriodService.currentPeriod$
    ]).subscribe(result => {
      if (result[1]) {
        const currentPeriod = result[1];
        const type = this.activatedRoute.snapshot.queryParamMap.get(
          'type'
        ) as AccountingCashBookEntryTypeEnum;

        if (result[0]) {
          this.readOnlyModel = result;
          let model = cloneDeep(result[0]);

          if (model) {
            this.model = {
              ...model,
              type: model.type || type,
              organisation_id: model.school?.organisation_id,
              date_of_entry: FormlyUtil.fromIsoDateString(model.date_of_entry),
              amount: model.amount ? model.amount / 100 : undefined,
              receipt_documents: model.receipt_documents?.map((item: any) => item.id),
              cash_book_entry_category_id: model.cash_book_entry_category,

              person_id: {
                label: ModelUtil.getFullName(
                  model.student_balances?.[0]?.current_person_data,
                  this.translocoService
                ),
                value: {
                  label: ModelUtil.getFullName(
                    model.student_balances?.[0]?.current_person_data,
                    this.translocoService
                  ),
                  value: model.student_balances?.[0]?.current_person_data
                }
              },
              student_balances: model.student_balances?.map(item => ({
                id: item.id,
                month_of_balance: FormlyUtil.fromIsoDateString(item.month_of_balance),
                amount: item.amount ? item.amount / 100 : undefined
              }))
            };
          }
        } else {
          this.model = {
            type
          };
        }

        this.fields = [
          {
            key: 'id'
          },
          this.formlyService.createOrganisationFieldConfig({ required: true }),
          this.formlyService.createSchoolFieldConfig({ required: true }),
          FormlyUtil.createRow([
            FormlyUtil.createDatePickerField('date_of_entry', {
              props: {
                label: 'date',
                required: true
              }
            }),
            FormlyUtil.createSelectField('type', {
              props: {
                required: true,
                options: Object.values(AccountingCashBookEntryTypeEnum).map(value => ({
                  label: 'cash_book_entry_type.' + value,
                  value: value
                }))
              }
            })
          ]),
          FormlyUtil.createSelectField('cash_book_entry_category_id', {
            props: {
              label: 'cash_book_entry_category',
              required: true,
              options: []
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                const createRequest = (type?: string) => {
                  return this.apollo
                    .query<{
                      result: AccountingCashBookEntryCategoryModel[];
                    }>({
                      query: gql`
                        query ReadAccountingCashBookEntryCategories(
                          $where: accounting_cash_book_entry_category_bool_exp = {}
                        ) {
                          result: accounting_cash_book_entry_category(
                            order_by: { name: asc }
                            where: $where
                          ) {
                            id
                            name
                            is_student_balance
                            is_receipt_optional
                          }
                        }
                      `,
                      variables: {
                        where: {
                          type: { _eq: type },
                          accounting_period_id: {
                            _eq: currentPeriod.id
                          }
                        }
                      }
                    })
                    .pipe(
                      map(queryResult =>
                        queryResult.data.result.map(item => ({
                          label: item.name,
                          value: item
                        }))
                      )
                    );
                };

                if (field.props && field.model?.type) {
                  field.props.options = createRequest(field.model?.type);
                }

                return field.options?.fieldChanges?.pipe(
                  filter(event => event.type === 'valueChanges' && event.field.key === 'type'),
                  tap(event => {
                    field.formControl?.setValue(undefined);

                    if (event.value && field.props) {
                      field.props.options = createRequest(event.value);
                    }
                  })
                );
              }
            }
          }),
          FormlyUtil.createRow([
            this.formlyService.createFileUploadFieldConfig('receipt_documents', {
              props: {
                label: 'receipts',
                required: true,
                namespace: 'receipt_document',
                multiple: true
              },
              expressions: {
                hide: field => {
                  return (
                    field.model?.type === AccountingCashBookEntryTypeEnum.INCOMING ||
                    field.model?.cash_book_entry_category_id?.is_receipt_optional
                  );
                }
              }
            })
          ]),
          FormlyUtil.createRow([
            {
              ...this.formlyService.createPersonFieldConfig({
                required: true,
                forceSelection: true,
                conditions: field => {
                  return [
                    { latest_student: {} },
                    {
                      school_students_active: {
                        school_id: {
                          _eq: field.model?.school_id
                        }
                      }
                    }
                  ];
                }
              }),
              expressions: {
                hide: field => {
                  return (
                    field.model?.type !== AccountingCashBookEntryTypeEnum.INCOMING ||
                    !field.model?.cash_book_entry_category_id?.is_student_balance
                  );
                }
              }
            }
          ]),
          FormlyUtil.createRepeat(
            {
              key: 'student_balances',
              props: {
                hideLabel: true,
                disableMargin: true,
                disableTopMargin: true,
                required: true,
                initialCount: 1
              },
              expressions: {
                hide: field => {
                  return (
                    field.parent?.model?.type !== AccountingCashBookEntryTypeEnum.INCOMING ||
                    !field.parent?.model?.cash_book_entry_category_id?.is_student_balance
                  );
                }
              }
            },
            [
              {
                key: 'id'
              },
              FormlyUtil.createRow([
                FormlyUtil.createNumberField('amount', {
                  props: {
                    required: true,
                    maxFractionDigits: 2,
                    iconPre: 'pi pi-euro',
                    min: 0
                  }
                })
              ]),
              FormlyUtil.createRow([
                FormlyUtil.createDatePickerField('month_of_balance', {
                  props: {
                    label: 'for_month',
                    dateFormat: 'mm/yy',
                    view: 'month',
                    showButtonBar: false,
                    required: true
                  }
                })
              ])
            ]
          ),
          FormlyUtil.createRow([
            FormlyUtil.createNumberField('amount', {
              props: {
                required: true,
                maxFractionDigits: 2,
                iconPre: 'pi pi-euro',
                min: 0
              },
              expressions: {
                hide: field => {
                  return (
                    field.model?.type === AccountingCashBookEntryTypeEnum.INCOMING &&
                    field.model?.cash_book_entry_category_id?.is_student_balance
                  );
                }
              }
            })
          ]),
          FormlyUtil.createRow([
            FormlyUtil.createTextField('payed_by', {
              hide: true,
              props: {
                required: true
              },
              expressions: {
                hide: field => {
                  return field.model?.type !== AccountingCashBookEntryTypeEnum.INCOMING;
                }
              }
            })
          ]),
          FormlyUtil.createRow([
            FormlyUtil.createTextAreaField('description', {
              props: {
                required: this.authorizationService.cannot(AccountingCashBookEntryPermission.UPDATE)
              }
            })
          ])
        ];

        this.loadingService.stopLoading();
      }
    });

    this.submit = (formSaveModel: FormSaveModel) => {
      const input = {
        ...formSaveModel.input,
        accounting_period_id: this.accountingPeriodService.currentPeriod()?.id,
        date_of_entry: FormlyUtil.toIsoDateString(formSaveModel.input.date_of_entry),
        amount: formSaveModel.input.amount
          ? Math.round(formSaveModel.input.amount * 100)
          : formSaveModel.input.student_balances
              ?.map((item: any) => item.amount)
              .reduce((a: any, b: any) => a + b, 0) * 100,

        cash_book_entry_category_id:
          formSaveModel.input.cash_book_entry_category_id?.id ||
          formSaveModel.input.cash_book_entry_category_id,
        receipt_documents: {
          data: (
            QueryUtil.comparer({
              itemsA: formSaveModel.input.receipt_documents,
              itemsB: this.readOnlyModel.receipt_documents,
              compareFunc: (itemA: any, itemB: any) => itemA === itemB?.id,
              resultMapFunc: item => item
            }) || []
          )?.map((id: any) => ({
            id
          }))
        },

        ...(!this.model?.id &&
        formSaveModel.input.type === AccountingCashBookEntryTypeEnum.INCOMING &&
        !!formSaveModel.input?.cash_book_entry_category_id?.is_student_balance
          ? {
              student_balances: {
                data: formSaveModel.input.student_balances?.map((item: any) => ({
                  month_of_balance: FormlyUtil.toIsoDateString(item.month_of_balance),
                  person_id: formSaveModel.input.person_id?.value?.value?.person_id,
                  amount: item.amount ? Math.round(item.amount * 100) : null,
                  description: formSaveModel.input.description
                }))
              }
            }
          : {})
      };

      ModelUtil.deleteKey(input, 'organisation_id');
      ModelUtil.deleteKey(input, 'person_id');

      const params: string[] = ['$input: accounting_cash_book_entry_insert_input!'];
      const queries: string[] = [
        `
         result: insert_accounting_cash_book_entry_one(object: $input) {
            __typename
         }
        `
      ];
      let variables: any = { input };

      if (this.model?.id) {
        ModelUtil.deleteKey(input);
        ModelUtil.deleteKey(input, 'accounting_period_id');
        ModelUtil.deleteKey(input, 'receipt_documents');
        ModelUtil.deleteKey(input, 'student_balances');

        // Clear params
        params.length = 0;
        queries.length = 0;

        params.push('$id: bigint!', '$input: accounting_cash_book_entry_set_input!');

        queries.push(`
          result: update_accounting_cash_book_entry_by_pk(
            pk_columns: { id: $id }
            _set: $input
          ) {
            __typename
          }
        `);

        variables = { id: this.model.id, input };

        const newBalances = QueryUtil.comparer({
          itemsA: formSaveModel.input.student_balances,
          itemsB: this.readOnlyModel.student_balances,
          compareFunc: (itemA: any, itemB: any) => itemA?.id === itemB?.id,
          resultMapFunc: item => item
        })?.map((item: any) => ({
          cash_book_entry_id: this.model.id,
          person_id: formSaveModel.input.person_id?.value?.value?.person_id,
          month_of_balance: FormlyUtil.toIsoDateString(item.month_of_balance),
          amount: item.amount ? Math.round(item.amount * 100) : null,
          description: formSaveModel.input.description
        }));

        if (newBalances?.length) {
          params.push('$newBalances: [accounting_student_balance_insert_input!] = [] ');
          queries.push('insert_accounting_student_balance(objects: $newBalances) { __typename }');
          variables = {
            ...variables,
            newBalances
          };
        }

        const updateBalances: any[] =
          // Deleted ones first
          QueryUtil.comparer({
            itemsA: this.readOnlyModel.student_balances,
            itemsB: formSaveModel.input.student_balances,
            compareFunc: (itemA: any, itemB: any) => !!itemB?.id && itemA.id === itemB.id,
            resultMapFunc: item => ({
              where: {
                id: { _eq: item.id }
              },
              _set: { deleted_at: 'now()' }
            })
          }) || [];

        updateBalances.push(
          ...(this.readOnlyModel.student_balances
            ?.map((item: any) => {
              const balance = formSaveModel.input.student_balances?.find(
                (student_balance: any) => student_balance.id === item.id
              );
              if (
                balance &&
                (balance.month_of_balance !== item.month_of_balance ||
                  balance.amount !== item.amount ||
                  balance.description !== formSaveModel.input.description ||
                  balance.person_id !== formSaveModel.input.person_id?.value?.value?.person_id)
              ) {
                return {
                  where: {
                    id: { _eq: item.id }
                  },
                  _set: {
                    month_of_balance: FormlyUtil.toIsoDateString(balance.month_of_balance),
                    amount: balance.amount ? Math.round(balance.amount * 100) : null,
                    description: formSaveModel.input.description,
                    person_id: formSaveModel.input.person_id?.value?.value?.person_id
                  }
                };
              }
              return undefined;
            })
            .filter((item: any) => !!item) || [])
        );

        if (updateBalances.length) {
          params.push('$updateBalances: [accounting_student_balance_updates!] = [] ');
          queries.push(
            'update_accounting_student_balance_many(updates: $updateBalances) { __typename }'
          );
          variables = {
            ...variables,
            updateBalances
          };
        }

        const updateReceiptDocuments = [
          // Get deleted receipt documents
          ...(QueryUtil.comparer({
            itemsA: this.readOnlyModel.receipt_documents,
            itemsB: formSaveModel.input.receipt_documents,
            compareFunc: (itemA: any, itemB: any) => !!itemB && itemA.id === itemB,
            resultMapFunc: item => ({
              where: {
                id: { _eq: item.id }
              },
              _set: { deleted_at: 'now()' }
            })
          }) || [])
        ];

        if (updateReceiptDocuments.length) {
          params.push('$updateReceiptDocuments: [file_receipt_document_updates!] = [] ');
          queries.push(
            'update_file_receipt_document_many(updates: $updateReceiptDocuments) { __typename }'
          );
          variables = {
            ...variables,
            updateReceiptDocuments
          };
        }

        const newReceiptDocuments = (
          QueryUtil.comparer({
            itemsA: formSaveModel.input.receipt_documents,
            itemsB: this.readOnlyModel.receipt_documents,
            compareFunc: (itemA: any, itemB: any) => itemA === itemB?.id,
            resultMapFunc: item => item
          }) || []
        )?.map((id: any) => ({
          id,
          cash_book_entry_id: this.model.id
        }));

        if (newReceiptDocuments.length) {
          params.push('$newReceiptDocuments: [file_receipt_document_insert_input!] = [] ');
          queries.push(
            'insert_file_receipt_document(objects: $newReceiptDocuments) { __typename }'
          );
          variables = {
            ...variables,
            newReceiptDocuments
          };
        }
      }

      return this.apollo.mutate({
        mutation: gql`
            mutation CreateUpdateAccountingCashBookEntry(
              ${params.join('\n')}
            ) {
             ${queries.join('\n')}
            }
          `,
        variables
      });
    };

    this.accountingPeriodService.getPeriods().subscribe();
  }
}
