<app-file-upload
  [accept]="this.props.accept"
  [formControl]="this.formControl"
  [formlyAttributes]="this.field"
  [maxFiles]="this.props.maxFiles"
  [multiple]="this.props.multiple"
  [namespace]="this.props.namespace"
  [ngClass]="{
    'ng-dirty ng-invalid p-invalid': this.showError
  }"
  [readonly]="this.props.readonly"
  [size]="this.props.size">
</app-file-upload>
