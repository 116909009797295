<lib-busy *transloco="let transloco" [height]="'50vh'" [isLoading]="this.isLoading()">
  <div class="flex flex-col gap-6">
    <lib-detail [fields]="this.fields()"></lib-detail>

    <p-accordion [multiple]="true">
      <p-accordionTab header="{{ transloco('courses') }}">
        <app-course-list
          [columns]="this.courseColumns()"
          [patchOptions]="this.coursePatchOptions"
          [showExport]="true"
          [showFilter]="true"
          [showSort]="true"
          [stateKey]="'student-detail-course-list'">
          <ng-template let-data libTemplate="latest_course_students.statusCell">
            <p-tag
              [severity]="
                data?.latest_course_students?.[0]?.status ===
                this.AcademyCourseStudentStatusEnum.ACTIVE
                  ? 'success'
                  : 'secondary'
              "
              [value]="transloco('status.' + data?.latest_course_students?.[0]?.status)"></p-tag>
          </ng-template>
        </app-course-list>
      </p-accordionTab>

      <p-accordionTab header="{{ transloco('class_book_entries') }}">
        <app-class-book-entry-list
          [columns]="this.cbeColumns()"
          [patchOptions]="this.cbePatchOptions"
          [showExport]="true"
          [showFilter]="true"
          [showSort]="true"
          [stateKey]="'student-detail-cbe-list'">
          <ng-template let-data libTemplate="class_book_entry_students.statusCell">
            <p-tag
              [severity]="
                data?.class_book_entry_students?.[0]?.status ===
                this.AcademyClassBookEntryStudentStatusEnum.PRESENT
                  ? 'success'
                  : data?.class_book_entry_students?.[0]?.status ===
                      this.AcademyClassBookEntryStudentStatusEnum.ABSENT
                    ? 'danger'
                    : data?.class_book_entry_students?.[0]?.status ===
                        this.AcademyClassBookEntryStudentStatusEnum.EXCUSED
                      ? 'warning'
                      : 'secondary'
              "
              [value]="transloco('status.' + data?.class_book_entry_students?.[0]?.status)"></p-tag>
          </ng-template>
        </app-class-book-entry-list>
      </p-accordionTab>

      <p-accordionTab
        header="{{ transloco('student_balances') }}"
        *ngxPermissionsOnly="this.AccountingStudentBalancePermission.CREATE">
        <app-student-balance-list
          [showExport]="true"
          [personId]="this.personData()?.person_id"
          [stateKey]="'student-detail-student-balance-list'">
        </app-student-balance-list>
      </p-accordionTab>

      <p-accordionTab
        *ngxPermissionsOnly="this.StaffStudentPermission.CREATE"
        header="{{ transloco('notes') }}">
        <ng-template pTemplate="content">
          <app-note-list
            [patchInput]="this.notePatchInput"
            [patchOptions]="this.notePatchOptions"
            [stateKey]="'student-detail-note-list'"
            [table]="'note_person_note'"></app-note-list>
        </ng-template>
      </p-accordionTab>
    </p-accordion>
  </div>
</lib-busy>
