<lib-layout-base-page *transloco="let transloco">
  <div class="m-base-form">
    <lib-formly-form
      [fields]="this.fields"
      [form]="this.form"
      [model]="this.model"
      [navigateBackOnCancel]="true"
      [navigateBackOnSave]="!this.isGenerating()"
      [options]="this.options"
      (savedEvent)="this.savedEvent($event)"
      [submit]="this.submit">
      @if (this.model.id) {
        <button
          submit
          (click)="this.generate()"
          [loading]="this.isGenerating()"
          [label]="transloco('generate')"
          class="p-button-warning"
          icon="pi pi-cog"
          pButton
          type="submit"></button>
      }
    </lib-formly-form>
  </div>
</lib-layout-base-page>
