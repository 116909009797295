import { Component, inject, OnInit, signal } from '@angular/core';
import { TabViewModule } from 'primeng/tabview';
import { TranslocoModule } from '@jsverse/transloco';
import { Router } from '@angular/router';
import { MenuModule } from 'primeng/menu';
import { TabMenuModule } from 'primeng/tabmenu';
import { MenuItem } from 'primeng/api';
import { CourseTypeListComponent } from '~madrasa/academy/components/course-type-list/course-type-list.component';
import { SubjectListComponent } from '~madrasa/academy/components/subject-list/subject-list.component';
import { SchoolPeriodListComponent } from '~madrasa/academy/components/school-period-list/school-period-list.component';
import { BasePageComponent } from '~ngx-shared/layout';
import { TemplateDirective } from '~ngx-shared/directives';
import { OrganisationListComponent } from '~madrasa/academy/components/organisation-list/organisation-list.component';
import { CourseNameListComponent } from '~madrasa/academy/components/course-name-list/course-name-list.component';
import { TenantService } from '~madrasa/services';
import { TeacherHourlyRateListComponent } from '~madrasa/academy/components/teacher-hourly-rate-list/teacher-hourly-rate-list.component';
import { EducationTypeListComponent } from '~madrasa/staff/components/education-type-list/education-type-list.component';
import { ListTabMenuComponent } from '~madrasa/core/components/list-tab-menu/list-tab-menu.component';
import { PersonDocumentTypeListComponent } from '~madrasa/file/components/person-document-type-list/person-document-type-list.component';
import { CertificateTemplateListComponent } from '~madrasa/academy/components/certificate-template-list/certificate-template-list.component';

@Component({
  selector: 'app-academy-management-page',
  standalone: true,
  imports: [
    CourseTypeListComponent,
    SubjectListComponent,
    SchoolPeriodListComponent,
    BasePageComponent,
    TabViewModule,
    TranslocoModule,
    OrganisationListComponent,
    CourseNameListComponent,
    TeacherHourlyRateListComponent,
    EducationTypeListComponent,
    MenuModule,
    TabMenuModule,
    ListTabMenuComponent,
    TemplateDirective,
    PersonDocumentTypeListComponent,
    CertificateTemplateListComponent
  ],
  templateUrl: './academy-management-page.component.html',
  styleUrl: './academy-management-page.component.scss'
})
export class AcademyManagementPageComponent implements OnInit {
  router = inject(Router);
  tenantService = inject(TenantService);

  readonly items = signal<MenuItem[]>([]);

  ngOnInit(): void {
    this.items.set([
      {
        id: 'school_periods'
      },
      {
        id: 'organisations'
      },
      {
        id: 'subjects'
      },
      {
        id: 'course_types'
      },
      ...(this.tenantService.currentTenant?.are_course_names_forced
        ? [
            {
              id: 'course_names'
            }
          ]
        : []),
      {
        id: 'certificate_templates'
      },
      {
        id: 'education_types'
      },
      ...(this.tenantService.canCrudTeacher
        ? [
            {
              id: 'teacher_hourly_rates'
            }
          ]
        : []),
      {
        id: 'person_document_types'
      }
    ]);
  }
}
