import { ToolbarModule } from 'primeng/toolbar';
import { ButtonDirective } from 'primeng/button';
import { TranslocoDirective } from '@jsverse/transloco';
import { Component, forwardRef, input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgxFilesizeModule } from 'ngx-filesize';

@Component({
  selector: 'lib-file',
  standalone: true,
  imports: [ToolbarModule, ButtonDirective, TranslocoDirective, NgxFilesizeModule],
  templateUrl: './file.component.html',
  styleUrl: './file.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FileComponent)
    }
  ]
})
export class FileComponent implements ControlValueAccessor {
  readonly accept = input<string | undefined>();
  readonly size = input<number | undefined>();

  readonly sizeLimit = 15 * 1024 * 1024;

  disabled = false;
  fileName: string;
  fileSize: number;

  private touched = false;

  fileSelected(files: FileList | null) {
    this.markAsTouched();
    this.fileName = '';
    this.fileSize = 0;
    if (files?.length) {
      const file = files.item(0);
      if (file) {
        this.fileName = file.name;
        this.fileSize = file.size;
        this.writeValue(file);
      }
    } else {
      this.writeValue(undefined);
    }
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  // Form methods

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: File | undefined): void {
    this.onChange(value);
  }

  private onChange = (value: File | undefined) => {};

  private onTouched = () => {};
}
