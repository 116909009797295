import { Component, inject, OnInit, signal } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { ButtonDirective } from 'primeng/button';
import { NgxPermissionsModule } from 'ngx-permissions';
import { RouterLink } from '@angular/router';
import { TableModule } from 'primeng/table';
import { NgxFilesizeModule } from 'ngx-filesize';
import { TooltipModule } from 'primeng/tooltip';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, switchMap } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { DateTimePipe } from '~ngx-shared/pipes';
import {
  BankDataImport,
  BankingDataImportService
} from '~madrasa/accounting/services/bank-data-import.service';
import { AccountingPeriodService } from '~madrasa/accounting/services/accounting-period.service';
import { BasePageComponent, ConfirmationService } from '~ngx-shared/layout';
import { AccountingAccountModel } from '~ngx-shared/models';

@UntilDestroy()
@Component({
  selector: 'app-bank-data-import-list-page',
  standalone: true,
  imports: [
    BasePageComponent,
    TranslocoDirective,
    ButtonDirective,
    NgxPermissionsModule,
    RouterLink,
    TableModule,
    NgxFilesizeModule,
    DateTimePipe,
    TooltipModule
  ],
  templateUrl: './bank-data-import-list-page.component.html',
  styleUrl: './bank-data-import-list-page.component.scss'
})
export class BankDataImportListPageComponent implements OnInit {
  readonly accountingPeriodService = inject(AccountingPeriodService);
  readonly bankDataImportService = inject(BankingDataImportService);
  readonly confirmationService = inject(ConfirmationService);
  readonly apollo = inject(Apollo);

  readonly files = signal<BankDataImport[]>([]);

  private _periodId: number;

  ngOnInit() {
    this.accountingPeriodService.currentPeriod$.pipe(untilDestroyed(this)).subscribe(period => {
      if (period?.id) {
        this._periodId = period.id;
        this.setFiles();
      }
    });

    this.accountingPeriodService.getPeriods().subscribe();
  }

  getRow(data: any): BankDataImport | undefined {
    return data;
  }

  getBookedCount(data: any) {
    let result = 0;
    const row = this.getRow(data);
    if (row) {
      result = row.items?.filter(item => item.is_booked).length || 0;
    }
    return result;
  }

  delete(data: any) {
    const row = this.getRow(data);
    if (row) {
      this.confirmationService.confirmDelete(row.name, () => {
        this.bankDataImportService.deleteImport(row.id);
        this.setFiles();
      });
    }
  }

  private setFiles() {
    const imports = this.bankDataImportService
      .imports()
      ?.filter(item => item.accounting_period_id === this._periodId);
    this.files.set([...(imports || [])]);

    this.bankDataImportService.imports$
      .pipe(
        untilDestroyed(this),
        switchMap(imports => {
          const accountIds = imports
            ?.map(item => item.active_account_id)
            ?.filter((item): item is number => !!item);

          return this.apollo
            .query<{
              result: AccountingAccountModel[];
            }>({
              query: gql`
                query ReadAccountingAccounts($where: accounting_account_bool_exp = {}) {
                  result: accounting_account(
                    where: $where
                    order_by: [{ account_group: { number: asc } }, { number: asc }, { name: asc }]
                  ) {
                    id
                    full_name
                  }
                }
              `,
              variables: {
                where: {
                  id: {
                    _in: accountIds
                  }
                }
              }
            })
            .pipe(
              map(queryResult => queryResult.data?.result),
              map(accounts => {
                imports?.forEach(item => {
                  const account = accounts?.find(account => account.id === item.active_account_id);
                  if (account) {
                    item.active_account_name = account.full_name;
                  }
                });

                return imports;
              })
            );
        })
      )
      .subscribe(imports => {});
  }
}
