import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslocoDirective } from '@jsverse/transloco';
import { ButtonDirective } from 'primeng/button';
import { UpperCasePipe } from '@angular/common';
import { NavigationService } from '~ngx-shared/services';
import { LoadingService } from '~ngx-shared/layout';
import { BusyComponent } from '~ngx-shared/layout/busy/busy.component';

@UntilDestroy()
@Component({
  selector: 'lib-layout-base-page',
  templateUrl: './base-page.component.html',
  styleUrls: ['./base-page.component.css'],
  imports: [TranslocoDirective, ButtonDirective, UpperCasePipe, BusyComponent],
  standalone: true
})
export class BasePageComponent implements OnInit {
  @Input() title?: string;
  @Input() titleParamValue?: string;
  @Input() titleAddon?: string | number;
  @Input() showBackButton = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    public loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.pipe(untilDestroyed(this)).subscribe(data => {
      if ('breadcrumb' in data && !this.title) {
        this.title = data['breadcrumb'];
      }
      if ('breadcrumbParamValue' in data && !this.titleParamValue) {
        this.titleParamValue = data['breadcrumbParamValue'];
      }
    });
  }

  back() {
    this.navigationService.back();
  }
}
