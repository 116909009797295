import { Component, inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute } from '@angular/router';
import { filter, map, of, switchMap, tap } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { FormlyService } from '~madrasa/services';
import { AcademyCertificateTemplateModel, AcademyCourseTypeModel } from '~ngx-shared/models';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { ModelUtil, QueryUtil } from '~ngx-shared/utils';

@UntilDestroy()
@Component({
  selector: 'app-create-update-certificate-template-form-page',
  standalone: true,
  imports: [BasePageComponent, FormlyModule],
  templateUrl: './create-update-certificate-template-form-page.component.html',
  styleUrl: './create-update-certificate-template-form-page.component.scss'
})
export class CreateUpdateCertificateTemplateFormPageComponent implements OnInit {
  readonly apollo = inject(Apollo);
  readonly activatedRoute = inject(ActivatedRoute);
  readonly loadingService = inject(LoadingService);
  readonly formlyService = inject(FormlyService);

  form = new FormGroup({});
  readOnlyModel: AcademyCertificateTemplateModel = {};
  model: AcademyCertificateTemplateModel = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.create_update_certificate_template'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  ngOnInit(): void {
    this.loadingService.startLoading();

    this.activatedRoute.data
      .pipe(
        untilDestroyed(this),
        switchMap(data => {
          const result = !!data?.['crud'] && !data['crud']['is_new'] && !!data['crud']['id'];
          if (result) {
            return this.apollo
              .query<{ result: AcademyCertificateTemplateModel }>({
                query: gql`
                  query ReadAcademyCertificateTemplateById($id: bigint!) {
                    result: academy_certificate_template_by_pk(id: $id) {
                      id
                      created_at
                      updated_at
                      name
                      organisation_id
                      paper_template_id
                      options
                      description

                      certificate_template_course_types {
                        id
                        course_type_id
                      }
                    }
                  }
                `,
                variables: {
                  id: data['crud']['id']
                }
              })
              .pipe(map(queryResult => queryResult.data?.result));
          } else {
            return of(undefined);
          }
        })
      )
      .subscribe(result => {
        if (result) {
          this.readOnlyModel = result;
          let model = cloneDeep(result);

          if (model) {
            this.model = {
              ...model,
              course_types: model.certificate_template_course_types?.map(
                (courseType: any) => courseType.course_type_id
              )
            };
          }
        }

        this.fields = [
          {
            key: 'id'
          },
          FormlyUtil.createRow([
            FormlyUtil.createTextField('name', {
              props: {
                required: true
              }
            })
          ]),
          this.formlyService.createOrganisationFieldConfig(),
          this.formlyService.createPaperTemplateFieldConfig({ required: false }),
          FormlyUtil.createRow([
            FormlyUtil.createMultiSelectField('course_types', {
              props: {
                required: true,
                options: []
              },
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  const createRequest = (variables?: any) => {
                    return this.apollo
                      .query<{
                        result: AcademyCourseTypeModel[];
                      }>({
                        query: gql`
                          query ReadAcademyCourseTypes($where: academy_course_type_bool_exp!) {
                            result: academy_course_type(order_by: { name: asc }, where: $where) {
                              id
                              name
                            }
                          }
                        `,
                        variables
                      })
                      .pipe(
                        map(queryResult =>
                          queryResult.data?.result?.map(item => ({
                            label: item.name,
                            value: item.id
                          }))
                        )
                      );
                  };

                  if (field.props && !field.props['initialised']) {
                    field.props['initialised'] = true;

                    let variables: any = {};
                    if (field.model?.organisation_id) {
                      variables = {
                        ...variables,
                        where: { organisation_id: { _eq: field.model.organisation_id } }
                      };
                    }

                    field.props.options = createRequest(variables);
                  }

                  return field.options?.fieldChanges?.pipe(
                    filter(
                      event =>
                        event.type === 'valueChanges' && event.field.key === 'organisation_id'
                    ),
                    tap(event => {
                      if (field.props?.['initialised']) {
                        field.formControl?.setValue(undefined);
                      }
                      if (field.props && event.value) {
                        field.props.options = createRequest({
                          where: { organisation_id: { _eq: event.value } }
                        });
                      }
                    })
                  );
                }
              }
            })
          ]),
          FormlyUtil.createRow([
            FormlyUtil.createTable(
              {
                key: 'options',
                props: {
                  showIndex: true,
                  columns: [
                    {
                      header: 'name',
                      key: 'name'
                    },
                    {
                      header: 'description',
                      key: 'description'
                    },
                    {
                      header: 'madrasa.forms.create_update_certificate_template.is_title',
                      key: 'is_title'
                    }
                  ]
                }
              },
              [
                FormlyUtil.createTextField('name', {
                  props: {
                    hideLabel: true,
                    disableMargin: true,
                    required: true
                  }
                }),
                FormlyUtil.createTextField('description', {
                  props: {
                    hideLabel: true,
                    disableMargin: true
                  }
                }),
                FormlyUtil.createCheckboxField('is_title', {
                  props: {
                    hideLabel: true,
                    disableMargin: true,
                    required: true
                  }
                })
              ]
            )
          ]),
          FormlyUtil.createRow([FormlyUtil.createTextAreaField('description')])
        ];

        this.loadingService.stopLoading();
      });

    this.submit = (formSaveModel: FormSaveModel) => {
      const input = {
        ...formSaveModel.input,
        certificate_template_course_types: {
          data:
            formSaveModel.input.course_types?.map((courseTypeId: number) => ({
              course_type_id: courseTypeId
            })) || []
        }
      };

      ModelUtil.deleteKey(input, 'course_types');

      const params: string[] = ['$input: academy_certificate_template_insert_input!'];
      const queries: string[] = [
        `
         result: insert_academy_certificate_template_one(object: $input) {
            __typename
         }
        `
      ];
      let variables: any = { input };

      if (this.model?.id) {
        ModelUtil.deleteKey(input);
        ModelUtil.deleteKey(input, 'certificate_template_course_types');

        // Clear params
        params.length = 0;
        queries.length = 0;

        params.push('$id: bigint!', '$input: academy_certificate_template_set_input!');

        queries.push(`
          result: update_academy_certificate_template_by_pk(
            pk_columns: { id: $id }
            _set: $input
          ) {
            __typename
          }
        `);

        variables = { id: this.model.id, input };

        const newCourseTypes = QueryUtil.comparer({
          itemsA: formSaveModel.input.course_types,
          itemsB: this.readOnlyModel.certificate_template_course_types,
          compareFunc: (itemA: any, itemB: any) => itemA === itemB?.id,
          resultMapFunc: item => item
        })?.map((id: any) => ({
          certificate_template_id: this.model.id,
          course_type_id: id
        }));

        if (newCourseTypes?.length) {
          params.push(
            '$newCourseTypes: [academy_certificate_template_course_type_insert_input!] = [] '
          );
          queries.push(
            'insert_academy_certificate_template_course_type(objects: $newCourseTypes) { __typename }'
          );
          variables = {
            ...variables,
            newCourseTypes
          };
        }

        const updateCourseTypes = [
          // Get deleted receipt documents
          ...(QueryUtil.comparer({
            itemsA: this.readOnlyModel.certificate_template_course_types,
            itemsB: formSaveModel.input.course_types,
            compareFunc: (itemA: any, itemB: any) => !!itemB && itemA.id === itemB,
            resultMapFunc: item => ({
              where: {
                id: { _eq: item.id }
              },
              _set: { deleted_at: 'now()' }
            })
          }) || [])
        ];

        if (updateCourseTypes.length) {
          params.push(
            '$updateCourseTypes: [academy_certificate_template_course_type_updates!] = [] '
          );
          queries.push(
            'update_academy_certificate_template_course_type_many(updates: $updateCourseTypes) { __typename }'
          );
          variables = {
            ...variables,
            updateCourseTypes
          };
        }
      }

      return this.apollo.mutate({
        mutation: gql`
            mutation CreateUpdateAcademyCertificateTemplate(
              ${params.join('\n')}
            ) {
             ${queries.join('\n')}
            }
          `,
        variables
      });
    };
  }
}
