import { Component, computed, effect, inject, input, ViewChild } from '@angular/core';
import { ButtonDirective } from 'primeng/button';
import { TranslocoDirective } from '@jsverse/transloco';
import { Apollo, gql } from 'apollo-angular';
import { TooltipModule } from 'primeng/tooltip';
import { RouterLink } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import { map } from 'rxjs';
import {
  AcademyOrganisationModel,
  AcademySchoolModel,
  AccountingAccountCategoryEnum,
  AccountingAccountGroupModel,
  AccountingAccountModel
} from '~ngx-shared/models';
import { ConfirmationService } from '~ngx-shared/layout';
import { TemplateDirective } from '~ngx-shared/directives';
import {
  DataProviderOptionModel,
  GraphQlAdvancedFilterComponent,
  GraphQlAdvancedSortComponent,
  GraphQlAdvancedTableComponent,
  GraphQlColumnModel,
  GraphQlTableModel
} from '~ngx-shared/graph-ql';

@Component({
  selector: 'app-account-list',
  standalone: true,
  imports: [
    ButtonDirective,
    GraphQlAdvancedFilterComponent,
    GraphQlAdvancedSortComponent,
    GraphQlAdvancedTableComponent,
    TemplateDirective,
    TranslocoDirective,
    TooltipModule,
    RouterLink,
    NgxPermissionsModule
  ],
  templateUrl: './account-list.component.html',
  styleUrl: './account-list.component.scss'
})
export class AccountListComponent {
  readonly apollo = inject(Apollo);
  readonly confirmationService = inject(ConfirmationService);

  showFilter = input<boolean>(false);
  showSort = input<boolean>(false);
  showExport = input<boolean>(false);
  showColumnFilter = input<boolean>(true);
  showCreateButton = input<boolean>(true);

  stateKey = input<string>();
  patchOptions = input<(options: DataProviderOptionModel) => DataProviderOptionModel>();
  columns = input<GraphQlColumnModel[]>([]);
  accountPeriodId = input.required<number>();

  graphQlTable = computed(() => {
    let columns = this.columns();

    columns.unshift(...this.getDefaultColumns());

    const graphQlTable: GraphQlTableModel = {
      table: 'accounting_account',
      isPaginated: true,

      showCurrentPageReport: true,
      columns
    };
    return graphQlTable;
  });
  @ViewChild('advancedTable') tableComponent: GraphQlAdvancedTableComponent;

  graphQlPatchOptions: (options: DataProviderOptionModel) => DataProviderOptionModel;

  constructor() {
    effect(() => {
      this.graphQlPatchOptions = options => {
        const currentPeriodId = this.accountPeriodId();
        const patchOptions = this.patchOptions();
        if (patchOptions) {
          options = patchOptions(options);
        }

        if (!options.filter) {
          options.filter = {};
        }
        if (!options.filter._and) {
          options.filter._and = [];
        }

        if (Array.isArray(options?.filter?._and)) {
          options.filter._and = options.filter._and.filter((cond: any) => !cond.account_group);
          options.filter._and.push({
            account_group: {
              accounting_period_id: {
                _eq: currentPeriodId
              }
            }
          });
        }

        // Add default sorting by updated_at
        if (!options.sortBy?.length) {
          options.sortBy = [
            { account_group: { number: 'asc_nulls_last' } },
            { account_group: { name: 'asc_nulls_last' } },
            { account_group: { category: 'asc_nulls_last' } },
            { number: 'asc_nulls_last' },
            { name: 'asc_nulls_last' }
          ];
        }

        return options;
      };
    });
  }

  getDefaultColumns(): GraphQlColumnModel[] {
    const columns: GraphQlColumnModel[] = [
      {
        path: 'id',
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        isNotExportable: true,
        isNotSelectable: true
      },
      {
        label: 'group',
        path: 'account_group.full_name',
        filter: { isNotFilterable: true },
        sort: { isSortable: false }
      },
      {
        label: 'group',
        path: 'account_group_id',
        filter: {
          label: 'group',
          path: 'account_group_id',
          type: 'array',
          options: this.apollo
            .query<{
              result: AccountingAccountGroupModel[];
            }>({
              query: gql`
                query ReadAccountingAccountGroupModels($where: accounting_account_group_bool_exp) {
                  result: accounting_account_group(where: $where) {
                    id
                    full_name
                  }
                }
              `,
              variables: {
                where: {}
              }
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(item => ({
                  label: item.full_name,
                  value: item.id
                }))
              )
            )
        },
        sort: { isSortable: false },
        isNotSelectable: true
      },
      {
        path: 'account_group.category',
        type: 'tag',
        classHeader: 'text-center',
        classBody: 'text-center',
        translate: true,
        prefix: 'account_category.',
        patchResult: (result: any, column: GraphQlColumnModel) => {
          switch (result?.account_group?.category) {
            case AccountingAccountCategoryEnum.ACTIVE:
              column.severity = 'success';
              break;
            case AccountingAccountCategoryEnum.PASSIVE:
              column.severity = 'danger';
              break;
            case AccountingAccountCategoryEnum.INCOME:
              column.severity = 'info';
              break;
            case AccountingAccountCategoryEnum.EXPENSE:
              column.severity = 'warning';
              break;
          }
          return 'account_category.' + result?.account_group?.category;
        },
        filter: {
          type: 'array',
          options: Object.values(AccountingAccountCategoryEnum).map(value => ({
            label: 'account_category.' + value,
            value: value
          }))
        },
        sort: { isSortable: true }
      },
      {
        path: 'number',
        type: 'number',
        filter: { type: 'number' },
        sort: { isSortable: true },
        isNotSelectable: true
      },
      {
        path: 'name',
        filter: {
          label: 'name',
          path: 'id',
          type: 'array',
          options: this.apollo
            .query<{
              result: AccountingAccountModel[];
            }>({
              query: gql`
                query ReadAccountingAccountModels($where: accounting_account_bool_exp) {
                  result: accounting_account(where: $where) {
                    id
                    full_name
                  }
                }
              `,
              variables: {
                where: {}
              }
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(item => ({
                  label: item.full_name,
                  value: item.id
                }))
              )
            )
        },
        sort: { isSortable: true },
        isNotSelectable: true
      },
      {
        label: 'name',
        path: 'full_name',
        filter: { isNotFilterable: true },
        sort: { isSortable: false }
      },
      {
        path: 'school.organisation_id',
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        isNotExportable: true,
        isNotSelectable: true
      },
      {
        path: 'school_id',
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        isNotExportable: true,
        isNotSelectable: true
      },
      {
        label: 'organisation',
        path: 'school.organisation.name',
        filter: {
          label: 'organisation',
          path: 'school.organisation_id',
          type: 'array',
          options: this.apollo
            .query<{
              result: AcademyOrganisationModel[];
            }>({
              query: gql`
                query ReadAcademyOrganisation($where: academy_organisation_bool_exp) {
                  result: academy_organisation(where: $where) {
                    id
                    name
                  }
                }
              `,
              variables: {
                where: {}
              }
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(organisation => ({
                  label: organisation.name,
                  value: organisation.id
                }))
              )
            )
        },
        sort: { isSortable: true }
      },
      {
        label: 'school',
        path: 'school.name',
        filter: {
          label: 'school',
          path: 'school_id',
          type: 'array',
          options: this.apollo
            .query<{
              result: AcademySchoolModel[];
            }>({
              query: gql`
                query ReadAcademySchool($where: academy_school_bool_exp) {
                  result: academy_school(where: $where) {
                    id
                    name
                  }
                }
              `,
              variables: {
                where: {}
              }
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(school => ({
                  label: school.name,
                  value: school.id
                }))
              )
            )
        },
        sort: { isSortable: false }
      },
      {
        path: 'opening_amount',
        type: 'currency',
        filter: { isNotFilterable: true },
        sort: { isSortable: false }
      },
      {
        label: 'amount',
        path: 'sum_amount',
        type: 'currency',
        filter: { isNotFilterable: true },
        sort: { isSortable: false }
      },
      {
        path: 'created_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true }
      },
      {
        path: 'updated_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'entry_rows_aggregate.count',
        query: `entry_rows_aggregate { aggregate { count } }`,
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        isNotExportable: true,
        isNotSelectable: true
      }
    ];

    return columns;
  }

  getRow(data: any): AccountingAccountModel | undefined {
    return data;
  }

  delete(data: any) {
    const row = this.getRow(data);
    if (row) {
      let mutation: any = gql`
        mutation SoftDeleteAccountingAccountById($id: bigint!) {
          result: update_accounting_account_by_pk(
            pk_columns: { id: $id }
            _set: { deleted_at: "now()" }
          ) {
            __typename
          }
        }
      `;

      this.confirmationService.confirmDeleteApollo({
        name: row.name,
        mutationOptions: {
          mutation,
          variables: {
            id: row.id
          }
        },
        success: () => this.tableComponent?.updateTable()
      });
    }
  }
}
