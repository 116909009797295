import { Component, OnInit } from '@angular/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormlyModule, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { FormlyService } from '~madrasa/services';

@UntilDestroy()
@Component({
  selector: 'app-search-person-form-dialog',
  standalone: true,
  imports: [FormlySelectModule, MultiSelectModule, ReactiveFormsModule, FormlyModule, FormlyModule],
  templateUrl: './search-person-form-dialog.component.html',
  styleUrl: './search-person-form-dialog.component.scss'
})
export class SearchPersonFormDialogComponent implements OnInit {
  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.search_person'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  constructor(
    public dialogRef: DynamicDialogRef,
    private formlyService: FormlyService
  ) {}

  ngOnInit(): void {
    this.fields = [
      this.formlyService.createPersonFieldConfig({
        forceSelection: true
      })
    ];
  }

  savedEvent(formSaveModel: FormSaveModel) {
    if (!formSaveModel.hasError) {
      this.dialogRef.close(formSaveModel);
    }
  }

  canceled($event: boolean) {
    if (this.dialogRef) {
      this.dialogRef.close(undefined);
    }
  }
}
