<div *transloco="let transloco" class="space-y-3">
  @if (this.columns && this.columns.length) {
    <span class="block text-2xl">{{ transloco('filter_by') }}</span>
    @for (filter of this.filters; track filter; let index = $index) {
      <div class="flex items-center gap-3">
        <div class="flex-1">
          <p-dropdown
            (onChange)="
              filter.matchMode = undefined; filter.value = undefined; this.updateState(filter)
            "
            [(ngModel)]="filter.column"
            [placeholder]="'&nbsp;'"
            [options]="this.columns"
            appendTo="body"
            class="p-fluid w-full">
            <ng-template let-item pTemplate="item">
              <span>{{ transloco(item.label ?? item.path) }}</span>
            </ng-template>
            <ng-template let-selectedItem pTemplate="selectedItem">
              <span>{{ transloco(selectedItem.label ?? selectedItem.path) }}</span>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="flex items-center gap-3">
          @if (
            filter?.column?.type !== 'array' &&
            filter?.column?.type !== 'boolean' &&
            filter?.column?.type !== 'is_null'
          ) {
            <div class="flex-1">
              <p-dropdown
                (onChange)="filter.matchMode = $event.value; this.updateState(filter)"
                [ngModel]="filter?.matchMode"
                [options]="this.getMatchModes(filter?.column)"
                [placeholder]="'&nbsp;'"
                appendTo="body"
                class="p-fluid w-full">
                <ng-template let-item pTemplate="item">
                  <span>{{ transloco('primeng.' + item) }}</span>
                </ng-template>
                <ng-template let-selectedItem pTemplate="selectedItem">
                  <span>{{ transloco('primeng.' + selectedItem) }}</span>
                </ng-template>
              </p-dropdown>
            </div>
          }
          <div class="flex-1">
            @switch (this.filter.column?.type) {
              @case ('date') {
                <p-calendar
                  (ngModelChange)="filter.value = $event; this.updateState(filter)"
                  [ngModel]="filter?.value"
                  [selectionMode]="'single'"
                  [showIcon]="true"
                  appendTo="body"
                  class="p-fluid w-full">
                </p-calendar>
              }
              @case ('time') {
                <p-calendar
                  (ngModelChange)="filter.value = $event; this.updateState(filter)"
                  [ngModel]="filter?.value"
                  [selectionMode]="'single'"
                  [showIcon]="true"
                  [timeOnly]="true"
                  appendTo="body"
                  class="p-fluid w-full">
                </p-calendar>
              }
              @case ('array') {
                <p-dropdown
                  (onChange)="filter.value = $event.value; this.updateState(filter)"
                  [group]="!!filter?.column?.optionsGroup"
                  [ngModel]="filter?.value"
                  [optionGroupChildren]="filter?.column?.optionsGroupChildren || 'items'"
                  [optionGroupLabel]="filter?.column?.optionsGroup || 'label'"
                  [optionLabel]="filter?.column?.optionsLabel || 'label'"
                  [optionValue]="filter?.column?.optionsValue || 'value'"
                  [options]="(filter?.column?.options | selectOptions: filter | async)!"
                  [placeholder]="'&nbsp;'"
                  appendTo="body"
                  class="p-fluid w-full">
                  <ng-template let-item pTemplate="item">
                    <span>{{
                      transloco(item[filter?.column?.optionsLabel || 'label'] || '')
                    }}</span>
                  </ng-template>
                  <ng-template let-selectedItem pTemplate="selectedItem">
                    <span>{{
                      transloco(selectedItem[filter?.column?.optionsLabel || 'label'] || '')
                    }}</span>
                  </ng-template>
                  <ng-template let-group pTemplate="group">
                    <span>{{
                      transloco(group[filter?.column?.optionsGroup || 'label'] || '')
                    }}</span>
                  </ng-template>
                </p-dropdown>
              }
              @case ('boolean') {
                <p-dropdown
                  (onChange)="filter.value = $event.value; this.updateState(filter)"
                  [ngModel]="filter?.value"
                  [options]="this.booleanOptions"
                  [placeholder]="'&nbsp;'"
                  appendTo="body"
                  class="p-fluid w-full">
                  <ng-template let-item pTemplate="item">
                    <span>{{
                      transloco(item[filter?.column?.optionsLabel || 'label'] || '')
                    }}</span>
                  </ng-template>
                  <ng-template let-selectedItem pTemplate="selectedItem">
                    <span>{{
                      transloco(selectedItem[filter?.column?.optionsLabel || 'label'] || '')
                    }}</span>
                  </ng-template>
                </p-dropdown>
              }
              @case ('is_null') {
                <p-dropdown
                  (onChange)="filter.value = $event.value; this.updateState(filter)"
                  [ngModel]="filter?.value"
                  [options]="this.isNullOptions"
                  [placeholder]="'&nbsp;'"
                  appendTo="body"
                  class="p-fluid w-full">
                  <ng-template let-item pTemplate="item">
                    <span>{{
                      transloco(item[filter?.column?.optionsLabel || 'label'] || '')
                    }}</span>
                  </ng-template>
                  <ng-template let-selectedItem pTemplate="selectedItem">
                    <span>{{
                      transloco(selectedItem[filter?.column?.optionsLabel || 'label'] || '')
                    }}</span>
                  </ng-template>
                </p-dropdown>
              }
              @default {
                <input
                  (ngModelChange)="filter.value = $event; this.updateState(filter)"
                  [ngModel]="filter?.value"
                  class="w-full"
                  pInputText />
                <span> </span>
              }
            }
          </div>
        </div>
        <div class="">
          <button (click)="this.removeFilter(index)" pButton type="button">
            <i class="pi pi-times"></i>
          </button>
        </div>
      </div>
    }
    <div>
      <button
        (click)="this.addFilter()"
        [label]="transloco('add')"
        class="p-button-success"
        icon="pi pi-plus"
        pButton
        type="button"></button>
    </div>
  }
</div>
