import { inject, Injectable } from '@angular/core';
import { select, setProp } from '@ngneat/elf';
import { toSignal } from '@angular/core/rxjs-interop';
import { parse } from 'date-fns';
import { StoreService } from '~ngx-shared/services';
import { AccountingCashBookEntryTypeEnum } from '~ngx-shared/models';

export type BankDataImportActionEntryType = {
  entry_id?: number;
  amount?: number;
  account_id?: number;
};

export type BankDataImportActionStudentType = {
  person_id?: any;
  balances?: {
    student_balances_id?: number;
    amount?: number;
    month_of_balance?: string;
  }[];
};

export type BankDataImportActionType = {
  rows?: BankDataImportActionEntryType[];
  receipt_documents?: string[];
  student_balances?: BankDataImportActionStudentType[];
};

export type BankDataLineType = {
  Echtzeit?: string;
  Buchungsdatum?: string;
  Valutadatum?: string;
  Buchungstext?: string;
  'Interne Notiz'?: string;
  Währung?: string;
  Betrag?: string;
  Belegdaten?: string;
  Belegnummer?: string;
  Auftraggebername?: string;
  Auftraggeberkonto?: string;
  'Auftraggeber BLZ'?: string;
  Empfängername?: string;
  Empfängerkonto?: string;
  'Empfänger BLZ'?: string;
  Zahlungsgrund?: string;
  Zahlungsreferenz?: string;
};

export type BankDataImportItem = {
  [key: string]: any;
  date?: Date;
  title?: string;
  mandate?: string;
  amount?: number;
  reason_for_payment?: string;
  payment_reference?: string;
  type?: AccountingCashBookEntryTypeEnum;

  action?: BankDataImportActionType;
  line?: BankDataLineType;

  is_booked?: boolean;
};

export type BankDataImport = {
  [key: string]: any;
  id?: string;
  name?: string;
  accounting_period_id?: number;
  active_account_name?: string;
  active_account_id?: number;
  file_name?: string;
  file_size?: string;
  file_content?: string;
  items?: BankDataImportItem[];
  is_analyzed?: boolean;
  is_done?: boolean;
  incoming_amount?: number;
  outgoing_amount?: number;
  created_at?: Date;
  updated_at?: Date;
};

type BankDataImportStore = {
  imports?: BankDataImport[];
};

@Injectable({
  providedIn: 'root'
})
export class BankingDataImportService {
  readonly storeService = inject(StoreService);

  bandDataImportStore = this.storeService.createStore<BankDataImportStore>({
    name: 'accounting-bank-data-import',
    initial: {
      imports: []
    },
    persist: { storage: 'local' }
  });

  // Shows the current selected period
  imports$ = this.bandDataImportStore.pipe(select(state => state.imports));

  readonly imports = toSignal(this.imports$);

  getImport(id: string): BankDataImport | undefined {
    return this.imports()?.find(item => item.id === id);
  }

  saveImport(importData: BankDataImport | undefined) {
    if (importData?.id) {
      this.deleteImport(importData.id);

      this.bandDataImportStore.update(
        setProp('imports', imports => [...(imports || []), importData])
      );
    }
  }

  deleteImport(id: string | undefined) {
    if (id) {
      this.bandDataImportStore.update(
        setProp('imports', imports => imports?.filter(item => item.id !== id))
      );
    }
  }

  analyze(bankDataItem: BankDataImport) {
    const lines = bankDataItem.file_content?.split('\n');

    if (!lines || lines.length === 0) {
      return bankDataItem;
    }

    // Get header row and trim it
    const header = lines[0].split(';').map(columnName => columnName.trim());

    // Create a mapping of header names to their indexes
    const headerIndexes: { [key: string]: number } = {};
    header.forEach((columnName, index) => {
      headerIndexes[columnName] = index;
    });

    // Parse the remaining lines
    const items: BankDataImportItem[] = [];

    lines.slice(1).forEach(line => {
      const values = line.split(';').map(value => value.trim());
      if (values.length > 3) {
        const csvItem: BankDataLineType = {
          Echtzeit: values[headerIndexes['Echtzeit']],
          Buchungsdatum: values[headerIndexes['Buchungsdatum']],
          Valutadatum: values[headerIndexes['Valutadatum']],
          Buchungstext: values[headerIndexes['Buchungstext']],
          'Interne Notiz': values[headerIndexes['Interne Notiz']],
          Währung: values[headerIndexes['Währung']],
          Betrag: values[headerIndexes['Betrag']],
          Belegdaten: values[headerIndexes['Belegdaten']],
          Belegnummer: values[headerIndexes['Belegnummer']],
          Auftraggebername: values[headerIndexes['Auftraggebername']],
          Auftraggeberkonto: values[headerIndexes['Auftraggeberkonto']],
          'Auftraggeber BLZ': values[headerIndexes['Auftraggeber BLZ']],
          Empfängername: values[headerIndexes['Empfängername']],
          Empfängerkonto: values[headerIndexes['Empfängerkonto']],
          'Empfänger BLZ': values[headerIndexes['Empfänger BLZ']],
          Zahlungsgrund: values[headerIndexes['Zahlungsgrund']],
          Zahlungsreferenz: values[headerIndexes['Zahlungsreferenz']]
        };

        let mandate = '';

        let amount = parseFloat(
          csvItem.Betrag?.replace('.', '')?.replace(',', '.')?.replace(' ', '') || '0'
        );
        let type: AccountingCashBookEntryTypeEnum = AccountingCashBookEntryTypeEnum.INCOMING;
        if (amount > 0) {
          mandate = csvItem.Auftraggebername || '';
        } else {
          mandate = csvItem.Empfängername || '';
          type = AccountingCashBookEntryTypeEnum.OUTGOING;
          amount = amount * -1;
        }

        items.push({
          line: csvItem,
          date: parse(csvItem.Valutadatum || '', 'dd.MM.yyyy', new Date()),
          title: csvItem.Buchungstext,
          reason_for_payment: csvItem.Zahlungsgrund,
          payment_reference: csvItem.Zahlungsreferenz,
          amount,
          type,
          mandate
        });
      }
    });

    bankDataItem.items = items;
    bankDataItem.is_analyzed = true;
    return bankDataItem;
  }
}
