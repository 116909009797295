import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { FormlyUtil, FormSaveModel } from '~ngx-shared/formly';
import { PersonUtil } from '~madrasa/core/utils/person.util';
import { ModelUtil, QueryUtil } from '~ngx-shared/utils';
import { FilePersonMediaTypeEnum } from '~ngx-shared/models';

@Injectable({ providedIn: 'root' })
export class StaffService {
  constructor(private apollo: Apollo) {}

  studentSubmit(formSaveModel: FormSaveModel, model?: any, readOnlyModel?: any): Observable<any> {
    let input = {
      ...formSaveModel.input,
      date_of_birth: FormlyUtil.toIsoDateString(formSaveModel.input.date_of_birth),
      ...(formSaveModel.input?.profile_picture?.length
        ? {
            person_media: {
              data: {
                id: formSaveModel.input.profile_picture[0],
                person_media_type: FilePersonMediaTypeEnum.PROFILE_PICTURE
              }
            }
          }
        : {}),
      person: { data: {} },
      organisation_persons_active: {
        data: (model?.person_id
          ? PersonUtil.getNewOrganisations(
              formSaveModel.input.organisations,
              readOnlyModel?.organisation_persons_active
            )
          : formSaveModel.input.organisations
        )?.map((id: number) => ({
          organisation_id: id
        }))
      },
      side_a_person_relationships: {
        data: (model?.person_id
          ? formSaveModel.input.relationships?.filter((item: any) => !item.id)
          : formSaveModel.input.relationships || []
        )?.map((item: any) => {
          return {
            person_in_relationship_id: item.person_in_relationship_id,
            relationship: item.relationship
          };
        })
      },
      students: {
        data: [
          {
            ...formSaveModel.input.latest_student,
            registration_date: FormlyUtil.toIsoDateString(
              formSaveModel.input.latest_student.registration_date
            )
          }
        ]
      },
      school_students_active: {
        data: (model?.person_id
          ? PersonUtil.getNewSchools(
              formSaveModel.input.schools,
              readOnlyModel?.school_students_active
            )
          : formSaveModel.input.schools
        )?.map((id: number) => ({
          school_id: id
        }))
      }
    };

    ModelUtil.deleteKey(input, 'latest_student');
    ModelUtil.deleteKey(input, 'schools');
    ModelUtil.deleteKey(input, 'person_in_relationship_select');
    ModelUtil.deleteKey(input, 'relationships');
    ModelUtil.deleteKey(input, 'organisations');
    ModelUtil.deleteKey(input, 'profile_picture');

    const params: string[] = [];
    const queries: string[] = [];
    let variables: any = { input };

    if (model?.person_id) {
      ModelUtil.deleteKey(input);
      ModelUtil.deleteKey(input, 'person');

      // Get deleted schools
      const updateSchools = [
        ...PersonUtil.getDeletedSchools(
          readOnlyModel?.school_students_active,
          formSaveModel.input.schools
        )
      ];

      if (updateSchools.length) {
        params.push('$updateSchools: [academy_school_student_active_updates!] = []');
        queries.push(
          'update_academy_school_student_active_many(updates: $updateSchools) { __typename }'
        );
        variables = {
          ...variables,
          updateSchools
        };
      }

      // Get deleted organisations
      const updateOrganisations = [
        ...PersonUtil.getDeletedOrganisations(
          readOnlyModel?.organisation_persons_active,
          formSaveModel.input.organisations
        )
      ];

      if (updateOrganisations.length) {
        params.push('$updateOrganisations: [academy_organisation_person_active_updates!] = []');
        queries.push(
          'update_academy_organisation_person_active_many(updates: $updateOrganisations) { __typename }'
        );
        variables = {
          ...variables,
          updateOrganisations
        };
      }

      // Get deleted relationships
      const updateRelationships = [
        ...PersonUtil.getDeletedRelationships(
          readOnlyModel?.side_a_person_relationships,
          formSaveModel.input.relationships
        ),
        ...PersonUtil.getDeletedRelationships(
          readOnlyModel?.side_b_person_relationships,
          formSaveModel.input.relationships,
          true
        )
      ];

      // Get updated relationships
      updateRelationships.push(
        ...PersonUtil.getUpdatedRelationships(
          readOnlyModel?.side_a_person_relationships,
          formSaveModel.input.relationships
        ),
        ...PersonUtil.getUpdatedRelationships(
          readOnlyModel?.side_b_person_relationships,
          formSaveModel.input.relationships,
          true
        )
      );

      if (updateRelationships.length) {
        params.push('$updateRelationships: [core_person_relationship_updates!] = []');
        queries.push(
          `update_core_person_relationship_many(updates: $updateRelationships) { __typename }`
        );
        variables = {
          ...variables,
          updateRelationships
        };
      }

      const updatePersonMedia = [
        // Get deleted person media
        ...(QueryUtil.comparer({
          itemsA: readOnlyModel?.['profile_picture']?.map((item: any) => item.id),
          itemsB: formSaveModel.input?.profile_picture,
          compareFunc: (itemA: any, itemB: any) => itemA === itemB,
          resultMapFunc: item => ({
            where: {
              id: { _eq: item }
            },
            _set: { deleted_at: 'now()' }
          })
        }) || [])
      ];

      if (updatePersonMedia.length) {
        params.push('$updatePersonMedia: [file_person_media_updates!] = []');
        queries.push('update_file_person_media_many(updates: $updatePersonMedia) { __typename }');
        variables = {
          ...variables,
          updatePersonMedia
        };
      }
    }

    return this.apollo.mutate({
      mutation: gql`
          mutation CreateCorePersonData(
            $input: core_person_data_insert_input!,
            ${params.join('\n')}
            ) {
            result: insert_core_person_data_one(object: $input) {
              id
              person_id
            }
            ${queries.join('\n')}
          }
        `,
      variables
    });
  }

  parentSubmit(formSaveModel: FormSaveModel, model?: any, readOnlyModel?: any): Observable<any> {
    let input = {
      ...formSaveModel.input,
      date_of_birth: FormlyUtil.toIsoDateString(formSaveModel.input.date_of_birth),
      ...(formSaveModel.input?.profile_picture?.length
        ? {
            person_media: {
              data: {
                id: formSaveModel.input.profile_picture[0],
                person_media_type: FilePersonMediaTypeEnum.PROFILE_PICTURE
              }
            }
          }
        : {}),
      person: { data: {} },
      organisation_persons_active: {
        data: (model?.person_id
          ? PersonUtil.getNewOrganisations(
              formSaveModel.input.organisations,
              readOnlyModel?.organisation_persons_active
            )
          : formSaveModel.input.organisations
        )?.map((id: number) => ({
          organisation_id: id
        }))
      },
      side_a_person_relationships: {
        data: (model?.person_id
          ? formSaveModel.input.relationships?.filter((item: any) => !item.id)
          : formSaveModel.input.relationships || []
        )?.map((item: any) => {
          return {
            person_in_relationship_id: item.person_in_relationship_id,
            relationship: item.relationship
          };
        })
      },
      parents: {
        data: [
          {
            status: formSaveModel.input.latest_parent.status
          }
        ]
      },
      school_parents_active: {
        data: (model?.person_id
          ? PersonUtil.getNewSchools(
              formSaveModel.input.schools,
              readOnlyModel?.school_parents_active
            )
          : formSaveModel.input.schools
        )?.map((id: number) => ({
          school_id: id
        }))
      }
    };

    ModelUtil.deleteKey(input, 'latest_parent');
    ModelUtil.deleteKey(input, 'schools');
    ModelUtil.deleteKey(input, 'person_in_relationship_select');
    ModelUtil.deleteKey(input, 'relationships');
    ModelUtil.deleteKey(input, 'organisations');
    ModelUtil.deleteKey(input, 'profile_picture');

    const params: string[] = [];
    const queries: string[] = [];
    let variables: any = { input };

    if (model?.person_id) {
      ModelUtil.deleteKey(input);
      ModelUtil.deleteKey(input, 'person');

      // Get deleted schools
      const updateSchools = [
        ...PersonUtil.getDeletedSchools(
          readOnlyModel?.school_parents_active,
          formSaveModel.input.schools
        )
      ];

      if (updateSchools.length) {
        params.push('$updateSchools: [academy_school_parent_active_updates!] = []');
        queries.push(
          'update_academy_school_parent_active_many(updates: $updateSchools) { __typename }'
        );
        variables = {
          ...variables,
          updateSchools
        };
      }

      // Get deleted organisations
      const updateOrganisations = [
        ...PersonUtil.getDeletedOrganisations(
          readOnlyModel?.organisation_persons_active,
          formSaveModel.input.organisations
        )
      ];

      if (updateOrganisations.length) {
        params.push('$updateOrganisations: [academy_organisation_person_active_updates!] = []');
        queries.push(
          'update_academy_organisation_person_active_many(updates: $updateOrganisations) { __typename }'
        );
        variables = {
          ...variables,
          updateOrganisations
        };
      }

      // Get deleted relationships
      const updateRelationships = [
        ...PersonUtil.getDeletedRelationships(
          readOnlyModel?.side_a_person_relationships,
          formSaveModel.input.relationships
        ),
        ...PersonUtil.getDeletedRelationships(
          readOnlyModel?.side_b_person_relationships,
          formSaveModel.input.relationships,
          true
        )
      ];

      // Get updated relationships
      updateRelationships.push(
        ...PersonUtil.getUpdatedRelationships(
          readOnlyModel?.side_a_person_relationships,
          formSaveModel.input.relationships
        ),
        ...PersonUtil.getUpdatedRelationships(
          readOnlyModel?.side_b_person_relationships,
          formSaveModel.input.relationships,
          true
        )
      );

      if (updateRelationships.length) {
        params.push('$updateRelationships: [core_person_relationship_updates!] = []');
        queries.push(
          `update_core_person_relationship_many(updates: $updateRelationships) { __typename }`
        );
        variables = {
          ...variables,
          updateRelationships
        };
      }

      const updatePersonMedia = [
        // Get deleted person media
        ...(QueryUtil.comparer({
          itemsA: readOnlyModel?.['profile_picture']?.map((item: any) => item.id),
          itemsB: formSaveModel.input?.profile_picture,
          compareFunc: (itemA: any, itemB: any) => itemA === itemB,
          resultMapFunc: item => ({
            where: {
              id: { _eq: item }
            },
            _set: { deleted_at: 'now()' }
          })
        }) || [])
      ];

      if (updatePersonMedia.length) {
        params.push('$updatePersonMedia: [file_person_media_updates!] = []');
        queries.push('update_file_person_media_many(updates: $updatePersonMedia) { __typename }');
        variables = {
          ...variables,
          updatePersonMedia
        };
      }
    }

    return this.apollo.mutate({
      mutation: gql`
          mutation CreateCorePersonData(
            $input: core_person_data_insert_input!,
            ${params.join('\n')}
            ) {
            result: insert_core_person_data_one(object: $input) {
              id
              person_id
            }
            ${queries.join('\n')}
          }
        `,
      variables
    });
  }
}
