<div
  [ngClass]="{
    'mb-5': !this.props.disableMargin
  }"
  class="mt-3 flex flex-col gap-3"
  *transloco="let transloco">
  @if (this.props.label && !this.props.hideLabel) {
    <label [for]="id" class="mb-5">
      <span class="text-2xl">{{ transloco(props.label) }}</span>
      @if (this.props.required && !this.props.hideRequiredMarker) {
        <span aria-hidden="true" class="text-red-600">*</span>
      }
      @if (this.props.description) {
        <p>{{ transloco(this.props.description) }}</p>
      }
      <hr class="m-0" />
    </label>
  }
  @if (this.props.description) {
    <p>{{ transloco(this.props.description) }}</p>
  }

  <div class="flex flex-col">
    <p-table
      responsiveLayout="scroll"
      *transloco="let transloco"
      [value]="this.field.fieldGroup || []"
      styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm md:shadow-none shadow">
      <ng-template pTemplate="header">
        <tr>
          @if (this.props.showIndex) {
            <th></th>
          }
          @for (column of this.props.columns; track column) {
            <th [ngClass]="column.class">
              {{ transloco(column.header || column.key) }}
            </th>
          }
          @if (!this.props.canRemove || this.props.canRemove(this.field, -1)) {
            <th></th>
          }
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-data let-index="rowIndex">
        <tr>
          @if (this.props.showIndex) {
            <td class="text-center">{{ index + 1 }}</td>
          }
          @for (column of this.props.columns; track column) {
            <td>
              <div class="flex flex-col items-center">
                @let columnField = this.getField(data, column);
                @if (columnField) {
                  <formly-field class="w-full" [field]="columnField"> </formly-field>
                }
              </div>
            </td>
          }
          @if (
            !this.formControl.disabled &&
            index + 1 > (this.props.minCount || 0) &&
            !(
              this.props.maxCount &&
              this.props.initialCount &&
              this.props.initialCount === this.props.maxCount
            )
          ) {
            @if (!this.props.canRemove || this.props.canRemove(this.field, index)) {
              <td>
                <div class="align-items-center flex justify-center">
                  <button
                    class="p-button-danger p-button-text"
                    icon="pi pi-trash"
                    pButton
                    type="button"
                    (click)="this.remove(index)"></button>
                </div>
              </td>
            }
          }
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td
            class="text-center"
            [attr.colspan]="(this.props.columns?.length || 1) + (this.props.showIndex ? 2 : 1)">
            {{ transloco('no_data') }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  @if (
    (!this.props.canAdd || this.props.canAdd(this.field)) &&
    !this.formControl.disabled &&
    (this.props.maxCount ? this.props.maxCount > this.formControl.length : true)
  ) {
    <div class="align-items-center my-5 flex justify-end">
      <button
        pButton
        class="p-button-primary"
        icon="pi pi-plus"
        [label]="
          this.props.addText
            ? !this.props.addTextValue
              ? transloco(this.props.addText)
              : transloco('add_value', { value: this.props.addTextValue })
            : transloco('add')
        "
        type="button"
        (click)="this.add()"></button>
    </div>
  }

  @if (this.showError) {
    <small class="p-error">
      <formly-validation-message
        class="ui-message-text"
        [field]="this.field"></formly-validation-message>
    </small>
  }
</div>
