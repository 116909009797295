import {
  AfterContentInit,
  Component,
  computed,
  ContentChildren,
  inject,
  input,
  QueryList,
  TemplateRef
} from '@angular/core';
import { CurrencyPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { TagModule } from 'primeng/tag';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { ButtonDirective } from 'primeng/button';
import { RouterLink } from '@angular/router';
import { PersonDetailLinkComponent } from '~madrasa/staff/components/person-detail-link/person-detail-link.component';
import { NgxFilesizeModule } from 'ngx-filesize';
import { TemplateDirective } from '~ngx-shared/directives';
import { ModelUtil, TemplateUtil } from '~ngx-shared/utils';
import { DatePipe, DateTimePipe } from '~ngx-shared/pipes';

export type DetailField = {
  label: string;
  value: any;
  overwriteValue?: (value: any) => any;
  type?:
    | 'string'
    | 'date'
    | 'datetime'
    | 'currency'
    | 'file'
    | 'filesize'
    | 'array'
    | 'full_name'
    | 'address'
    | 'boolean'
    | 'link'
    | 'person_link';
  path?: string;
  translate?: boolean;
  key?: string;
  meta?: any;
  routerLink?: string | string[];
  childField?: DetailField;
  typeOptions?: any;

  isBusy?: boolean;
  downloadFile?: (field: DetailField, item: any) => void;
};

@Component({
  selector: 'lib-detail',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    TagModule,
    TranslocoDirective,
    DatePipe,
    DateTimePipe,
    CurrencyPipe,
    ButtonDirective,
    RouterLink,
    PersonDetailLinkComponent,
    NgxFilesizeModule,
    NgClass
  ],
  templateUrl: './detail.component.html',
  styleUrl: './detail.component.css'
})
export class DetailComponent implements AfterContentInit {
  readonly translocoService = inject(TranslocoService);
  readonly fields = input.required<DetailField[]>();

  readonly fieldLeft = computed(() => {
    const fields = this.fields();
    return fields.slice(0, Math.ceil(fields.length / 2));
  });

  readonly fieldRight = computed(() => {
    const fields = this.fields();
    return fields.slice(Math.ceil(fields.length / 2));
  });

  @ContentChildren(TemplateDirective) templates: QueryList<TemplateDirective>;

  keyTemplates: Map<string, TemplateRef<any>> | undefined = undefined;

  ngAfterContentInit(): void {
    TemplateUtil.setTemplates(this, this.templates, undefined, [
      {
        templates: 'keyTemplates',
        name: 'Key'
      }
    ]);
  }

  getValueAsString(field: DetailField, item?: any): string {
    if (!item) {
      item = TemplateUtil.getValueAsString(field.value, field.path, item =>
        this._convert(item, field)
      );
    } else {
      item = TemplateUtil.getValueAsString(item, field.path, item => this._convert(item, field));
    }
    if (!item || (Array.isArray(item) && item.length === 0)) {
      item = '------';
    }
    return item;
  }

  getValueAsNumber(field: DetailField, item?: any): number {
    return +this.getValueAsString(field, item);
  }

  getChildField(field: DetailField, value?: any): DetailField {
    if (value) {
      return { ...field, value };
    }
    return field;
  }

  private _convert(item: any, field: DetailField): any {
    if (field.overwriteValue) {
      item = field.overwriteValue(item);
    }

    if (field.type === 'full_name') {
      item = ModelUtil.getFullName(item, this.translocoService);
    } else if (field.type === 'address') {
      item = ModelUtil.getAddresses(item);
    }

    return item;
  }

  openFile(field: DetailField, item: any) {
    if (field.downloadFile && item) {
      // TODO: Better way to handle busy state
      field.isBusy = true;

      field.downloadFile(field, item);

      field.isBusy = false;
    }
  }
}
