<lib-layout-base-page *transloco="let transloco">
  <div class="flex flex-col">
    <p-tabView libTabViewQuery="t">
      <p-tabPanel header="{{ transloco('general_information') }}">
        <ng-template pTemplate="content">
          <div class="flex flex-col gap-6">
            <lib-detail [fields]="this.fields()">
              <ng-template let-data libTemplate="entry_linkKey">
                @if (data) {
                  <a
                    [routerLink]="['/', 'accounting', 'entries', 'detail', data?.id]"
                    class="cursor-pointer hover:text-primary hover:underline">
                    <span>{{ transloco('account_entry') }} - Nr.: {{ data?.number }}</span>
                  </a>
                } @else {
                  <span>------</span>
                }
              </ng-template>
            </lib-detail>

            @if (this.item()?.type !== this.AccountingCashBookEntryTypeEnum.INCOMING) {
              <span class="block text-2xl">{{ transloco('receipts') }}</span>

              <app-file-table
                [files]="this.item()?.receipt_documents || []"
                namespace="receipt_document"></app-file-table>
            } @else if (this.item()?.cash_book_entry_category?.is_student_balance) {
              <span class="block text-2xl">{{ transloco('student_balances') }}</span>

              <app-student-balance-list
                [patchOptions]="this.studentPatchOptions"
                [stateKey]="
                  'cash-book-entry-detail-student-balance-list'
                "></app-student-balance-list>
            }
          </div>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </div>
</lib-layout-base-page>
