<p-toolbar *transloco="let transloco">
  <div class="p-toolbar-group-left gap-2">
    <input
      #uploader
      (change)="this.fileSelected(uploader.files); uploader.value = ''"
      [accept]="this.accept()"
      [disabled]="this.disabled"
      [size]="this.size() || this.sizeLimit"
      hidden
      type="file" />

    <button
      (click)="uploader.click()"
      [disabled]="this.disabled"
      label="{{ transloco('select') }}"
      pButton
      type="button"></button>

    @if (!this.fileName) {
      <span>{{ transloco('select_file') }}</span>
    }
    @if (this.fileName) {
      <span>{{ this.fileName }} - {{ this.fileSize | filesize: { separator: ',' } }}</span>
    }
  </div>
</p-toolbar>
