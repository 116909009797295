import {
  Component,
  computed,
  effect,
  inject,
  input,
  OnInit,
  signal,
  ViewChild
} from '@angular/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { TooltipModule } from 'primeng/tooltip';
import { MenuModule } from 'primeng/menu';
import { Apollo, gql } from 'apollo-angular';
import { addMonths, endOfMonth, format, startOfMonth } from 'date-fns';
import { CurrencyPipe, NgClass } from '@angular/common';
import { ButtonDirective } from 'primeng/button';
import { map } from 'rxjs';
import { ConfirmationService } from '~ngx-shared/layout';
import { PersonDetailLinkComponent } from '~madrasa/staff/components/person-detail-link/person-detail-link.component';
import { ModelUtil } from '~ngx-shared/utils';
import { TemplateDirective } from '~ngx-shared/directives';
import {
  DataProviderOptionModel,
  GraphQlAdvancedFilterComponent,
  GraphQlAdvancedSortComponent,
  GraphQlAdvancedTableComponent,
  GraphQlColumnModel,
  GraphQlTableModel
} from '~ngx-shared/graph-ql';
import { AcademyOrganisationModel, AcademySchoolModel } from '~ngx-shared/models';

@Component({
  selector: 'app-student-balance-per-month-of-balance-list',
  standalone: true,
  imports: [
    NgxPermissionsModule,
    TemplateDirective,
    TranslocoDirective,
    TooltipModule,
    MenuModule,
    PersonDetailLinkComponent,
    GraphQlAdvancedFilterComponent,
    GraphQlAdvancedSortComponent,
    GraphQlAdvancedTableComponent,
    CurrencyPipe,
    NgClass,
    ButtonDirective
  ],
  templateUrl: './student-balance-per-month-of-balance-list.component.html',
  styleUrl: './student-balance-per-month-of-balance-list.component.scss'
})
export class StudentBalancePerMonthOfBalanceListComponent implements OnInit {
  readonly apollo = inject(Apollo);
  readonly confirmationService = inject(ConfirmationService);
  readonly translocoService = inject(TranslocoService);

  showFilter = input<boolean>(true);
  showSort = input<boolean>(true);
  showExport = input<boolean>(true);
  showColumnFilter = input<boolean>(true);

  stateKey = input<string>();

  readonly columns = signal<GraphQlColumnModel[]>([]);

  graphQlTable = computed(() => {
    const signalCols = this.columns();
    let columns = [...signalCols];

    columns.unshift(...this.getDefaultColumns());

    const graphQlTable: GraphQlTableModel = {
      table: 'core_current_person_data',
      isPaginated: true,

      showCurrentPageReport: true,
      columns
    };
    return graphQlTable;
  });

  @ViewChild('advancedTable') tableComponent: GraphQlAdvancedTableComponent;

  graphQlPatchOptions: (options: DataProviderOptionModel) => DataProviderOptionModel;

  constructor() {
    effect(() => {
      this.graphQlPatchOptions = options => {
        options.filter = {
          ...options.filter,
          _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
        };
        if (Array.isArray(options?.filter?._and)) {
          options.filter._and = options.filter._and.filter((cond: any) => !cond.latest_student);
          options.filter._and.push({ latest_student: {} });
        }
        return options;
      };
    });
  }

  ngOnInit() {
    const columns: GraphQlColumnModel[] = [];

    // Get the prev 3 months and the next 3 months
    for (let i = -3; i <= 3; i++) {
      let date = new Date();
      date = addMonths(date, i);
      const firstDay = startOfMonth(date);
      const lastDay = endOfMonth(date);
      columns.push(
        this.createColumn(
          firstDay,
          format(firstDay, 'MM/yyyy'),
          format(firstDay, 'yyyy-MM-dd'),
          format(lastDay, 'yyyy-MM-dd')
        )
      );
    }
    this.columns.set(columns);
  }

  getSum(data: any) {
    return data?.reduce((acc: any, curr: any) => acc + curr.amount, 0);
  }

  getDefaultColumns(): GraphQlColumnModel[] {
    return [
      {
        label: 'Id',
        path: 'person_id',
        type: 'number',
        classHeader: 'text-center',
        classBody: 'text-center',
        sort: { isSortable: true },
        filter: { type: 'number' }
      },
      {
        path: 'first_name',
        filter: { type: 'string' },
        sort: { isSortable: true },
        isNotExportable: true,
        isNotSelectable: true
      },
      {
        path: 'last_name',
        filter: { type: 'string' },
        sort: { isSortable: true },
        isNotExportable: true,
        isNotSelectable: true
      },
      {
        label: 'organisations',
        path: 'organisation_persons_active.organisation.name',
        filter: {
          label: 'organisation',
          path: 'organisation_persons_active.organisation_id',
          type: 'array',
          options: this.apollo
            .query<{
              result: AcademyOrganisationModel[];
            }>({
              query: gql`
                query ReadAcademyOrganisation($where: academy_organisation_bool_exp) {
                  result: academy_organisation(where: $where) {
                    id
                    name
                  }
                }
              `,
              variables: {
                where: {}
              }
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(organisation => ({
                  label: organisation.name,
                  value: organisation.id
                }))
              )
            )
        },
        sort: { isSortable: false }
      },
      {
        label: 'schools',
        path: 'school_students_active.school.name',
        filter: {
          label: 'school',
          path: 'school_students_active.school_id',
          type: 'array',
          options: this.apollo
            .query<{
              result: AcademySchoolModel[];
            }>({
              query: gql`
                query ReadAcademySchool($where: academy_school_bool_exp) {
                  result: academy_school(where: $where) {
                    id
                    name
                  }
                }
              `,
              variables: {
                where: {}
              }
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(school => ({
                  label: school.name,
                  value: school.id
                }))
              )
            )
        },
        sort: { isSortable: false }
      },
      {
        label: 'student',
        path: 'current_person_data',
        query: 'id',
        patchResult: value => ModelUtil.getFullName(value, this.translocoService),
        filter: { isNotFilterable: true },
        sort: { isSortable: false }
      },
      {
        path: 'academic_degree_prefix',
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        isNotExportable: true,
        isNotSelectable: true
      },
      {
        path: 'academic_degree_suffix',
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        isNotExportable: true,
        isNotSelectable: true
      },
      {
        path: 'first_name',
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        isNotExportable: true,
        isNotSelectable: true
      },
      {
        path: 'last_name',
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        isNotExportable: true,
        isNotSelectable: true
      }
    ];
  }

  createColumn(date: Date, label: string, start: string, end: string): GraphQlColumnModel {
    const forMonth = `for_month_${label.replaceAll('/', '_')}`;
    return {
      date,
      label,
      path: forMonth,
      classHeader: 'text-right',
      classBody: 'text-right',
      query: `
            ${forMonth}: student_balances(
              where: { month_of_balance: { _gte: "${start}", _lte: "${end}" } }

              order_by: { amount: desc }
            ) {
              id
              amount
              description
            }
          `,
      patchResult: value => {
        return String(
          (value[forMonth]?.reduce((acc: any, curr: any) => acc + curr.amount, 0) || 0) / 100
        ).replaceAll('.', ',');
      },
      filter: { isNotFilterable: true },
      sort: { isSortable: false }
    };
  }

  addColumn(direction: 'front' | 'back') {
    const columns = this.columns();
    const date =
      direction === 'front' ? columns[0]?.['date'] : columns[columns.length - 1]?.['date'];
    if (date) {
      let newDate = new Date(date);
      newDate = addMonths(newDate, direction === 'front' ? -1 : 1);
      const firstDay = startOfMonth(newDate);
      const lastDay = endOfMonth(newDate);
      const newColumn = this.createColumn(
        firstDay,
        format(firstDay, 'MM/yyyy'),
        format(firstDay, 'yyyy-MM-dd'),
        format(lastDay, 'yyyy-MM-dd')
      );
      if (direction === 'front') {
        columns.unshift(newColumn);
      } else {
        columns.push(newColumn);
      }
      this.columns.set(columns.splice(0));
    }
  }
}
