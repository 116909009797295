import { Component, computed, inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslocoDirective } from '@jsverse/transloco';
import { PrimeTemplate } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { map, of, switchMap, tap } from 'rxjs';
import { TabViewQueryDirective, TemplateDirective } from '~ngx-shared/directives';
import { DetailComponent, DetailField } from '~ngx-shared/ui/detail/detail.component';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { AccountingEntryModel } from '~ngx-shared/models';
import { EntryRowListComponent } from '~madrasa/accounting/components/entry-row-list/entry-row-list.component';
import { FileTableComponent } from '~madrasa/core/components/file-table/file-table.component';

@UntilDestroy()
@Component({
  selector: 'app-entry-detail-page',
  standalone: true,
  imports: [
    BasePageComponent,
    TranslocoDirective,
    DetailComponent,
    PrimeTemplate,
    TabViewModule,
    TabViewQueryDirective,
    EntryRowListComponent,
    TemplateDirective,
    RouterLink,
    FileTableComponent
  ],
  templateUrl: './entry-detail-page.component.html',
  styleUrl: './entry-detail-page.component.scss'
})
export class EntryDetailPageComponent {
  readonly apollo = inject(Apollo);
  readonly activatedRoute = inject(ActivatedRoute);
  readonly loadingService = inject(LoadingService);

  readonly item = toSignal(
    this.activatedRoute.data.pipe(
      untilDestroyed(this),
      tap(() => this.loadingService.startLoading()),
      switchMap(data => {
        const result = !!data?.['crud'] && !!data['crud']['id'];
        if (result) {
          return this.apollo
            .query<{ result: AccountingEntryModel }>({
              query: gql`
                query ReadAccountingAccountEntryById($id: bigint!) {
                  result: accounting_entry_by_pk(id: $id) {
                    id
                    number
                    created_at
                    updated_at
                    accounting_period_id
                    date_of_entry
                    number
                    title
                    transaction_identifier
                    external_bill_number
                    description
                    amount

                    cost_center {
                      name
                    }

                    cash_book_entry {
                      id
                      number
                    }

                    receipt_documents {
                      id
                      name
                      size
                    }
                  }
                }
              `,
              variables: {
                id: data['crud']['id']
              }
            })
            .pipe(map(queryResult => queryResult.data?.result));
        } else {
          return of(undefined);
        }
      }),
      tap(() => this.loadingService.stopLoading())
    )
  );

  readonly fields = computed<DetailField[]>(() => {
    const item = this.item();
    if (!item) {
      return [];
    }
    return [
      {
        label: 'number',
        value: item.number
      },
      {
        label: 'created_at',
        type: 'datetime',
        value: item.created_at
      },
      {
        label: 'updated_at',
        type: 'datetime',
        value: item.updated_at
      },
      {
        label: 'accounting_period',
        value: item.accounting_period_id
      },
      {
        label: 'cost_center',
        value: item.cost_center?.name
      },
      {
        key: 'cash_book_entry',
        label: 'cash_book_entry',
        value: item.cash_book_entry
      },
      {
        label: 'title',
        value: item.title
      },
      {
        label: 'transaction_identifier',
        value: item.transaction_identifier
      },
      {
        label: 'external_bill_number',
        value: item.external_bill_number
      },
      {
        label: 'date',
        type: 'date',
        value: item.date_of_entry
      },
      {
        label: 'amount',
        type: 'currency',
        value: item.amount
      },
      {
        label: 'description',
        value: item.description
      }
    ];
  });
}
