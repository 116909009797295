import { Component, computed, effect, inject, input, ViewChild } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslocoDirective } from '@jsverse/transloco';
import { TooltipModule } from 'primeng/tooltip';
import { MenuModule } from 'primeng/menu';
import { ButtonDirective } from 'primeng/button';
import { map } from 'rxjs';
import { TagModule } from 'primeng/tag';
import { PersonDetailLinkComponent } from '~madrasa/staff/components/person-detail-link/person-detail-link.component';
import { TemplateDirective } from '~ngx-shared/directives';
import {
  AcademyOrganisationModel,
  AcademySchoolModel,
  AccountingCashBookEntryModel,
  AccountingCashBookEntryPermission,
  AccountingCashBookEntryTypeEnum
} from '~ngx-shared/models';
import {
  DataProviderOptionModel,
  GraphQlAdvancedFilterComponent,
  GraphQlAdvancedSortComponent,
  GraphQlAdvancedTableComponent,
  GraphQlColumnModel,
  GraphQlTableModel
} from '~ngx-shared/graph-ql';
import { ConfirmationService } from '~ngx-shared/layout';
import { AuthorizationService } from '~ngx-shared/authentication';
import { DatePipe } from '~ngx-shared/pipes';
import { PrintService } from '~madrasa/services';

@Component({
  selector: 'app-cash-book-entry-list',
  standalone: true,
  imports: [
    GraphQlAdvancedFilterComponent,
    GraphQlAdvancedSortComponent,
    GraphQlAdvancedTableComponent,
    NgxPermissionsModule,
    TemplateDirective,
    TranslocoDirective,
    TooltipModule,
    MenuModule,
    ButtonDirective,
    PersonDetailLinkComponent,
    TagModule,
    DatePipe
  ],
  templateUrl: './cash-book-entry-list.component.html',
  styleUrl: './cash-book-entry-list.component.scss'
})
export class CashBookEntryListComponent {
  readonly apollo = inject(Apollo);
  readonly confirmationService = inject(ConfirmationService);
  readonly authorizationService = inject(AuthorizationService);
  readonly printService = inject(PrintService);

  showFilter = input<boolean>(false);
  showSort = input<boolean>(false);
  showExport = input<boolean>(false);
  showColumnFilter = input<boolean>(true);
  showCreateButton = input<boolean>(false);
  showSchool = input<boolean>(true);

  stateKey = input<string>();
  patchOptions = input<(options: DataProviderOptionModel) => DataProviderOptionModel>();
  columns = input<GraphQlColumnModel[]>([]);
  accountPeriodId = input.required<number>();

  graphQlTable = computed(() => {
    let columns = this.columns();

    columns.unshift(...this.getDefaultColumns());

    const graphQlTable: GraphQlTableModel = {
      table: 'accounting_cash_book_entry',
      isPaginated: true,

      showCurrentPageReport: true,
      columns
    };
    return graphQlTable;
  });
  @ViewChild('advancedTable') tableComponent: GraphQlAdvancedTableComponent;

  graphQlPatchOptions: (options: DataProviderOptionModel) => DataProviderOptionModel;
  protected readonly AccountingCashBookEntryPermission = AccountingCashBookEntryPermission;
  protected readonly AccountingCashBookEntryTypeEnum = AccountingCashBookEntryTypeEnum;

  constructor() {
    effect(() => {
      this.graphQlPatchOptions = options => {
        const currentPeriodId = this.accountPeriodId();
        const patchOptions = this.patchOptions();
        if (patchOptions) {
          options = patchOptions(options);
        }

        if (!options.filter) {
          options.filter = {};
        }
        if (!options.filter._and) {
          options.filter._and = [];
        }

        if (Array.isArray(options?.filter?._and)) {
          options.filter._and = options.filter._and.filter(
            (cond: any) => !cond.accounting_period_id
          );
          options.filter._and.push({
            accounting_period_id: {
              _eq: currentPeriodId
            }
          });
        }

        // Add default sorting by updated_at
        if (!options.sortBy?.length) {
          options.sortBy = [{ updated_at: 'desc_nulls_last' }];
        }

        return options;
      };
    });
  }

  getDefaultColumns(): GraphQlColumnModel[] {
    const columns: GraphQlColumnModel[] = [
      {
        path: 'number',
        classHeader: 'text-center',
        classBody: 'text-center',
        type: 'number',
        filter: { type: 'number' },
        sort: { isSortable: true }
      },
      {
        path: 'id',
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        isNotExportable: true,
        isNotSelectable: true
      },
      {
        path: 'school_id',
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        isNotExportable: true,
        isNotSelectable: true
      },
      ...(this.showSchool()
        ? ([
            {
              label: 'organisation',
              path: 'school.organisation.name',
              filter: {
                label: 'organisation',
                path: 'school.organisation_id',
                type: 'array',
                options: this.apollo
                  .query<{
                    result: AcademyOrganisationModel[];
                  }>({
                    query: gql`
                      query ReadAcademyOrganisation($where: academy_organisation_bool_exp) {
                        result: academy_organisation(where: $where) {
                          id
                          name
                        }
                      }
                    `,
                    variables: {
                      where: {}
                    }
                  })
                  .pipe(
                    map(queryResult =>
                      queryResult.data.result.map(organisation => ({
                        label: organisation.name,
                        value: organisation.id
                      }))
                    )
                  )
              },
              sort: { isSortable: true }
            },
            {
              label: 'school',
              path: 'school.name',
              filter: {
                label: 'school',
                path: 'school_id',
                type: 'array',
                options: this.apollo
                  .query<{
                    result: AcademySchoolModel[];
                  }>({
                    query: gql`
                      query ReadAcademySchool($where: academy_school_bool_exp) {
                        result: academy_school(where: $where) {
                          id
                          name
                        }
                      }
                    `,
                    variables: {
                      where: {}
                    }
                  })
                  .pipe(
                    map(queryResult =>
                      queryResult.data.result.map(school => ({
                        label: school.name,
                        value: school.id
                      }))
                    )
                  )
              },
              sort: { isSortable: false }
            }
          ] as GraphQlColumnModel[])
        : []),
      {
        path: 'type',
        classHeader: 'text-center',
        classBody: 'text-center',
        translate: true,
        prefix: 'cash_book_entry_type.',
        filter: {
          type: 'array',
          options: Object.values(AccountingCashBookEntryTypeEnum).map(value => ({
            label: 'cash_book_entry_type.' + value,
            value: value
          }))
        },
        sort: { isSortable: true }
      },
      {
        label: 'date',
        path: 'date_of_entry',
        type: 'date',
        filter: { type: 'date' },
        sort: { isSortable: true }
      },
      {
        label: 'cash_book_entry_category',
        path: 'cash_book_entry_category.name',
        filter: { type: 'string' },
        sort: { isSortable: true }
      },
      {
        path: 'amount',
        type: 'currency',
        filter: { isNotFilterable: true },
        sort: { isSortable: true }
      },
      ...(this.authorizationService.can(AccountingCashBookEntryPermission.UPDATE)
        ? ([
            {
              label: 'is_invoiced',
              classHeader: 'text-center',
              classBody: 'text-center',
              path: 'entry.id',
              type: 'boolean',
              filter: {
                type: 'is_null'
              },
              sort: { isSortable: true }
            }
          ] as GraphQlColumnModel[])
        : []),
      {
        label: 'student',
        path: 'student_balance_current_person_data',
        query: `
          student_balances{
            id
            month_of_balance
            current_person_data {
              person_id
              academic_degree_prefix
              academic_degree_suffix
              first_name
              last_name
            }
          }
        `,
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        isNotExportable: true
      },
      {
        path: 'payed_by',
        filter: { type: 'string' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        label: 'created_by',
        path: 'created_by_current_person_data',
        query: `
            created_by_current_person_data {
              person_id
              academic_degree_prefix
              academic_degree_suffix
              first_name
              last_name
            }
        `,
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        isNotExportable: true
      },
      {
        path: 'created_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true }
      },
      {
        path: 'updated_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true },
        hidden: true
      }
    ];

    return columns;
  }

  getRow(data: any): AccountingCashBookEntryModel | undefined {
    return data;
  }

  delete(data: any) {
    const row = this.getRow(data);
    if (row) {
      let mutation: any = gql`
        mutation SoftDeleteAccountingCashBookEntryById($id: bigint!) {
          result: update_accounting_cash_book_entry_by_pk(
            pk_columns: { id: $id }
            _set: { deleted_at: "now()" }
          ) {
            __typename
          }
        }
      `;

      this.confirmationService.confirmDeleteApollo({
        name: `${row.number} - ${row.cash_book_entry_category?.name} - ${(row.amount || 0) / 100}€`,
        mutationOptions: {
          mutation,
          variables: {
            id: row.id
          }
        },
        success: () => this.tableComponent?.updateTable()
      });
    }
  }

  generate(data: any) {
    const row = this.getRow(data);
    if (row) {
      this.printService.cashBookEntry(row.id, String(row.number));
    }
  }
}
