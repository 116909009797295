import { Component, computed, effect, inject } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { PrimeTemplate } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, of, switchMap, tap } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ButtonDirective } from 'primeng/button';
import { NgClass } from '@angular/common';
import {
  AcademyCertificatePermission,
  AcademyCertificateTypeEnum,
  AcademyCourseModel,
  AcademyCoursePermission,
  AcademyCourseStudentStatusEnum
} from '~ngx-shared/models';
import { TabViewQueryDirective } from '~ngx-shared/directives';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { AuthorizationService, Role } from '~ngx-shared/authentication';
import { PersonListComponent } from '~madrasa/staff/components/person-list/person-list.component';
import { DataProviderOptionModel, GraphQlColumnModel } from '~ngx-shared/graph-ql';
import { UnitListComponent } from '~madrasa/academy/components/unit-list/unit-list.component';
import { ClassBookEntryListComponent } from '~madrasa/academy/components/class-book-entry-list/class-book-entry-list.component';
import { CourseDetailComponent } from '~madrasa/academy/components/course-detail/course-detail.component';
import { StudentListComponent } from '~madrasa/staff/components/student-list/student-list.component';
import { NoteListComponent } from '~madrasa/note/components/note-list/note-list.component';
import { FormSaveModel } from '~ngx-shared/formly';
import { CourseStudentListComponent } from '~madrasa/academy/components/course-student-list/course-student-list.component';
import { CertificateListComponent } from '~madrasa/academy/components/certificate-list/certificate-list.component';

@UntilDestroy()
@Component({
  selector: 'app-course-detail-page',
  standalone: true,
  imports: [
    BasePageComponent,
    TranslocoDirective,
    PrimeTemplate,
    TabViewModule,
    TabViewQueryDirective,
    NgxPermissionsModule,
    PersonListComponent,
    UnitListComponent,
    ClassBookEntryListComponent,
    CourseDetailComponent,
    StudentListComponent,
    NoteListComponent,
    CourseStudentListComponent,
    CertificateListComponent,
    ButtonDirective,
    RouterLink,
    NgClass
  ],
  templateUrl: './course-detail-page.component.html',
  styleUrl: './course-detail-page.component.scss'
})
export class CourseDetailPageComponent {
  readonly apollo = inject(Apollo);
  readonly router = inject(Router);
  readonly activatedRoute = inject(ActivatedRoute);
  readonly loadingService = inject(LoadingService);
  readonly authorizationService = inject(AuthorizationService);

  readonly item = toSignal(
    this.activatedRoute.data.pipe(
      untilDestroyed(this),
      tap(() => this.loadingService.startLoading()),
      switchMap(data => {
        const result = !!data?.['crud'] && !!data['crud']['id'];
        if (result) {
          return this.apollo
            .query<{ result: AcademyCourseModel }>({
              query: gql`
                query ReadAcademyCourseById($id: bigint!) {
                  result: academy_course_by_pk(id: $id) {
                    id
                    name
                    school_id
                    school {
                      organisation_id
                    }
                    course_name {
                      name
                    }
                    ${AcademyCertificateTypeEnum.FIRST_SEMESTER}: certificates_aggregate(
                      where: { certificate_type: { _eq: first_semester } }
                    ) {
                      aggregate {
                        count
                      }
                    }
                    ${AcademyCertificateTypeEnum.SECOND_SEMESTER}: certificates_aggregate(
                      where: { certificate_type: { _eq: second_semester } }
                    ) {
                      aggregate {
                        count
                      }
                    }
                  }
                }
              `,
              variables: {
                id: data['crud']['id']
              }
            })
            .pipe(map(queryResult => queryResult.data?.result));
        } else {
          return of(undefined);
        }
      }),
      tap(() => this.loadingService.stopLoading())
    )
  );

  readonly studentColumns = computed<GraphQlColumnModel[]>(() => {
    const item = this.item();
    if (!item) {
      return [];
    }
    return [
      {
        label: 'course_student_status',
        path: 'latest_course_students.status',
        query: `latest_course_students (where: { course_id: { _eq: ${this.item()?.id} } }) { status }`,
        translate: true,
        prefix: 'status.',
        classHeader: 'text-center',
        classBody: 'text-center',
        type: 'tag',
        patchResult: (result: any, column: GraphQlColumnModel) => {
          switch (result.latest_course_students?.[0]?.status) {
            case AcademyCourseStudentStatusEnum.INACTIVE:
              column.severity = 'danger';
              break;
            case AcademyCourseStudentStatusEnum.ACTIVE:
              column.severity = 'success';
              break;
          }
          return 'status.' + result.latest_course_students?.[0]?.status;
        },
        filter: { isNotFilterable: true },
        sort: { isSortable: false }
      }
    ];
  });

  readonly teacherColumns = computed<GraphQlColumnModel[]>(() => {
    const item = this.item();
    if (!item) {
      return [];
    }
    return [
      {
        path: 'course_teachers.teaching_role',
        query: `course_teachers (where: { course_id: { _eq: ${this.item()?.id} } }) { teaching_role }`,
        translate: true,
        prefix: 'teaching_role.',
        filter: { isNotFilterable: true },
        sort: { isSortable: false }
      }
    ];
  });

  studentPatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  teacherPatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  unitPatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  cbePatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;

  notePatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  notePatchInput: (formSaveModel: FormSaveModel, input: any) => any;

  protected readonly Role = Role;
  protected readonly AcademyCoursePermission = AcademyCoursePermission;
  protected readonly AcademyCertificatePermission = AcademyCertificatePermission;
  protected readonly AcademyCertificateTypeEnum = AcademyCertificateTypeEnum;

  constructor() {
    effect(() => {
      const id = this.item()?.id;
      if (id) {
        this.studentPatchOptions = options => {
          options.filter = {
            ...options.filter,
            _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
          };
          if (Array.isArray(options?.filter?._and)) {
            options.filter._and = options.filter._and.filter(
              (cond: any) => !cond.latest_course_students
            );
            options.filter._and.push({
              latest_course_students: {
                course_id: {
                  _eq: this.item()?.id
                }
              }
            });
          }

          // Add default sorting
          if (!options.sortBy?.length) {
            options.sortBy = [{ first_name: 'asc_nulls_last' }];
          }
          return options;
        };

        this.teacherPatchOptions = options => {
          options.filter = {
            ...options.filter,
            _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
          };
          if (Array.isArray(options?.filter?._and)) {
            options.filter._and = options.filter._and.filter((cond: any) => !cond.course_teachers);
            options.filter._and.push({
              course_teachers: {
                course_id: {
                  _eq: this.item()?.id
                }
              }
            });
          }

          // Add default sorting
          if (!options.sortBy?.length) {
            options.sortBy = [{ first_name: 'asc_nulls_last' }];
          }
          return options;
        };

        this.unitPatchOptions = options => {
          options.filter = {
            ...options.filter,
            _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
          };
          if (Array.isArray(options?.filter?._and)) {
            options.filter._and = options.filter._and.filter((cond: any) => !cond.course_id);
            options.filter._and.push({
              course_id: {
                _eq: this.item()?.id
              }
            });
          }
          return options;
        };

        this.cbePatchOptions = options => {
          options.filter = {
            ...options.filter,
            _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
          };
          if (Array.isArray(options?.filter?._and)) {
            options.filter._and = options.filter._and.filter((cond: any) => !cond.course_id);
            options.filter._and.push({
              course_id: {
                _eq: this.item()?.id
              }
            });
          }
          return options;
        };

        this.notePatchOptions = options => {
          options.filter = {
            ...options.filter,
            _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
          };
          if (Array.isArray(options?.filter?._and)) {
            options.filter._and = options.filter._and.filter((cond: any) => !cond.course_id);
            options.filter._and.push({
              course_id: {
                _eq: this.item()?.id
              }
            });
          }
          return options;
        };

        this.notePatchInput = (formSaveModel, input) => {
          return {
            ...input,
            course_id: this.item()?.id
          };
        };
      }
    });
  }
}
