import { Component, inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Apollo, gql } from 'apollo-angular';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { combineLatest, filter, map, of, switchMap, tap } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { ModelUtil } from '~ngx-shared/utils';
import { AcademyCertificateTypeEnum, AcademyCourseModel } from '~ngx-shared/models';
import { FormlyService, UserService } from '~madrasa/services';
import { SchoolPeriodService } from '~madrasa/academy/services/school-period.service';
import { FormlyModule, FormlyUtil, FormSaveModel, FormSubmitModel } from '~ngx-shared/formly';
import { BasePageComponent, LoadingService } from '~ngx-shared/layout';
import { AuthorizationService, Role } from '~ngx-shared/authentication';

@UntilDestroy()
@Component({
  selector: 'app-create-update-course-certificate-form-page',
  standalone: true,
  imports: [BasePageComponent, FormlyModule],
  templateUrl: './create-update-course-certificate-form-page.component.html',
  styleUrl: './create-update-course-certificate-form-page.component.scss'
})
export class CreateUpdateCourseCertificateFormPageComponent implements OnInit {
  readonly schoolPeriodService = inject(SchoolPeriodService);
  readonly apollo = inject(Apollo);
  readonly activatedRoute = inject(ActivatedRoute);
  readonly loadingService = inject(LoadingService);
  readonly authorizationService = inject(AuthorizationService);
  readonly formlyService = inject(FormlyService);
  readonly translocoService = inject(TranslocoService);
  readonly userService = inject(UserService);

  form = new FormGroup({});
  readOnlyModel: any = {};
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      transloco: 'madrasa.forms.create_update_course_certificate'
    }
  };
  fields: FormlyFieldConfig[];
  submit: FormSubmitModel;

  ngOnInit(): void {
    this.loadingService.startLoading();

    combineLatest([
      this.activatedRoute.data.pipe(
        untilDestroyed(this),
        switchMap(data => {
          const result = !!data?.['crud'] && !data['crud']['is_new'] && !!data['crud']['id'];
          if (result) {
            return this.apollo
              .query<any>({
                query: gql`
                  query ReadAcademyCourseCertificatePerParamQuery($courseId: bigint!) {
                    course: academy_course_by_pk(id: $courseId) {
                      id
                      school_id
                      school {
                        organisation_id
                      }
                      course_type_id
                      name
                      course_name {
                        name
                      }
                      certificate_templates {
                        id
                        name
                        options
                      }
                      latest_course_students_active {
                        current_person_data {
                          academic_degree_prefix
                          academic_degree_suffix
                          first_name
                          last_name
                          person_id
                          date_of_birth
                        }
                      }
                      certificates(where: { certificate_type: { _eq: ${this.activatedRoute.snapshot.queryParamMap.get(
                        'certificate_type'
                      )} } }) {
                        id
                        person_id
                        current_person_data {
                          academic_degree_prefix
                          academic_degree_suffix
                          first_name
                          last_name
                          person_id
                          date_of_birth
                        }
                        certificate_type
                        date_of_certificate
                        options
                        certificate_subjects {
                          id
                          subject_id
                          grade
                        }
                      }
                    }
                    subjects: academy_subject(
                      order_by: { name: asc }
                      where: { units: {
                        course_id: { _eq: $courseId }
                        ${
                          this.authorizationService.can(Role.TEACHER)
                            ? `
                        _or: [
                          { course: { course_teachers: { person_id: { _eq: ${this.userService.user()?.person_id} } } } }
                          {
                            unit_teachers: { person_id: { _eq: ${this.userService.user()?.person_id} } }
                          }
                        ]`
                            : ''
                        }
                      }
                    }) {
                      id
                      name
                    }
                  }
                `,
                variables: {
                  courseId: data['crud']['id']
                }
              })
              .pipe(map(queryResult => queryResult.data));
          } else {
            return of<AcademyCourseModel>({});
          }
        })
      ),
      this.schoolPeriodService.currentPeriod$
    ]).subscribe(result => {
      if (result[0] && result[1]) {
        const schoolPeriod = result[1];

        this.readOnlyModel = result[0];
        let model = cloneDeep(result[0]);

        if (model) {
          this.model = {
            organisation_id: model.course?.school?.organisation_id,
            school_id: model.course?.school_id,
            school_period_id: schoolPeriod.id,
            course_id: model.course?.id,
            course: model.course?.id,
            date_of_certificate: FormlyUtil.fromIsoDateString(
              model.course?.certificates?.find(
                (certificate: any) => !!certificate.date_of_certificate
              )?.date_of_certificate,
              false
            ),
            certificate_type: this.activatedRoute.snapshot.queryParamMap.get(
              'certificate_type'
            ) as keyof typeof AcademyCertificateTypeEnum
          };
        }

        this.fields = [
          this.formlyService.createOrganisationFieldConfig({
            required: true,
            readonly: true
          }),
          this.formlyService.createSchoolFieldConfig({ required: true, readonly: true }),
          FormlyUtil.createRow([
            FormlyUtil.createSelectField('school_period_id', {
              props: {
                label: 'school_period',
                required: true,
                readonly: true,
                options: this.schoolPeriodService.getPeriods().pipe(
                  map(
                    schoolPeriods =>
                      schoolPeriods?.map(schoolPeriod => ({
                        label: schoolPeriod.name,
                        value: schoolPeriod.id
                      })) || []
                  )
                )
              }
            }),
            FormlyUtil.createSelectField('certificate_type', {
              props: {
                required: true,
                readonly: true,
                options: Object.values(AcademyCertificateTypeEnum).map(value => ({
                  label: 'certificate_type.' + value,
                  value: value
                }))
              }
            })
          ]),
          FormlyUtil.createRow([
            {
              key: 'course_id'
            },
            FormlyUtil.createSelectField('course', {
              props: {
                required: true,
                readonly: true,
                options: []
              },
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  const createRequest = (
                    schoolPeriodId?: number,
                    schoolId?: number,
                    courseId?: number
                  ) => {
                    return this.apollo
                      .query<{
                        result: AcademyCourseModel[];
                      }>({
                        query: gql`
                          query ReadAcademyCourses($where: academy_course_bool_exp = {}) {
                            result: academy_course(order_by: { name: asc }, where: $where) {
                              id
                              course_type_id
                              name
                              course_name {
                                name
                              }
                              certificate_templates {
                                id
                                name
                                options
                              }
                            }
                          }
                        `,
                        variables: {
                          where: {
                            school_id: { _eq: schoolId },
                            school_period_id: { _eq: schoolPeriodId }
                          }
                        }
                      })
                      .pipe(
                        map(queryResult =>
                          queryResult.data.result.map(item => ({
                            label: item.name || item.course_name?.name,
                            value: item
                          }))
                        ),
                        tap(options => {
                          if (courseId) {
                            field.formControl?.setValue(
                              options.find(option => option.value.id === courseId)?.value
                            );
                          }
                        })
                      );
                  };

                  if (field.props && field.model.school_id && field.model.school_period_id) {
                    field.props.options = createRequest(
                      field.model.school_period_id,
                      field.model.school_id,
                      field.model.course_id
                    );
                  }
                  return field.options?.fieldChanges?.pipe(
                    filter(
                      event =>
                        event.type === 'valueChanges' &&
                        (event.field.key === 'school_id' || event.field.key === 'school_period_id')
                    ),
                    tap(event => {
                      field.formControl?.setValue(undefined);

                      if (field.props && field.model.school_id && field.model.school_period_id) {
                        field.props.options = createRequest(
                          field.model.school_period_id,
                          field.model.school_id
                        );
                      }
                    })
                  );
                }
              }
            }),
            FormlyUtil.createDatePickerField('date_of_certificate', {
              props: {
                required: false
              }
            })
          ]),
          FormlyUtil.createRow([
            FormlyUtil.createTable(
              {
                key: 'certificate_students',
                props: {
                  label: 'students',
                  showIndex: true,
                  canAdd: () => false,
                  canRemove: () => false,
                  columns: [
                    {
                      header: 'id',
                      key: 'person_id'
                    },
                    {
                      header: 'name',
                      key: 'student'
                    }
                  ]
                },
                hooks: {
                  onInit: (field: FormlyFieldConfig) => {
                    // Merge the students with the available certificate subjects

                    this.readOnlyModel.subjects?.forEach((subject: any) => {
                      if (field.props) {
                        field.props['columns'].push({
                          header: subject.name,
                          key: `subject_id_${subject.id}`
                        });

                        (field.fieldArray as any).fieldGroup?.push(
                          FormlyUtil.createNumberField(`subject_id_${subject.id}`, {
                            className: 'min-w-32',
                            props: {
                              disableMargin: true,
                              hideLabel: true,
                              required: false,
                              min: 1,
                              max: 5,
                              showClear: true,
                              placeholder: 'grade'
                            }
                          }),
                          {
                            key: `certificate_subject_id_${subject.id}`
                          }
                        );
                      }
                    });

                    this.readOnlyModel.course?.certificate_templates?.forEach(
                      (certificate_template: any) => {
                        certificate_template?.options?.forEach((option: any, index: number) => {
                          if (!option.is_title && field.props) {
                            field.props['columns'].push({
                              header: option.name,
                              key: `option_idx_${index}`
                            });

                            (field.fieldArray as any).fieldGroup?.push(
                              FormlyUtil.createNumberField(`option_idx_${index}`, {
                                className: 'min-w-32',
                                props: {
                                  disableMargin: true,
                                  hideLabel: true,
                                  required: false,
                                  min: 1,
                                  max: 5,
                                  showClear: true,
                                  placeholder: 'grade'
                                }
                              })
                            );
                          }
                        });
                      }
                    );

                    // Merge students from latest_course_students_active with certificates ones
                    const personDatas: any[] =
                      this.readOnlyModel.course?.latest_course_students_active?.map(
                        (student: any) => student.current_person_data
                      ) || [];

                    this.readOnlyModel.course?.certificates?.forEach((certificate: any) => {
                      const student = personDatas.find(
                        personData => personData.person_id === certificate.person_id
                      );
                      if (!student) {
                        personDatas.push(certificate.current_person_data);
                      }
                    });

                    const certificateStudents: any[] = [];

                    personDatas.forEach(data => {
                      const item: any = {
                        person_id: data?.person_id,
                        student: ModelUtil.getFullName(data, this.translocoService)
                      };

                      const certificate = this.readOnlyModel.course?.certificates?.find(
                        (certificate: any) => certificate.person_id === data?.person_id
                      );

                      if (certificate) {
                        item.certificate_id = certificate.id;
                        certificate.certificate_subjects?.forEach((certificate_subject: any) => {
                          item[`subject_id_${certificate_subject.subject_id}`] =
                            certificate_subject.grade;
                          item[`certificate_subject_id_${certificate_subject.subject_id}`] =
                            certificate_subject.id;
                        });

                        this.readOnlyModel.course?.certificate_templates?.forEach(
                          (certificate_template: any) => {
                            certificate_template?.options?.forEach((option: any, index: number) => {
                              if (!option.is_title) {
                                item[`option_idx_${index}`] = certificate.options.find(
                                  (opt: any) => opt.name === option.name
                                )?.grade;
                              }
                            });
                          }
                        );
                      }

                      certificateStudents.push(item);
                    });

                    this.model = {
                      ...this.model,
                      certificate_students: certificateStudents
                    };
                  }
                }
              },
              [
                {
                  key: 'certificate_id'
                },
                FormlyUtil.createLabelField('person_id', {
                  props: {
                    label: 'id',
                    disableMargin: true,
                    hideLabel: true,
                    labelClass: 'font-normal'
                  }
                }),
                FormlyUtil.createLabelField('student', {
                  className: 'min-w-32',
                  props: {
                    label: 'student',
                    disableMargin: true,
                    hideLabel: true,
                    labelClass: 'font-normal'
                  }
                })
              ]
            )
          ])
        ];

        this.loadingService.stopLoading();
      }
    });

    this.submit = (formSaveModel: FormSaveModel) => {
      const input = {
        ...formSaveModel.input
      };
      ModelUtil.deleteKey(input);
      ModelUtil.deleteKey(input, 'organisation_id');
      ModelUtil.deleteKey(input, 'certificate_students');
      ModelUtil.deleteKey(input, 'course');

      const params: string[] = [];
      const queries: string[] = [];

      const inserts: any[] = [];
      const updates: any[] = [];
      const newCertificateSubjects: any[] = [];
      const updateCertificateSubjects: any[] = [];

      let variables: any = {};

      formSaveModel.input.certificate_students?.forEach((item: any) => {
        if (item.certificate_id) {
          const options: any[] = [];
          this.readOnlyModel.course?.certificate_templates?.forEach((certificate_template: any) => {
            certificate_template?.options?.forEach((option: any, index: number) => {
              if (!option.is_title) {
                options.push({
                  name: option.name,
                  grade: item[`option_idx_${index}`] ? String(item[`option_idx_${index}`]) : null
                });
              }
            });
          });

          updates.push({
            where: {
              id: { _eq: item.certificate_id }
            },
            _set: {
              options,
              date_of_certificate: FormlyUtil.toIsoDateString(
                formSaveModel.input.date_of_certificate
              )
            }
          });

          Object.keys(item).forEach(key => {
            if (key.startsWith('subject_id_')) {
              const subjectId = +key.replace('subject_id_', '');
              if (!item[`certificate_subject_id_${subjectId}`]) {
                newCertificateSubjects.push({
                  certificate_id: item.certificate_id,
                  subject_id: subjectId,
                  grade: item[key] ? String(item[key]) : null
                });
              }
            }
          });

          Object.keys(item).forEach(key => {
            if (key.startsWith('certificate_subject_id_')) {
              updateCertificateSubjects.push({
                where: {
                  id: { _eq: item[key] }
                },
                _set: {
                  grade: item[`subject_id_${key.replace('certificate_subject_id_', '')}`]
                    ? String(item[`subject_id_${key.replace('certificate_subject_id_', '')}`])
                    : null
                }
              });
            }
          });
        } else {
          const options: any[] = [];
          this.readOnlyModel.course?.certificate_templates?.forEach((certificate_template: any) => {
            certificate_template?.options?.forEach((option: any, index: number) => {
              if (!option.is_title) {
                options.push({
                  name: option.name,
                  grade: item[`option_idx_${index}`] ? String(item[`option_idx_${index}`]) : null
                });
              }
            });
          });

          // Insert
          inserts.push({
            ...input,
            date_of_certificate: FormlyUtil.toIsoDateString(
              formSaveModel.input.date_of_certificate
            ),
            person_id: item.person_id,
            options,
            certificate_subjects: {
              data: Object.keys(item)
                .map(key => {
                  if (key.startsWith('subject_id_')) {
                    return {
                      subject_id: +key.replace('subject_id_', ''),
                      grade: item[key] ? String(item[key]) : null
                    };
                  }
                  return undefined;
                })
                .filter(value => value)
            }
          });
        }
      });

      if (inserts.length) {
        params.push('$inserts: [academy_certificate_insert_input!]= []');
        queries.push(`
         inserts: insert_academy_certificate(objects: $inserts) {
            __typename
         }
        `);
        variables = {
          ...variables,
          inserts
        };
      }

      if (updates.length) {
        params.push('$updates: [academy_certificate_updates!]= []');
        queries.push(`
         updates: update_academy_certificate_many(updates: $updates) {
            __typename
         }
        `);
        variables = {
          ...variables,
          updates
        };
      }

      if (newCertificateSubjects.length) {
        params.push('$newCertificateSubjects: [academy_certificate_subject_insert_input!] = []');
        queries.push(`
            insert_academy_certificate_subject(objects: $newCertificateSubjects) {
              __typename
            }
          `);
        variables = {
          ...variables,
          newCertificateSubjects
        };
      }

      if (updateCertificateSubjects.length) {
        params.push('$updateCertificateSubjects: [academy_certificate_subject_updates!] = []');
        queries.push(`
            update_academy_certificate_subject_many(updates: $updateCertificateSubjects) {
              __typename
            }
          `);
        variables = {
          ...variables,
          updateCertificateSubjects
        };
      }

      return this.apollo.mutate({
        mutation: gql`
            mutation CreateUpdateAcademyCourseCertificate(
              ${params.join('\n')}
            ) {
             ${queries.join('\n')}
            }
          `,
        variables
      });
    };

    this.schoolPeriodService.getPeriods().subscribe();
  }
}
