import { Component, computed, effect, inject, input, ViewChild } from '@angular/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslocoDirective } from '@jsverse/transloco';
import { TooltipModule } from 'primeng/tooltip';
import { MenuModule } from 'primeng/menu';
import { ButtonDirective } from 'primeng/button';
import { AccountingPeriodModel } from '~ngx-shared/models';
import { TemplateDirective } from '~ngx-shared/directives';
import {
  DataProviderOptionModel,
  GraphQlAdvancedTableComponent,
  GraphQlColumnModel,
  GraphQlTableModel
} from '~ngx-shared/graph-ql';
import { AccountingPeriodService } from '~madrasa/accounting/services/accounting-period.service';
import { Role } from '~ngx-shared/authentication';

@Component({
  selector: 'app-accounting-period-list',
  standalone: true,
  imports: [
    GraphQlAdvancedTableComponent,
    NgxPermissionsModule,
    TemplateDirective,
    TranslocoDirective,
    TooltipModule,
    MenuModule,
    ButtonDirective
  ],
  templateUrl: './accounting-period-list.component.html',
  styleUrl: './accounting-period-list.component.scss'
})
export class AccountingPeriodListComponent {
  readonly accountingPeriodService = inject(AccountingPeriodService);

  showExport = input<boolean>(false);
  showColumnFilter = input<boolean>(true);

  stateKey = input<string>();
  patchOptions = input<(options: DataProviderOptionModel) => DataProviderOptionModel>();
  columns = input<GraphQlColumnModel[]>([]);

  graphQlTable = computed(() => {
    let columns = this.columns();

    columns.unshift(...this.getDefaultColumns());

    const graphQlTable: GraphQlTableModel = {
      table: 'accounting_accounting_period',
      isPaginated: true,

      showCurrentPageReport: true,
      columns
    };
    return graphQlTable;
  });

  @ViewChild('advancedTable') tableComponent: GraphQlAdvancedTableComponent;

  readonly year = computed(() => {
    // Get the current year
    return new Date().getFullYear();
  });

  graphQlPatchOptions: (options: DataProviderOptionModel) => DataProviderOptionModel;
  protected readonly Role = Role;

  constructor() {
    effect(() => {
      this.graphQlPatchOptions = options => {
        const patchOptions = this.patchOptions();
        if (patchOptions) {
          options = patchOptions(options);
        }

        // Add default sorting by updated_at
        if (!options.sortBy?.length) {
          options.sortBy = [{ id: 'desc_nulls_last' }];
        }

        return options;
      };
    });

    this.accountingPeriodService.getPeriods().subscribe();
  }

  getDefaultColumns(): GraphQlColumnModel[] {
    const columns: GraphQlColumnModel[] = [
      {
        label: 'year',
        path: 'id',
        type: 'number',
        classHeader: 'text-center',
        classBody: 'text-center',
        sort: { isSortable: true },
        filter: { type: 'number' }
      },
      {
        path: 'is_active',
        type: 'boolean',
        classHeader: 'text-center',
        classBody: 'text-center',
        filter: { type: 'boolean' },
        sort: { isSortable: true }
      },
      {
        path: 'is_archived',
        type: 'boolean',
        classHeader: 'text-center',
        classBody: 'text-center',
        filter: { type: 'boolean' },
        sort: { isSortable: true }
      },
      {
        path: 'created_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true }
      },
      {
        path: 'updated_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true },
        hidden: true
      }
    ];

    return columns;
  }

  getRow(data: any): AccountingPeriodModel | undefined {
    return data;
  }

  setCurrentPeriod(data: any) {
    const period = this.getRow(data);
    if (period) {
      this.accountingPeriodService.setCurrentPeriod(period);
    }
  }

  createNewPeriod() {
    this.accountingPeriodService.createNewPeriod().subscribe();
  }
}
