<lib-layout-base-page
  *transloco="let transloco"
  [titleAddon]="this.accountingPeriodService.currentPeriod()?.id"
  [titleParamValue]="this.item()?.name">
  <a
    [label]="transloco('edit')"
    [routerLink]="['/', 'accounting', 'account-plan', 'accounts', this.item()?.id]"
    action
    class="p-button-warning text-white"
    icon="pi pi-pencil"
    pButton
    type="button"></a>

  <div class="flex flex-col">
    <p-tabView libTabViewQuery="t">
      <p-tabPanel header="{{ transloco('general_information') }}">
        <ng-template pTemplate="content">
          <div class="flex flex-col gap-6">
            <lib-detail [fields]="this.fields()"></lib-detail>

            <lib-busy [isLoading]="!this.accountingPeriodService.currentPeriod()?.id">
              <app-entry-list
                [patchOptions]="this.studentPatchOptions"
                [accountPeriodId]="this.accountingPeriodService.currentPeriod()?.id!"
                [showExport]="true"
                [showFilter]="true"
                [showSort]="true"
                [stateKey]="'account-detail-entry-list'"></app-entry-list>
            </lib-busy>
          </div>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </div>
</lib-layout-base-page>
