import { Component, computed, effect, inject, input, ViewChild } from '@angular/core';
import { ButtonDirective } from 'primeng/button';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { TooltipModule } from 'primeng/tooltip';
import { MenuModule } from 'primeng/menu';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs';
import { CurrencyPipe } from '@angular/common';
import {
  DataProviderOptionModel,
  GraphQlAdvancedFilterComponent,
  GraphQlAdvancedSortComponent,
  GraphQlAdvancedTableComponent,
  GraphQlColumnModel,
  GraphQlTableModel
} from '~ngx-shared/graph-ql';
import { PersonDetailLinkComponent } from '~madrasa/staff/components/person-detail-link/person-detail-link.component';
import { TemplateDirective } from '~ngx-shared/directives';
import { ConfirmationService } from '~ngx-shared/layout';
import { AuthorizationService } from '~ngx-shared/authentication';
import {
  AcademyOrganisationModel,
  AcademySchoolModel,
  AccountingStudentBalanceModel,
  AccountingStudentBalancePermission
} from '~ngx-shared/models';
import { ModelUtil } from '~ngx-shared/utils';
import { FormlyUtil } from '~ngx-shared/formly';
import { DatePipe } from '~ngx-shared/pipes';

@Component({
  selector: 'app-student-balance-list',
  standalone: true,
  imports: [
    GraphQlAdvancedFilterComponent,
    GraphQlAdvancedSortComponent,
    GraphQlAdvancedTableComponent,
    NgxPermissionsModule,
    TemplateDirective,
    TranslocoDirective,
    TooltipModule,
    MenuModule,
    ButtonDirective,
    PersonDetailLinkComponent
  ],
  templateUrl: './student-balance-list.component.html',
  styleUrl: './student-balance-list.component.scss',
  providers: [CurrencyPipe, DatePipe]
})
export class StudentBalanceListComponent {
  readonly apollo = inject(Apollo);
  readonly confirmationService = inject(ConfirmationService);
  readonly authorizationService = inject(AuthorizationService);
  readonly translocoService = inject(TranslocoService);
  readonly currencyPipe = inject(CurrencyPipe);
  readonly datePipe = inject(DatePipe);

  showFilter = input<boolean>(false);
  showSort = input<boolean>(false);
  showExport = input<boolean>(false);
  showColumnFilter = input<boolean>(true);
  showCreateButton = input<boolean>(false);

  stateKey = input<string>();
  patchOptions = input<(options: DataProviderOptionModel) => DataProviderOptionModel>();
  columns = input<GraphQlColumnModel[]>([]);
  personId = input<number>();

  graphQlTable = computed(() => {
    let columns = this.columns();

    columns.unshift(...this.getDefaultColumns());

    const graphQlTable: GraphQlTableModel = {
      table: 'accounting_student_balance',
      isPaginated: true,

      showCurrentPageReport: true,
      columns
    };
    return graphQlTable;
  });
  @ViewChild('advancedTable') tableComponent: GraphQlAdvancedTableComponent;

  graphQlPatchOptions: (options: DataProviderOptionModel) => DataProviderOptionModel;
  protected readonly AccountingStudentBalancePermission = AccountingStudentBalancePermission;

  constructor() {
    effect(() => {
      this.graphQlPatchOptions = options => {
        const personId = this.personId();
        const patchOptions = this.patchOptions();
        if (patchOptions) {
          options = patchOptions(options);
        }

        if (!options.filter) {
          options.filter = {};
        }
        if (!options.filter._and) {
          options.filter._and = [];
        }

        if (personId && Array.isArray(options?.filter?._and)) {
          options.filter._and = options.filter._and.filter((cond: any) => !cond.person_id);
          options.filter._and.push({
            person_id: {
              _eq: personId
            }
          });
        }

        // Add default sorting by updated_at
        if (!options.sortBy?.length) {
          options.sortBy = [{ updated_at: 'desc_nulls_last' }];
        }

        return options;
      };
    });
  }

  getDefaultColumns(): GraphQlColumnModel[] {
    const columns: GraphQlColumnModel[] = [
      {
        path: 'id',
        classHeader: 'text-center',
        classBody: 'text-center',
        type: 'number',
        filter: { type: 'number' },
        sort: { isSortable: true }
      },
      {
        label: 'organisations',
        path: 'current_person_data.organisation_persons_active.organisation.name',
        filter: {
          label: 'organisation',
          path: 'organisation_persons_active.organisation_id',
          type: 'array',
          options: this.apollo
            .query<{
              result: AcademyOrganisationModel[];
            }>({
              query: gql`
                query ReadAcademyOrganisation($where: academy_organisation_bool_exp) {
                  result: academy_organisation(where: $where) {
                    id
                    name
                  }
                }
              `,
              variables: {
                where: {}
              }
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(organisation => ({
                  label: organisation.name,
                  value: organisation.id
                }))
              )
            )
        },
        sort: { isSortable: false },
        hidden: true
      },
      {
        label: 'schools',
        path: 'current_person_data.school_students_active.school.name',
        filter: {
          label: 'school',
          path: 'school_students_active.school_id',
          type: 'array',
          options: this.apollo
            .query<{
              result: AcademySchoolModel[];
            }>({
              query: gql`
                query ReadAcademySchool($where: academy_school_bool_exp) {
                  result: academy_school(where: $where) {
                    id
                    name
                  }
                }
              `,
              variables: {
                where: {}
              }
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(school => ({
                  label: school.name,
                  value: school.id
                }))
              )
            )
        },
        sort: { isSortable: false },
        hidden: true
      },
      {
        label: 'student',
        path: 'current_person_data',
        query: `
            current_person_data {
              person_id
              academic_degree_prefix
              academic_degree_suffix
              first_name
              last_name
          }
        `,
        patchResult: value =>
          ModelUtil.getFullName(value?.current_person_data, this.translocoService),
        filter: { isNotFilterable: true },
        sort: { isSortable: false }
      },
      {
        label: 'student_id',
        path: 'person_id',
        filter: { type: 'number' },
        sort: { isSortable: true },
        isNotSelectable: true
      },
      {
        label: 'for_month',
        path: 'month_of_balance',
        type: 'date',
        typeOptions: 'MM/yyyy',
        filter: { type: 'date' },
        sort: { isSortable: true }
      },
      {
        path: 'amount',
        type: 'currency',
        filter: { isNotFilterable: true },
        sort: { isSortable: true }
      },
      {
        path: 'description',
        filter: { type: 'string' },
        sort: { isSortable: true }
      },
      {
        label: 'created_by',
        path: 'created_by_current_person_data',
        query: `
            created_by_current_person_data {
              person_id
              academic_degree_prefix
              academic_degree_suffix
              first_name
              last_name
            }
        `,
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        isNotExportable: true
      },
      {
        path: 'entry_id',
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        isNotExportable: true,
        isNotSelectable: true
      },
      {
        path: 'cash_book_entry_id',
        filter: { isNotFilterable: true },
        sort: { isSortable: false },
        isNotExportable: true,
        isNotSelectable: true
      },
      {
        path: 'created_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true }
      },
      {
        path: 'updated_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true },
        hidden: true
      }
    ];

    return columns;
  }

  getRow(data: any): AccountingStudentBalanceModel | undefined {
    return data;
  }

  delete(data: any) {
    const row = this.getRow(data);
    if (row) {
      let mutation: any = gql`
        mutation SoftDeleteAccountingStudentBalanceById($id: bigint!) {
          result: update_accounting_student_balance_by_pk(
            pk_columns: { id: $id }
            _set: { deleted_at: "now()" }
          ) {
            __typename
          }
        }
      `;

      this.confirmationService.confirmDeleteApollo({
        name: `${row.id} - ${(row.amount || 0) / 100}€`,
        mutationOptions: {
          mutation,
          variables: {
            id: row.id
          }
        },
        success: () => this.tableComponent?.updateTable()
      });
    }
  }

  invert(data: any) {
    const row = this.getRow(data);
    if (row?.amount && row?.month_of_balance && row?.person_id) {
      const message =
        this.translocoService.translate(
          'madrasa.forms.create_update_student_balance.confirmation_invert_start'
        ) +
        ': ' +
        this.currencyPipe.transform(-(row.amount / 100) || 0) +
        ' ' +
        this.translocoService.translate(
          'madrasa.forms.create_update_student_balance.confirmation_invert_middle'
        ) +
        ' ' +
        this.datePipe.transform(row.month_of_balance, 'MM/yyyy') +
        ' ' +
        this.translocoService.translate(
          'madrasa.forms.create_update_student_balance.confirmation_invert_end'
        );

      this.confirmationService.confirm(
        () => {
          this.apollo
            .mutate({
              mutation: gql`
                mutation CreateUpdateAccountingStudentBalance(
                  $input: accounting_student_balance_insert_input!
                ) {
                  result: insert_accounting_student_balance_one(object: $input) {
                    __typename
                  }
                }
              `,
              variables: {
                input: {
                  month_of_balance: FormlyUtil.toIsoDateString(row.month_of_balance),
                  person_id: row.person_id,
                  amount: -(row.amount || 0)
                }
              }
            })
            .subscribe(() => this.tableComponent?.updateTable());
        },
        undefined,
        message
      );
    }
  }
}
