import { Injectable } from '@angular/core';
import { select, setProp, setProps } from '@ngneat/elf';
import { Apollo, gql } from 'apollo-angular';
import { Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { StoreService } from '~ngx-shared/services/store.service';
import { CoreTenantModel } from '~ngx-shared/models';
import { AuthenticationService, AuthorizationService, Role } from '~ngx-shared/authentication';

type TenantStoreModel = {
  currentTenant?: CoreTenantModel;
  tenants?: CoreTenantModel[];
  date?: Date;
};

@Injectable({ providedIn: 'root' })
export class TenantService {
  tenantStore = this.storeService.createStore<TenantStoreModel>({
    name: 'tenant',
    initial: undefined,
    persist: { storage: 'local' }
  });

  currentTenant$ = this.tenantStore.pipe(select(state => state.currentTenant));
  tenants$ = this.tenantStore.pipe(select(state => state.tenants));
  canCrudTeacher$ = this.currentTenant$.pipe(map(() => this.canCrudTeacher));
  canCrudDirector$ = this.currentTenant$.pipe(map(() => this.canCrudDirector));
  canCrudCoursePrice$ = this.currentTenant$.pipe(map(() => this.canCrudCoursePrice));

  constructor(
    private apollo: Apollo,
    private storeService: StoreService,
    private authorizationService: AuthorizationService,
    private authenticationService: AuthenticationService
  ) {}

  get currentTenant(): CoreTenantModel | undefined {
    return this.tenantStore.value?.currentTenant;
  }

  get canCrudTeacher(): boolean | undefined {
    return (
      (!this.authorizationService.can(Role.DIRECTOR) ||
        this.tenantStore.value?.currentTenant?.can_director_crud_teacher) &&
      (!this.authorizationService.can(Role.ORGANISATOR) ||
        this.tenantStore.value?.currentTenant?.can_organisator_crud_teacher)
    );
  }

  get canCrudDirector(): boolean | undefined {
    return (
      !this.authorizationService.can(Role.ORGANISATOR) ||
      this.tenantStore.value?.currentTenant?.can_organisator_crud_director
    );
  }

  get canCrudCoursePrice(): boolean | undefined {
    return (
      (!this.authorizationService.can(Role.DIRECTOR) ||
        this.tenantStore.value?.currentTenant?.can_director_crud_course_price) &&
      (!this.authorizationService.can(Role.ORGANISATOR) ||
        this.tenantStore.value?.currentTenant?.can_organisator_crud_course_price)
    );
  }

  getTenants(fromCache: boolean = true): Observable<CoreTenantModel[] | undefined> {
    // Check for date and tenants in store
    // If date is longer than 1 Day, fetch tenants, else return from store
    if (fromCache && this.tenantStore.value?.tenants?.length) {
      let date = this.tenantStore.value?.date;
      if (date) {
        date = new Date(date);
        const now = new Date();
        const diff = now.getTime() - date.getTime();
        if (diff < 24 * 60 * 60 * 1000) {
          // Check if there are valid tenants with ids
          if (
            this.tenantStore.value?.tenants?.some(tenant => tenant?.id) &&
            this.tenantStore.value?.currentTenant?.id
          ) {
            return this.tenants$;
          }
        }
      }
    }
    return this.apollo
      .query<{ result: CoreTenantModel[] }>({
        query: gql`
          query ReadCoreTenantsOrderedById {
            result: core_tenant(order_by: { id: desc }) {
              id
              created_at
              updated_at
              name
              is_active
              are_course_names_forced
              organisations_allowed_count
              schools_allowed_count
              can_director_crud_teacher
              can_director_crud_course_price
              can_organisator_crud_teacher
              can_organisator_crud_course_price
              can_organisator_crud_director
            }
          }
        `
      })
      .pipe(
        map(queryResult => queryResult.data?.result),
        tap(tenants => {
          let currentTenant = tenants && tenants.length ? tenants[0] : undefined;

          const claims = this.authenticationService.claims();
          if (claims && claims['tenantId']) {
            const tenant = tenants?.find(
              (tenant: CoreTenantModel) => tenant.id == claims['tenantId']
            );
            if (tenant) {
              currentTenant = tenant;
            }
          }

          this.tenantStore.update(
            setProps({
              tenants,
              currentTenant,
              date: new Date()
            })
          );
        })
      );
  }

  setCurrentTenant(currentTenant: CoreTenantModel): void {
    const selectedTenant = this.tenantStore.value.tenants?.find(
      (tenant: CoreTenantModel) => tenant.id === currentTenant.id
    );
    if (selectedTenant) {
      this.tenantStore.update(setProp('currentTenant', selectedTenant));
    }
  }

  clearTenants(): void {
    this.tenantStore.update(
      setProps({ tenants: undefined, currentTenant: undefined, date: undefined })
    );
  }
}
