import { Component, effect, inject, input, OnInit, ViewChild } from '@angular/core';
import { ButtonDirective } from 'primeng/button';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { Router, RouterLink } from '@angular/router';
import { TooltipModule } from 'primeng/tooltip';
import { Apollo, gql } from 'apollo-angular';
import { ConfirmationService } from '~ngx-shared/layout';
import { TemplateDirective } from '~ngx-shared/directives';
import {
  DataProviderOptionModel,
  GraphQlAdvancedFilterComponent,
  GraphQlAdvancedSortComponent,
  GraphQlAdvancedTableComponent,
  GraphQlColumnModel,
  GraphQlTableModel
} from '~ngx-shared/graph-ql';
import { AccountingTravelCostModel, FileTravelCostDocumentModel } from '~ngx-shared/models';
import { ModelUtil } from '~ngx-shared/utils';
import { PersonDetailLinkComponent } from '~madrasa/staff/components/person-detail-link/person-detail-link.component';
import { FileService } from '~madrasa/services';

@Component({
  selector: 'app-travel-cost-list',
  standalone: true,
  imports: [
    ButtonDirective,
    GraphQlAdvancedFilterComponent,
    GraphQlAdvancedSortComponent,
    GraphQlAdvancedTableComponent,
    TemplateDirective,
    TranslocoDirective,
    RouterLink,
    TooltipModule,
    PersonDetailLinkComponent
  ],
  templateUrl: './travel-cost-list.component.html',
  styleUrl: './travel-cost-list.component.scss'
})
export class TravelCostListComponent implements OnInit {
  readonly apollo = inject(Apollo);
  readonly router = inject(Router);
  readonly fileService = inject(FileService);
  readonly translocoService = inject(TranslocoService);
  readonly confirmationService = inject(ConfirmationService);

  showFilter = input<boolean>(true);
  showSort = input<boolean>(true);
  showExport = input<boolean>(true);
  showDocuments = input<boolean>(true);
  showCreateButton = input<boolean>(true);
  showActions = input<boolean>(true);
  showColumnFilter = input<boolean>(true);
  patchOptions = input<(options: DataProviderOptionModel) => DataProviderOptionModel>();
  stateKey = input<string>();
  accountingPeriodId = input<number>();

  @ViewChild('advancedTable') tableComponent: GraphQlAdvancedTableComponent;

  graphQlPatchOptions: (options: DataProviderOptionModel) => DataProviderOptionModel;
  graphQlTable: GraphQlTableModel;

  constructor() {
    effect(() => {
      this.graphQlPatchOptions = options => {
        const accountingPeriodId = this.accountingPeriodId();
        const patchOptions = this.patchOptions();
        if (patchOptions) {
          options = patchOptions(options);
        }

        if (!options.filter) {
          options.filter = {};
        }
        if (!options.filter._and) {
          options.filter._and = [];
        }

        if (accountingPeriodId && Array.isArray(options?.filter?._and)) {
          options.filter._and = options.filter._and.filter(
            (cond: any) => !cond.accounting_period_id
          );
          options.filter._and.push({
            accounting_period_id: {
              _eq: accountingPeriodId
            }
          });
        }

        // Add default sorting by updated_at
        if (!options.sortBy?.length) {
          options.sortBy = [{ updated_at: 'desc_nulls_last' }];
        }

        return options;
      };
    });
  }

  ngOnInit() {
    const columns: GraphQlColumnModel[] = [
      {
        path: 'id',
        type: 'number',
        classHeader: 'text-center',
        classBody: 'text-center',
        sort: { isSortable: true },
        filter: { type: 'number' }
      },
      {
        label: 'accounting_period',
        path: 'accounting_period_id',
        type: 'number',
        classHeader: 'text-center',
        classBody: 'text-center',
        sort: { isSortable: true },
        filter: { type: 'number' }
      },
      {
        label: 'for_month',
        path: 'month_of_cost',
        type: 'date',
        typeOptions: 'MM/yyyy',
        filter: { type: 'date' },
        sort: { isSortable: true }
      },
      {
        label: 'teacher',
        path: 'current_person_data',
        query: `
            current_person_data {
              person_id
              academic_degree_prefix
              academic_degree_suffix
              first_name
              last_name
          }
        `,
        patchResult: value =>
          ModelUtil.getFullName(value?.current_person_data, this.translocoService),
        filter: { isNotFilterable: true },
        sort: { isSortable: false }
      },
      {
        label: 'travel_cost_price',
        path: 'travel_cost_price.name',
        filter: { type: 'string' },
        sort: { isSortable: true }
      },
      {
        path: 'kilometer',
        type: 'number',
        filter: { type: 'number' },
        sort: { isSortable: true }
      },
      {
        path: 'ticket_cost',
        type: 'currency',
        filter: { isNotFilterable: true },
        sort: { isSortable: true }
      },
      {
        path: 'calculated_cost',
        type: 'currency',
        filter: { isNotFilterable: true },
        sort: { isSortable: true }
      },

      ...(this.showDocuments()
        ? [
            {
              label: 'documents',
              path: 'travel_cost_documents',
              query: `
                    travel_cost_documents {
                      id
                      name
                    }`,
              filter: { isNotFilterable: true },
              sort: { isSortable: false }
            }
          ]
        : []),
      {
        path: 'created_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'updated_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true },
        hidden: true
      }
    ];

    this.graphQlTable = {
      table: 'accounting_travel_cost',
      isPaginated: true,

      showCurrentPageReport: true,
      columns: columns
    };
  }

  getRow(data: any): AccountingTravelCostModel | undefined {
    return data;
  }

  delete(data: any) {
    const row = this.getRow(data);
    if (row) {
      let mutation: any = gql`
        mutation SoftDeleteAccountingTravelCostById($id: bigint!) {
          result: update_accounting_travel_cost_by_pk(
            pk_columns: { id: $id }
            _set: { deleted_at: "now()" }
          ) {
            __typename
          }
        }
      `;

      this.confirmationService.confirmDeleteApollo({
        name: String(row?.id),
        mutationOptions: {
          mutation,
          variables: {
            id: row.id
          }
        },
        success: () => this.tableComponent?.updateTable()
      });
    }
  }

  downloadFile(data: FileTravelCostDocumentModel, show: boolean = false) {
    if (data) {
      this.fileService.downloadFile(data.id, 'travel_cost_document', show ? undefined : data.name);
    }
  }
}
