import { Component, computed, effect, inject, input, signal } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { AccordionModule } from 'primeng/accordion';
import { TranslocoDirective } from '@jsverse/transloco';
import { Router } from '@angular/router';
import { TagModule } from 'primeng/tag';
import { NgxPermissionsModule } from 'ngx-permissions';
import { BusyComponent } from '~ngx-shared/layout';
import { DetailComponent, DetailField } from '~ngx-shared/ui/detail/detail.component';
import { CorePersonDataModel, StaffStudentModel, StaffTeacherPermission } from '~ngx-shared/models';
import { CourseListComponent } from '~madrasa/academy/components/course-list/course-list.component';
import { UnitListComponent } from '~madrasa/academy/components/unit-list/unit-list.component';
import { ClassBookEntryListComponent } from '~madrasa/academy/components/class-book-entry-list/class-book-entry-list.component';
import { DataProviderOptionModel, GraphQlColumnModel } from '~ngx-shared/graph-ql';
import { TemplateDirective } from '~ngx-shared/directives';
import { OrganisationTeacherSalaryListComponent } from '~madrasa/academy/components/organisation-teacher-salary-list/organisation-teacher-salary-list.component';
import { SchoolTeacherSalaryListComponent } from '~madrasa/academy/components/school-teacher-salary-list/school-teacher-salary-list.component';
import { NoteListComponent } from '~madrasa/note/components/note-list/note-list.component';
import { FormSaveModel } from '~ngx-shared/formly';
import { NotePersonNoteTypeEnum } from '~ngx-shared/models/note';
import { Role } from '~ngx-shared/authentication';
import { TeacherWageListComponent } from '~madrasa/accounting/components/teacher-wage-list/teacher-wage-list.component';
import { TravelCostListComponent } from '~madrasa/accounting/components/travel-cost-list/travel-cost-list.component';

@Component({
  selector: 'app-teacher-detail',
  standalone: true,
  imports: [
    BusyComponent,
    DetailComponent,
    AccordionModule,
    TranslocoDirective,
    CourseListComponent,
    UnitListComponent,
    ClassBookEntryListComponent,
    TagModule,
    TemplateDirective,
    OrganisationTeacherSalaryListComponent,
    SchoolTeacherSalaryListComponent,
    NoteListComponent,
    NgxPermissionsModule,
    TeacherWageListComponent,
    TravelCostListComponent
  ],
  templateUrl: './teacher-detail.component.html',
  styleUrl: './teacher-detail.component.scss'
})
export class TeacherDetailComponent {
  readonly apollo = inject(Apollo);
  readonly router = inject(Router);

  readonly inputPersonData = input<CorePersonDataModel | undefined>(undefined, {
    alias: 'personData'
  });
  readonly isLoading = signal(true);
  readonly personData = signal<CorePersonDataModel | undefined>(undefined);

  readonly fields = computed<DetailField[]>(() => {
    const item = this.personData();
    if (!item?.latest_teacher) {
      return [];
    }
    return [
      {
        label: 'updated_at',
        type: 'datetime',
        value: item.updated_at
      },
      {
        label: 'organisation',
        value: item.organisation_persons_active?.map(opa => opa.organisation?.name).join(', ')
      },
      {
        label: 'school',
        value: item.latest_teacher.school_teachers_active?.map(sta => sta.school?.name).join(', ')
      },
      {
        label: 'status',
        translate: true,
        value: 'status.' + item.latest_teacher.status
      },
      {
        label: 'start_of_work',
        type: 'date',
        value: item.latest_teacher.start_of_work
      },
      {
        label: 'initial_worked_months',
        value: item.latest_teacher.initial_worked_months
      },
      {
        label: 'worked_months',
        value: item.latest_teacher.worked_months
      }
    ];
  });

  readonly teacherSalaryFields = computed<DetailField[]>(() => {
    const item = this.personData();
    if (!item?.latest_teacher) {
      return [];
    }
    return [
      {
        label: 'teacher_hourly_rate',
        type: 'string',
        value: item.latest_teacher.teacher_hourly_rate?.name
      },
      {
        label: 'hourly_rate_value',
        type: 'currency',
        value: item.latest_teacher.hourly_rate_value
      },
      {
        label: 'monthly_rate',
        type: 'currency',
        value: item.latest_teacher.monthly_rate
      }
    ];
  });

  readonly cbeColumns = computed<GraphQlColumnModel[]>(() => {
    const personId = this.inputPersonData()?.person_id;
    return [
      {
        label: 'present',
        path: 'class_book_entry_teachers.present',
        query: `
          class_book_entry_teachers (limit: 1, where: { person_id: { _eq: ${personId || 0} } }) {
            present
          }
        `,
        patchResult: value =>
          value?.class_book_entry_teachers.map((cbeTeacher: any) => cbeTeacher.present).join(', '),
        filter: { isNotFilterable: true }
      }
    ];
  });

  schoolTeacherSalaryPatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  organisationTeacherSalaryPatchOptions?: (
    options: DataProviderOptionModel
  ) => DataProviderOptionModel;
  coursePatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  unitPatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  cbePatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  teacherWagePatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  travelCostPatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;

  notePatchOptions?: (options: DataProviderOptionModel) => DataProviderOptionModel;
  notePatchInput: (formSaveModel: FormSaveModel, input: any) => any;
  protected readonly StaffTeacherPermission = StaffTeacherPermission;
  protected readonly Role = Role;

  constructor() {
    effect(
      () => {
        const personId = this.inputPersonData()?.person_id;
        if (personId) {
          this.isLoading.set(true);
          this.apollo
            .query<{ result: StaffStudentModel[] }>({
              query: gql`
                query ReadStaffTeacherById($personId: bigint!) {
                  result: staff_latest_teacher(where: { person_id: { _eq: $personId } }, limit: 1) {
                    id
                    person_id
                    updated_at
                    hourly_rate
                    monthly_rate
                    start_of_work
                    status
                    school_teachers_active {
                      school {
                        name
                      }
                    }
                    initial_worked_months
                    teacher_hourly_rate {
                      name
                    }
                    hourly_rate_value
                    worked_months
                  }
                }
              `,
              variables: {
                personId: personId
              }
            })
            .pipe(map(queryResult => queryResult.data?.result?.[0]))
            .subscribe(staffTeacher => {
              const personData = cloneDeep(this.inputPersonData());
              if (personData) {
                personData.latest_teacher = staffTeacher;
                this.personData.set(personData);
              }
              this.isLoading.set(false);
            });

          this.schoolTeacherSalaryPatchOptions = options => {
            options.filter = {
              ...options.filter,
              _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
            };
            if (Array.isArray(options?.filter?._and)) {
              options.filter._and = options.filter._and.filter((cond: any) => !cond.person_id);
              options.filter._and.push({ person_id: { _eq: personId } });
            }
            return options;
          };

          this.organisationTeacherSalaryPatchOptions = options => {
            options.filter = {
              ...options.filter,
              _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
            };
            if (Array.isArray(options?.filter?._and)) {
              options.filter._and = options.filter._and.filter((cond: any) => !cond.person_id);
              options.filter._and.push({ person_id: { _eq: personId } });
            }
            return options;
          };

          this.coursePatchOptions = options => {
            options.filter = {
              ...options.filter,
              _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
            };
            if (Array.isArray(options?.filter?._and)) {
              options.filter._and = options.filter._and.filter(
                (cond: any) => !cond.course_teachers
              );
              options.filter._and.push({
                course_teachers: {
                  person_id: {
                    _eq: personId
                  }
                }
              });
            }
            return options;
          };

          this.unitPatchOptions = options => {
            options.filter = {
              ...options.filter,
              _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
            };
            if (Array.isArray(options?.filter?._and)) {
              options.filter._and = options.filter._and.filter((cond: any) => !cond.unit_teachers);
              options.filter._and.push({
                unit_teachers: {
                  person_id: {
                    _eq: personId
                  }
                }
              });
            }

            if (!options.sortBy?.length) {
              options.sortBy = [{ weekday: 'asc_nulls_last' }, { starts_at: 'asc_nulls_last' }];
            }
            return options;
          };

          this.cbePatchOptions = options => {
            options.filter = {
              ...options.filter,
              _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
            };
            if (Array.isArray(options?.filter?._and)) {
              options.filter._and = options.filter._and.filter(
                (cond: any) => !cond.class_book_entry_teachers
              );
              options.filter._and.push({
                class_book_entry_teachers: {
                  person_id: {
                    _eq: personId
                  }
                }
              });
            }
            return options;
          };

          this.teacherWagePatchOptions = options => {
            options.filter = {
              ...options.filter,
              _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
            };
            if (Array.isArray(options?.filter?._and)) {
              options.filter._and = options.filter._and.filter((cond: any) => !cond.person_id);
              options.filter._and.push({
                person_id: {
                  _eq: personId
                }
              });
            }
            return options;
          };

          this.travelCostPatchOptions = options => {
            options.filter = {
              ...options.filter,
              _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
            };
            if (Array.isArray(options?.filter?._and)) {
              options.filter._and = options.filter._and.filter((cond: any) => !cond.person_id);
              options.filter._and.push({
                person_id: {
                  _eq: personId
                }
              });
            }
            return options;
          };

          this.notePatchOptions = options => {
            options.filter = {
              ...options.filter,
              _and: [...(Array.isArray(options?.filter?._and) ? options.filter._and : [])]
            };
            if (Array.isArray(options?.filter?._and)) {
              options.filter._and = options.filter._and.filter((cond: any) => !cond.person_id);
              options.filter._and.push({
                person_note_type: {
                  _eq: NotePersonNoteTypeEnum.TEACHER
                },
                person_id: {
                  _eq: personId
                }
              });
            }
            return options;
          };

          this.notePatchInput = (formSaveModel, input) => {
            return {
              ...input,
              person_id: personId,
              person_note_type: NotePersonNoteTypeEnum.TEACHER
            };
          };
        }
      },
      { allowSignalWrites: true }
    );
  }
}
