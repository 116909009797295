import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { LoggerService } from '~ngx-shared/services';

const RETRY_COUNT = 3;
const RETRY_DELAY = 2000;

@Injectable()
export class GlobalInterceptor implements HttpInterceptor {
  constructor(private loggerService: LoggerService) {}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(httpRequest)
      .pipe(
        retry({ count: RETRY_COUNT, delay: RETRY_DELAY }),
        catchError(this.handleError.bind(this))
      );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    const errorMsg = this.formatErrorMessage(error);
    const stacktrace = error.error instanceof Error ? error.error.stack : error.message;
    this.loggerService.error(errorMsg, stacktrace);
    console.error(errorMsg);
    return throwError(() => new Error(errorMsg));
  }

  private formatErrorMessage(error: HttpErrorResponse): string {
    const timestamp = new Date().toISOString();
    let errorMsg = `Timestamp: ${timestamp}\n`;

    if (error.status === 0) {
      errorMsg += 'Error: Der Server ist derzeit nicht erreichbar!';
    } else if (error.error instanceof ErrorEvent) {
      errorMsg += `Client-side error: ${error.error.message}`;
    } else {
      errorMsg += `Server-side error: Code ${error.status}, Message: ${error.message}`;
    }

    if (error.url) {
      errorMsg += `\nURL: ${error.url}`;
    }

    if (error.statusText) {
      errorMsg += `\nStatus Text: ${error.statusText}`;
    }

    if (error.headers) {
      errorMsg += `\nHeaders: ${JSON.stringify(error.headers.keys().map(key => ({ [key]: error.headers.get(key) })))}`;
    }

    // Add url
    errorMsg += `\nURL: ${error.url}`;

    return errorMsg;
  }
}
