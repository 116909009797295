import { Component, computed, effect, inject, input, ViewChild } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { TranslocoDirective } from '@jsverse/transloco';
import { TooltipModule } from 'primeng/tooltip';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ConfirmationService } from '~ngx-shared/layout';
import {
  DataProviderOptionModel,
  GraphQlAdvancedFilterComponent,
  GraphQlAdvancedSortComponent,
  GraphQlAdvancedTableComponent,
  GraphQlColumnModel,
  GraphQlTableModel
} from '~ngx-shared/graph-ql';
import {
  AccountingAccountCategoryEnum,
  AccountingEntryRowModel,
  AccountingEntryRowTypeEnum
} from '~ngx-shared/models';

@Component({
  selector: 'app-entry-row-list',
  standalone: true,
  imports: [
    GraphQlAdvancedFilterComponent,
    GraphQlAdvancedSortComponent,
    GraphQlAdvancedTableComponent,
    TranslocoDirective,
    TooltipModule,
    NgxPermissionsModule
  ],
  templateUrl: './entry-row-list.component.html',
  styleUrl: './entry-row-list.component.scss'
})
export class EntryRowListComponent {
  readonly apollo = inject(Apollo);
  readonly confirmationService = inject(ConfirmationService);

  showFilter = input<boolean>(false);
  showSort = input<boolean>(false);
  showExport = input<boolean>(false);
  showColumnFilter = input<boolean>(true);
  showCreateButton = input<boolean>(false);

  stateKey = input<string>();
  patchOptions = input<(options: DataProviderOptionModel) => DataProviderOptionModel>();
  columns = input<GraphQlColumnModel[]>([]);
  entryId = input.required<number>();

  graphQlTable = computed(() => {
    let columns = this.columns();

    columns.unshift(...this.getDefaultColumns());

    const graphQlTable: GraphQlTableModel = {
      table: 'accounting_entry_row',
      isPaginated: true,

      showCurrentPageReport: true,
      columns
    };
    return graphQlTable;
  });
  @ViewChild('advancedTable') tableComponent: GraphQlAdvancedTableComponent;

  graphQlPatchOptions: (options: DataProviderOptionModel) => DataProviderOptionModel;

  constructor() {
    effect(() => {
      this.graphQlPatchOptions = options => {
        const entryId = this.entryId();
        const patchOptions = this.patchOptions();
        if (patchOptions) {
          options = patchOptions(options);
        }

        if (!options.filter) {
          options.filter = {};
        }
        if (!options.filter._and) {
          options.filter._and = [];
        }

        if (Array.isArray(options?.filter?._and)) {
          options.filter._and = options.filter._and.filter(
            (cond: any) => !cond.accounting_period_id
          );
          options.filter._and.push({
            entry_id: {
              _eq: entryId
            }
          });
        }

        // Add default sorting by updated_at
        if (!options.sortBy?.length) {
          options.sortBy = [{ type: 'asc_nulls_last' }];
        }

        return options;
      };
    });
  }

  getDefaultColumns(): GraphQlColumnModel[] {
    const columns: GraphQlColumnModel[] = [
      {
        path: 'id',
        type: 'number',
        classHeader: 'text-center',
        classBody: 'text-center',
        sort: {
          isSortable: true
        },
        filter: {
          type: 'number'
        }
      },
      {
        path: 'type',
        type: 'tag',
        classHeader: 'text-center',
        classBody: 'text-center',
        translate: true,
        patchResult: (result: any, column: GraphQlColumnModel) => {
          switch (result?.type) {
            case AccountingEntryRowTypeEnum.DEBIT:
              column.severity = 'success';
              break;
            case AccountingEntryRowTypeEnum.CREDIT:
              column.severity = 'danger';
              break;
          }
          return result?.type;
        },
        filter: {
          type: 'array',
          options: Object.values(AccountingEntryRowTypeEnum).map(value => ({
            label: value,
            value: value
          }))
        },
        sort: { isSortable: true }
      },

      {
        path: 'account.account_group.category',
        type: 'tag',
        classHeader: 'text-center',
        classBody: 'text-center',
        translate: true,
        prefix: 'account_category.',
        patchResult: (result: any, column: GraphQlColumnModel) => {
          switch (result?.account_group?.category) {
            case AccountingAccountCategoryEnum.ACTIVE:
              column.severity = 'success';
              break;
            case AccountingAccountCategoryEnum.PASSIVE:
              column.severity = 'danger';
              break;
            case AccountingAccountCategoryEnum.INCOME:
              column.severity = 'info';
              break;
            case AccountingAccountCategoryEnum.EXPENSE:
              column.severity = 'warning';
              break;
          }
          return 'account_category.' + result?.account?.account_group?.category;
        },
        filter: {
          type: 'array',
          options: Object.values(AccountingAccountCategoryEnum).map(value => ({
            label: 'account_category.' + value,
            value: value
          }))
        },
        sort: { isSortable: true }
      },
      {
        label: 'account',
        path: 'account.full_name',
        filter: { isNotFilterable: true },
        sort: { isSortable: false }
      },
      {
        path: 'amount',
        type: 'currency',
        filter: { isNotFilterable: true },
        sort: { isSortable: true }
      },
      {
        path: 'created_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true },
        hidden: true
      },
      {
        path: 'updated_at',
        type: 'datetime',
        filter: { type: 'date' },
        sort: { isSortable: true },
        hidden: true
      }
    ];

    return columns;
  }

  getRow(data: any): AccountingEntryRowModel | undefined {
    return data;
  }
}
